import UIBaseComponent from "../../Backflipt_UI_Components/src/ui-base-component/ui-base-component";
import React from 'react';
import {
    getAttrValueForEventFromClosest,
    getValueForPathOrDefault, setValueForPathOrDefault
} from "../../Backflipt_UI_Components/utils/generic-utils";
import ModalContainer from "../modal-container/modal-container-v2";
import {SOURCE_TYPE} from "./templates-config";
import {getJsonFromUrl, getRssFeed} from "../../service/tenant-service";
import {ERRORS} from "../../config/errors";

const R = require('ramda');

export default class BaseContentTemplate extends UIBaseComponent {

    getLoadingView() {
        return <div className="common-loading-text">Loading..</div>
    }

    beforeRender() {
        const mapping = this.props.mapping || {};
        this.state = {
            contentAttributes: mapping.contentAttributes || []
        };
        this.loadDataAsync();
        return super.beforeRender();
    }

    loadDataAsync() {
        let contentAttributes = this.state.contentAttributes || [];
        let source = (contentAttributes.sources && contentAttributes.sources[0]) || {};
        const sourceType = getValueForPathOrDefault(source, "type", "");
        if(!sourceType) return this.setState({sourceUrlData: null, dataLoaded: true});
        if(sourceType === SOURCE_TYPE.URL) {
            const url = getValueForPathOrDefault(source, "url", "");
            return getJsonFromUrl({url})
                .then(resp => this.setState({sourceUrlData: resp, dataLoaded: true}))
                .catch(resp => {
                    let errors = R.clone(this.state.errors || {});
                    let error = (resp && resp.responseJSON) || {};
                    let editTemplate = this.props.editTemplate;
                    let errorMessage = editTemplate ? ERRORS.HOMEPAGE.PREVIEW[error.code] : ERRORS.HOMEPAGE.PUBLISH[error.code];
                    setValueForPathOrDefault(errors, "url.message", errorMessage);
                    return this.setState({dataLoaded: true, errors})
                })
        }
        else {
            const feedNames = getValueForPathOrDefault(source, "sourceId", []);
            return getRssFeed(feedNames)
                .then(resp => this.setState({sourceUrlData: resp, dataLoaded: true}))
                .catch(_ => this.setState({dataLoaded: true}))

        }
    }

    getTransformedDataFromAttrMapping(attrMappings, sourceUrlData) {
        return R.map(data => {
            return R.map(value => data[value], attrMappings);
        }, sourceUrlData);
    }

    showEditContentTemplatePopupView(contentAttributes, sourceUrlData, configurer) {
        return ModalContainer.addModalComponent('edit-template', configurer, {contentAttributes: contentAttributes, sourceUrlData: sourceUrlData, onSave: this.saveContentAttributes})
    }

    saveContentAttributes = (contentAttributes) => {

        let mapping = R.clone(this.props.mapping);
        mapping.contentAttributes = contentAttributes;
        return this.props.onSaveMapping(mapping)
            .then(_ => this.setState({contentAttributes}))
            .then(_ => ModalContainer.removeModal('edit-template'));
    };

    onImageLoadError = (e) => {
        let feedId = getAttrValueForEventFromClosest(e, 'data-feed-id');
        let imgLabel = getAttrValueForEventFromClosest(e, 'data-img-label');
        let prop = feedId + imgLabel;
        let imageLoadError = R.clone(this.state.imageLoadError || {});
        imageLoadError[prop] = true;
        return this.setState({imageLoadError: imageLoadError});
    };

}