import UIBaseComponent from "../../Backflipt_UI_Components/src/ui-base-component/ui-base-component";
import $ from 'jquery';
import Bluebird from 'bluebird';
import {
    getAttrValueFromClosest,
    getValueForPathOrDefault,
    mapIndexed
} from "../../Backflipt_UI_Components/utils/generic-utils";
import React from "react";
import {getLayoutById} from "../../service/tenant-service";

const R = require("ramda");
export default class HomePreview extends UIBaseComponent {

    beforeRender() {
        this.position = 0;
        if(!this.contentContainers) this.contentContainers = {};
        if(!this.contentMappings) this.contentMappings = {};
        if(!this.contentTemplates) this.contentTemplates = {};
        this.loadDataAsync();
        return super.beforeRender();
    }

    loadDataAsync() {
        let selectedLayoutId = this.props.selectedLayoutId;
        if(selectedLayoutId)
            return getLayoutById(selectedLayoutId)
                .then(pageLayout => this.setState({pageLayout, dataLoaded: true}));
    }

    getView() {
        let selectedLayoutId = this.props.selectedLayoutId;
        return super.getView()
            .then(_ => {

                if(!this.state.dataLoaded) return this.loadingView;

                const previewView = this.getPreviewView();

                return (
                    <div data-id={selectedLayoutId} className="layout custom-scrolling">
                        {previewView}
                    </div>
                );

            });
    };

    getPreviewView = () => {
        this.position = 0;
        let pageLayout = this.state.pageLayout || {};
        //let selectedLayoutId = getValueForPathOrDefault(pageLayout, "layoutId");
        //let selectedLayoutId = pageLayout.layoutId;
        //let layout = this.getLayoutById(selectedLayoutId) || {};
       //let layout = R.find(layout => layout.id === selectedLayoutId, this.state.layouts || {});
        //let config = layout ? layout.config : {};

        let config = pageLayout.config || {};
        if(config) return this.getHtmlFromConfig(config.cells);
    };

    getHtmlFromConfig(contents) {
        if(!contents) return null;

        let self = this;

        return mapIndexed((content, index) => {

            let contentType = content.type || "ROW";

            let childContents = self.getHtmlFromConfig(content.content);

            return (contentType === "ROW") ? this.getRowDiv(index, childContents): this.getColumnDiv(index, childContents, ++this.position);

        }, contents);
    }

    getRowDiv(index, children) {
        return (
            <div data-row-index={index} className={"row cols-" +  (children.length)}>{children}</div>
        );
    }

    getColumnDiv(index, children, position) {
        return (
            <div id={position} data-position={position} data-col-index={index} className="col">{children}</div>
        );
    }

    postRenderView() {
        return super.postRenderView()
            .then(this.renderContentTemplatesInPageLayout);
    }

    renderContentTemplatesInPageLayout = () => {

        let pageLayout = this.state.pageLayout;

        let selectedLayoutId = this.props.selectedLayoutId;

        if(!pageLayout || (pageLayout.id !== selectedLayoutId)) return Promise.resolve();

        let config = R.clone(this.props.config) || {};

        let mappings = getValueForPathOrDefault(config, "mappings", []);

        //let sections = this.getSectionsOfLayout(this.state.pageLayout || {});
        let sections = [];

        this.$container.find('[data-position]').each((index, elem) => {
            const $col = $(elem);
            const position = $col.attr('data-position');
            //const rowIndex = getAttrValueFromClosest($col, 'data-position');
            sections.push(position);
        });

        const sectionsFromMappings = R.filter(x => x, R.map(m => m.contentTemplateId && m.position, mappings || []));

        const deletableCells = R.difference(sections, sectionsFromMappings);

        // let deletableCells = R.difference(sectionsFromMappings, sections);

        // deletableCells = R.isEmpty(deletableCells)? emptyCells: deletableCells;
        // const _self = this;
        const jobs = R.map(m => {
            if(m.contentTemplateId) {
                const props = {
                    editTemplate: this.props.editTemplate || false,
                    mapping: m,
                    onDrag: this.props.onDrag,
                    onDrop: this.props.onDrop,
                    onSaveMapping: this.saveMapping,
                    onDeleteMapping: this.deleteMapping
                };
                const rowIndex = m.rowIndex;
                // const colIndex = m.colIndex;
                const position = m.position;
                const key = position;

                // const contentContainer = this.$container.find(`[data-row-index="${rowIndex}"]`).find(`[data-col-index="${colIndex}"]`)[0];
                const contentContainer = this.$container.find(`[data-position="${position}"]`)[0];
                if(!contentContainer) return Promise.resolve();

                let template = this.contentTemplates[key];
                let mappingExists = R.equals(this.contentMappings[key], m);
                let containerExists = R.equals(this.contentContainers[key], contentContainer);
                if(!mappingExists || !containerExists) {
                    let {default: TemplateModule} = require(`../content-templates/${m.contentTemplateId}/content-template.js`);
                    template = new TemplateModule(contentContainer, props);
                    this.contentTemplates[key] = template;
                    this.contentContainers[key] = contentContainer;
                    this.contentMappings[key] = m;
                    return template.render();
                }
                template.props = props;
                return template.renderView();

            } else return Promise.resolve();
        }, mappings);

        return Bluebird.all(jobs)
            .then(_ => {
                return R.map(cell => {
                    const key = cell;
                    const template = this.contentTemplates[key];
                    delete this.contentMappings[key];
                    delete this.contentTemplates[key];
                    delete this.contentContainers[key];
                    if(template) return template.destroy();
                    else return Promise.resolve();
                }, deletableCells);
            })
            .then(_ => {
                return R.map(cell => {
                    const position = cell;
                    // const $contentContainer = this.$container.find(`[data-row-index="${rowIndex}"]`).find(`[data-col-index="${colIndex}"]`);
                    const $contentContainer = this.$container.find(`[data-position="${position}"]`);
                    let editTemplate = this.props.editTemplate || false;
                    let html = editTemplate? "<div class='dd-text'>Drag and Drop any content template here</div>": "";
                    return $contentContainer.html(html);
                }, deletableCells);
            });
    };

    destroy() {
        R.map(ct => ct.destroy(), R.values(this.contentTemplates || {}));
        this.contentTemplates = {};
        return super.destroy();
    }

    saveMapping = (mapping) => {
        let config = R.clone(this.props.config) || {};
        let mappings = getValueForPathOrDefault(config, "mappings", []);
        let mappingIndex = R.findIndex(m => m.position === mapping.position, mappings);
        mappings[mappingIndex] = mapping;
        config.mappings = mappings;
        return this.props.onSaveMapping(mapping);
    };

    deleteMapping = (mapping) => {
        let config = R.clone(this.props.config) || {};
        let mappings = getValueForPathOrDefault(config, "mappings", []);
        let mappingIndex = R.findIndex(m => m.position === mapping.position, mappings);
        mappings = R.remove(mappingIndex, 1, mappings);
        config.mappings = mappings;
        this.props.onDeleteMapping(mapping);
    };

}
