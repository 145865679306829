import BaseTemplateConfigurator from "../base-template-configurator";
import ContentTemplate from "./content-template";
import {ATTR_MAPPING} from "./sample-data";

const ATTRIBUTES = require('./attributes.json');


export default class Configurator extends BaseTemplateConfigurator {

    get attributes() { return ATTRIBUTES; }

    get attrMappings() {return ATTR_MAPPING;}

    StaticContentTemplate = class StaticContentTemplate extends ContentTemplate {
        loadDataAsync() {
            const props = {
                sourceUrlData: this.props.sourceUrlData,
                dataLoaded: true
            };
            return this.setState(props);
        }
    }
}
