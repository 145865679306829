import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import React from 'react'
import { ROUTES } from '../../config/app-config'
import { goToRouteOnEvent } from '../../utils/generic-utils'
import { prepareRESTURI } from '../../Backflipt_UI_Components/utils/generic-utils'

const R = require('ramda');

export default class TenantDetail extends UIBaseComponent {

    getTabsView() {

        let cr = this.props.routeAttributes.route;

        let guiTabActiveClass = cr === ROUTES.ADMIN_TENANT_DETAIL_GUI ? "active" : "";
        let appsTabActiveClass = R.startsWith(ROUTES.ADMIN_TENANT_DETAIL_APPS, cr) ? "active" : "";
        let usersTabActiveClass = cr === ROUTES.ADMIN_TENANT_DETAIL_USERS ? "active" : "";
        let userAuthTabActiveClass = cr === ROUTES.ADMIN_TENANT_DETAIL_USER_AUTH ? "active" : "";
        let feedTabActiveClass = cr === ROUTES.ADMIN_TENANT_FEED_UPLOAD ? "active" : "";
        let homepageCustomizationActiveClass = cr === ROUTES.ADMIN_TENANT_HOMEPAGE_CUSTOMIZATION ? "active" : "";

        let params = {tenant: this.props.tenantPath};

        return (
            <div className="common-tabs-wrapper">
                <div className="tabs-wrapper">
                    <span className={"common-tab " + guiTabActiveClass} data-route={prepareRESTURI(ROUTES.ADMIN_TENANT_DETAIL_GUI, params)}
                          onclick={goToRouteOnEvent}>GUI Settings</span>
                    <span className={"common-tab " + appsTabActiveClass} data-route={prepareRESTURI(ROUTES.ADMIN_TENANT_DETAIL_APPS, params)}
                          onclick={goToRouteOnEvent}>Applications </span>
                    <span className={"common-tab " + usersTabActiveClass} data-route={prepareRESTURI(ROUTES.ADMIN_TENANT_DETAIL_USERS, params)}
                          onclick={goToRouteOnEvent}>User Management </span>
                    <span className={"common-tab " + userAuthTabActiveClass} data-route={prepareRESTURI(ROUTES.ADMIN_TENANT_DETAIL_USER_AUTH, params)}
                          onclick={goToRouteOnEvent}>User Authentication </span>
                    <span className={"common-tab " + feedTabActiveClass} data-route={prepareRESTURI(ROUTES.ADMIN_TENANT_FEED_UPLOAD, params)}
                          onclick={goToRouteOnEvent}>Client Specific Feed </span>
                    <span className={"common-tab " + homepageCustomizationActiveClass} data-route={prepareRESTURI(ROUTES.ADMIN_TENANT_HOMEPAGE_CUSTOMIZATION, params)}
                          onclick={goToRouteOnEvent}>Homepage Customization </span>
                </div>
                <div className="tabs-active"/>
            </div>
        );
    }

    getView() {
        return super.getView()
            .then(_ => {

                let tenant = this.props.tenant || {};

                return (
                    <div className="customer-content-container added-clients-wrapper">
                        <div className="semi-tabs-common-wrapper">
                            <div className="common-tab-button">
                                <span className="customer-back-btn" data-route={ROUTES.ADMIN_TENANTS} onclick={goToRouteOnEvent}>
                                  <img src="assets/images/customer-back-btn.png" alt=""/>
                                </span>
                                <div className="customer-name-text">{tenant.name}</div>
                            </div>
                        </div>

                        {this.getTabsView()}

                        <div className="customer-tabs-content-wrapper">

                            {/* Client Management GUI Settings Content */}
                            <div id="tenant_detail_gui_container"/>

                            {/* Client Management Application Content */}
                            <div id="tenant_detail_apps_container"/>

                            {/* Client Management User Management Content */}
                            <div id="tenant_detail_users_container"/>

                            {/* Client Management User Authentication Content */}
                            <div id="tenant_detail_user_auth_container"/>

                            {/* Client Management Feed Upload Content */}
                            <div id="tenant_feed_upload_container"/>

                            {/* Client Homepage Customization Content */}
                            <div id="tenant_homepage_customization"/>

                        </div>
                    </div>
                );
            });
    }
}