import React from 'react'
import BaseTemplateConfigurator from "../base-template-configurator";
import ContentTemplate from "./content-template";
import {ATTR_MAPPING} from "./sample-data";
import {
    getAttrValueForEventFromClosest,
    getValueForPathOrDefault, setValueForPathOrDefault,
    VALIDATION_TYPE
} from "../../../Backflipt_UI_Components/utils/generic-utils";
import {RSS_FEEDS, SOURCE_TYPE} from "../templates-config";
import {getRssFeed} from "../../../service/tenant-service";
const ATTRIBUTES = require('./attributes.json');

const R = require('ramda');

export default class Configurator extends BaseTemplateConfigurator {

    get attributes() {
        return ATTRIBUTES;
    }

    get attrMappings() {return ATTR_MAPPING;}

    StaticContentTemplate = class StaticContentTemplate extends ContentTemplate {
        loadDataAsync() {
            return this.setState({sourceUrlData: this.props.sourceUrlData, dataLoaded: true});
        }
    };

    getView() {
        return super.getView()
            .then(_ => {

                let contentAttributes = this.state.contentAttributes || {};

                let source = (contentAttributes.sources && contentAttributes.sources[0]) || {};

                let sourceUrlData = this.state.sourceUrlData || {};

                let sourceUrl = getValueForPathOrDefault(source, "url", "");

                let sourceType = getValueForPathOrDefault(source, "type", SOURCE_TYPE.URL);

                let sourceId = getValueForPathOrDefault(source, "sourceId", "");

                let errors = this.state.errors || {};

                let sourceUrlError = !!getValueForPathOrDefault(errors, "sources.0.url", "");

                let sourceUrlErrorText = getValueForPathOrDefault(errors, "sources.0.url.message", "Please enter valid source url");

                return (
                    <div data-form-id="configure-content-template" className="ui dimmer modals page transition visible active" style="display: flex !important;">
                        <div
                            className="ui modal common-popup-wrapper home-page-common-popup-wrapper transition visible active scrolling"
                            id="commonPopupModal" style="display: block !important;">
                            <div className="content edit-content-template">
                                <div className="header">Edit Content Template</div>
                                <div className="description">
                                    <div className="home-page-common-popup">
                                        <div className="hp-common-popup-left-wrapper">
                                            <div className="popup-common-div-wrapper">
                                                <h4>Panel name</h4>
                                                <input data-key="name" data-required="true"
                                                       data-validation-type={VALIDATION_TYPE.NON_EMPTY} type="text"
                                                       className={"common-popup-input " + (errors.name ? "validation-error" : "")}
                                                       value={contentAttributes.name || ''} onkeyup={this.onDataChange}
                                                       oninput={this.onInputChange} autofocus={true}/>
                                                <p className={"error-text " + (errors.name ? "" : "hide")}>{(errors.name && errors.name.message) || "Name cannot be empty"}</p>
                                            </div>
                                            <input type="hidden" data-key="sources.0.type" value={sourceType}/>
                                            <div className="common-radio-wrapper">
                                                <div className="ui radio checkbox">
                                                    <input type="radio" id="feed-url" onclick={this.toggleFeedType} data-source-type={SOURCE_TYPE.PRE_CONFIGURED_FEED} checked={sourceType === SOURCE_TYPE.PRE_CONFIGURED_FEED ? "checked" : ""} />
                                                        <label>Feed URL</label>
                                                </div>
                                                <div className="ui radio checkbox">
                                                    <input id="upload-json" type="radio" onclick={this.toggleFeedType} data-source-type={SOURCE_TYPE.URL} checked={sourceType === SOURCE_TYPE.URL ? "checked" : ""}/>
                                                        <label>Upload JSON</label>
                                                </div>
                                            </div>
                                            <div className={"popup-common-div-wrapper json-url-input-wrapper" + (sourceType === SOURCE_TYPE.URL? "": " hide")}>
                                                <h4>JSON URL</h4>
                                                {sourceType === SOURCE_TYPE.URL?(<input data-key="sources.0.url" type="text" data-required="true"
                                                       data-validation-type={VALIDATION_TYPE.URL}
                                                       className={"common-popup-input " + (sourceUrlError ? "validation-error" : "")}
                                                       value={sourceUrl || ''}
                                                       onkeyup={this.onDataChange}
                                                       oninput={this.onInputChange}/>): ""}
                                                <span className="popup-upload-json" onclick={this.uploadJSON}>Upload JSON</span>
                                                <p className={"error-text " + (sourceType === SOURCE_TYPE.URL && sourceUrlError ? "" : "hide")}>{sourceUrlErrorText}</p>
                                                <p className={"error-text " + (sourceType === SOURCE_TYPE.PRE_CONFIGURED_FEED && sourceUrlError ? "" : "hide")}>{sourceUrlErrorText}</p>
                                            </div>
                                            <div className={sourceType === SOURCE_TYPE.PRE_CONFIGURED_FEED? "": "hide"}>
                                                {this.getFeedDropdownView(R.values(RSS_FEEDS), sourceId, "sources.0.sourceId", this.onFeedChange)}
                                            </div>
                                            <p className={"error-text " + (errors.sourceId ? "" : "hide")}>{"Please select a feed source"}</p>
                                            <div className={"common-key-mapping-wrapper" + ((sourceUrlData && !R.isEmpty(sourceUrlData))?"": " hide")}>
                                                <div className="popup-common-div-wrapper">
                                                    <h4>Configure Keys</h4>
                                                    <div className="key-mapping-details-wrapper">
                                                        {this.getKeyValueMappingView(sourceUrlData)}
                                                    </div>
                                                </div>
                                            </div>
                                            <p className={"error-text " + (errors.keyValueMapping ? "" : "hide")}>{(errors.keyValueMapping && errors.keyValueMapping.message) || "Please configure at least one key"}</p>
                                        </div>
                                        <div className="hp-common-popup-right-wrapper">
                                            <div className="popup-common-div-wrapper">
                                                <h4>Preview</h4>
                                            </div>
                                            {this.getTemplatePreviewView()}
                                        </div>
                                    </div>
                                </div>
                                <div className="actions">
                                    <div id="addOrUpdateTenantAction"
                                         className={"ui primary button buttons-primary-text buttons-primary-background "}
                                         onclick={this.saveTemplateChanges}>Save
                                    </div>
                                    <div
                                        className="ui cancel button buttons-secondary-text buttons-secondary-background"
                                        onclick={this.cancelTemplateChanges}>Cancel
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
    }

    getFeedDropdownView(dropdownList, selectedValue, inputDataKey, onChangeHandler) {
        if(onChangeHandler) this.ddOnChangeHandlers = R.assoc(inputDataKey, onChangeHandler, this.ddOnChangeHandlers || {});
        let views = R.map(value => {
            return (
                <div className={"item " + (selectedValue === value ? "active selected" : "")} data-value={value}>{value}</div>
            );
        }, dropdownList);
        return (
            <div key={new Date().getTime()} data-dd-controller={onChangeHandler?inputDataKey: ""} className={"ui selection dropdown json-url-dropdwn-wrapper" + (this.state.loading? " disabled": "")} tabIndex={0}>
                <input id={"dd-input-" + inputDataKey} type="hidden" data-key={inputDataKey} value={selectedValue}/>
                <i className="dropdown icon"></i>
                <div className="default text">{selectedValue ? selectedValue : "Select"}</div>
                <div className="menu" tabIndex={-1}>
                    <div className={"item " + (selectedValue ? "" : "active selected")} data-value="">Select</div>
                    {views}
                </div>
            </div>
        )
    }

    toggleFeedType = (e) => {
        let sourceType = getAttrValueForEventFromClosest(e, 'data-source-type');
        let contentAttributes = R.clone(this.state.contentAttributes || {});
        setValueForPathOrDefault(contentAttributes, "sources.0.attributeMappings", {});
        setValueForPathOrDefault(contentAttributes, "sources.0.type", sourceType);
        let sourceId = getValueForPathOrDefault(this.state.contentAttributes, "sources.0.sourceId", "");
        if(sourceType === SOURCE_TYPE.PRE_CONFIGURED_FEED) {
            return this.setState({contentAttributes, loading: true, errors: null})
                .then(_ => getRssFeed([sourceId]))
                .then(resp => this.setState({sourceUrlData: resp, selected: null, loading: null, errors: null}))
                .catch(_ => this.setState({sourceUrlData: null, loading: null, errors: null}))
        }
        return this.setState({contentAttributes, sourceUrlData: null, sourceType, errors: null});
    };

    onFeedChange = (value, text, $selectedItem) => {
        let errors = R.clone(this.state.errors || {});
        let contentAttributes = R.clone(this.state.contentAttributes || {});
        if(!value) setValueForPathOrDefault(contentAttributes, "sources.0.attributeMappings", {});
        else delete errors.sourceId;
        setValueForPathOrDefault(contentAttributes, "sources.0.sourceId", value);
        return this.setState({contentAttributes, loading: true, errors})
            .then(_ => getRssFeed([value]))
            .then(resp => this.setState({sourceUrlData: resp, selected: null, loading: null}))
            .catch(_ => this.setState({sourceUrlData: null, loading: null}))
    };

}
