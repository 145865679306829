import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import React from 'react'
import { ROUTES } from '../../config/app-config'
import {gotoRoute} from '../../utils/generic-utils'
import { getToggleActiveIconUrl, getToggleInActiveIconUrl } from '../../utils/domain-utils'
import {getProfile} from "../../dao/user-profile-dao";
import {updateProfile} from "../../service/user-profile-service";

export default class UserHome extends UIBaseComponent {

    getView () {
        return super.getView()
            .then(_ => {

                let toggleActiveIconUrl = getToggleActiveIconUrl();

                let toggleInActiveIconUrl = getToggleInActiveIconUrl();

                let appsIconUrl = this.props.routeAttributes.route === ROUTES.APPS_ZOOM ? toggleInActiveIconUrl : toggleActiveIconUrl;

                //let appsRoute = this.props.routeAttributes.route === ROUTES.APPS_ZOOM ? ROUTES.USER_HOME : ROUTES.APPS_ZOOM;

                return (
                    <div className="ics-landing-body-wrapper">
                        <div className={"admin-reg-flow-container " + (this.props.routeAttributes.route === ROUTES.APPS_ZOOM ? "only-user-apps" : "")}>
                            <div id="user-apps" className="common-tabs-container apps-tab"/>
                            <div className="ics-announcements-main-wrapper">
                                <div className="ics-announcements-toggle-wrapper">
                                    <div className="ics-announcements-toggle-icons">
                                        <div className="active-toggle-wrapper">
                                            <img src={appsIconUrl} onclick={this.toggleRSBFeed} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="ics-announcements-content-wrapper">
                                    {/*<div id="tenant-stats"/>*/}
                                    <div id="common-feed" className="first-section"/>
                                    <div id="tenant-feed" className="second-section" />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
    }

    toggleRSBFeed = () => {
        let showRSBFeed = this.props.routeAttributes.route === ROUTES.APPS_ZOOM;
        let route = showRSBFeed? ROUTES.USER_HOME: ROUTES.APPS_ZOOM;
        let profile = getProfile() || {};
        let attributes = profile.attributes || {};
        let preferences = attributes.preferences || {};
        preferences.showRSBFeed = showRSBFeed;
        attributes.preferences = preferences;
        profile.attributes = attributes;
        gotoRoute(route);
        return updateProfile(profile);
    }
}