import { DATA_TYPE, VALIDATION_TYPE } from '../Backflipt_UI_Components/utils/generic-utils'
import React from 'react'

export let getAddOrUpdateTenantPopupView = (tenant, loading, errors = {}, onValueModified, onDataModified, onYesClicked, onNoClicked) => {

    if(!tenant || !onDataModified || !onYesClicked || !onNoClicked) throw Error("tenant, onDataModified, onYesClicked & onNoClicked are mandatory");

    let newTenant = !tenant.id;

    return (
        <div data-form-id="add-new-tenant"
             className="ui modal common-popup-wrapper add-client-popup scrolling transition visible active"
             style="display: block !important;">
            <div className="content">
                <div className="header">{newTenant ? "Add" : "Edit"} Client</div>
                <div className="description">
                    <table className="ui table">
                        <input type="hidden" data-key="id" data-type={DATA_TYPE.NUMBER} value={tenant.id}/>
                        <tbody>
                        <tr>
                            <td>Client Name <span className="mandatory-start">*</span></td>
                            <td>
                                <input data-key="name" data-required="true"
                                       data-validation-type={VALIDATION_TYPE.NON_EMPTY} type="text"
                                       className={"common-popup-input " + (errors.name ? "validation-error" : "")}
                                       value={tenant.name || ''} onkeyup={onDataModified} oninput={onValueModified} autofocus={true}/>
                                <p className={"error-text " + (errors.name ? "" : "hide")}>{(errors.name && errors.name.message) || "Client Name cannot be empty"}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>Address</td>
                            <td><input data-key="address" type="text" className="common-popup-input"
                                       value={tenant.address || ''} onkeyup={onDataModified}/></td>
                        </tr>
                        <tr>
                            <td>Website <span className="mandatory-start hide">*</span></td>
                            <td>
                                <input data-key="website" type="text" data-validation-type={VALIDATION_TYPE.URL}
                                       className={"common-popup-input " + (errors.website ? "validation-error" : "")}
                                       value={tenant.website || ''} onkeyup={onDataModified} oninput={onValueModified}/>
                                <p className={"error-text " + (errors.website ? "" : "hide")}>Website must be a
                                    valid Url</p>
                            </td>
                        </tr>
                        <tr>
                            <td>Contact Name</td>
                            <td><input data-key="contactName" type="text" className="common-popup-input"
                                       value={tenant.contactName || ''} onkeyup={onDataModified}/>
                            </td>
                        </tr>
                        <tr>
                            <td>Contact Email <span className="mandatory-start">*</span></td>
                            <td>
                                <input data-key="contactEmailId" type="text" data-required="true"
                                       data-validation-type={VALIDATION_TYPE.EMAIL_ID}
                                       className={"common-popup-input " + (errors.contactEmailId ? "validation-error" : "")}
                                       value={tenant.contactEmailId || ''} onkeyup={onDataModified} oninput={onValueModified}/>
                                <p className={"error-text " + (errors.contactEmailId ? "" : "hide")}>Contact Email
                                    Id must be a valid Email Id</p>
                                <p className={"error-text " + (errors.serverError ? "" : "hide")}>Something went wrong! Please try again.</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="actions">
                    <div id="addOrUpdateTenantAction" className={"ui primary button buttons-primary-text buttons-primary-background " + (loading ? "loading disabled" : "")}
                         onclick={onYesClicked}>{newTenant ? "Add" : "Update"} Client
                    </div>
                    <div className="ui cancel button buttons-secondary-text buttons-secondary-background" onclick={onNoClicked}>Cancel</div>
                </div>
            </div>
        </div>
    );
};