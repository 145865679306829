export const SOURCE_URL_DATA = [
    {
        "firstName": "Tim",
        "lastName": "Kelly",
        "emailId": "kelly@acme.com",
        "role": "Director",
        "profilePic": "assets/images/default-team-members-profile-pic.png"
    },
    {
        "firstName": "John",
        "lastName": "Smith",
        "emailId": "john@acme.com",
        "role": "Director",
        "profilePic": "assets/images/default-team-members-profile-pic.png"
    },
    {
        "firstName": "Jim",
        "lastName": "Karner",
        "emailId": "jim@acme.com",
        "role": "Director",
        "profilePic": "assets/images/default-team-members-profile-pic.png"
    },
    {
        "firstName": "Coby",
        "lastName": "Fuller",
        "emailId": "coby@acme.com",
        "role": "Director",
        "profilePic": "assets/images/default-team-members-profile-pic.png"
    },
    {
        "firstName": "Clark",
        "lastName": "Thomas",
        "emailId": "clark@acme.com",
        "role": "Director",
        "profilePic": "assets/images/default-team-members-profile-pic.png"
    }
];

export const ATTR_MAPPING = {
    "firstName": "firstName",
    "lastName":"lastName",
    "emailId": "emailId",
    "role": "role",
    "profilePic": "profilePic"
};