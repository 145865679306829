import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import React from 'react'
import {adminSignIn} from '../../service/user-profile-service'
import EventsHandler from '../../event-handler'
import Events from '../../config/events'
import {
    getClosestForEventWithAttribute,
    serializeForm,
    validateForm,
    VALIDATION_TYPE
} from '../../Backflipt_UI_Components/utils/generic-utils'
import { ROUTES } from '../../config/app-config'
import $ from 'jquery';
import { getLogoUrl } from '../../utils/domain-utils'

const R = require('ramda');

export default class AdminSignIn extends UIBaseComponent {

    getView() {
        return super.getView()
            .then(_ => {
                let formLogo = getLogoUrl();
                let errors = this.state.errors || {};
                return (
                    <div data-form-id="signin-form" className="ics-form-wrapper ics-admin-sign-in-wrapper">
                        <div className="ics-common-wrapper">
                            <div className="form-common-header-wrapper">
                                <img src={formLogo} className="ics-form-logo" alt=""/>
                                <span className="ics-form-header-pipeline"></span>
                                <span className="form-header-text"><h3>Admin Portal</h3></span>
                            </div>
                            <div className="ics-common-body-wrapper">
                                <div className="common-input-label-wrapper">
                                    <div className="ui form">
                                        <div className="inline field">
                                            <label>Work Email<span className="mandatory-start">*</span></label>
                                            <input id="user-name" type="text" data-key="userName" data-required="true" data-validation-type={VALIDATION_TYPE.EMAIL_ID} onkeyup={this.onDataChange} className={errors.userName ? "validation-error" : ""} autofocus={true} oninput={this.onValueChange}/>
                                            <p className={"error-text " + (errors.userName ? "" : "hide")}>{(errors.userName && errors.userName.message) || "Please provide a valid email address!"}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="common-input-label-wrapper">
                                    <div className="ui form">
                                        <div className="inline field">
                                            <label>Password<span className="mandatory-start">*</span></label>
                                            <input type="password" data-key="password" data-required="true" data-validation-type={VALIDATION_TYPE.NON_EMPTY} onkeyup={this.onDataChange} className={errors.password ? "validation-error" : ""} oninput={this.onValueChange} />
                                            <p className={"error-text " + (errors.password ? "" : "hide")}>{(errors.password && errors.password.message) || "Please enter your Password."}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="common-form-btn-wrapper">
                                    <button id="login-action" className={"ui right labeled icon button " + (this.state.loading? "loading disabled": "")} onclick={this.signIn}>
                                        <i className="right arrow icon"></i>
                                        Continue
                                    </button>
                                </div>
                            </div>
                            <div className="common-form-divider">
                                <div className="ui divider"></div>
                            </div>
                            <div className="form-common-bottom-wrapper">
                                <div className="form-bottom-right-wrapper">
                                    <span className="bottom-common-anchor-tag">
                                        <a href={"#" + ROUTES.FORGOT_PASSWORD}>Forgot Password?</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
    };

    postRenderView () {
        return super.postRenderView()
            .then(this.applyAutoFocus)
            ;
    }

    applyAutoFocus = () => this.$container.find('[autofocus]').focus();

    onDataChange = (e) => {
        if(e.keyCode === 13) {
            e.preventDefault();
            getClosestForEventWithAttribute(e, 'data-form-id')
                .find('#login-action')
                .click();
        }
    };

    onValueChange = (e) => {
        return this.setState({errors: null})
            .then(_ => $(e.target).focus());
    };

    signIn = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let data = serializeForm($form);
        let errors = validateForm($form);
        data.userName = data.userName.toLowerCase().trim();
        if(R.isEmpty(data.userName))  errors.userName = {...errors.userName,message:"Please enter your work email."};
        if(errors && !R.isEmpty(errors)) return this.setState({errors});
        return this.setState({loading: true})
            .then(_ => adminSignIn(data))
            .then(success => {
                this.setState({loading: false});
                if(success) return EventsHandler.push({name: Events.GO_TO_HOME});
                let errors = {userName: {message: "Login failed. Please enter valid work email and password."}};
                return this.setState({errors});
            })
            .catch(resp => this.setState({loading: false, errors: {password: {message: "Something went wrong! Please try again."}}}));
    };

}