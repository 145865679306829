import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import EventsHandler from '../../event-handler'
import Events from '../../config/events'
import {
    convertToMailToUrl, getAttrValueForEventFromClosest,
    getClosestForEventWithAttribute,
    prepareRESTURI,
    serializeForm,
    validateForm,
    VALIDATION_TYPE
} from '../../Backflipt_UI_Components/utils/generic-utils'
import {emailCheck, verifyUserNameExists} from '../../service/user-profile-service'
import React from 'react'
import {ROUTES} from '../../config/app-config'
import $ from "jquery";
import {getAdminMail, getLogoUrl} from '../../utils/domain-utils'
import {goToRouteOnEvent} from "../../utils/generic-utils";
import {ERRORS} from "../../config/errors";

const R = require('ramda');

export default class ForgotPassword extends UIBaseComponent {

    beforeRender() {
        this.state = {
            stage: 1
        };
        return super.beforeRender();
    }

    showTenants() {
        let tenantsInfo = this.state.tenantsInfo || {};
        let tenantId = this.state.tenantId || tenantsInfo[0].id;
        return R.map(tenant =>
                <div className={"item " + (tenantId ===  tenant.id? "active" : "")} data-id={tenant.id}
                     onclick={this.onTenantChange}>{tenant.name}</div>,
            tenantsInfo
        )
    }

    onTenantChange = (e) => {
        let key = getAttrValueForEventFromClosest(e, 'data-id');
        let selectedTenantId = parseInt(key);
        let tenantId = this.state.tenantId || "";
        if(selectedTenantId !== tenantId) {
            setTimeout(() => {
                return this.setState({tenantId: selectedTenantId})
                    .then(_ => this.$container.find('.ui.dropdown').dropdown());
            }, 100);
        }
    };

    getTenantDropdownView() {
        let errors = this.state.errors || {};

        let adminMail = getAdminMail();
        let mailLink = convertToMailToUrl(adminMail);

        let formLogo = getLogoUrl();
        let tenantsInfo = this.state.tenantsInfo || {};
        let tenantId = this.state.tenantId || tenantsInfo[0].id;
        return (

            <div data-form-id="forgot-password" className="ics-form-wrapper ics-admin-forgot-password-wrapper select-client-wrapper ">
                <div className="ics-common-wrapper">
                    <div className="form-common-header-wrapper">
                        <img src={formLogo} className="ics-form-logo" alt=""/>
                        <span className="ics-form-header-pipeline"></span>
                        <img src={formLogo} className="ics360-form-logo hide" alt=""/>
                        <span className="ics-form-header-pipeline hide"></span>
                        <span className="form-header-text"> <h3>Forgot Password</h3></span>
                    </div>
                    <div className="ics-common-body-wrapper">
                        <div className="common-input-label-wrapper">
                            <div className="ui form">
                                <div className="inline field">
                                    <label>{"Select client for " + (this.state.emailId)}</label>
                                    <input type="hidden" data-key="emailId" value={this.state.emailId}/>
                                    <div key={new Date().getTime()} className="ui dropdown item common-popup-dropdown">
                                        {R.find(R.propEq('id', tenantId), tenantsInfo).name}
                                        <input type="hidden" data-key="tenantId" value={tenantId}/>
                                        <i className ="dropdown icon"/>
                                        <div className="menu">
                                            {this.showTenants()}
                                        </div>
                                    </div>
                                    <p className={"error-text " + (errors.admin ? "" : "hide")}>Your account is disabled! Please contact your administrator at <a href={mailLink}>{adminMail}</a></p>
                                    <p className={"error-text " + (errors.emailId ? "" : "hide")}>{(errors.emailId && errors.emailId.message) || "Please enter valid work email!"} </p>
                                </div>
                            </div>
                        </div>
                        <div className="common-form-btn-wrapper">
                            <button id="back-button"
                                    className="ui left labeled icon button"
                                    onclick={this.goBack}>
                                <i className="left arrow icon"/>
                                Back
                            </button>
                            <button id="submit-button" className={"ui right labeled icon button " + (this.state.loading? "loading disabled" : "")} onclick={this.sendLink}>
                                <i className="right arrow icon"></i>
                                Send Link
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getEmailIdView() {
        let errors = this.state.errors || {};

        let adminMail = getAdminMail();
        let mailLink = convertToMailToUrl(adminMail);

        let formLogo = getLogoUrl();
        return (

            <div data-form-id="forgot-password" className="ics-form-wrapper ics-admin-forgot-password-wrapper">
                <div className="ics-common-wrapper">
                    <div className="form-common-header-wrapper">
                        <img src={formLogo} className="ics-form-logo" alt=""/>
                        <span className="ics-form-header-pipeline"></span>
                        <img src={formLogo} className="ics360-form-logo hide" alt=""/>
                        <span className="ics-form-header-pipeline hide"></span>
                        <span className="form-header-text"> <h3>Forgot Password</h3></span>
                    </div>
                    <div className="ics-common-body-wrapper">
                        <h3 className="form-secondary-header-text">Please enter registered email account, we
                            will send you a password reset link</h3>
                        <div className="common-input-label-wrapper">
                            <div className="ui form">
                                <div className="inline field">
                                    <label>Work Email</label>
                                    <input data-key="emailId" type="text" data-required="true"
                                           data-validation-type={VALIDATION_TYPE.EMAIL_ID}
                                           className={"common-popup-input " + (errors.emailId ? "validation-error" : "")}
                                           onkeyup={this.onDataChange} autofocus={true} oninput={this.onValueChange}/>
                                    <p className={"error-text " + (errors.admin ? "" : "hide")}>Your account is disabled! Please contact your administrator at <a href={mailLink}>{adminMail}</a></p>
                                    <p className={"error-text " + (errors.emailId ? "" : "hide")}>{(errors.emailId && errors.emailId.message) || "Please enter valid work email!"} </p>
                                </div>
                            </div>
                        </div>
                        <div className="common-form-btn-wrapper">
                            <button id="back-button"
                                    className="ui left labeled icon button"
                                    data-route={ROUTES.SIGN_IN}
                                    onclick={goToRouteOnEvent}>
                                <i className="left arrow icon"/>
                                Back
                            </button>
                            <button id="submit-button" className={"ui right labeled icon button " + (this.state.loading? "loading disabled" : "")} onclick={this.sendLink}>
                                <i className="right arrow icon"></i>
                                Send Link
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    goBack = (e) => {
        return this.setState({stage: 1, tenantsInfo: null, errors: null});
    };

    getStageView() {
        if(this.state.stage === 1) return this.getEmailIdView();
        else return this.getTenantDropdownView();
    };

    getView() {
        return super.getView()
            .then(_ => {
                return this.getStageView();
            });
    };


    onDataChange = (e) => {
        if(e.keyCode === 13) {
            e.preventDefault();
            getClosestForEventWithAttribute(e, 'data-form-id')
                .find('#submit-button')
                .click();
        }
    };

    sendLink = (e) => {
        let uri = window.location.href.replace(window.location.hash, "");
        let resetPasswordUrl = prepareRESTURI(uri + "#" + ROUTES.RESET_PASSWORD, {token: "${token}"});
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let data = serializeForm($form);
        let errors = validateForm($form);
        data.emailId = data.emailId.toLowerCase().trim();
        if(this.state.stage === 1) {
         if(R.isEmpty(data.emailId))  errors.emailId = {...errors.emailId,message:"Please enter your work email."};
         if (errors && !R.isEmpty(errors)) return this.setState({errors});
         return this.setState({emailId: data.emailId, loading: true, errors: null})
             .then(_ => verifyUserNameExists(data.emailId))
             .then(resp => {

                 // For admin
                 if(window.env.adminApp) {
                     data = {...data, clientPageUrl: resetPasswordUrl}
                       return emailCheck(data)
                       .then(resp => {
                             if (resp.success) return EventsHandler.push({name: Events.GO_TO_ROUTE, route: ROUTES.VERIFY_EMAIL});
                             let errors = {emailId: {message: "No user exists with this email!"}};
                             return this.setState({errors, loading: false});
                         }
                       );
                 }

                 // For tenant users
                 let tenantsInfo = resp.tenantsInfo;
                 if(tenantsInfo.length === 1) {
                     let tenantId = tenantsInfo[0].id;
                     data = {...data, tenantId, clientPageUrl: resetPasswordUrl};
                     return this.setState({tenantsInfo})
                         .then(_ => emailCheck(data))
                         .then(resp => {
                                 if (resp.success) return EventsHandler.push({name: Events.GO_TO_ROUTE, route: ROUTES.VERIFY_EMAIL});
                                 let errors = {emailId: {message: "No user exists with this email!"}};
                                 return this.setState({errors, loading: false});
                             }
                         );
                 }
                 return this.setState({stage: 2, showTenants: true, tenantsInfo, loading: false})
             })
             .catch(resp => {
                 let error = (resp && resp.responseJSON) || {};
                 error.message = ERRORS.USER_SIGN_IN[error.code] || ERRORS.COMMON;
                 if(ERRORS.CONTACT.ADMIN.includes(error.code))
                     return this.setState({loading: false, errors: {admin: true}});
                 return this.setState({loading: false, errors: {emailId: error}})
             });
         }
         else {
            data = {...data, clientPageUrl: resetPasswordUrl};
            if (errors && !R.isEmpty(errors)) return this.setState({errors});
            return this.setState({loading: true, errors: null})
                 .then(_ => emailCheck(data))
                 .then(resp => {
                         if (resp.success) return EventsHandler.push({name: Events.GO_TO_ROUTE, route: ROUTES.VERIFY_EMAIL});
                         let errors = {emailId: {message: "No user exists with this email!"}};
                         return this.setState({errors, loading: false});
                     }
                 )
                .catch(resp => {
                    let error = (resp && resp.responseJSON) || {};
                    error.message = ERRORS.USER_SIGN_IN[error.code] || ERRORS.COMMON;
                    if(ERRORS.CONTACT.ADMIN.includes(error.code))
                        return this.setState({loading: false, errors: {admin: true}});
                    return this.setState({loading: false, errors: {emailId: error}})
                });

         }
    };

    postRenderView () {
        return super.postRenderView()
            .then(this.applyAutoFocus)
            .then(_ => this.$container.find('.ui.dropdown').dropdown());
    }

    onValueChange = (e) => {
        return this.setState({errors: null})
            .then(_ => $(e.target).focus());
    };

    applyAutoFocus = () => this.$container.find('[autofocus]').focus();
}