import React from 'react';
import {getValueForPathOrDefault, mapIndexed} from "../../../Backflipt_UI_Components/utils/generic-utils";
import {ATTR_MAPPING, SOURCE_URL_DATA} from "./sample-data";
import Configurator from "./configurator";
import BaseContentTemplate from "../base-content-template";
import {DEFAULT_IMAGE} from "../templates-config";
import ModalContainer from "../../modal-container/modal-container-v2";

const R = require('ramda');

export default class ContentTemplate extends BaseContentTemplate {

    getView() {
        return super.getView()
            .then(_ => {

                if(!this.state.dataLoaded) return this.getLoadingView();

                const contentAttributes = this.state.contentAttributes || {};

                let source = (contentAttributes.sources && contentAttributes.sources[0]) || {};

                let attributeMappings = source.attributeMappings || {};

                attributeMappings = R.isEmpty(attributeMappings)? ATTR_MAPPING : attributeMappings;

                let sourceUrlData = this.state.sourceUrlData || SOURCE_URL_DATA;

                let teamMembersData = this.getTransformedDataFromAttrMapping(attributeMappings, sourceUrlData);

                let errors = this.state.errors || {};

                let sourceUrlError = !!getValueForPathOrDefault(errors, "url", "");

                let sourceUrlErrorText = getValueForPathOrDefault(errors, "url.message", "");

                teamMembersData =
                    mapIndexed((feed, index) => {
                        feed.id = index;
                        return feed;
                    }, teamMembersData);

                let unMappedData = this.getUnMappedData(attributeMappings, sourceUrlData);

                return (
                    <div data-template={"team-members"} className="template team-wrapper custom-scrolling" draggable={true} ondragstart={this.props.onDrag}>
                        <div className="template-wrapper common-white-bg custom-scrolling">
                        <div className="common-header-actions-wrapper">
                            <h2>{contentAttributes.name || "Team Members"}</h2>
                            <div className={"common-action-btns-wrapper" + (this.props.editTemplate ? "" : " hide")}>
                     <span className="common-edit-icon">
                     <img onclick={() => this.showEditContentTemplatePopupView(contentAttributes, this.state.sourceUrlData, Configurator)} src="assets/images/common-edit-icon.png" alt=""/>
                     </span>
                                <span className="common-delete-icon">
                     <img onclick={() => this.props.onDeleteMapping(this.props.mapping)} src="assets/images/common-delete-icon.png" alt=""/>
                     </span>
                            </div>
                        </div>
                        <div className="template-content custom-scrolling">
                            {sourceUrlError?<div className="hp-error-text">{sourceUrlErrorText}</div>: (mapIndexed((t, index) => this.getTeamMembersView(t, true, unMappedData[index]), teamMembersData))}
                        </div>
                        </div>
                    </div>
                );
            });
    }

    getTeamMembersView(data, showAddInfo = false, unMappedData) {

        let imageLoadError = this.state.imageLoadError || {};
        let profilePicUrl = imageLoadError[data.id + "profile"] ? DEFAULT_IMAGE.TEAM_MEMBERS : data.profilePic;

        let firstName = (data.firstName) ? data.firstName : "";
        let lastName = (data.lastName) ? data.lastName : "";
        let role = (data.role) ? data.role : "";
        let emailId = (data.emailId) ? data.emailId : "";



        return (
                    <div data-feed-id={data.id} className="common-team-member-wrapper">
                        <img data-img-label="profile" className={"team-member-pic " + (profilePicUrl ? "" : " invisible")}
                             src={profilePicUrl} onerror={this.onImageLoadError} alt=""/>
                            <div className="team-member-details-wrapper">
                                <h4 className={"common-card-header-text" + (firstName || lastName ? "" : " invisible")}>{firstName + " " + lastName}</h4>
                                <p className={"common-secodary-text" + (role ? "" : " invisible")}>{role || "Role"}</p>
                                <p className={"common-detail-text" + (emailId ? "" : " invisible")}>{emailId || "Email"}</p>
                            </div>
                        <i className={"info circle icon member-info" + (showAddInfo?"": " hide")} id="memberDetailPopup" onclick={() => this.getMoreUserInfoView(data, unMappedData)}></i>
                    </div>
        )
    }

    getUnMappedData = (attributeMappings, sourceUrlData) => {
        let attributeValues = R.values(attributeMappings);
        return R.map(urlData => R.invertObj(R.filter(value => !R.includes(value, attributeValues), R.invertObj(urlData))), sourceUrlData);
    };

    getMoreUserInfoView = (data, unMappedData) => {
        let view = this.userInfoPopup(data, unMappedData);
        return ModalContainer.addModalView('user-info-popup', view);
    };

    closeMoreUserInfoPopup()  {
        return ModalContainer.removeModal('user-info-popup');
    };

    userInfoPopup (data, unMappedData) {
        return(
            <div className="ui modal common-popup-wrapper hp-member-detail-popup-wrapper" style="display: block">
                <div className="content">
                    <div className="header">Team Member Details</div>
                    <div>
                        {this.getTeamMembersView(data)}
                    </div>
                    <div className="description">
                        <table className="ui table">
                            <tbody>
                            {this.getInfoRowView(unMappedData)}
                            </tbody>
                        </table>
                    </div>
                    <div className="actions">
                        <div className="ui cancel button" onclick={this.closeMoreUserInfoPopup}>Close</div>
                    </div>
                </div>
            </div>
        )
    }

    getInfoRowView(data) {
        let keys = R.keys(data);
        // if(R.isEmpty(data)) return (
        //     <tr>
        //         <td>There is no information about the user to be shown.</td>
        //     </tr>
        // );
        return R.map(key => this.infoView(key, data[key]), keys);
    }

    infoView(key, value) {
        return (
            <tr>
                <td>{key}</td>
                <td>{value}</td>
            </tr>
        );
    }

}

