import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import React from 'react'
import * as AColorPicker from 'a-color-picker'

export default class ColorPicker extends UIBaseComponent {

    getView () {
        return super.getView()
            .then(_ => {
                return (
                    <div className="hide" key={new Date().getTime()}>
                        <div id="cp-container" class={"ui popup right center transition visible "  + (this.state.hidden ? "hide" : "")}>
                            <div className = "color-picker-header-wrapper hide">
                                <div className = "color-picker-header-text">Color Picker</div>
                                <div className="color-picker-header-close" onclick={this.hide}>x</div>
                            </div>
                            <div className="picker"
                                 acp-color={this.props.color}
                                 acp-show-rgb="no"
                                 acp-show-hsl="no"
                                 acp-show-hex="yes"
                                 acp-palette="PALETTE_MATERIAL_500">
                            </div>
                        </div>
                    </div>
                );
            });
    }

    show = () => {
        this.colorPicker && this.colorPicker.show();
        return this.setState({hidden: false});
    };

    toggle = () => {
        this.colorPicker && this.colorPicker.toggle();
        return this.setState({hidden: !this.state.hidden});
    };

    hide = () => {
        this.colorPicker && this.colorPicker.hide();
        return this.setState({hidden: true});
    };

    setProps(props) {
        this.props = props;
        this.state.hidden = false;
        return this.render();
    }

    postRenderView () {
        return super.postRenderView()
            .then(this.showColorPicker)
            .then(this.showCPInPopup);
    }

    showCPInPopup = () => {
        let popupEl = this.$container.find('#cp-container');
        this.popuView = this.props.$container
            .popup({
                target: this.props.$container,
                position : "right center",
                popup: popupEl,
                on    : 'click',
                onHide: () => {
                    this.state.hidden = true;
                    return true;
                }
            })
            .popup('show');
    };

    showColorPicker = () => {
        if(this.colorPicker) {
            this.colorPicker.setColor(this.props.color, true);
            if(this.popuView && this.props.$container && this.popuView[0] !== this.props.$container[0]) {
                this.popuView.popup('hide');
            }
            if(!this.state.hidden) {
                this.colorPicker.show();
            }
            return this.colorPicker;
        }
        let _this = this;
        this.colorPicker = AColorPicker.createPicker(this.$container.find('.picker')[0], {})
            .on('change', (picker, color) => {
                _this.props.onColorPicked && _this.props.onColorPicked(_this, color);
            });
        return this.colorPicker;
    };
}