import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import React from 'react'

export default class AccessRequests extends UIBaseComponent {

    getView() {
        return super.getView()
            .then(_ => {
                return (
                    <div>
                        Not implemented yet.
                        <div className="portal-access-requests-content-wrapper hide">
                            <div className="semi-tabs-common-wrapper">
                                <div className="semi-tabs-wrapper">
                                    <div className="ui horizontal list">
                                        <div className="item active">
									  	<span className="semi-tabs-icon">
									  		<img className="ui mini image" src="assets/images/all-request-white-icon.png" alt=""/>
									  	</span>
                                            <span className="content ">117 All Requests</span>
                                        </div>
                                        <div className="item">
									    <span className="semi-tabs-icon">
									    	<img className="ui mini image" src="assets/images/pending-color-icon.png" alt=""/>
									    </span>
                                            <span className="content">100 Pending</span>
                                        </div>
                                        <div className="item">
									  	<span className="semi-tabs-icon">
									    	<img className="ui mini image approve-color-icon" src="assets/images/approve-color-icon.png" alt=""/>
									    </span>
                                            <span className="content">12 Approved</span>
                                        </div>
                                        <div className="item">
									   	<span className="semi-tabs-icon">
									    	<img className="ui mini image" src="assets/images/delete-color-icon.png" alt=""/>
									    </span>
                                            <span className="content">5 Rejected</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="common-search-wrapper">
                                    <div className="ui search">
                                        <input className="common-search-input" type="text" placeholder="Search Requests" />
                                        <img className="common-search-icon" src="assets/images/search-icon.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="common-table-wrapper">
                                <table className="ui single line table">
                                    <thead>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Company Name</th>
                                        <th>Email Address</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>James</td>
                                        <td>Butt</td>
                                        <td className="table-grey-text">Benton, John B Jr</td>
                                        <td className="table-grey-text">jbutt@bentonjJohnbjr.com</td>
                                        <td className="table-grey-text">Pending</td>
                                        <td className="common-action-td">
                                            <span className="common-action-tag" id="approveRequestPopup">Approve</span>
                                            <span className="common-action-tag" id="rejectRequestPopup">Reject</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>James</td>
                                        <td>Butt</td>
                                        <td className="table-grey-text">Benton, John B Jr</td>
                                        <td className="table-grey-text">jbutt@bentonjJohnbjr.com</td>
                                        <td className="table-grey-text">Pending</td>
                                        <td className="common-action-td">
                                            <span className="common-action-tag">Approve</span>
                                            <span className="common-action-tag">Reject</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>James</td>
                                        <td>Butt</td>
                                        <td className="table-grey-text">Benton, John B Jr</td>
                                        <td className="table-grey-text">jbutt@bentonjJohnbjr.com</td>
                                        <td className="table-grey-text">Pending</td>
                                        <td className="common-action-td">
                                            <span className="common-action-tag">Approve</span>
                                            <span className="common-action-tag">Reject</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>James</td>
                                        <td>Butt</td>
                                        <td className="table-grey-text">Benton, John B Jr</td>
                                        <td className="table-grey-text">jbutt@bentonjJohnbjr.com</td>
                                        <td className="table-grey-text">Pending</td>
                                        <td className="common-action-td">
                                            <span className="common-action-tag">Approve</span>
                                            <span className="common-action-tag">Reject</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>James</td>
                                        <td>Butt</td>
                                        <td className="table-grey-text">Benton, John B Jr</td>
                                        <td className="table-grey-text">jbutt@bentonjJohnbjr.com</td>
                                        <td className="table-grey-text">Pending</td>
                                        <td className="common-action-td">
                                            <span className="common-action-tag">Approve</span>
                                            <span className="common-action-tag">Reject</span>
                                        </td>
                                    </tr>
                                    <tr className="yellow-bg">
                                        <td>James</td>
                                        <td>Butt</td>
                                        <td className="table-grey-text">Benton, John B Jr</td>
                                        <td className="table-grey-text">jbutt@bentonjJohnbjr.com</td>
                                        <td className="table-grey-text">Pending</td>
                                        <td className="common-action-td">
                                            <span className="common-action-tag">Reject</span>
                                            <span className="common-action-tag">Delete</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>James</td>
                                        <td>Butt</td>
                                        <td className="table-grey-text">Benton, John B Jr</td>
                                        <td className="table-grey-text">jbutt@bentonjJohnbjr.com</td>
                                        <td className="table-grey-text">Pending</td>
                                        <td className="common-action-td">
                                            <span className="common-action-tag">Approve</span>
                                            <span className="common-action-tag">Reject</span>
                                        </td>
                                    </tr>
                                    <tr className="red-bg">
                                        <td>James</td>
                                        <td>Butt</td>
                                        <td className="table-grey-text">Benton, John B Jr</td>
                                        <td className="table-grey-text">jbutt@bentonjJohnbjr.com</td>
                                        <td className="table-grey-text">Pending</td>
                                        <td className="common-action-td">
                                            <span className="common-action-tag">Approve</span>
                                            <span className="common-action-tag">Delete</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>James</td>
                                        <td>Butt</td>
                                        <td className="table-grey-text">Benton, John B Jr</td>
                                        <td className="table-grey-text">jbutt@bentonjJohnbjr.com</td>
                                        <td className="table-grey-text">Pending</td>
                                        <td className="common-action-td">
                                            <span className="common-action-tag">Approve</span>
                                            <span className="common-action-tag">Reject</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>James</td>
                                        <td>Butt</td>
                                        <td className="table-grey-text">Benton, John B Jr</td>
                                        <td className="table-grey-text">jbutt@bentonjJohnbjr.com</td>
                                        <td className="table-grey-text">Pending</td>
                                        <td className="common-action-td">
                                            <span className="common-action-tag">Approve</span>
                                            <span className="common-action-tag">Reject</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>James</td>
                                        <td>Butt</td>
                                        <td className="table-grey-text">Benton, John B Jr</td>
                                        <td className="table-grey-text">jbutt@bentonjJohnbjr.com</td>
                                        <td className="table-grey-text">Pending</td>
                                        <td className="common-action-td">
                                            <span className="common-action-tag">Approve</span>
                                            <span className="common-action-tag">Reject</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="ui modal common-popup-wrapper approve-request-popup"
                                 id="approveRequestPopupModal">
                                <div className="content">
                                    <div className="secondary-header">Are you sure you want to Approve this Request?
                                    </div>
                                    <div className="description">
                                        <table className="ui table">
                                            <tbody>
                                            <tr>
                                                <td>Full Name</td>
                                                <td>Tim Kelly</td>
                                            </tr>
                                            <tr>
                                                <td>Company Name</td>
                                                <td>Acme</td>
                                            </tr>
                                            <tr>
                                                <td>Company Address</td>
                                                <td>UK</td>
                                            </tr>
                                            <tr>
                                                <td>Email Address</td>
                                                <td>tim.kelly@acme.com</td>
                                            </tr>
                                            <tr>
                                                <td>Contact Number</td>
                                                <td>+1 954 032 0234</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="actions">
                                        <div className="ui primary button">Yes</div>
                                        <div className="ui cancel button">No</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui modal common-popup-wrapper reject-request-popup"
                                 id="rejectRequestPopupModal">
                                <div className="content">
                                    <div className="secondary-header">Are you sure you want to Reject this Request?
                                    </div>
                                    <div className="description">
                                        <table className="ui table">
                                            <tbody>
                                            <tr>
                                                <td>Full Name</td>
                                                <td>Tim Kelly</td>
                                            </tr>
                                            <tr>
                                                <td>Company Name</td>
                                                <td>Acme</td>
                                            </tr>
                                            <tr>
                                                <td>Company Address</td>
                                                <td>UK</td>
                                            </tr>
                                            <tr>
                                                <td>Email Address</td>
                                                <td>tim.kelly@acme.com</td>
                                            </tr>
                                            <tr>
                                                <td>Contact Number</td>
                                                <td>+1 954 032 0234</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="actions">
                                        <div className="ui primary button">Yes</div>
                                        <div className="ui cancel button">No</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
    }
}