import React from 'react'
import Bluebird from 'bluebird';
import $ from 'jquery';
import UIBaseComponent from "../../Backflipt_UI_Components/src/ui-base-component/ui-base-component";
import {
    getAttrValueForEventFromClosest, getAttrValueFromClosest,
    getValueForPathOrDefault, mapIndexed,
    setValueForPathOrDefault
} from "../../Backflipt_UI_Components/utils/generic-utils";
import {
    createPageForTenant, getAllLayouts,
    getAllPagesForTenant,
    publishPageForTenant,
    unPublishPageForTenant,
    updatePageForTenant
} from "../../service/tenant-service";
import Layouts from "./layouts";
import HomePreview from "./home-preview";
import {CONTENT_TEMPLATES, SOURCE_TYPE} from "../content-templates/templates-config";
import {convertListToObj, orderByAsc} from "../../utils/generic-utils";
import {PAGE_TYPE} from "../../config/app-config";

const R = require('ramda');

export default class HomepageCustomization extends UIBaseComponent {

    beforeRender() {
        this.staticTemplates = {};
        this.loadDataAsync();
        return super.beforeRender();
    }

    loadDataAsync() {
        let jobs = [getAllPagesForTenant(this.props.tenant.id), getAllLayouts()];
        return Bluebird.all(jobs)
            .then(resp => {
                let homePage = R.find(page => page.type === PAGE_TYPE.HOME_PAGE, resp[0]) || {};
                let savedConfig = (homePage && homePage.savedConfig) || {};
                let layouts = resp[1];
                layouts = orderByAsc(layouts, "name");
                let defaultLayout = layouts[0] || {};
                let selectedLayoutId = (savedConfig.layoutId) ? savedConfig.layoutId : defaultLayout.id;
                return this.setState({homePage, selectedLayoutId, layouts, originalSavedConfig: savedConfig})
            })
            .catch(_ => this.setState({homePage: {}, savedConfig: {}, originalSavedConfig: {}}));
    }

    getView() {

        return super.getView()
            .then(_ => {
                let homePage = this.state.homePage || {};
                return (
                    <div className="home-page-customization-container">
                        <div className="customization-wrapper">
                            <div id="layouts" className="layouts">
                                <h1>Layouts</h1>
                                <div className="layout-lists-wrapper">
                                </div>
                            </div>
                            <div id="static-content-templates" className="content-templates">
                                <h1>Content Templates</h1>
                                {this.getStaticContentTemplatesView()}
                            </div>
                            <div className="home-preview">
                                <h1>Home Preview</h1>
                                <div className="home-preview-actions">
                                    <button onclick={this.unPublishHomepage} className={"ui button" + (this.disableUnPublish()? " disabled": "")}>Unpublish</button>
                                    <button onclick={this.publishHomepage} className={"ui button" + (this.disablePublish()? " disabled": "")}>Publish</button>
                                    <button onclick={this.createOrSaveHomepage} className={"ui primary button" + (this.hasUnsavedChanges()?" disabled": "")}>Save</button>
                                    <button onclick={this.cancelChanges} className={"ui button" + (this.hasUnsavedChanges()?" disabled": "")}>Cancel</button>
                                </div>
                                <div className="home-page-tabs-and details-wrapper">
                                    <div className="common-tabs-wrapper">
                                        <div className="tabs-wrapper"><span data-type="number" className="common-tab active">{homePage.name || "Home"}</span>
                                        </div>
                                    </div>
                                    <div className="tabs-active app-tab-primary-background" />
                                    <div id="home-page-preview" className="layout-wrapper" ondragover={this.onDragOver} ondrop={this.onDrop}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
    }

    getStaticContentTemplatesView() {
        return R.map(contentTemplate => {
            return <div data-template={contentTemplate.id}/>
        }, CONTENT_TEMPLATES);
    }

    disableUnPublish() {
        let homePage = this.state.homePage || {};
        let publishedConfig = getValueForPathOrDefault(homePage, "publishedConfig", {});
        return R.isEmpty(publishedConfig)
    }

    disablePublish() {
        let homePage = this.state.homePage || {};
        //let savedConfig = getValueForPathOrDefault(homePage, "savedConfig", {});
        let originalSavedConfig = this.state.originalSavedConfig;
        let publishedConfig = getValueForPathOrDefault(homePage, "publishedConfig", {});
        return !(!R.isEmpty(originalSavedConfig) && !R.equals(publishedConfig, originalSavedConfig));
    }

    hasUnsavedChanges(){
        let homePage = this.state.homePage || {};
        let savedConfig = getValueForPathOrDefault(homePage, "savedConfig", {});
        return R.equals(this.state.originalSavedConfig, savedConfig);
    };

    getLayoutFromId = (id) => id && R.find(layout => layout.id === id, this.state.layouts || []);

    cancelChanges = () => {
        let homePage = R.clone(this.state.homePage) || {};
        setValueForPathOrDefault(homePage, "savedConfig", this.state.originalSavedConfig);
        let selectedLayoutId = getValueForPathOrDefault(this.state.originalSavedConfig, "layoutId", 1);
        return this.setState({homePage, selectedLayoutId});
    };

    onDrag = (e) => {
        let contentName = getAttrValueForEventFromClosest(e, 'data-template');
        //let rowIndex = getAttrValueForEventFromClosest(e, 'data-row-index') || "";
        let position = getAttrValueForEventFromClosest(e, 'data-position') || "";
        let content = {
            contentName: contentName,
            //rowIndex: rowIndex,
            position: position
        };
        return e.dataTransfer.setData("text", JSON.stringify(content))
    };

    onDragOver = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "copy";
    };

    // onDragEnd = (e) => {
    //     e.preventDefault();
    //     const $el = $(e.target);
    //     $el.addClass('drag-in-progress');
    // };

    onDrop = (e) => {
        e.preventDefault();
        let content = JSON.parse(e.dataTransfer.getData("text"));

        let contentName = content.contentName;
        //let dragPointRowIndex = content.rowIndex;
        let dragPointPosition = content.position;

        //let dropPointRowIndex = getAttrValueForEventFromClosest(e, 'data-row-index') || "";
        //let dropPointColIndex = getAttrValueForEventFromClosest(e, 'data-col-index') || "";
        let dropPointPosition = getAttrValueForEventFromClosest(e, 'data-position');

        if(!dropPointPosition) return;

        let homePage = R.clone(this.state.homePage) || [];

        let savedConfig = homePage.savedConfig || {};
        savedConfig.layoutId = this.state.selectedLayoutId;

        let mappings = savedConfig.mappings || []   ; //this.getContentByName(contentName) || {};
        let mapping = {};

        let mappingAtDragPoint = R.find(m => (m.position === dragPointPosition), mappings) || {};
        let mappingAtDropPoint = R.find(m => (m.position === dropPointPosition), mappings) || {};

        if(dragPointPosition) {

            if(!R.isEmpty(mappingAtDropPoint)) return;

            let mappingIndex = R.findIndex(cm => cm.position === dragPointPosition, mappings);

            //mappingAtDragPoint.rowIndex = dropPointRowIndex;
            mappingAtDragPoint.position = dropPointPosition;

            mappings[mappingIndex] = mappingAtDragPoint;

            savedConfig.mappings = mappings;
            homePage.savedConfig = savedConfig;

            return this.setState({homePage});
        }

        //mapping.rowIndex = dropPointRowIndex;
        // mapping.colIndex = colIndex;
        mapping.position = dropPointPosition;
        mapping.contentTemplateId = contentName;
        mapping.contentAttributes = {
            name: "",
            sources: []
        };


        let mappingIndex = R.findIndex(cm => cm.position === dropPointPosition, mappings);

        if(mappingIndex === -1) mappings.push(mapping);
        else mappings[mappingIndex] = mapping;

        savedConfig.mappings = mappings;
        homePage.savedConfig = savedConfig;

        return this.setState({homePage});
    };

    saveMapping = (mapping) => {
        let homePage = R.clone(this.state.homePage) || {};
        let mappings = getValueForPathOrDefault(homePage, "savedConfig.mappings", []);
        let mappingIndex = R.findIndex(m => m.position === mapping.position, mappings);
        mappings[mappingIndex] = mapping;
        setValueForPathOrDefault(homePage, "savedConfig.mappings", mappings);
        return this.setState({homePage});
    };

    deleteMapping = (mapping) => {
        let homePage = R.clone(this.state.homePage) || {};
        let mappings = getValueForPathOrDefault(homePage, "savedConfig.mappings", []);
        let mappingIndex = R.findIndex(m => m.position === mapping.position, mappings);
        mappings = R.remove(mappingIndex, 1, mappings);
        setValueForPathOrDefault(homePage, "savedConfig.mappings", mappings);
        return this.setState({homePage});
    };

    postRenderView() {
        return super.postRenderView()
            .then(this.renderLayoutsList)
            .then(this.renderStaticContentTemplatesList)
            .then(this.renderHomepagePreviewInPageLayout);
    }

    renderLayoutsList = () => {
        const container = this.$container.find('#layouts')[0];
        let selectedLayoutId = this.state.selectedLayoutId;
        let layouts = this.state.layouts;
        const props = {
            onLayoutSelected: this.onLayoutSelected,
            loadLayouts: false,
            layouts,
            selectedLayoutId
        };

        if(!this.layoutsView) {
            this.layoutsView = new Layouts(container, props);
            return this.layoutsView.render();
        }
        this.layoutsView.props = props;
        return this.layoutsView.render();
    };

    destroy() {
        if(this.layoutsView) this.layoutsView.destroy();
        if(this.homepagePreviewView) this.homepagePreviewView.destroy();
        this.homepagePreviewView = null;
        this.layoutsView = null;
        R.forEach(ct => ct.destroy(), R.values(this.staticTemplates || {}));
        this.staticTemplates = {};
        return super.destroy();
    };

    onLayoutSelected = (layoutId) => {
        let homePage = R.clone(this.state.homePage) || {};
        let mappings = getValueForPathOrDefault(homePage, "savedConfig.mappings", []);
        // let layout = this.getLayoutFromId(layoutId);
        // let sections = this.getSectionsOfLayout(layout);
        // let sectionsObj = R.invertObj(convertListToObj(sections));
        // mappings = R.map(m => {
        //     m.rowIndex = sectionsObj[m.position] || m.rowIndex;
        //     return m;
        // }, mappings);
        setValueForPathOrDefault(homePage, "savedConfig.mappings", mappings);
        setValueForPathOrDefault(homePage, "savedConfig.layoutId", layoutId);
        return this.setState({selectedLayoutId: layoutId, homePage});
    };

    renderStaticContentTemplatesList = () => {
        const $container = this.$container.find("#static-content-templates");
        const props = {
            editTemplate: false,
            onDrag: this.onDrag
        };
        return R.map(contentTemplate =>{
            let id = contentTemplate.id;
            let template = this.staticTemplates[id];
            if(!template) {
                const container = $container.find(`[data-template="${id}"]`)[0];
                let {default: Module} = require(`../content-templates/${id}/content-template.js`);
                template = new Module(container, props);
                this.staticTemplates[id] = template;
                return template.render();
            }

            return template.renderView();

        }, CONTENT_TEMPLATES)
    };

    renderHomepagePreviewInPageLayout = () => {
        const container = this.$container.find('#home-page-preview')[0];
        let homePage = this.state.homePage || {};
        let config = getValueForPathOrDefault(homePage, "savedConfig", {});

        const props = {
            selectedLayoutId: this.state.selectedLayoutId,
            config,
            editTemplate: true,
            onSaveMapping: this.saveMapping,
            onDeleteMapping: this.deleteMapping,
            onDrag: this.onDrag,
            onDragOver: this.onDragOver,
            onDrop: this.onDrop
        };

        if(!this.homepagePreviewView) {
            this.homepagePreviewView = new HomePreview(container, props);
            return this.homepagePreviewView.render();
        }
        this.homepagePreviewView.props = props;
        return this.homepagePreviewView.render();
    };

    createOrSaveHomepage = () => {
        let homePage = R.clone(this.state.homePage) || {};
        let tenantId = this.props.tenant.id;
        let savedConfig = homePage.savedConfig || {};
        homePage.type = PAGE_TYPE.HOME_PAGE;
        homePage.name = "Home";
        savedConfig.layoutId = this.state.selectedLayoutId;
        const layout = this.getLayoutFromId(this.state.selectedLayoutId);
        let positions = this.getPositionsOfLayout(layout);
        let mappings = savedConfig.mappings || [];
        mappings = R.filter(mapping => R.includes(mapping.position, positions), mappings);
        setValueForPathOrDefault(savedConfig, "mappings", mappings);
        homePage.savedConfig = savedConfig;
        let originalSavedConfig = this.state.originalSavedConfig || {};
        return (R.isEmpty(originalSavedConfig)? createPageForTenant(tenantId, homePage): updatePageForTenant(homePage.id, tenantId, homePage))
                .then(homePage => this.setState({homePage, originalSavedConfig: savedConfig}));
    };

    unPublishHomepage = () => {
        let homePage = R.clone(this.state.homePage) || {};
        let savedConfig = getValueForPathOrDefault(homePage, "savedConfig", {});
        let tenantId = this.props.tenant.id;
        return unPublishPageForTenant(homePage.id, tenantId)
            .then(homePage => {
                homePage.savedConfig = savedConfig;
                return this.setState({homePage});
            });
    };

    publishHomepage = () => {
        let homePage = R.clone(this.state.homePage) || {};
        let savedConfig = getValueForPathOrDefault(homePage, "savedConfig", {});
        let tenantId = this.props.tenant.id;
        return publishPageForTenant(homePage.id, tenantId)
            .then(homePage => {
                homePage.savedConfig = savedConfig;
                return this.setState({homePage});
            });
    };

    getPositionsOfLayout(layout, position = 0) {
        let layoutConfig = layout.config;
        let cells = layoutConfig.cells;
        let sections = [];
        mapIndexed((cell, _) => {
            let cellContent = cell.content;
            R.forEach(_ => {
                ++position;
                sections.push(position.toString())
            }, cellContent)
        }, cells);
        return sections;
    }
}
