import Backbone from 'backbone'

import EventsHandler from '../event-handler'
import Events from '../config/events'
import * as UserProfileDAO from '../dao/user-profile-dao'
import { getMyTenantDetails, isRouteAllowedForMe } from '../dao/user-profile-dao'

import * as LayoutManager from '../layout/layout-manager'

import { ROUTES } from '../config/app-config'
import { getParamsMapForQueryString, prepareRESTURI } from '../Backflipt_UI_Components/utils/generic-utils'
import { decodeURIData } from '../service/tenant-service'
import _ from 'underscore'
import { getTitle } from '../utils/domain-utils'
import {getUserPreferences} from "../dao/user-profile-dao";

const R = require('ramda');

//let breadCrumb = {};

let Router = Backbone.Router.extend({

    noAuthURIs: [ROUTES.SIGN_IN, ROUTES.FORGOT_PASSWORD, "signup", ROUTES.VERIFY_EMAIL, ROUTES.SUCCESS_VIEW, ROUTES.RESET_PASSWORD,
    ROUTES.REGISTER_USER, ROUTES.SSO_LOGIN],

    titles: {

        "": () => getTitle(),

        [ROUTES.HOME]: 'Home',

        [ROUTES.SIGN_IN]: "Signin",
        [ROUTES.RESET_PASSWORD]: () => "Reset Password",
        [ROUTES.REGISTER_USER]: () => "Registration",
        [ROUTES.SSO_LOGIN]: () => "SSO",

        [ROUTES.ADMIN_ACCESS_REQUESTS]: "Access Requests",
        [ROUTES.ADMIN_TENANTS]: "Tenants",
        [ROUTES.ADMIN_CONFIGURATIONS]: "Configurations",
        [ROUTES.ADMIN_COMMON_FEED_UPLOAD]: "Common Feed",
        [ROUTES.ADMIN_STANDARD_APPS]: "Standard Apps",
        [ROUTES.ADMIN_EMAIL_TEMPLATES]: "Email Templates",
        [ROUTES.ADMIN_TENANT_DETAIL]: (data) => decodeURIData(data).name,
        [ROUTES.ADMIN_TENANT_DETAIL_GUI]: "GUI Settings",
        [ROUTES.ADMIN_TENANT_DETAIL_APPS]: "Apps",
        [ROUTES.ADMIN_TENANT_DETAIL_APPS_USER_CREDENTIALS]: "User Credentials",
        [ROUTES.ADMIN_TENANT_DETAIL_USERS]: "Users",
        [ROUTES.ADMIN_TENANT_DETAIL_USER_AUTH]: "User Authentication",
        [ROUTES.ADMIN_TENANT_FEED_UPLOAD]: "Client Specific Feed",
        [ROUTES.ADMIN_TENANT_HOMEPAGE_CUSTOMIZATION]: "Homepage Customization",

        [ROUTES.USER_HOME]: "",
        [ROUTES.APPS_ZOOM]: "",

        [ROUTES.USER_ADMIN_HOME]: "Administration",
        [ROUTES.USER_ADMIN_GUI]: "GUI Settings",
        [ROUTES.USER_ADMIN_USER_AUTH]: "User Authentication",
        [ROUTES.USER_ADMIN_APPS]: "Apps",
        [ROUTES.USER_ADMIN_APPS_USER_CREDENTIALS]: "User Credentials",
        [ROUTES.USER_ADMIN_USERS]: "Users",
        [ROUTES.USER_ADMIN_DETAILS]: "Client Details",
        [ROUTES.USER_ADMIN_FEED_UPLOAD]: "Client Specific Feed",
        [ROUTES.USER_ADMIN_HOMEPAGE_CUSTOMIZATION]: "Homepage Customization"

    },

    routes: {
        [ROUTES.HOME]: 'home',

        [ROUTES.SIGN_IN]: "dynamicSignIn",
        [ROUTES.FORGOT_PASSWORD]: "dynamicRoute",
        [ROUTES.RESET_PASSWORD]: ROUTES.RESET_PASSWORD,
        [ROUTES.SSO_LOGIN]: ROUTES.SSO_LOGIN,
        [ROUTES.SUCCESS_VIEW]: "dynamicRoute",
        [ROUTES.VERIFY_EMAIL]: "dynamicRoute",
        [ROUTES.REGISTER_USER]: ROUTES.REGISTER_USER,

        [ROUTES.ADMIN_ACCESS_REQUESTS]: "dynamicRoute",
        [ROUTES.ADMIN_TENANTS]: "dynamicRoute",
        [ROUTES.ADMIN_CONFIGURATIONS]: ROUTES.ADMIN_CONFIGURATIONS,
        [ROUTES.ADMIN_COMMON_FEED_UPLOAD]: "dynamicRoute",
        [ROUTES.ADMIN_STANDARD_APPS]: "dynamicRoute",
        [ROUTES.ADMIN_EMAIL_TEMPLATES]: "dynamicRoute",
        [ROUTES.ADMIN_TENANT_DETAIL]: ROUTES.ADMIN_TENANT_DETAIL,
        [ROUTES.ADMIN_TENANT_DETAIL_GUI]: "tenantDetailTabView",
        [ROUTES.ADMIN_TENANT_DETAIL_APPS]: "tenantDetailTabView",
        [ROUTES.ADMIN_TENANT_DETAIL_APPS_USER_CREDENTIALS]: "tenantAppUserCredentialsView",
        [ROUTES.ADMIN_TENANT_DETAIL_USERS]: "tenantDetailTabView",
        [ROUTES.ADMIN_TENANT_DETAIL_USER_AUTH]: "tenantDetailTabView",
        [ROUTES.ADMIN_TENANT_FEED_UPLOAD]: "tenantDetailTabView",
        [ROUTES.ADMIN_TENANT_HOMEPAGE_CUSTOMIZATION]: "tenantDetailTabView",

        [ROUTES.USER_HOME]: "dynamicRoute",
        [ROUTES.APPS_ZOOM]: "dynamicRoute",

        [ROUTES.USER_ADMIN_HOME]: ROUTES.USER_ADMIN_HOME,
        [ROUTES.USER_ADMIN_GUI]: "userAdminView",
        [ROUTES.USER_ADMIN_USER_AUTH]: "userAdminView",
        [ROUTES.USER_ADMIN_APPS]: "userAdminView",
        [ROUTES.USER_ADMIN_APPS_USER_CREDENTIALS]: "userAdminAppUserCredentialsView",
        [ROUTES.USER_ADMIN_USERS]: "userAdminView",
        [ROUTES.USER_ADMIN_DETAILS]: "userAdminView",
        [ROUTES.USER_ADMIN_FEED_UPLOAD]: "userAdminView",
        [ROUTES.USER_ADMIN_HOMEPAGE_CUSTOMIZATION]: "userAdminView",

        '*actions': 'default'
    },

    dynamicRoute: function (routeAttributes) {
        return LayoutManager.render(routeAttributes.route, {routeAttributes});
    },

    [ROUTES.ADMIN_CONFIGURATIONS]: function() {
        return this.goToRoute(ROUTES.ADMIN_COMMON_FEED_UPLOAD);
    },

    [ROUTES.ADMIN_TENANT_DETAIL]: function(routeAttributes, tenant) {
        let uri = prepareRESTURI(ROUTES.ADMIN_TENANT_DETAIL_USER_AUTH, {tenant});
        return this.goToRoute(uri);
    },

    [ROUTES.USER_ADMIN_HOME]: function() {
        return this.goToRoute(ROUTES.USER_ADMIN_USER_AUTH);
    },

    [ROUTES.RESET_PASSWORD]: function(routeAttributes, token) {
        return LayoutManager.render(ROUTES.RESET_PASSWORD, {token});
    },

    [ROUTES.SSO_LOGIN]: function(routeAttributes, token) {
        return LayoutManager.render(ROUTES.SSO_LOGIN, {token});
    },

    [ROUTES.REGISTER_USER]: function (routeAttributes, token) {
        return LayoutManager.render(ROUTES.REGISTER_USER, {token});
    },

    "userAdminView": function(routeAttributes) {
          let tenant = getMyTenantDetails();
          return LayoutManager.render(routeAttributes.route, {tenant, routeAttributes});
    },

    tenantDetailTabView: function (routeAttributes, tenantPath) {
        let tenant = decodeURIData(tenantPath);
        return LayoutManager.render(routeAttributes.route, {tenantPath, tenant, routeAttributes});
    },

    tenantAppUserCredentialsView: function(routeAttributes, tenantPath, appPath) {
        let tenant = decodeURIData(tenantPath);
        let app = decodeURIData(appPath);
        return LayoutManager.render(routeAttributes.route, {tenantPath, tenant, appPath, app, routeAttributes});
    },

    userAdminAppUserCredentialsView: function(routeAttributes, appPath) {
        let tenant = getMyTenantDetails();
        let app = decodeURIData(appPath);
        return LayoutManager.render(routeAttributes.route, {tenant, appPath, app, routeAttributes})
    },

    home: function () {
        if(window.env.adminApp) return this.goToRoute(ROUTES.ADMIN_TENANTS);
        else {
            let preferences = getUserPreferences();
            if(preferences.showRSBFeed === undefined) return this.goToRoute(ROUTES.USER_HOME);
            let showRSBFeed = preferences.showRSBFeed;
            let route = showRSBFeed? ROUTES.USER_HOME: ROUTES.APPS_ZOOM;
            return this.goToRoute(route);
        }
    },

    dynamicSignIn: function(routeAttributes) {
        if(window.env.adminApp) return LayoutManager.render("admin_sign_in", {routeAttributes});
        else return LayoutManager.render("user_sign_in", {routeAttributes});
    },

    goToRoute: function (route) {
        return this.navigate(route, {trigger: true});
    },

    reloadCurrentRoute: function () {
        return Backbone.history.loadUrl(Backbone.history.fragment);
    },

    default: function () {
        return this.goToRoute(ROUTES.DEFAULT);
    },

    goToHome: function () {
        return this.goToRoute(ROUTES.HOME);
    },

    execute: function (callback, args, name) {
        // $('body').scrollTop(0);
        let attribs = this.getCurrentRouteAttributes();
        let cr = attribs.route;

        let isNoAuthRoute = R.includes(cr, this.noAuthURIs);

        if (UserProfileDAO.isUserSignedIn()) {
            if (cr === ROUTES.SIGN_IN) return this.goToHome();
        } else if (!isNoAuthRoute) return EventsHandler.push({name: Events.GO_TO_SIGNIN});

        if(UserProfileDAO.isUserSignedIn() && window.env.adminApp !== UserProfileDAO.amIAdmin()) return UserProfileDAO.logout();

        let notAllowed = cr === "*actions" ? false : UserProfileDAO.isUserSignedIn() && !isRouteAllowedForMe(cr);

        if(notAllowed) return this.goBack();

        document.title = this.getTitleForRoute(cr, args);

        args = R.prepend(attribs, args);

        if (callback) callback.apply(this, args);
    },

    start: function () {
        window.history.scrollRestoration = 'manual';
        Backbone.history.start();
    },

    goBack: function () {
        Backbone.history.history.back();
    },

    getCurrentRoute: function () {
        return this.getCurrentRouteAttributes().route;
    },

    getRouteAttributesForURI: function (uri) {
        let Router = this,
            fragment = uri,
            routes = _.pairs(Router.routes),
            route = null,
            params = null,
            handler = null,
            matched = false;

        matched = _.find(routes, function (handler) {
            route = _.isRegExp(handler[0]) ? handler[0] : Router._routeToRegExp(handler[0]);
            return route.test(fragment);
        });

        if (matched) {
            // NEW: Extracts the params using the internal
            // function _extractParameters
            params = R.filter(x => x, Router._extractParameters(route, fragment) || []);
            route = matched[0];
            handler = matched[1];
        }

        params = R.mergeAll(R.map(getParamsMapForQueryString, params));

        return {
            route,
            fragment,
            handler,
            params
        };
    },

    getCurrentRouteAttributes: function () {
        return this.getRouteAttributesForURI(Backbone.history.fragment);
    },

    getBreadCrumbForCurrentURI: function () {
        let ra = this.getCurrentRouteAttributes();
        return this.getBreadCrumbForRoute(ra.route, ra.params);
    },

    getTitleForCurrentURI: function () {
        let ra = this.getCurrentRouteAttributes();
        return this.getTitleForRoute(ra.route, ra.params);
    },

    getTitleForRoute: function (route, params = []) {
        let bc = this.getBreadCrumbForRoute(route, params);
        let rts = R.filter(x => x, R.reverse(bc));
        return R.join(" | ", rts);
    },

    getBreadCrumbForRoute: function (route, params = []) {

        // if(!this.titles[route]) return [];

        params = R.filter(x => x, params);

        // if(breadCrumb[route]) return breadCrumb[route];

        route = route && route.trim();

        if (!route || R.isEmpty(route) || route === "/") {
            let title = this.titles[route];
            if(typeof title === "function") return [title(...params)];
            else return [title];
        }

        let parts = route.split("/");

        let parentURI = R.join("/", R.dropLast(1, parts));

        let cr = R.last(parts);

        let isDynamicRoute = R.startsWith(":", cr);

        let bc = this.getBreadCrumbForRoute(parentURI, isDynamicRoute ? R.dropLast(1, params) : params);

        let value = isDynamicRoute && this.titles[route] ? this.titles[route](...params) : this.titles[route];

        let result = R.filter(x => x, R.append(value, bc));

        // if(!isDynamicRoute) breadCrumb[route] = result;

        return result;
    }
});

let router = new Router();

export default router;
