import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import React from 'react'
import { ROUTES } from '../../config/app-config'
import { goToRouteOnEvent } from '../../utils/generic-utils'
import { getMyTenantLogoUrl } from '../../dao/user-profile-dao'

const R = require('ramda');

export default class TenantAdminHome extends UIBaseComponent {

    getView () {
        return super.getView()
            .then(_ => {
                return (
                    <div className="ics-landing-body-wrapper">
                        <div className="administration-flow-container">
                            <div className="common-tabs-container">
                                <div className="administration-header-text">
                                    <h3> Administration </h3>
                                </div>
                                <div className="admin-common-tabs-content-wrapper">
                                    {/* Client Management Semi Tabs contents Before Adding Customer */}
                                    <div className="customer-content-container">

                                        {this.getTabsView()}

                                        <div className="customer-tabs-content-wrapper app-background">

                                            {/* Administration User Authentication Content */}
                                            <div id="tenant_detail_user_auth_container"/>

                                            {/* Administration GUI Settings Content */}
                                            <div id="tenant_detail_gui_container"/>

                                            {/* Administration Application Content */}
                                            <div id="tenant_detail_apps_container"/>

                                            {/* Administration User Management Content */}
                                            <div id="tenant_detail_users_container"/>

                                            {/* Administration Client Details Content */}
                                            <div id="tenant_details_container" />

                                            {/* Administration Client Feed Upload Content */}
                                            <div id="tenant_feed_upload_container" />

                                            {/* Administration Client Homepage Customization Content */}
                                            <div id="tenant_homepage_customization" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
    }

    getTabsView() {
        let cr = this.props.routeAttributes.route;
        let activeStyle = "app-tab-primary-text app-tab-primary-background active";
        let inactiveStyle = "app-tab-secondary-text app-tab-secondary-background";

        let guiTabActiveClass = cr === ROUTES.USER_ADMIN_GUI ? activeStyle: inactiveStyle;
        let appsTabActiveClass = R.startsWith(ROUTES.USER_ADMIN_APPS, cr) ? activeStyle: inactiveStyle;
        let usersTabActiveClass = cr === ROUTES.USER_ADMIN_USERS ? activeStyle: inactiveStyle;
        let userAuthTabActiveClass = cr === ROUTES.USER_ADMIN_USER_AUTH ? activeStyle: inactiveStyle;
        let tenantDetailsActiveClass = cr === ROUTES.USER_ADMIN_DETAILS ? activeStyle: inactiveStyle;
        let feedTabActiveClass = cr === ROUTES.USER_ADMIN_FEED_UPLOAD ? activeStyle: inactiveStyle;
        let homepageCustomizationActiveClass = cr === ROUTES.USER_ADMIN_HOMEPAGE_CUSTOMIZATION ? activeStyle : inactiveStyle;

        let logoUrl = getMyTenantLogoUrl() || "assets/images/comp-logo-1.png";

        return (
            <div className="common-tabs-wrapper">
                <div className="tabs-wrapper">
                    <span className={"common-tab " + userAuthTabActiveClass} data-route={ROUTES.USER_ADMIN_USER_AUTH} onclick={goToRouteOnEvent}>User Authentication </span>
                    <span className={"common-tab " + guiTabActiveClass} data-route={ROUTES.USER_ADMIN_GUI} onclick={goToRouteOnEvent}>GUI Settings </span>
                    <span className={"common-tab " + appsTabActiveClass} data-route={ROUTES.USER_ADMIN_APPS} onclick={goToRouteOnEvent}>Applications </span>
                    <span className={"common-tab " + usersTabActiveClass} data-route={ROUTES.USER_ADMIN_USERS} onclick={goToRouteOnEvent}>User Management </span>
                    <span className={"common-tab " + tenantDetailsActiveClass} data-route={ROUTES.USER_ADMIN_DETAILS} onclick={goToRouteOnEvent}>Client Details </span>
                    <span className={"common-tab " + feedTabActiveClass} data-route={ROUTES.USER_ADMIN_FEED_UPLOAD} onclick={goToRouteOnEvent}>Client Specific Feed </span>
                    <span className={"common-tab " + homepageCustomizationActiveClass} data-route={ROUTES.USER_ADMIN_HOMEPAGE_CUSTOMIZATION} onclick={goToRouteOnEvent}>Homepage Customization </span>

                </div>
                <div className="comp-logo-wrapper hide">
                    <img src={logoUrl} alt=""/>
                </div>
                <div className="tabs-active app-tab-primary-background"/>
            </div>
        );
    }
}