
const R = require('ramda');

export const SESSION_ID = "sid";

export const USER_ROLES = {
    ADMIN: {
        id: "ADMIN",
        name: "Integrated Cyber Admin"
    },
    TENANT_ADMIN: {
        id: "TENANT_ADMIN",
        name: "Admin"
    },
    USER: {
        id: "USER",
        name: "User"
    }
};

export const ROUTES = {

    DEFAULT: 'home',

    /******************************* Common Routes *****************************/

    HOME: 'home',
    SIGN_IN: 'signin',
    FORGOT_PASSWORD: 'password/forgot',
    RESET_PASSWORD: 'password/reset/:token',
    SSO_LOGIN: 'sso/login/:token',
    SUCCESS_VIEW: 'passwordReset/successful',
    VERIFY_EMAIL: 'email/verify',
    REGISTER_USER: 'register/:token',

    /******************************* ICS Admin Routes *****************************/

    ADMIN_ACCESS_REQUESTS: 'admin/access-requests',
    ADMIN_TENANTS: "admin/tenants",
    ADMIN_CONFIGURATIONS: "admin/configurations",
    ADMIN_COMMON_FEED_UPLOAD: "admin/configurations/common-feed",
    ADMIN_STANDARD_APPS: "admin/configurations/standard-apps",
    ADMIN_EMAIL_TEMPLATES: "admin/configurations/email-templates",
    ADMIN_TENANT_DETAIL: "admin/tenants/:tenant",
    ADMIN_TENANT_DETAIL_GUI: "admin/tenants/:tenant/gui",
    ADMIN_TENANT_DETAIL_APPS: "admin/tenants/:tenant/apps",
    ADMIN_TENANT_DETAIL_APPS_USER_CREDENTIALS: "admin/tenants/:tenant/apps/:app/users/credentials",
    ADMIN_TENANT_DETAIL_USERS: "admin/tenants/:tenant/users",
    ADMIN_TENANT_DETAIL_USER_AUTH: "admin/tenants/:tenant/user-authentication",
    ADMIN_TENANT_FEED_UPLOAD: "admin/tenants/:tenant/feed",
    ADMIN_TENANT_HOMEPAGE_CUSTOMIZATION: "admin/tenants/:tenant/homepage-customization",

    /******************************* User Routes *****************************/

    USER_HOME: "apps",
    APPS_ZOOM: "apps/zoom",

    /******************************* Tenant Admin Routes *****************************/

    USER_ADMIN_HOME: "administration",
    USER_ADMIN_GUI: "administration/gui",
    USER_ADMIN_USER_AUTH: "administration/user-authentication",
    USER_ADMIN_APPS: "administration/apps",
    USER_ADMIN_APPS_USER_CREDENTIALS: "administration/apps/:app/users/credentials",
    USER_ADMIN_USERS: "administration/users",
    USER_ADMIN_DETAILS: "administration/details",
    USER_ADMIN_FEED_UPLOAD: "administration/feed",
    USER_ADMIN_HOMEPAGE_CUSTOMIZATION: "administration/homepage-customization"
};

const ALL_USER_ROLE_IDS = R.map(r => r.id, R.values(USER_ROLES));

const TENANT_ADMIN_USER_ROLE_IDS = [USER_ROLES.TENANT_ADMIN.id, USER_ROLES.USER.id];

export const ROLES_BASED_ROUTES = {

    /******************************* Common Routes *****************************/
    [ROUTES.HOME]: ALL_USER_ROLE_IDS,
    [ROUTES.SIGN_IN]: ALL_USER_ROLE_IDS,
    [ROUTES.RESET_PASSWORD]: ALL_USER_ROLE_IDS,
    [ROUTES.FORGOT_PASSWORD]: ALL_USER_ROLE_IDS,
    [ROUTES.SUCCESS_VIEW]: ALL_USER_ROLE_IDS,
    [ROUTES.VERIFY_EMAIL]: ALL_USER_ROLE_IDS,
    [ROUTES.REGISTER_USER]: ALL_USER_ROLE_IDS,

    /******************************* Admin Routes *****************************/

    [ROUTES.ADMIN_ACCESS_REQUESTS]: [USER_ROLES.ADMIN.id],
    [ROUTES.ADMIN_TENANTS]: [USER_ROLES.ADMIN.id],
    [ROUTES.ADMIN_CONFIGURATIONS]: [USER_ROLES.ADMIN.id],
    [ROUTES.ADMIN_COMMON_FEED_UPLOAD]: [USER_ROLES.ADMIN.id],
    [ROUTES.ADMIN_STANDARD_APPS]: [USER_ROLES.ADMIN.id],
    [ROUTES.ADMIN_EMAIL_TEMPLATES]: [USER_ROLES.ADMIN.id],
    [ROUTES.ADMIN_TENANT_DETAIL]: [USER_ROLES.ADMIN.id],
    [ROUTES.ADMIN_TENANT_DETAIL_GUI]: [USER_ROLES.ADMIN.id],
    [ROUTES.ADMIN_TENANT_DETAIL_APPS]: [USER_ROLES.ADMIN.id],
    [ROUTES.ADMIN_TENANT_DETAIL_APPS_USER_CREDENTIALS]: [USER_ROLES.ADMIN.id],
    [ROUTES.ADMIN_TENANT_DETAIL_USERS]: [USER_ROLES.ADMIN.id],
    [ROUTES.ADMIN_TENANT_DETAIL_USER_AUTH]: [USER_ROLES.ADMIN.id],
    [ROUTES.ADMIN_TENANT_FEED_UPLOAD]: [USER_ROLES.ADMIN.id],
    [ROUTES.ADMIN_TENANT_HOMEPAGE_CUSTOMIZATION]: [USER_ROLES.ADMIN.id],

    /******************************* User Routes *****************************/
    [ROUTES.SSO_LOGIN]: TENANT_ADMIN_USER_ROLE_IDS,
    [ROUTES.USER_HOME]: TENANT_ADMIN_USER_ROLE_IDS,
    [ROUTES.APPS_ZOOM]: TENANT_ADMIN_USER_ROLE_IDS,

    /******************************* Tenant Admin Routes *****************************/
    [ROUTES.USER_ADMIN_HOME]: [USER_ROLES.TENANT_ADMIN.id],
    [ROUTES.USER_ADMIN_GUI]: [USER_ROLES.TENANT_ADMIN.id],
    [ROUTES.USER_ADMIN_USER_AUTH]: [USER_ROLES.TENANT_ADMIN.id],
    [ROUTES.USER_ADMIN_APPS]: [USER_ROLES.TENANT_ADMIN.id],
    [ROUTES.USER_ADMIN_APPS_USER_CREDENTIALS]: [USER_ROLES.TENANT_ADMIN.id],
    [ROUTES.USER_ADMIN_USERS]: [USER_ROLES.TENANT_ADMIN.id],
    [ROUTES.USER_ADMIN_DETAILS]: [USER_ROLES.TENANT_ADMIN.id],
    [ROUTES.USER_ADMIN_FEED_UPLOAD]: [USER_ROLES.TENANT_ADMIN.id],
    [ROUTES.USER_ADMIN_HOMEPAGE_CUSTOMIZATION]: [USER_ROLES.TENANT_ADMIN.id]
};

export const COMMON_FEED_SAMPLE = require('./common-feed-sample.json');

export const DEFAULT_PROFILE_LOGO = "assets/images/default-profile-pic.svg";

export const DEFAULT_COUNTRY_CODE = "+1";

export const PAGE_TYPE = {
    HOME_PAGE: 'HOME_PAGE'
};

