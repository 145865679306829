export const SOURCE_URL_DATA = [
    {
        "title" : "Feeds heading here",
        "description" : "While Athena appears designed to fit into \n" +
            "Google all kind of requirement.",
        "image" : "https://backflipt.com/wp-content/uploads/2017/08/Backflipt_AI.png",
        "logo" : "assets/images/feed-logo.png",
        "date" : "4/02/2020 14:30 PST",
        "author" : "Clarence K. Smith",
        "authorPhotoUrl" : "https://www.lipsum.com/authorPhoto.png"
    },
    {
        "title" : "Feeds heading here",
        "description" : "While Athena appears designed to fit into \n" +
            "Google all kind of requirement.",
        "image" : "https://backflipt.com/wp-content/uploads/2017/08/Backflipt_AI.png",
        "logo" : "assets/images/feed-logo.png",
        "date" : "02/12/2020 15:30 PST",
        "author" : "Kevin Townsend",
        "authorPhotoUrl": "https://www.securityweek.com/Kevin TownSend/Kevin.png"
    },
    {
        "title" : "Feeds heading here",
        "description" : "While Athena appears designed to fit into \n" +
            "Google all kind of requirement.",
        "image" : "https://backflipt.com/wp-content/uploads/2017/08/Backflipt_AI.png",
        "logo": "assets/images/feed-logo.png",
        "date": "26/05/2020 6:30 PST",
        "author": "Zerah Dacan",
        "authorPhotoUrl":"https://homepages.cae.wisc.edu/~ece533/images/mountain.png"
    }
];

export const ATTR_MAPPING = {
    "title": "title",
    "description":"description",
    "image": "image",
    "logo": "logo",
    "date": "date",
    "author": "author",
    "readMoreHyperlink": "readMoreHyperlink"
};