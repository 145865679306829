import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import React from 'react'
import {getTenantById, updateSSOConfigForTenant, updateTenant} from '../../service/tenant-service'
import {
    getAttrValueForEventFromClosest,
    getClosestForEventWithAttribute,
    serializeForm,
    validateForm,
    VALIDATION_TYPE
} from '../../Backflipt_UI_Components/utils/generic-utils'
import $ from "jquery";
import { getTitle } from '../../utils/domain-utils'

const R = require('ramda');

export default class TenantDetailUserAuth extends UIBaseComponent {

    beforeRender() {
        this.loadDataAsync();
        return super.beforeRender();
    }

    loadDataAsync() {
        if(this.state.dataLoaded) return Promise.resolve();
        return getTenantById(this.props.tenant.id)
            .then(tenant => this.setState({originalTenant: tenant, tenant, ssoConfig: null, dataLoaded: true}));
    }

    saveUserAuthType = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let errors = validateForm($form);
        let tenant = R.clone(this.state.tenant);

        tenant.ssoConfig = serializeForm($form);
        let certificate = this.state.certificate;
        if(tenant.ssoEnabled && !certificate) errors.certificate = true;

        if(!R.isEmpty(errors) && tenant.ssoEnabled) return this.setState({tenant, errors});

        if(!R.equals(tenant, this.state.originalTenant)) {
            return this.setState({loading: true})
                .then(_ => tenant.ssoEnabled? updateSSOConfigForTenant(tenant.id, tenant.ssoEnabled, tenant.ssoConfig, certificate) : updateTenant(tenant))
                .then(tenant => this.setState({originalTenant: tenant, tenant, errors: null, loading: false, certificate: null}));
        }
    };

    resetUserAuthType = (e) => this.setState({tenant: this.state.originalTenant, errors: null});

    hasUnSavedChanges() {
        let tenant = this.state.tenant || {};
        let originalTenant = this.state.originalTenant || {};
        return !R.equals(tenant, originalTenant) || !!this.state.certificate;
    }

    toggleEnableDisableUserAuth = (e) => {
        let tenant = R.clone(this.state.tenant);
        let key = getAttrValueForEventFromClosest(e, 'data-value');
        if(key === "normal") {
            tenant.ssoEnabled = false;
            return this.setState({tenant, errors: null});
        }
        tenant.ssoEnabled = true;
        let attributes = tenant.attributes;
        attributes.twoFactorAuthEnabled = false;
        tenant.attributes = attributes;
        return this.setState({tenant, errors: null});
    };

    toggleEnableDisableTFA = (e) => {
        let tenant = R.clone(this.state.tenant);
        let attributes = tenant.attributes;
        attributes.twoFactorAuthEnabled = !attributes.twoFactorAuthEnabled;
        tenant.attributes = attributes;
        return this.setState({tenant, errors: null})
    };

    // toggleEnableDisable = (e) => {
    //     let $form = getClosestForEventWithAttribute(e, 'data-form-id');
    //     let tenant = R.clone(this.state.tenant);
    //     let ssoConfig = serializeForm($form);
    //     tenant.ssoConfig = tenant.ssoConfig === null && this.isObjectEmpty(ssoConfig)? tenant.ssoConfig: ssoConfig;
    //     let ssoEnabled = e.target.value === "true";
    //     if(tenant.ssoEnabled !== ssoEnabled){
    //         tenant.ssoEnabled = ssoEnabled;
    //         return this.setState({tenant, errors: null});
    //     }
    // };

    isObjectEmpty(obj){
        return Object.values(obj).every(x => (x === null || x === ''));
    }

    getView() {
        return super.getView()
            .then(_ => {

                let tenant = this.state.tenant || {};
                let ssoConfig = tenant.ssoConfig || {};
                let ssoEnabled = tenant.ssoEnabled || false;
                let twoFactorAuthEnabled = (tenant.attributes && tenant.attributes.twoFactorAuthEnabled) || false;
                let errors = this.state.errors || {};
                let title = getTitle();

                if(this.state.dataLoaded)
                return (
                    <div data-form-id="sso-config" className="sso-settings-tab-content-wrapper">
                        <div className="sso-settings-wrapper">
                            <div className="sso-header-wrapper">
                                <div className="sso-header-text">
                                    <p className="hide">{"Single Sign-On allows your users to login to " + title + " portal using external credentials, such as an Active Directory user account, using SAML."}</p>
                                    <p>User Authentication Methods</p>
                                </div>
                                <div className="sso-header-action-btns">
                                    <div className="actions">
                                        <div className={"ui primary button buttons-primary-text buttons-primary-background " + (this.state.loading? "loading disabled" : "") + (this.hasUnSavedChanges() ? "" : " disabled")} onclick={this.saveUserAuthType}>Save</div>
                                        <div className={"ui cancel button buttons-secondary-text buttons-secondary-background" + (this.hasUnSavedChanges() ? "" : " disabled")} onclick={this.resetUserAuthType}>Cancel</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui divider sso-divider"/>
                            <div className="sso-settings-status-wrapper ics360-creden">
                                <input type="radio" data-value="normal" onclick={this.toggleEnableDisableUserAuth} checked={ssoEnabled? "" : "checked"}/>
                                    <p className="secondary-header primary-text">IC360 Credentials</p>
                                    <div className="sso-settings-status-detail-wrapper ">
                                        <div className="ic360-credentials-wrapper">
                                            <p className="sso-secondary-text">By default, users provide username and
                                                password to authenticate with IC360 app</p>
                                            <div className="ui checkbox"><input id="enable-tfa"
                                                                                type="checkbox"
                                                                                onclick={this.toggleEnableDisableTFA} disabled={ssoEnabled? "true": ""} checked={twoFactorAuthEnabled && !ssoEnabled? "checked": ""}/><label
                                                htmlFor="enable-tfa">Enable Two Factor
                                                Authentication</label></div>
                                            <p className="sso-secondary-text">When this option is selected, apart from
                                                providing username and password, users will have to enter the One Time
                                                Password sent to their registered mobile number to access the IC360
                                                app.</p>
                                        </div>
                                    </div>
                            </div>
                            <div className="ui divider sso-divider"/>
                            <div className="sso-settings-status-wrapper">
                                <input type="radio" data-value="sso" onclick={this.toggleEnableDisableUserAuth} data-name="enable-sso-config-input" checked={ssoEnabled? "checked": ""}/>
                                <p className="secondary-header primary-text">Single Sign-On</p>
                                <p className="sso-secondary-text">{"Single Sign-On allows your users to login to " + title + " portal using external credentials, such as an Active Directory user account, using SAML."}</p>
                                <div className="ui form checkbox-form-wrapper hide">
                                    <div className="inline fields">
                                        <div className="field">
                                            <div className="ui radio checkbox">
                                                <input id="enable-sso-config-input-enable" type="radio" name="enable-sso-config-input" onclick={this.toggleEnableDisable} value="true" checked={ssoEnabled ? "checked": ""}/>
                                                <label htmlFor="enable-sso-config-input-enable">Enable</label>
                                            </div>
                                        </div>
                                        <div className="field">
                                            <div className="ui radio checkbox">
                                                <input data-value="false" id="enable-sso-config-input-disable" type="radio" name="enable-sso-config-input" onclick={this.toggleEnableDisable} value="false" checked={ssoEnabled ? "": "checked"}/>
                                                <label htmlFor="enable-sso-config-input-disable">Disable</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ui divider sso-status-divider"/>
                                </div>
                                <div className={"sso-settings-status-detail-wrapper " + (ssoEnabled? "": "hide")}>
                                    <table className="ui table">
                                        <tbody>
                                            <tr>
                                                <td>Entity Id<span className="mandatory-start">*</span></td>
                                                <td>
                                                    <input type="text" data-required="true" data-key="entityId" className={errors.entityId ? "validation-error" : ""} data-validation-type={VALIDATION_TYPE.NON_EMPTY} value={ssoConfig.entityId || ""} autofocus={true} oninput={this.onValueChange}/>
                                                    <p className={"error-text " + (errors.entityId ? "" : "hide")}>EntityId cannot be empty</p>
                                                </td>
                                            </tr>
                                            <tr className="hide">
                                                <td>Logout page URL</td>
                                                <td>
                                                    <input type="text" data-key="logoutUrl" data-validation-type={VALIDATION_TYPE.URL} value={ssoConfig.logoutUrl || ""} className={errors.logoutUrl ? "validation-error" : ""} placeholder=" Example:https://idp.company.com/i"  oninput={this.onValueChange}/>
                                                    <p className={"error-text " + (errors.logoutUrl ? "" : "hide")}>Please enter a valid Logout URL</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Sign-in page URL <span className="mandatory-start">*</span></td>
                                                <td>
                                                    <input type="text" data-required="true" data-key="loginUrl" data-validation-type={VALIDATION_TYPE.URL} value={ssoConfig.loginUrl || ""} className={errors.loginUrl ? "validation-error" : ""} placeholder=" Example: https://www.integrated-cyber.com" oninput={this.onValueChange}/>
                                                    <p className={"error-text " + (errors.loginUrl ? "" : "hide")}>Please enter a valid Sign-in URL</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Identity Provider Certificate<span className="mandatory-start">*</span>
                                                </td>
                                                <td>
                                                    <input type="file" className={errors.certificate ? "validation-error" : ""} accept=".cert" onchange={this.newCertificateUploaded}/>
                                                    <p className={"error-text " + (errors.certificate ? "" : "hide")}>Please upload a valid certificate</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Current Certificate</td>
                                                <td>
                                                    {this.getCertificateDownloadView()}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
    }

    getCertificateDownloadView() {
        let ssoConfig = this.state.tenant && this.state.tenant.ssoConfig;
        let certificate = (ssoConfig && ssoConfig.certificate) || this.state.certificate;
        if(certificate) {
            let fileName = `idp-${this.props.tenant.name}.cert`;
            let url = this.state.certificate ? window.URL.createObjectURL(this.state.certificate) : `data:application/x-binary;charset=utf-8,${encodeURIComponent(certificate)}`;
            return (
                <a href={url} download={fileName}>Download</a>
            );
        }
        return "No certificate uploaded";
    }

    newCertificateUploaded = (e) => {
        let certificate = e.target.files[0];
        let errors = R.clone(this.state.errors || {});
        delete errors.certificate;
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let tenant = R.clone(this.state.tenant);
        tenant.ssoConfig = serializeForm($form);
        tenant.ssoConfig.certificate = certificate;
        return this.setState({errors, certificate, tenant});
    };

    onValueChange = (e) => {
        let tenant = R.clone(this.state.tenant);
        let dataKey = getAttrValueForEventFromClosest(e,'data-key');
        let keys = R.keys(tenant.ssoConfig);
        let errors = R.clone(this.state.errors) || {};
        delete errors[dataKey];
        if(R.includes(dataKey, keys)) {
            tenant.ssoConfig[dataKey] = e.target.value;
            return this.setState({tenant, errors})
                .then(_ => $(e.target).focus());
        }};

    postRenderView () {
        return super.postRenderView()
            .then(this.applyAutoFocus)
            ;
    }

    applyAutoFocus = () => this.$container.find('[autofocus]').focus();
}