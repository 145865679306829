import React from 'react'
import Bluebird from 'bluebird'
import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import { getAttrValueForEventFromClosest } from '../../Backflipt_UI_Components/utils/generic-utils'

const R = require('ramda');

class Modals extends UIBaseComponent {

    beforeRender() {
        this.initData();
        return super.beforeRender();
    }

    initData() {
        this.state.modals = [];
    }

    getView() {
        return super.getView()
            .then(_ => {

                return R.map(mc => {

                    if(mc.standAlone) return <div id={mc.id}/>;
                    return (
                        <div id={mc.id} className="ui dimmer modals page transition visible active" style="display: flex !important;">
                            {mc.view}
                        </div>
                    );
                }, this.state.modals);
            });
    }

    closeModal = (e) => {
        let id = getAttrValueForEventFromClosest(e, 'data-modal-id');
        let mc = R.find(mc => mc.id === id, this.state.modals);
        if(mc && mc.onModalClose) mc.onModalClose();
        if(id) ModalContainer.removeModal(id);
    };

    postRenderView() {
        return super.postRenderView()
            .then(_ => {
                let prs = R.map(mc => {
                    if(!mc.claz) return Promise.resolve();
                    if(!mc.instance) {
                        let claz = mc.claz;
                        mc.instance = new claz(this.$container.find('#' + mc.id)[0], mc.props);
                        return mc.instance.render();
                    }
                    return mc.instance.renderView();
                }, this.state.modals);
                return Bluebird.all(prs);
            });
    }
}

let modalContainer = new Modals(document.getElementById('modal-container'));

modalContainer.render();

let ModalContainer = {

    addModalComponent: (id, claz, props = {}, extension = {}) => {
        let modals = R.append(R.merge({id, claz, props, standAlone: true}, extension), modalContainer.state.modals);
        return modalContainer.setState({modals});
    },

    addModal: (id, claz, props = {}, extension = {}) => {
        let modals = R.append(R.merge({id, claz, props}, extension), modalContainer.state.modals);
        return modalContainer.setState({modals});
    },

    addModalView: (id, view, props = {}, extension = {}) => {
        let modals = R.append(R.merge({id, view, props}, extension), modalContainer.state.modals);
        return modalContainer.setState({modals});
    },

    updateModalView: (id, view, props = {}, extension = {}) => {
        let index = R.findIndex(mc => mc.id === id, modalContainer.state.modals);
        if(index < 0) return;
        let old = modalContainer.state.modals[index];
        props = R.merge(old.props || {}, props ||{});
        let modals = R.update(index, R.merge(old, R.merge({id, view, props}, extension)), modalContainer.state.modals);
        return modalContainer.setState({modals});
    },

    getModal: (id) => {
        return R.find(mc => mc.id === id, modalContainer.state.modals);
    },

    getModelEl: (id) => modalContainer.$container.find('#' + id),

    removeModal: (id) => {
        let mc = ModalContainer.getModal(id);
        if(mc) {
            let modals = R.reject(mc => mc.id === id, modalContainer.state.modals);
            return modalContainer.setState({modals})
                .then(_ => mc.instance && mc.instance.destroy())
                .then(_ => mc.$modal && mc.$modal.modal('hide') && mc.$modal.remove());
        }
        return Promise.resolve();
    }
};

export default ModalContainer;