import EventsHandler from '../event-handler'
import Events from '../config/events'
import * as UserProfileService from '../service/user-profile-service'
import * as UserService from '../service/user-service'
import $ from 'jquery'

import AppCache from '../config/app-cache'
import { ROLES_BASED_ROUTES, SESSION_ID } from '../config/app-config'
import { getAllNodesForPath, getValueForPathOrDefault } from '../Backflipt_UI_Components/utils/generic-utils'
import { getLogoUrlForTenant } from '../service/tenant-service'

const R = require('ramda');

export let isUserSignedIn = () => !!getSessionId();

export let getSessionId = () => AppCache.session && AppCache.session[SESSION_ID];

export let setSession = (session) => {
    AppCache.session = session;
    return UserService.getMyTenantDetails()
        .then(setMyTenantDetails);
};

export let getSession = () => AppCache.session;

export let logout = () => {
    if(isUserSignedIn()) {
        R.forEach(k => delete AppCache[k], R.keys(AppCache));
        EventsHandler.push({name: Events.SIGN_OUT});
        if(window.signout) window.signout();
    } else {
        EventsHandler.push({name: Events.GO_TO_SIGNIN});
    }
};

export let createSession = () => {
    if(isUserSignedIn()) return Promise.resolve();
    return UserProfileService.autoLogin();
};

export let getProfile = () => AppCache.session;

export let getUserPreferences = () => {
    let attributes = (getProfile() || {}).attributes || {};
    let preferences = attributes.preferences || {};
    return preferences;
};

export let getMyRoles = () => (getProfile() || {}).roles || [];

export let amIAdmin = () => R.any(x => x === "ADMIN", getMyRoles());

export let getMyTenantId = () => getProfile().tenantId;

export let getMyTenantDetails = () => AppCache.tenantDetails;

export let setMyTenantDetails = (tenant) => {
    AppCache.tenantDetails = tenant;
    setMyTenantStyles();
    return tenant;
};

export let setMyPreferences = (preferences) => {
    AppCache.myPreferences = preferences;
    return preferences;
};

export let getMyPreferences = () => AppCache.myPreferences || {};

export let isRouteAllowedForMe = (route) => !R.isEmpty(R.intersection(getMyRoles(), ROLES_BASED_ROUTES[route] || []));

export let getColorForAttribute = (attributeName) => getValueForPathOrDefault(getMyTenantDetails(), "attributes.colors." + attributeName);

export let getStyleAttributeForColor = (attributeName) => {
    let color = getColorForAttribute(attributeName);
    return color && {style: "color: " + color + " !important;"};
};

export let getStyleAttributeForBGColor = (attributeName) => {
    let backgroundColor = getColorForAttribute(attributeName);
    return backgroundColor && {style: "background-color: " + backgroundColor + " !important;"};
};

export let setMyTenantStyles = () => {
    let tenant = getMyTenantDetails() || {};
    let backgroundColors = getAllNodesForPath(tenant, "attributes.colors..background") || [];
    backgroundColors = R.join(" ", R.map(obj => {
        let path = R.join("-", R.drop(2, obj.path));
        return `.${path} { background-color: ${obj.value} !important; }`
    }, backgroundColors));
    let textColors = getAllNodesForPath(tenant, "attributes.colors..text") || [];
    textColors = R.join(" ", R.map(obj => {
        let path = R.join("-", R.drop(2, obj.path));
        return `.${path} { color: ${obj.value} !important; }`
    }, textColors));
    let html = backgroundColors + " " + textColors;
    return $('#tenant-styles').html(html);
};

export let getMyTenantLogoUrl = () => getLogoUrlForTenant(getMyTenantDetails());