import UIBaseComponent from "../../Backflipt_UI_Components/src/ui-base-component/ui-base-component";
import {
    DATA_TYPE,
    getAttrValueForEventFromClosest,
    mapIndexed
} from "../../Backflipt_UI_Components/utils/generic-utils";
import React from "react";
import {getAllLayouts} from "../../service/tenant-service";
import {orderByAsc} from "../../utils/generic-utils";

const R = require("ramda");

export default class Layouts extends UIBaseComponent {


    getLoadingView() {
        return <div className="common-loading-text">Loading...</div>
    }
    beforeRender() {
        // this.state = {
        //     selectedLayoutId: this.props.selectedLayoutId
        // };
        this.loadDataAsync();
        return super.beforeRender();
    }

    loadDataAsync() {
        let layouts = this.props.layouts;
        if(this.props.loadLayouts) {
            return getAllLayouts()
                .then(resp => {
                    layouts = resp || [];
                    layouts = orderByAsc(layouts, "name");
                    return this.setState({layouts, dataLoaded: true})
                });
        }
        else {
            let dataLoaded = null;
            if(layouts) dataLoaded = true;
            return this.setState({layouts, dataLoaded});
        }
    }

    getView() {
        return super.getView()
            .then(_ => {

                const view = this.state.dataLoaded? this.getLayoutsView(): this.getLoadingView();

                return (
                    <div className="layouts">
                        <div className="layout-lists-wrapper custom-scrolling">
                        <h1>Layouts</h1>
                        {view}
                        </div>
                    </div>
                );
            });
    }

    getLayoutsView = () => {
        if(!(this.state.layouts)) return;
        const views = mapIndexed((layout, index) => this.getLayoutView(index, layout), this.state.layouts || []);
        if(R.isEmpty(views)) return "No Layouts Available";
        return views;
    };

    getLayoutView = (index, layout) => {

        let views = this.getCellsView(layout.config && layout.config.cells);
        this.position = 0;
        return (
            <div data-id={layout.id} data-type={DATA_TYPE.NUMBER} onclick={this.chooseLayout} className={"layout custom-scrolling " + (layout.id === this.props.selectedLayoutId  ? "selected-layout" : "")}>
                {views}
            </div>
        )
    };

    getCellsView(contents) {
        if(!contents) return null;

        let self = this;

        return mapIndexed((content, index) => {

            let contentType = content.type || "ROW";

            let childContents = self.getCellsView(content.content);

            return (contentType === "ROW") ? this.getLayoutRowView(index, childContents) : this.getLayoutColumnView(index, ++this.position, childContents);

        }, contents);
    }

    getLayoutRowView(index, children) {
        return (
            <div className={"row cols-" + (children.length)}>{children}</div>
        );
    }

    getLayoutColumnView(index, layoutIndex, children) {
        return (
            <div className="col">
                <div className="section-numbers">
                    {layoutIndex + children}
                </div>
            </div>
        );
    }

    chooseLayout = (e) => {
        const selectedLayoutId = getAttrValueForEventFromClosest(e, 'data-id');
        return this.props.onLayoutSelected && this.props.onLayoutSelected(selectedLayoutId);
    };
}
