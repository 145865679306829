import {getClientAppInstallerName} from "../utils/domain-utils";

const uri = window.location.href.replace(window.location.hash, "");

const API_HOST = uri + (uri.endsWith("/") ? "" : "/") + 'api';

const buildConfig = window.buildConfig || {};

const CLIENT_APP_URI = buildConfig.clientAppUri;

const Endpoints = {

    VALIDATE_USER_NAME: API_HOST + `/auth/user_name`,
    VALIDATE_EMAIL_WITH_TENANT: API_HOST + `/auth/email/tenant`,
    SIGN_IN: API_HOST + '/auth/login',
    USER_SIGN_IN: API_HOST + '/auth/user/login',
    SIGN_OUT: API_HOST + '/me/logout',
    GET_PROFILE: API_HOST + '/me',
    GET_MY_APP_CREDENTIALS: () => Endpoints.GET_PROFILE + "/apps/credentials",
    UPDATE_PASSWORD: API_HOST + '/me/password',
    FORGOT_PASSWORD: API_HOST + '/auth/forgot/password',
    RESET_PASSWORD: API_HOST + '/auth/password/reset',
    VERIFY_EMAIL_TOKEN: (token) => API_HOST + `/auth/token/${token}`,
    REGISTER_USER: (token) => API_HOST + `/auth/register/${token}`,
    CLIENT_APP_DOWNLOAD_URL: (fileExtension) => `${CLIENT_APP_URI}/${getClientAppInstallerName()}.${fileExtension}`,

    GET_ALL_TENANTS: API_HOST + "/tenants",
    GET_TENANT_BY_ID: (id) => Endpoints.GET_ALL_TENANTS + `/${id}`,

    UPDATE_SSO_CONFIG_FOR_TENANT: (id) => Endpoints.GET_TENANT_BY_ID(id) + '/sso_config',
    GET_LOGO_FOR_TENANT: (id, version = 0) => Endpoints.GET_TENANT_BY_ID(id) + `/logo?v=${version}`,
    UPDATE_APP_SEQUENCE_FOR_TENANT: (id) => Endpoints.GET_TENANT_BY_ID(id) + `/appSequence`,

    GET_ALL_STANDARD_APPS: API_HOST + "/standardApps",
    GET_STANDARD_APP_BY_ID: (id) => Endpoints.GET_ALL_STANDARD_APPS + `/${id}`,
    GET_APP_CONFIG_FOR_STANDARD_APP: (id) => Endpoints.GET_STANDARD_APP_BY_ID(id) + `/appConfig`,
    UPDATE_STANDARD_APP_SEQUENCE: (id) => Endpoints.GET_STANDARD_APP_BY_ID(id) + `/appSequence`,

    GET_ALL_APPS_FOR_TENANT: (tenantId) => Endpoints.GET_TENANT_BY_ID(tenantId) + "/apps",
    ADD_STANDARD_APP_FOR_TENANT: (tenantId) => Endpoints.GET_ALL_APPS_FOR_TENANT(tenantId) + "/standardApps",
    GET_APP_FOR_TENANT_BY_ID: (tenantId, id) => Endpoints.GET_ALL_APPS_FOR_TENANT(tenantId) + `/${id}`,
    GET_APP_CONFIG_FOR_APP: (tenantId, id) => Endpoints.GET_APP_FOR_TENANT_BY_ID(tenantId, id) + `/appConfig`,

    GET_ALL_USER_CREDENTIALS_FOR_APP: (tenantId, appId) => Endpoints.GET_APP_FOR_TENANT_BY_ID(tenantId, appId) + '/users/credentials',
    GET_USER_CREDENTIALS_FOR_APP: (tenantId, appId, userId) => Endpoints.GET_APP_FOR_TENANT_BY_ID(tenantId, appId) + `/users/${userId}/credentials`,
    UPDATE_LOGIN_TIME_FOR_APP_USER: (tenantId, appId, userId) => Endpoints.GET_USER_CREDENTIALS_FOR_APP(tenantId, appId, userId) + `/lastLoginTime`,

    SEND_REGISTRATION_MAIL_TO_USER: (tenantId, id) => Endpoints.GET_USER_FOR_TENANT_BY_ID(tenantId, id) + `/send/mail`,
    GET_ALL_USERS_FOR_TENANT: (tenantId) => Endpoints.GET_TENANT_BY_ID(tenantId) + "/users",
    GET_USER_FOR_TENANT_BY_ID: (tenantId, id) => Endpoints.GET_ALL_USERS_FOR_TENANT(tenantId) + `/${id}`,
    GET_IMAGE_FOR_USER: (userId, version = 0) => API_HOST + `/me/${userId}/image?v=${version}`,

    GET_TENANT_ACTIVITY_STATS: (tenantId) => Endpoints.GET_TENANT_BY_ID(tenantId) + "/activity/stats",

    LOGIN_SEND_OTP: API_HOST + '/auth/login/otp/send',
    LOGIN_VALIDATE_OTP: API_HOST + '/auth/login/otp/validate',

    REGISTER_SEND_OTP: (token) => API_HOST + `/auth/register/otp/send/${token}`,
    REGISTER_VALIDATE_OTP: (token) => API_HOST + `/auth/register/otp/validate/${token}`,

    GET_COMMON_FEED: API_HOST + "/feed/common",
    GET_TENANT_FEED: (tenantId) => API_HOST + `/tenants/${tenantId}/feed`,
    GET_FEED_DETAILS: (id) => Endpoints.GET_TENANT_BY_ID(id) + "/feed-details",
    GET_RSS_FEED: (feedNames) => API_HOST + `/feed/rss/${feedNames}`,

    GET_JSON: API_HOST + `/auth/getJSON`,

    STORE_KEY_VALUE: API_HOST + '/config',
    GET_VALUE_FOR_KEY: (key) => Endpoints.STORE_KEY_VALUE + `/${key}`,
    GET_ALL_VALUES_FOR_KEYS: (keys) => Endpoints.STORE_KEY_VALUE + `/all/${keys}`,

    GET_COUNTRY_CODES: API_HOST + '/auth/countryCodes',

    GET_ALL_LAYOUTS: API_HOST + '/layouts',
    GET_LAYOUT_BY_ID: (id) => Endpoints.GET_ALL_LAYOUTS + `/${id}`,

    GET_ALL_PAGES_FOR_TENANT: (tenantId) => Endpoints.GET_TENANT_BY_ID(tenantId) + `/pages`,
    GET_PAGE_FOR_TENANT_BY_ID: (id, tenantId) => Endpoints.GET_ALL_PAGES_FOR_TENANT(tenantId) + `/${id}`,
    PUBLISH_HOMEPAGE_FOR_TENANT: (id, tenantId) => Endpoints.GET_ALL_PAGES_FOR_TENANT(tenantId) + `/${id}/publish`,
    UNPUBLISH_HOMEPAGE_FOR_TENANT: (id, tenantId) => Endpoints.GET_ALL_PAGES_FOR_TENANT(tenantId) + `/${id}/unPublish`
};

export default Endpoints;