import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import React from 'react'
import * as HttpUtils from '../../utils/http-utils'
import EventsHandler from '../../event-handler'
import Events from '../../config/events'
import { setNewPassword } from '../../service/user-profile-service'
import {
    convertToMailToUrl,
    getClosestForEventWithAttribute, getValueForPathOrDefault,
    serializeForm,
    validateForm, VALIDATION_TYPE
} from '../../Backflipt_UI_Components/utils/generic-utils'
import Endpoints from '../../config/endpoints'
import {ROUTES} from '../../config/app-config'
import { goToRouteOnEvent } from '../../utils/generic-utils'
import $ from "jquery";
import {ERRORS} from "../../config/errors";
import {getAdminMail, getLogoUrl} from '../../utils/domain-utils'

const R = require('ramda');

export default class ResetPassword extends UIBaseComponent {

    beforeRender() {
        this.setState({enableButton: false});
        this.verifyToken();
        return super.beforeRender();
    };

    verifyToken() {
        return HttpUtils.getData(Endpoints.VERIFY_EMAIL_TOKEN(this.props.token))
            .then(resp => this.setState({verified: true, dataLoaded: true}))
            .catch(resp => {
                let error = (resp && resp.responseJSON) || {};
                error.description = error.description || ERRORS.RESET_PASSWORD[error.code] || ERRORS.COMMON;
                return this.setState({verified: false, dataLoaded: true, errors: {tokenError: error}})
            });
    }


    onPasswordChanged = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            getClosestForEventWithAttribute(e, 'data-form-id')
                .find('#submit-button')
                .click();
        }
    };

    getLoadingView() {
        return (
            <div className="main-loading-text">
                Loading...
            </div>
        )
    }

    getSuccessView() {
        let errors = this.state.errors || {};

        let adminMail = getAdminMail();
        let mailLink = convertToMailToUrl(adminMail);

        let formLogo = getLogoUrl();
        return (
            <div data-form-id="reset-password" className="ics-form-wrapper ics-admin-reset-password-wrapper">
                <div className="ics-common-wrapper">
                    <div className="form-common-header-wrapper">
                        <img src={formLogo} className="ics-form-logo" alt=""/>
                        <span className="ics-form-header-pipeline"></span>
                        <span className="form-header-text"> <h3>Reset Password</h3></span>
                        <p className="login-content-secondary-text hide">Password must contain 6 to 12 characters.</p>
                    </div>
                    <div className="ics-common-body-wrapper">
                        <div className="common-input-label-wrapper">
                            <div className="ui form">
                                <div className="inline field">
                                    <label>Password<span className="mandatory-start">*</span></label>
                                    <input data-key="newPassword" data-required = "true" data-validation-type={VALIDATION_TYPE.NON_EMPTY} type="password" id="password-input" onkeyup={this.onPasswordChanged} oninput={this.onValueChange} autofocus={true}/>
                                    <p className={"error-text " + (errors.newPassword ? "" : "hide")}>Password cannot be empty</p>
                                </div>
                            </div>
                        </div>
                        <div className="common-input-label-wrapper">
                            <div className="ui form">
                                <div className="inline field">
                                    <label className="retype-password-label">Re-type Password<span className="mandatory-start">*</span></label>
                                    <input data-key="confirmPassword" data-required = "true" data-validation-type={VALIDATION_TYPE.NON_EMPTY} type="password" id="retype-password-input" onkeyup={this.onPasswordChanged} oninput={this.onValueChange}/>
                                    <p className={"error-text " + (errors.admin ? "" : "hide")}>Your account is disabled! Please contact your administrator at <a href={mailLink}>{adminMail}</a></p>
                                    <p className={"error-text " + (errors.confirmPassword ? "" : "hide")}>{(errors.confirmPassword && errors.confirmPassword.message) || "Passwords didn't match."}</p>
                                    </div>
                            </div>
                        </div>
                        <div className="common-form-btn-wrapper">
                            <button id="submit-button" className={"ui right labeled icon button " + (this.state.loading? "loading disabled" : "")} onclick={this.resetPassword}>
                                <i className="right arrow icon"></i>
                                Create Password
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    getErrorView(){
        let errors = this.state.errors;
        let tokenErrorDesc = getValueForPathOrDefault(errors, "tokenError.description", ERRORS.COMMON);
        let formLogo = getLogoUrl();
        return (
            <div className="ics-form-wrapper successful-error-wrapper">
                <div className="ics-common-wrapper">
                    <div className="form-common-header-wrapper">
                        <img src={formLogo} className="ics-form-logo" alt=""/>
                    </div>
                    <div className="ics-common-body-wrapper">
                        <div className="success-error-img-wrapper">
                            <img src="assets/images/login-succes-icon.png" className="hide" alt=""/>
                            <img src="assets/images/login-error-icon.png" alt=""/>
                        </div>
                        <h3 className="form-secondary-header-text">{tokenErrorDesc}</h3>
                    </div>
                    <div className="common-form-btn-wrapper hide">
                        <button className={"ui right labeled icon button " + (this.state.loading? "loading disabled" : "")} data-route={ROUTES.SIGN_IN} onclick={goToRouteOnEvent}>
                            <i className="right arrow icon"></i>
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    getView() {
        return super.getView()
            .then(_ => {
                if(!this.state.dataLoaded) return this.getLoadingView();
                if (this.state.verified) return this.getSuccessView();
                return this.getErrorView()
                }
            );
    };

    resetPassword = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let errors = validateForm($form) || {};
        let data = serializeForm($form);
        if(data.newPassword !== data.confirmPassword) errors.confirmPassword = true;
        if (!R.isEmpty(errors)) return this.setState({errors});
        data.token = this.props.token;
        return this.setState({loading: true, errors: null})
            .then(_ => setNewPassword(data))
            .then(resp => {
                    if (resp && resp.success) return EventsHandler.push({name: Events.GO_TO_ROUTE, route: ROUTES.SUCCESS_VIEW});
                }
            )
            .catch(resp => {
                let error = (resp && resp.responseJSON) || {};
                if(ERRORS.CONTACT.ADMIN.includes(error.code))
                    return this.setState({loading: false, errors: {admin: true}});
                error.description = error.description || ERRORS.RESET_PASSWORD[error.code] || ERRORS.COMMON;
                return this.setState({verified: false, dataLoaded: true, errors: {tokenError: error}});
                });
    };

    postRenderView () {
        return super.postRenderView()
            .then(this.applyAutoFocus)
            ;
    }

    onValueChange = (e) => {
        return this.setState({errors: null})
            .then(_ => $(e.target).focus());
    };

    applyAutoFocus = () => this.$container.find('[autofocus]').focus();
}