import Endpoints from '../config/endpoints'
import * as HttpUtils from '../utils/http-utils'
import {setMyTenantDetails} from '../dao/user-profile-dao'
import {getValueForPathOrDefault, prepareRESTURI} from '../Backflipt_UI_Components/utils/generic-utils'
import {ROUTES} from '../config/app-config'
import base64 from 'base-64';
import {getEmailTemplatesCustomVariablesURL} from "../utils/domain-utils";

export let getAllTenants = () => HttpUtils.getData(Endpoints.GET_ALL_TENANTS);
export let createTenant = (tenant) => HttpUtils.postData(Endpoints.GET_ALL_TENANTS, tenant);
export let updateTenant = (tenant) => HttpUtils.putData(Endpoints.GET_TENANT_BY_ID(tenant.id), tenant);
export let getTenantById = (id) => HttpUtils.getData(Endpoints.GET_TENANT_BY_ID(id));
export let deleteTenantById = (id) => HttpUtils.deleteData(Endpoints.GET_TENANT_BY_ID(id));
export let updateMyTenant = (tenant) => updateTenant(tenant)
    .then(setMyTenantDetails);

export let getStandardApps = () => HttpUtils.getData(Endpoints.GET_ALL_STANDARD_APPS);
export let createStandardApp = (app) => HttpUtils.postData(Endpoints.GET_ALL_STANDARD_APPS, app);
export let updateStandardApp = (appId, app) => HttpUtils.putData(Endpoints.GET_STANDARD_APP_BY_ID(appId), app);
export let deleteStandardApp = (appId) => HttpUtils.deleteData(Endpoints.GET_STANDARD_APP_BY_ID(appId));
export let getStandardAppById = (appId) => HttpUtils.getData(Endpoints.GET_STANDARD_APP_BY_ID(appId));

export let getAllAppsForTenant = (tenantId) => HttpUtils.getData(Endpoints.GET_ALL_APPS_FOR_TENANT(tenantId));
export let createAppForTenant = (tenantId, app) => HttpUtils.postData(Endpoints.GET_ALL_APPS_FOR_TENANT(tenantId), app);
export let createStandardAppForTenant = (tenantId, appId) => HttpUtils.postData(Endpoints.ADD_STANDARD_APP_FOR_TENANT(tenantId), appId);
export let updateAppForTenant = (tenantId, app) => HttpUtils.putData(Endpoints.GET_APP_FOR_TENANT_BY_ID(tenantId, app.id), app);
export let getAppForTenant = (tenantId, appId) => HttpUtils.getData(Endpoints.GET_APP_FOR_TENANT_BY_ID(tenantId, appId));
export let deleteAppForTenantById = (tenantId, id) => HttpUtils.deleteData(Endpoints.GET_APP_FOR_TENANT_BY_ID(tenantId, id));

export let getAllUserCredentialsForApp = (tenantId, appId) => HttpUtils.getData(Endpoints.GET_ALL_USER_CREDENTIALS_FOR_APP(tenantId, appId));
export let getUserCredentialsForApp = (tenantId, appId, userId) => HttpUtils.getData(Endpoints.GET_USER_CREDENTIALS_FOR_APP(tenantId, appId, userId));
export let updateUserCredentialsForApp = (tenantId, appId, userId, credentials) => HttpUtils.postData(Endpoints.GET_USER_CREDENTIALS_FOR_APP(tenantId, appId, userId), credentials);
export let deleteUserCredentialsForApp = (tenantId, appId, userId) => HttpUtils.deleteData(Endpoints.GET_USER_CREDENTIALS_FOR_APP(tenantId, appId, userId));
export let updateLastLoginTimeForAppUser = (tenantId, appId, userId) => HttpUtils.postData(Endpoints.UPDATE_LOGIN_TIME_FOR_APP_USER(tenantId, appId, userId));

export let getAllLayouts = () => HttpUtils.getData(Endpoints.GET_ALL_LAYOUTS);
export let getLayoutById = (id) => HttpUtils.getData(Endpoints.GET_LAYOUT_BY_ID(id));
export let createLayout = (layout) => HttpUtils.postData(Endpoints.GET_ALL_LAYOUTS, layout);
export let updateLayoutById = (id) => HttpUtils.putData(Endpoints.GET_LAYOUT_BY_ID(id));
export let deleteLayoutById = (id) => HttpUtils.deleteData(Endpoints.GET_LAYOUT_BY_ID(id));

export let getAllPagesForTenant = (tenantId) => HttpUtils.getData(Endpoints.GET_ALL_PAGES_FOR_TENANT(tenantId));
export let createPageForTenant = (tenantId, page) => HttpUtils.postData(Endpoints.GET_ALL_PAGES_FOR_TENANT(tenantId), page);
export let updatePageForTenant = (id, tenantId, page) => HttpUtils.putData(Endpoints.GET_PAGE_FOR_TENANT_BY_ID(id, tenantId), page);
export let publishPageForTenant = (id, tenantId) => HttpUtils.putData(Endpoints.PUBLISH_HOMEPAGE_FOR_TENANT(id, tenantId));
export let unPublishPageForTenant = (id, tenantId) => HttpUtils.putData(Endpoints.UNPUBLISH_HOMEPAGE_FOR_TENANT(id, tenantId));

export let updateAppSequenceForTenant = (tenantId, appSequence) => HttpUtils.putData(Endpoints.UPDATE_APP_SEQUENCE_FOR_TENANT(tenantId), appSequence);

export let updateAppConfigForApp = (tenantId, app) => HttpUtils.putData(Endpoints.GET_APP_CONFIG_FOR_APP(tenantId, app.id), app);
export let updateStandardAppConfigForApp = (app) => HttpUtils.putData(Endpoints.GET_APP_CONFIG_FOR_STANDARD_APP(app.id), app);

export let getAllUsersForTenant = (tenantId) => HttpUtils.getData(Endpoints.GET_ALL_USERS_FOR_TENANT(tenantId));
export let createUserForTenant = (tenantId, user) => {
    let registerUrl = prepareRESTURI(window.buildConfig.clientAppUri + "#" + ROUTES.REGISTER_USER, {token: "${token}"});
    return HttpUtils.postData(Endpoints.GET_ALL_USERS_FOR_TENANT(tenantId), {user, registerUrl});
};
export let updateUserForTenant = (tenantId, user) => {
    let registerUrl = prepareRESTURI(window.buildConfig.clientAppUri + "#" + ROUTES.REGISTER_USER, {token: "${token}"});
    return HttpUtils.putData(Endpoints.GET_USER_FOR_TENANT_BY_ID(tenantId, user.id), {user, registerUrl});
};
export let deleteUserForTenantById = (tenantId, id) => HttpUtils.deleteData(Endpoints.GET_USER_FOR_TENANT_BY_ID(tenantId, id));

export let getTenantActivityStats = (tenantId) => HttpUtils.getData(Endpoints.GET_TENANT_ACTIVITY_STATS(tenantId));
export let getCommonFeed = () => HttpUtils.getData(Endpoints.GET_COMMON_FEED);
export let getTenantFeed = (tenantId) => HttpUtils.getData(Endpoints.GET_TENANT_FEED(tenantId));
export let getFeed = (feedUrl) => HttpUtils.getData(feedUrl);
export let getRssFeed = (feedNames) => HttpUtils.getData(Endpoints.GET_RSS_FEED(feedNames));

export let getJsonFromUrl = (url) => HttpUtils.postData(Endpoints.GET_JSON, url);

export let getFeedDetails = (tenantId) => HttpUtils.getData(Endpoints.GET_FEED_DETAILS(tenantId));

export let updateSSOConfigForTenant = (tenantId, ssoEnabled, ssoConfig, certificate) => {
    let data = {
        config: {
            ssoEnabled,
            ssoConfig,
            hasCertificate: !!certificate
        }
    };
    let files = certificate && {certificate};
    return HttpUtils.postMultipart(Endpoints.UPDATE_SSO_CONFIG_FOR_TENANT(tenantId), data, files);
};

export let uploadLogoForTenant = (tenantId, logo) => HttpUtils.postMultipart(Endpoints.GET_LOGO_FOR_TENANT(tenantId), null, {logo});

export let getLogoUrlForTenant = (tenant) => {
    let logoInfo = getValueForPathOrDefault(tenant, "attributes.logo");
    return logoInfo && Endpoints.GET_LOGO_FOR_TENANT(tenant.id, logoInfo.version);
};

export let encodeURIData = (data) => base64.encode(unescape(encodeURIComponent(JSON.stringify(data))));

export let decodeURIData = (encoded) => JSON.parse(decodeURIComponent(escape(base64.decode(encoded))));

export let getCustomVariablesForEmailTemplates = () => {
    let customVariablesURL = getEmailTemplatesCustomVariablesURL();
    return HttpUtils.getData(customVariablesURL, null, {});
};