import * as GenericUtils from '../utils/generic-utils';

let deviceId = null;

export const APP_TYPE = "web.backflipt";

export let getDeviceId = () => {
    if(!deviceId) {
        deviceId = GenericUtils.newUUID();
    }
    return deviceId;
};

export const ROUTES = {

    DEFAULT: 'home',

    HOME: 'home',

    EVENT_DETAIL: 'event/:eventId/detail',
};

export const APP_URLS = {
    // 360: "http://localhost:3001/",
    // WIDGET: "http://localhost:3002/",
    360: "https://www.backflipt.com/app/360/",
    WIDGET: "https://www.backflipt.com/app/content/"
};

export const trendingFileBanners = {
    RECO_NEW_ASSET: "New asset available.",
    RECO_UPDATED_ASSET: "Updated asset available.",
    RECO_SIMILAR_ASSET_SHARED: "Because you have shared similar assets.",
    TRENDING_CUSTOMER_ACTIVITY: "Popular among your customers.",
    TRENDING_COLLEAGUE_ACTIVITY: "Popular among your colleagues.",
    TRENDING_WINNING_DEAL: "Part of deals closed and won.",
    TRENDING_NEW_ASSET: "New asset available for your customers.",
    TRENDING_UPDATED_ASSET: "Updated asset available for your customers."
};

export const CLOUD_PROVIDERS = {
    BOX: "box",
    DROPBOX: "dropbox",
    EGNYTE: "egnyte",
    GOOGLE_DRIVE: "google_drive",
    ONEDRIVE: "onedrive",
    ONE_DRIVE_BUSINESS: "onedrive_business",
    SHAREFILE: "sharefile",
    SHAREPOINT: "sharepoint_online",
    SYNCPLICITY: "syncplicity",
    SYNCPLICITY_EU: "syncplicity_eu",
    ZOHO: "zoho",
    SALESFORCE: "salesforce"
};

export const CRM_PROVIDERS = {
    SALESFORCE: "salesforce",
    MS_DYNAMICS: "dynamics_online",
    ZOHO_CRM: "zoho_crm"
};

export const CLOUD_TYPE_NAME = {
    dropbox: 'Dropbox',
    box: 'Box',
    onedrive : 'OneDrive',
    onedrive_business: 'OneDrive for Business',
    sharefile : 'ShareFile',
    zoho : 'Zoho',
    google_drive:'Google Drive',
    salesforce:'Salesforce',
    sharepoint_online:'SharePoint',
    dynamics_online :'Microsoft Dynamics 365',
    zoho_crm: 'Zoho CRM',
    syncplicity: 'Syncplicity',
    syncplicity_eu: 'Syncplicity',
    egnyte: 'Egnyte'
};

export const DEFAULT_FILE_COUNT = 12;
export const FILE_NAME_LENGTH = 60;
export const DEFAULT_SEARCH_RESULTS_COUNT = 20;
export const FILE_PATH_LENGTH = 60;
// export const DEFAULT_SEARCH_RESULTS_COUNT = 100;

export const OAUTH_CONNECTION_LISTEN_INTERVAL = 5000;

export const fileExtnIcons = {
    "pdf": `${window.assetsUrl}/bcmp/common/images/file/pdf.svg`,
    "doc": `${window.assetsUrl}/bcmp/common/images/file/doc.svg`,
    "xls": `${window.assetsUrl}/bcmp/common/images/file/xls.svg`,
    "ppt": `${window.assetsUrl}/bcmp/common/images/file/ppt.svg`,
    "audio": `${window.assetsUrl}/bcmp/common/images/file/audio.svg`,
    "video": `${window.assetsUrl}/bcmp/common/images/file/video.svg`,
    "image": `${window.assetsUrl}/bcmp/common/images/file/image.svg`,
    "txt": `${window.assetsUrl}/bcmp/common/images/file/text.sv`
};

export const fileTypes = {
    "pdf": "pdf",
    "docx": "doc",
    "doc": "doc",
    "xlsx": "xls",
    "xls": "xls",
    "pptx": "ppt",
    "ppt": "ppt",
    "txt": "txt",
    "st": "txt",
    "602": "txt",
    "abw": "txt",
    "act": "txt",
    "adoc": "txt",
    "aim": "txt",
    "ans": "txt",
    "apkg": "txt",
    "apt": "txt",
    "asc": "txt",
    "ascii": "txt",
    "ase": "txt",
    "aty": "txt",
    "awp": "txt",
    "awt": "txt",
    "aww": "txt",
    "bad": "txt",
    "bbs": "txt",
    "bdp": "txt",
    "bdr": "txt",
    "bean": "txt",
    "bib": "txt",
    "bibtex": "txt",
    "bml": "txt",
    "bna": "txt",
    "boc": "txt",
    "brx": "txt",
    "btd": "txt",
    "bzabw": "txt",
    "calca": "txt",
    "charset": "txt",
    "chart": "txt",
    "chord": "txt",
    "cnm": "txt",
    "cod": "txt",
    "crwl": "txt",
    "cws": "txt",
    "cyi": "txt",
    "dca": "txt",
    "dfti": "txt",
    "dgs": "txt",
    "diz": "txt",
    "dne,.docxml": "txt",
    "docz": "txt",
    "dox": "txt",
    "dropbox": "txt",
    "dsc": "txt",
    "dvi": "txt",
    "dwd": "txt",
    "dx": "txt",
    "dxb": "txt",
    "dxp": "txt",
    "eio": "txt",
    "eit": "txt",
    "emf": "txt",
    "eml": "txt",
    "emlx": "txt",
    "emulecollection": "txt",
    "epp": "txt",
    "err": "txt",
    "etf": "txt",
    "etx": "txt",
    "euc": "txt",
    "fadein": "txt",
    "template": "txt",
    "faq": "txt",
    "fbl": "txt",
    "fcf": "txt",
    "fdf": "txt",
    "fdr": "txt",
    "fds": "txt",
    "fdt": "txt",
    "fdx": "txt",
    "fdxt": "txt",
    "fft": "txt",
    "fgs": "txt",
    "flr": "txt",
    "fodt": "txt",
    "fountain": "txt",
    "fpt": "txt",
    "frt": "txt",
    "fwd": "txt",
    "fwdn": "txt",
    "gmd": "txt",
    "gpd": "txt",
    "gpn": "txt",
    "gsd": "txt",
    "gthr": "txt",
    "gv": "txt",
    "hbk": "txt",
    "hht": "txt",
    "hs": "txt",
    "hwp": "txt",
    "hz": "txt",
    "idx": "txt",
    "iil": "txt",
    "ipf": "txt",
    "ipspot": "txt",
    "jarvis": "txt",
    "jis": "txt",
    "jnp": "txt",
    "joe": "txt",
    "jp1": "txt",
    "jrtf": "txt",
    "jtd": "txt",
    "kes": "txt",
    "klg": "txt",
    "knt": "txt",
    "kon": "txt",
    "kwd": "txt",
    "latex": "txt",
    "lbt": "txt",
    "lis": "txt",
    "lnt": "txt",
    "log": "txt",
    "lp2": "txt",
    "lst": "txt",
    "ltr": "txt",
    "ltx": "txt",
    "lue": "txt",
    "luf": "txt",
    "lwp": "txt",
    "lxfml": "txt",
    "lyt": "txt",
    "lyx": "txt",
    "man": "txt",
    "mbox": "txt",
    "mcw": "txt",
    "md5": "txt",
    "me": "txt",
    "mell": "txt",
    "mellel": "txt",
    "min": "txt",
    "mnt": "txt",
    "msg": "txt",
    "mw": "txt",
    "mwd": "txt",
    "mwp": "txt",
    "nb": "txt",
    "ndoc": "txt",
    "nfo": "txt",
    "ngloss": "txt",
    "njx": "txt",
    "note": "txt",
    "notes": "txt",
    "now": "txt",
    "nwctxt": "txt",
    "nwm": "txt",
    "nwp": "txt",
    "ocr": "txt",
    "odif": "txt",
    "odm": "txt",
    "odo": "txt",
    "odt": "txt",
    "ofl": "txt",
    "opeico": "txt",
    "openbsd": "txt",
    "ort": "txt",
    "ott": "txt",
    "p7s": "txt",
    "pages": "txt",
    "pages-tef": "txt",
    "pdpcmd": "txt",
    "pfx": "txt",
    "pjt": "txt",
    "plain": "txt",
    "plantuml": "txt",
    "pmo": "txt",
    "prt": "txt",
    "psw": "txt",
    "pu": "txt",
    "pvj": "txt",
    "pvm": "txt",
    "pwd": "txt",
    "pwdp": "txt",
    "pwdpl": "txt",
    "pwi": "txt",
    "pwr": "txt",
    "qdl": "txt",
    "qpf": "txt",
    "rad": "txt",
    "readme": "txt",
    "rft": "txt",
    "ris": "txt",
    "rpt": "txt",
    "rst": "txt",
    "rtd": "txt",
    "rtf": "txt",
    "rtfd": "txt",
    "rtx": "txt",
    "run": "txt",
    "rvf": "txt",
    "rzk": "txt",
    "rzn": "txt",
    "saf": "txt",
    "safetext": "txt",
    "sam": "txt",
    "save": "txt",
    "scriv": "txt",
    "scrivx": "txt",
    "sct": "txt",
    "scw": "txt",
    "sdm": "txt",
    "sdoc": "txt",
    "sdw": "txt",
    "se": "txt",
    "session": "txt",
    "sgm": "txt",
    "sig": "txt",
    "skcard": "txt",
    "sla": "txt",
    "gz": "txt",
    "smf": "txt",
    "sms": "txt",
    "ssa": "txt",
    "story": "txt",
    "strings": "txt",
    "stw": "txt",
    "sty": "txt",
    "sublime-project": "txt",
    "sublime-workspace": "txt",
    "sxg": "txt",
    "sxw": "txt",
    "tab": "txt",
    "tdf": "txt",
    "tex": "txt",
    "text": "txt",
    "textclipping": "txt",
    "tlb": "txt",
    "tm": "txt",
    "tmd": "txt",
    "tmdx": "txt",
    "tmv": "txt",
    "tmvx": "txt",
    "tpc": "txt",
    "trelby": "txt",
    "tvj": "txt",
    "u3i": "txt",
    "unauth": "txt",
    "unx": "txt",
    "uof": "txt",
    "uot": "txt",
    "upd": "txt",
    "utf8": "txt",
    "utxt": "txt",
    "vct": "txt",
    "vnt": "txt",
    "vw": "txt",
    "wbk": "txt",
    "webdoc": "txt",
    "wn": "txt",
    "wp": "txt",
    "wp4": "txt",
    "wp5": "txt",
    "wp6": "txt",
    "wp7": "txt",
    "wpa": "txt",
    "wpd": "txt",
    "wpl": "txt",
    "wps": "txt",
    "wpt": "txt",
    "wpw": "txt",
    "wri": "txt",
    "wsd": "txt",
    "wtt": "txt",
    "wtx": "txt",
    "xbdoc": "txt",
    "xbplate": "txt",
    "xdl": "txt",
    "xwp": "txt",
    "xy": "txt",
    "xy3": "txt",
    "xyp": "txt",
    "xyw": "txt",
    "zabw": "txt",
    "zrtf": "txt",
    "zw": "txt",
    "midi": "audio",
    "aif": "audio",
    "aifc": "audio",
    "aiff": "audio",
    "au": "audio",
    "wav": "audio",
    "mp3": "audio",
    "wma": "audio",
    "ogg": "audio",
    "264": "video",
    "3g2": "video",
    "3gp": "video",
    "3gp2": "video",
    "3gpp": "video",
    "3gpp2": "video",
    "3mm": "video",
    "3p2": "video",
    "60d": "video",
    "787": "video",
    "890": "video",
    "aaf": "video",
    "aec": "video",
    "aecap": "video",
    "aegraphic": "video",
    "aep": "video",
    "aepx": "video",
    "aet": "video",
    "aetx": "video",
    "ajp": "video",
    "ale": "video",
    "am": "video",
    "amc": "video",
    "amv": "video",
    "amx": "video",
    "anim": "video",
    "anx": "video",
    "aqt": "video",
    "arcut": "video",
    "arf": "video",
    "asf": "video",
    "asx": "video",
    "av": "video",
    "avb": "video",
    "avc": "video",
    "avchd": "video",
    "avd": "video",
    "ave": "video",
    "avi": "video",
    "avm": "video",
    "avp": "video",
    "avs": "video",
    "avv": "video",
    "awlive": "video",
    "axm": "video",
    "axv": "video",
    "bdm": "video",
    "bdmv": "video",
    "bdt2": "video",
    "bdt3": "video",
    "bik": "video",
    "bin": "video",
    "bix": "video",
    "bmc": "video",
    "bmk": "video",
    "bnp": "video",
    "box": "video",
    "bs4": "video",
    "bsf": "video",
    "bu": "video",
    "bvr": "video",
    "byu": "video",
    "camproj": "video",
    "camrec": "video",
    "camv": "video",
    "ced": "video",
    "cel": "video",
    "cine": "video",
    "cip": "video",
    "clk": "video",
    "clpi": "video",
    "cmmp": "video",
    "cmmtpl": "video",
    "cmproj": "video",
    "cmrec": "video",
    "cmv": "video",
    "cpi": "video",
    "cpvc": "video",
    "crec": "video",
    "cst": "video",
    "cvc": "video",
    "cx3": "video",
    "d2v": "video",
    "d3v": "video",
    "dad": "video",
    "dash": "video",
    "dat": "video",
    "dav": "video",
    "db2": "video",
    "dce": "video",
    "dck": "video",
    "dcr": "video",
    "ddat": "video",
    "dif": "video",
    "dir": "video",
    "divx": "video",
    "dlx": "video",
    "dmb": "video",
    "dmsd": "video",
    "dmsd3d": "video",
    "dmsm": "video",
    "dmsm3d": "video",
    "dmss": "video",
    "dmx": "video",
    "dnc": "video",
    "dpa": "video",
    "dpg": "video",
    "dream": "video",
    "dsy": "video",
    "dv": "video",
    "dv-avi": "video",
    "dv4": "video",
    "dvdmedia": "video",
    "dvr": "video",
    "dvr-ms": "video",
    "dvx": "video",
    "dxr": "video",
    "dzm": "video",
    "dzp": "video",
    "dzt": "video",
    "edl": "video",
    "evo": "video",
    "exo": "video",
    "exp": "video",
    "eye": "video",
    "eyetv": "video",
    "ezt": "video",
    "f4f": "video",
    "f4m": "video",
    "f4p": "video",
    "f4v": "video",
    "fbr": "video",
    "fbz": "video",
    "fcarch": "video",
    "fcp": "video",
    "fcproject": "video",
    "ffd": "video",
    "ffm": "video",
    "flc": "video",
    "flh": "video",
    "fli": "video",
    "flic": "video",
    "flv": "video",
    "flx": "video",
    "fpdx": "video",
    "ftc": "video",
    "fvt": "video",
    "g2m": "video",
    "g64": "video",
    "g64x": "video",
    "gcs": "video",
    "gfp": "video",
    "gifv": "video",
    "gl": "video",
    "gom": "video",
    "grasp": "video",
    "gts": "video",
    "gvi": "video",
    "gvp": "video",
    "gxf": "video",
    "h264": "video",
    "hdmov": "video",
    "hdv": "video",
    "hkm": "video",
    "ifo": "video",
    "imovielibrary": "video",
    "imoviemobile": "video",
    "imovieproj": "video",
    "imovieproject": "video",
    "inp": "video",
    "int": "video",
    "ircp": "video",
    "irf": "video",
    "ism": "video",
    "ismc": "video",
    "ismclip": "video",
    "ismv": "video",
    "iva": "video",
    "ivf": "video",
    "ivr": "video",
    "ivs": "video",
    "izz": "video",
    "izzy": "video",
    "jdr": "video",
    "jmv": "video",
    "jnr": "video",
    "jss": "video",
    "jts": "video",
    "jtv": "video",
    "k3g": "video",
    "kdenlive": "video",
    "kmv": "video",
    "ktn": "video",
    "lrec": "video",
    "lrv": "video",
    "lsf": "video",
    "lsx": "video",
    "lvix": "video",
    "m15": "video",
    "m1pg": "video",
    "m1v": "video",
    "m21": "video",
    "m2a": "video",
    "m2p": "video",
    "m2t": "video",
    "m2ts": "video",
    "m2v": "video",
    "m4e": "video",
    "m4u": "video",
    "m4v": "video",
    "m75": "video",
    "mani": "video",
    "meta": "video",
    "mgv": "video",
    "mj2": "video",
    "mjp": "video",
    "mjpeg": "video",
    "mjpg": "video",
    "mk3d": "video",
    "mkv": "video",
    "mmv": "video",
    "mnv": "video",
    "mob": "video",
    "mod": "video",
    "modd": "video",
    "moff": "video",
    "moi": "video",
    "moov": "video",
    "mov": "video",
    "movie": "video",
    "mp21": "video",
    "mp2v": "video",
    "mp4": "video",
    "infovid": "video",
    "mp4v": "video",
    "mpe": "video",
    "mpeg": "video",
    "mpeg1": "video",
    "mpeg2": "video",
    "mpeg4": "video",
    "mpf": "video",
    "mpg": "video",
    "mpg2": "video",
    "mpg4": "video",
    "mpgindex": "video",
    "mpl": "video",
    "mpls": "video",
    "mproj": "video",
    "mpsub": "video",
    "mpv": "video",
    "mpv2": "video",
    "mqv": "video",
    "msdvd": "video",
    "mse": "video",
    "msh": "video",
    "mswmm": "video",
    "mt2s": "video",
    "mts": "video",
    "mtv": "video",
    "mvb": "video",
    "mvc": "video",
    "mvd": "video",
    "mve": "video",
    "mvex": "video",
    "mvp": "video",
    "mvy": "video",
    "mxf": "video",
    "mxv": "video",
    "mys": "video",
    "n3r": "video",
    "ncor": "video",
    "nfv": "video",
    "nsv": "video",
    "ntp": "video",
    "nut": "video",
    "nuv": "video",
    "nvc": "video",
    "ogm": "video",
    "ogv": "video",
    "ogx": "video",
    "orv": "video",
    "osp": "video",
    "otrkey": "video",
    "pac": "video",
    "par": "video",
    "pds": "video",
    "pgi": "video",
    "photoshow": "video",
    "piv": "video",
    "pjs": "video",
    "playlist": "video",
    "plproj": "video",
    "pmf": "video",
    "pmv": "video",
    "pns": "video",
    "ppj": "video",
    "prel": "video",
    "pro": "video",
    "pro4dvd": "video",
    "pro5dvd": "video",
    "proqc": "video",
    "prproj": "video",
    "prtl": "video",
    "psb": "video",
    "psh": "video",
    "pssd": "video",
    "psv": "video",
    "pva": "video",
    "pvr": "video",
    "pxv": "video",
    "pz": "video",
    "qt": "video",
    "qtch": "video",
    "qtindex": "video",
    "qtl": "video",
    "qtm": "video",
    "qtz": "video",
    "r3d": "video",
    "rcd": "video",
    "rcproject": "video",
    "rcrec": "video",
    "rcut": "video",
    "rdb": "video",
    "rec": "video",
    "rm": "video",
    "rmd": "video",
    "rmp": "video",
    "rms": "video",
    "rmv": "video",
    "rmvb": "video",
    "roq": "video",
    "rp": "video",
    "rsx": "video",
    "rts": "video",
    "rum": "video",
    "rv": "video",
    "rvid": "video",
    "rvl": "video",
    "san": "video",
    "sbk": "video",
    "sbt": "video",
    "sbz": "video",
    "scc": "video",
    "scm": "video",
    "scn": "video",
    "screenflow": "video",
    "sdv": "video",
    "sec": "video",
    "sedprj": "video",
    "seq": "video",
    "sfd": "video",
    "sfera": "video",
    "sfvidcap": "video",
    "siv": "video",
    "smi": "video",
    "smil": "video",
    "smk": "video",
    "sml": "video",
    "smv": "video",
    "snagproj": "video",
    "spl": "video",
    "sqz": "video",
    "srt": "video",
    "ssf": "video",
    "ssm": "video",
    "stl": "video",
    "str": "video",
    "stx": "video",
    "svi": "video",
    "swf": "video",
    "swi": "video",
    "swt": "video",
    "tda3mt": "video",
    "tdt": "video",
    "tdx": "video",
    "theater": "video",
    "thp": "video",
    "tid": "video",
    "tivo": "video",
    "tix": "video",
    "tod": "video",
    "tp": "video",
    "tp0": "video",
    "tpd": "video",
    "tpr": "video",
    "trec": "video",
    "trp": "video",
    "ts": "video",
    "tsp": "video",
    "ttxt": "video",
    "tvlayer": "video",
    "tvrecording": "video",
    "tvs": "video",
    "tvshow": "video",
    "usf": "video",
    "usm": "video",
    "v264": "video",
    "vbc": "video",
    "vc1": "video",
    "vcpf": "video",
    "vcr": "video",
    "vcv": "video",
    "vdo": "video",
    "vdr": "video",
    "vdx": "video",
    "veg": "video",
    "vem": "video",
    "vep": "video",
    "vf": "video",
    "vft": "video",
    "vfw": "video",
    "vfz": "video",
    "vgz": "video",
    "vid": "video",
    "video": "video",
    "viewlet": "video",
    "viv": "video",
    "vivo": "video",
    "vix": "video",
    "vlab": "video",
    "vmlf": "video",
    "vmlt": "video",
    "vob": "video",
    "vp3": "video",
    "vp6": "video",
    "vp7": "video",
    "vpj": "video",
    "vr": "video",
    "vro": "video",
    "vs4": "video",
    "vse": "video",
    "vsp": "video",
    "vtt": "video",
    "w32": "video",
    "wcp": "video",
    "webm": "video",
    "wfsp": "video",
    "wgi": "video",
    "wlmp": "video",
    "wm": "video",
    "wmd": "video",
    "wmmp": "video",
    "wmv": "video",
    "wmx": "video",
    "wot": "video",
    "wp3": "video",
    "wsve": "video",
    "wtv": "video",
    "wve": "video",
    "wvm": "video",
    "wvx": "video",
    "wxp": "video",
    "xej": "video",
    "xel": "video",
    "xesc": "video",
    "xfl": "video",
    "xlmv": "video",
    "xml": "video",
    "xmv": "video",
    "xvid": "video",
    "y4m": "video",
    "yog": "video",
    "yuv": "video",
    "zeg": "video",
    "zm1": "video",
    "zm2": "video",
    "zm3": "video",
    "zmv": "video",
    "001": "image",
    "2bp": "image",
    "360": "image",
    "411": "image",
    "73i": "image",
    "8ca": "image",
    "8ci": "image",
    "8pbs": "image",
    "8xi": "image",
    "9": "image",
    "abm": "image",
    "accountpicture-ms": "image",
    "acorn": "image",
    "acr": "image",
    "adc": "image",
    "afx": "image",
    "agif": "image",
    "agp": "image",
    "aic": "image",
    "ais": "image",
    "albm": "image",
    "apd": "image",
    "apm": "image",
    "apng": "image",
    "aps": "image",
    "apx": "image",
    "arr": "image",
    "art": "image",
    "artwork": "image",
    "arw": "image",
    "aseprite": "image",
    "avatar": "image",
    "awd": "image",
    "blkrt": "image",
    "blz": "image",
    "bm2": "image",
    "bmf": "image",
    "bmp": "image",
    "bmq": "image",
    "bmx": "image",
    "bmz": "image",
    "bpg": "image",
    "brk": "image",
    "brn": "image",
    "brt": "image",
    "bss": "image",
    "bti": "image",
    "bw": "image",
    "c4": "image",
    "cal": "image",
    "cals": "image",
    "cam": "image",
    "can": "image",
    "cd5": "image",
    "cdc": "image",
    "cdg": "image",
    "ce": "image",
    "cid": "image",
    "cimg": "image",
    "cin": "image",
    "cit": "image",
    "clip": "image",
    "cmr": "image",
    "colz": "image",
    "cpbitmap": "image",
    "cpc": "image",
    "cpd": "image",
    "cpg": "image",
    "cps": "image",
    "cpt": "image",
    "cpx": "image",
    "csf": "image",
    "ct": "image",
    "cut": "image",
    "dc2": "image",
    "dcm": "image",
    "dcx": "image",
    "ddb": "image",
    "dds": "image",
    "ddt": "image",
    "dgt": "image",
    "dib": "image",
    "dic": "image",
    "dicom": "image",
    "djv": "image",
    "djvu": "image",
    "dm3": "image",
    "dmi": "image",
    "dpx": "image",
    "drz": "image",
    "dt2": "image",
    "dtw": "image",
    "dvl": "image",
    "ecw": "image",
    "exr": "image",
    "fac": "image",
    "face": "image",
    "fal": "image",
    "fax": "image",
    "fbm": "image",
    "fil": "image",
    "fits": "image",
    "flif": "image",
    "fpg": "image",
    "fpos": "image",
    "fppx": "image",
    "fpx": "image",
    "frm": "image",
    "g3": "image",
    "gbr": "image",
    "gcdp": "image",
    "gfb": "image",
    "gfie": "image",
    "ggr": "image",
    "gif": "image",
    "gih": "image",
    "gim": "image",
    "gmbck": "image",
    "gmspr": "image",
    "gp4": "image",
    "gro": "image",
    "grob": "image",
    "gry": "image",
    "hdp": "image",
    "hdr": "image",
    "hdrp": "image",
    "heic": "image",
    "heif": "image",
    "hf": "image",
    "hpi": "image",
    "hr": "image",
    "hrf": "image",
    "i3d": "image",
    "ic1": "image",
    "ic2": "image",
    "ic3": "image",
    "ica": "image",
    "icb": "image",
    "icn": "image",
    "icon": "image",
    "icpr": "image",
    "ilbm": "image",
    "img": "image",
    "imj": "image",
    "info": "image",
    "ink": "image",
    "iphotoproject": "image",
    "ipick": "image",
    "ipx": "image",
    "itc2": "image",
    "ithmb": "image",
    "ivue": "image",
    "iwi": "image",
    "j": "image",
    "j2c": "image",
    "j2k": "image",
    "jas": "image",
    "jb2": "image",
    "jbf": "image",
    "jbg": "image",
    "jbig": "image",
    "jbig2": "image",
    "jbmp": "image",
    "jbr": "image",
    "jfi": "image",
    "jfif": "image",
    "jia": "image",
    "jif": "image",
    "jiff": "image",
    "jng": "image",
    "jp2": "image",
    "jpc": "image",
    "jpd": "image",
    "jpe": "image",
    "jpeg": "image",
    "jpf": "image",
    "jpg": "image",
    "jpg-large": "image",
    "jpg2": "image",
    "jps": "image",
    "jpx": "image",
    "jtf": "image",
    "jwl": "image",
    "jxr": "image",
    "kdi": "image",
    "kdk": "image",
    "kfx": "image",
    "kic": "image",
    "kodak": "image",
    "kpg": "image",
    "lb": "image",
    "lbm": "image",
    "lif": "image",
    "lip": "image",
    "ljp": "image",
    "lrpreview": "image",
    "lzp": "image",
    "mac": "image",
    "mat": "image",
    "max": "image",
    "mbm": "image",
    "mcs": "image",
    "met": "image",
    "mic": "image",
    "miff": "image",
    "mip": "image",
    "mix": "image",
    "mng": "image",
    "mnr": "image",
    "mpo": "image",
    "mrb": "image",
    "mrxs": "image",
    "msk": "image",
    "msp": "image",
    "mxi": "image",
    "myl": "image",
    "ncd": "image",
    "ncr": "image",
    "nct": "image",
    "neo": "image",
    "nlm": "image",
    "nol": "image",
    "oc3": "image",
    "oc4": "image",
    "oc5": "image",
    "oci": "image",
    "odi": "image",
    "omf": "image",
    "oplc": "image",
    "ora": "image",
    "ota": "image",
    "otb": "image",
    "oti": "image",
    "ozb": "image",
    "ozj": "image",
    "ozt": "image",
    "pal": "image",
    "pano": "image",
    "pap": "image",
    "pat": "image",
    "pbm": "image",
    "pc1": "image",
    "pc2": "image",
    "pc3": "image",
    "pcd": "image",
    "pcx": "image",
    "pdd": "image",
    "pdn": "image",
    "pe4": "image",
    "pfi": "image",
    "pfr": "image",
    "pgf": "image",
    "pgm": "image",
    "pi1": "image",
    "pi2": "image",
    "pi3": "image",
    "pi4": "image",
    "pi5": "image",
    "pi6": "image",
    "pic": "image",
    "picnc": "image",
    "pict": "image",
    "pictclipping": "image",
    "piskel": "image",
    "pix": "image",
    "pixadex": "image",
    "pixela": "image",
    "pjpeg": "image",
    "pjpg": "image",
    "pm": "image",
    "pm3": "image",
    "pmg": "image",
    "pnc": "image",
    "png": "image",
    "pni": "image",
    "pnm": "image",
    "pnt": "image",
    "pntg": "image",
    "pop": "image",
    "pov": "image",
    "pp4": "image",
    "pp5": "image",
    "ppf": "image",
    "ppm": "image",
    "prw": "image",
    "psd": "image",
    "psdx": "image",
    "pse": "image",
    "psf": "image",
    "psp": "image",
    "pspbrush": "image",
    "pspimage": "image",
    "ptex": "image",
    "ptg": "image",
    "ptk": "image",
    "pts": "image",
    "ptx": "image",
    "pwp": "image",
    "px": "image",
    "pxd": "image",
    "pxicon": "image",
    "pxm": "image",
    "pxr": "image",
    "pyxel": "image",
    "pza": "image",
    "pzp": "image",
    "pzs": "image",
    "qif": "image",
    "qmg": "image",
    "qti": "image",
    "qtif": "image",
    "ras": "image",
    "rcl": "image",
    "rcu": "image",
    "rgb": "image",
    "rgba": "image",
    "rgf": "image",
    "ric": "image",
    "rif": "image",
    "riff": "image",
    "rix": "image",
    "rle": "image",
    "rli": "image",
    "rpf": "image",
    "rri": "image",
    "rs": "image",
    "rsb": "image",
    "rsr": "image",
    "rtl": "image",
    "rvg": "image",
    "s2mv": "image",
    "sai": "image",
    "sar": "image",
    "sbp": "image",
    "scg": "image",
    "sci": "image",
    "scp": "image",
    "scu": "image",
    "sdr": "image",
    "sep": "image",
    "sfc": "image",
    "sff": "image",
    "sfw": "image",
    "sgd": "image",
    "sgi": "image",
    "shg": "image",
    "sid": "image",
    "sim": "image",
    "skitch": "image",
    "skm": "image",
    "skypeemoticonset": "image",
    "sld": "image",
    "smp": "image",
    "sob": "image",
    "spa": "image",
    "spc": "image",
    "spe": "image",
    "sph": "image",
    "spiff": "image",
    "spj": "image",
    "spp": "image",
    "spr": "image",
    "sprite": "image",
    "sprite2": "image",
    "spu": "image",
    "sr": "image",
    "ste": "image",
    "sumo": "image",
    "sun": "image",
    "suniff": "image",
    "sup": "image",
    "sva": "image",
    "svm": "image",
    "t2b": "image",
    "taac": "image",
    "targa": "image",
    "tb0": "image",
    "tbn": "image",
    "texture": "image",
    "tfc": "image",
    "tg4": "image",
    "tga": "image",
    "thm": "image",
    "thumb": "image",
    "tif": "image",
    "tiff": "image",
    "tjp": "image",
    "tm2": "image",
    "tn": "image",
    "tn1": "image",
    "tn2": "image",
    "tn3": "image",
    "tny": "image",
    "tpf": "image",
    "tpi": "image",
    "tps": "image",
    "trif": "image",
    "tsr": "image",
    "tub": "image",
    "u": "image",
    "ufo": "image",
    "uga": "image",
    "ugoira": "image",
    "upf": "image",
    "urt": "image",
    "usertile-ms": "image",
    "v": "image",
    "vda": "image",
    "vff": "image",
    "vic": "image",
    "vicar": "image",
    "viff": "image",
    "vna": "image",
    "vpe": "image",
    "vrimg": "image",
    "vrphoto": "image",
    "vss": "image",
    "vst": "image",
    "wb0": "image",
    "wb1": "image",
    "wb2": "image",
    "wbc": "image",
    "wbd": "image",
    "wbm": "image",
    "wbmp": "image",
    "wbp": "image",
    "wbz": "image",
    "wdp": "image",
    "webp": "image",
    "wi": "image",
    "wic": "image",
    "wmp": "image",
    "wpb": "image",
    "wpe": "image",
    "wvl": "image",
    "xbm": "image",
    "xcf": "image",
    "xpm": "image",
    "xwd": "image",
    "y": "image",
    "ysp": "image",
    "zif": "image",
    "zvi": "image"
};
