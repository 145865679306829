import Bluebird from 'bluebird';
import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import {orderByAsc} from '../../utils/generic-utils'
import React from 'react'
import {
    getCustomVariablesForEmailTemplates
} from "../../service/tenant-service";
import {
    getAttrValueForEventFromClosest,
    getClosestForEventWithAttribute, serializeForm, validateForm,
    VALIDATION_TYPE
} from "../../Backflipt_UI_Components/utils/generic-utils";
import ModalContainer from "../modal-container/modal-container-v2";
import {
    getAllValuesforKeysInConfig,
    storeKeyValueInConfigAsObject
} from "../../service/storage-service";
import {TEMPLATES} from "../../config/email-templates-config";
import $ from "jquery";

const R = require('ramda');

export default class EmailTemplates extends UIBaseComponent {

    beforeRender() {
        console.log("before render");
        this.loadDataAsync();
        return super.beforeRender();
    }

    loadDataAsync () {
        console.log("fetching templates");
        let keys = [TEMPLATES["User Registration"].KEY,TEMPLATES["Reset Password"].KEY,TEMPLATES["Download Client"].KEY]
        return Bluebird.all([getAllValuesforKeysInConfig(keys), getCustomVariablesForEmailTemplates()])
            .then(result => {
                let templates = result[0] || [];
                let customVariables = result[1].customVariables || [];
                return this.setState({templates, customVariables, dataLoaded: true});
                }
            );
    }

    getHeaderView() {
        return (
            <div className="semi-tabs-common-wrapper hide">
                <div className="common-search-wrapper">
                    <div className="ui search">
                        <input className="common-search-input" type="text" placeholder="Search Application"
                               onkeyup={this.onSearch}/>
                        <img className="common-search-icon" src="assets/images/search-icon.png" alt=""/>
                    </div>
                </div>
            </div>
        );
    }

    /*onSearch = (e) => {
        let searchString = e.target.value.trim();
        if (this.state.searchString !== searchString) {
            this.state.searchString = searchString;

            let filteredTemplates = this.applySearch(this.state.templateNames);
            return this.setState({filteredTemplates});
        }
    };*/

    /*applySearch(templates) {
        let searchString = this.state.searchString;
        let filteredTemplates = !searchString ? templates : R.filter(t => {
            let inputKeys = R.map(k => k.toLowerCase(), R.flatten(R.map(key => key.split(" "), [t])));
            let searchKeys = R.map(k => k.toLowerCase(), searchString.split(" "));
            return R.find(input => {
                return R.find(sk => input.includes(sk), searchKeys);
            }, inputKeys);
        }, templates || []);

        return filteredTemplates;
    }*/

    getLoadingView() {
        return (
            <div className="loading-wrapper"><p>Loading...</p></div>
        );
    }

    getView() {
        return super.getView()
            .then(_ => {
                    return (
                        <div className="application-tab-content-wrapper standard-applications-wrapper">
                            {this.getHeaderView()}
                            <div className="common-table-wrapper">
                                <table className="ui single line table">
                                    <thead>
                                    <tr>
                                        <th>Email Templates</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.getTemplatesView()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    );
                }
            );
    }


    getTemplatesView(){
        if (this.state.dataLoaded) {
            let templates = orderByAsc(R.clone(this.state.templates), 'templateName')
            //if (!this.state.filteredTemplates || R.isEmpty(this.state.filteredTemplates)) return this.getNoDataView;
            return R.map(t => this.getTemplatesRowView(t), templates);
        } return this.getLoadingView();
    }

    getTemplatesRowView(template) {
        let templateName = template.templateName;
        return (
            <tr>
                <td className="template-name-text common-action-td">
                <span className="common-action-tag link-text" data-id={templateName} onclick={this.getEditEmailTemplatePopup}>{templateName}</span>
                </td>
                <td className="common-action-td">
                    <span className="common-action-tag link-text" data-id={templateName} onclick={this.getEditEmailTemplatePopup}>Edit</span>
                </td>
            </tr>
        );
    }

    getEditEmailTemplatePopup = (e) => {
        let template = this.getTemplateFromEvent(e)
        let view = this.editEmailTemplatePopup(template);
        ModalContainer.addModalView('update-template-popup', view);
    };

    updateEditEmailTemplatePopup = (e) => {
        let view = this.editEmailTemplatePopup(e);
        return ModalContainer.updateModalView('update-template-popup', view)
            .then(_ => ModalContainer.getModelEl('update-template-popup'))
            .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl('update-template-popup')));
    };

    closeEditEmailTemplatePopup = () => {
        return ModalContainer.removeModal('update-template-popup')
            .then(_ => this.setState({errors: null, loading: false}));
    };

    getTemplateByName = (name) => name && R.find(template => template.templateName === name, this.state.templates || []);

    getTemplateFromEvent(e) {
        let templateName = getAttrValueForEventFromClosest(e, 'data-id');
        return this.getTemplateByName(templateName);
    }

    editEmailTemplatePopup(template) {
        let templateName = template.templateName || "";
        let templateParamName = template.name || "";
        let templateParamSubject = template.subject || "";
        let templateParamBody = template.body || "";
        let errors = this.state.errors || {};
        return (
            <div data-form-id="add-new-update-app"
                 className="ui modal common-popup-wrapper add-application-popup-modal edit-template-modal scrolling transition visible active"
                 style="display: block !important;">
                <div className="content">
                    <div className="header">Edit Template</div>
                    <div className="description">
                        <table className="ui table">
                            <tbody>
                            <tr>
                                <td>Name <span className="mandatory-start">*</span></td>
                                <td>
                                    <input type="text" data-id={templateName} data-key="name" data-required="true"
                                           data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                           className={"common-popup-input " + (errors.name ? "validation-error" : "")}
                                           onkeyup={this.onAddOrUpdateDataChange} oninput={this.onValueChange}
                                           autofocus={true} value={templateParamName || ""}/>
                                    <p className={"error-text " + (errors.name ? "" : "hide")}>Name cannot be
                                        empty</p>
                                </td>
                            </tr>
                            <tr>
                                <td>Subject <span className="mandatory-start">*</span></td>
                                <td>
                                    <input type="text" data-id={templateName} data-key="subject" data-required="true"
                                           data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                           className={"common-popup-input " + (errors.subject ? "validation-error" : "")}
                                           onkeyup={this.onAddOrUpdateDataChange} oninput={this.onValueChange}
                                           value={templateParamSubject || ""}/>
                                    <p className={"error-text " + (errors.subject ? "" : "hide")}>Subject cannot be empty</p>
                                </td>
                            </tr>
                            <tr>
                                <td>Email body <span className="mandatory-start">*</span></td>
                                <td>
                                    <textarea type="textarea" id="inputEmail" data-id={templateName} data-key="body" data-required="true"
                                           data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                           className={"common-popup-input " + (errors.body ? "validation-error" : "")}
                                           onkeyup={this.onAddOrUpdateDataChange} oninput={this.onValueChange}
                                           value={templateParamBody || ""}/>
                                    <p className={"error-text " + (errors.body ? "" : "hide")}>Email body cannot be empty</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div
                            className="template-params-data edit-template-modal">{this.getTableView()}
                        </div>
                    </div>
                    <div className="actions">
                        <div id="updateTemplateAction"
                             className={"ui primary button buttons-primary-text buttons-primary-background " + (this.state.loading ? "loading disabled" : "")}
                             data-id={templateName}
                             onclick={this.saveTemplate}>Save
                        </div>
                        <div className="ui cancel button buttons-secondary-text buttons-secondary-background"
                             onclick={this.closeEditEmailTemplatePopup}>Cancel
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    saveTemplate= (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let errors = validateForm($form);
        let templateParams = serializeForm($form);
        let template = this.getTemplateFromEvent(e, 'data-id');
        let templateName = template.templateName;
        let templateKey = TEMPLATES[templateName].KEY;

        let params = {};
        params[templateKey] = {"name": templateParams.name, "subject": templateParams.subject, "body": templateParams.body, "templateName": templateName}

        if (errors && !R.isEmpty(errors)) {
            return this.setState({errors})
                .then(_ => this.updateEditEmailTemplatePopup({name: templateParams.name, subject: templateParams.subject, body:templateParams.body, templateName: template.templateName}))
        }

        return this.setState({loading: true})
            .then(_ => this.updateEditEmailTemplatePopup(params[templateKey]))
            .then(_ => storeKeyValueInConfigAsObject(params))
            .then(_ => {
                let templates = R.clone(this.state.templates);
                let templatesIndex = R.findIndex(R.propEq('templateName', templateName), templates);
                templates[templatesIndex] = params[templateKey];
                return this.setState({templates})
            })
            .then(_ => this.closeEditEmailTemplatePopup());
    };

    onValueChange = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let templateParams = serializeForm($form);
        let template = this.getTemplateFromEvent(e, 'data-id');
        let key = getAttrValueForEventFromClosest(e, 'data-key');
        let errors = R.clone(this.state.errors) || {};
        delete errors[key];
        return this.setState({errors})
            .then(_ => this.updateEditEmailTemplatePopup({
                name: templateParams.name, subject: templateParams.subject, body:templateParams.body, templateName: template.templateName}))
            .then(_ => $(e.target).focus());
    };

    showOrHideCustomVariableFormat = (e) => {
        let template = this.getTemplateFromEvent(e, 'data-id');
        return this.setState({showCustomVariable: !this.state.showCustomVariable})
        .then(_ => this.updateEditEmailTemplatePopup(template));
    };

    getTableView() {
        let customVariables = this.state.customVariables || [];
        return R.map(t => this.getTableRowView(t), customVariables)
    }

    getTableRowView = (customVariable) => {
        let variable = customVariable.VARIABLE;
        let variableExplanation = customVariable.EXPLANATION;
        return <tr>
                <td className="template-name-text common-action-td">
                    {variable}
                </td>
                <td className="template-name-text common-action-td">
                    {variableExplanation}
                </td>
        </tr>
    }

    applyAutoFocusForContainer = (container) => $(container).find('[autofocus]').focus();

    applyAutoFocus = () => this.applyAutoFocusForContainer(this.$container);
}
