import React from 'react'
import {
    getClosestForEventWithAttribute,
    serializeForm, validateForm
} from '../../Backflipt_UI_Components/utils/generic-utils'
import { getTenantById, updateTenant } from '../../service/tenant-service'
import { setMyTenantDetails } from '../../dao/user-profile-dao'
import CommonFeedUpload from '../common-feed-upload/common-feed-upload'

const R = require('ramda');

export default class TenantFeedUpload extends CommonFeedUpload {

    loadDataAsync() {
        if(this.state.dataLoaded) return Promise.resolve();
        return getTenantById(this.props.tenant.id)
            .then(tenant => {
                let feedUrl = tenant.attributes && tenant.attributes.clientFeedUrl;
                let feedTitle = (tenant.attributes && tenant.attributes.clientFeedTitle) || "Client Specific News";
                return this.setState({tenant, feedUrl, feedTitle, originalFeedUrl: feedUrl, originalFeedTitle: feedTitle});
            })
            .catch(_ => {
                let feedTitle = "Client Specific News";
                return this.setState({feedTitle, originalFeedTitle : feedTitle})
            });
    }

    getHeaderView() {
        return (
            <h5>{ "Please provide the URL to the JSON formatted file to be shown as feed for this client."}</h5>
        );
    }

    getFeedTitle() {
        return (
            <label>Client Specific Feed Title<span
                className="mandatory-start">*</span></label>
        )
    }

    saveFeedUrl = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let data = serializeForm($form);
        let errors = validateForm($form);
        let feedUrl = data.feedUrl;
        let feedTitle = data.feedTitle;
        let tenant = R.clone(this.state.tenant);
        let attributes = tenant.attributes;
        attributes.clientFeedUrl = feedUrl;
        attributes.clientFeedTitle = feedTitle;
        tenant.attributes = attributes;
        if(!R.isEmpty(errors)) return this.setState({feedUrl, feedTitle, errors});
        return this.setState({loading: true})
            .then(_ => updateTenant(tenant))
            .then(_ => setMyTenantDetails(tenant))
            .then(_ => this.setState({loading: false, originalFeedUrl: feedUrl, feedUrl, feedTitle, originalFeedTitle: feedTitle, tenant}));
    }

}