import React from 'react';
import {convertToUrl, getValueForPathOrDefault, mapIndexed} from "../../../Backflipt_UI_Components/utils/generic-utils";
import {ATTR_MAPPING, SOURCE_URL_DATA} from "./sample-data";
import Configurator from "./configurator";
import BaseContentTemplate from "../base-content-template";

const R = require('ramda');

export default class ContentTemplate extends BaseContentTemplate {

    getView() {
        return super.getView()
            .then(_ => {

                if(!this.state.dataLoaded) return this.getLoadingView();

                const contentAttributes = this.state.contentAttributes || {};

                let source = (contentAttributes.sources && contentAttributes.sources[0]) || {};

                let attributeMappings = source.attributeMappings || {};

                attributeMappings = R.isEmpty(attributeMappings)? ATTR_MAPPING : attributeMappings;

                let sourceUrlData = this.state.sourceUrlData || SOURCE_URL_DATA;

                let feedData = this.getTransformedDataFromAttrMapping(attributeMappings, sourceUrlData);

                let errors = this.state.errors || {};

                let sourceUrlError = !!getValueForPathOrDefault(errors, "url", "");

                let sourceUrlErrorText = getValueForPathOrDefault(errors, "url.message", "");

                feedData = mapIndexed((feed, index) => {
                                feed.id = index;
                                return feed;
                            }, feedData);

                return (
                <div data-template={"rss-feed"} className="template rss-wrapper custom-scrolling" draggable={true} ondragstart={this.props.onDrag}>
                    <div className="template-wrapper common-white-bg custom-scrolling">
                        <div className="common-header-actions-wrapper">
                            <h2>{contentAttributes.name || "RSS Feed"}</h2>
                            <div className={"hp-error-text" + (sourceUrlError?"": " hide")}>{sourceUrlErrorText}</div>
                            <div className={"common-action-btns-wrapper" + (this.props.editTemplate ? "" : " hide")}>
                     <span className="common-edit-icon">
                     <img
                         onclick={() => this.showEditContentTemplatePopupView(contentAttributes, this.state.sourceUrlData, Configurator)}
                         src="assets/images/common-edit-icon.png" alt=""/>
                     </span>
                                <span className="common-delete-icon">
                     <img onclick={() => this.props.onDeleteMapping(this.props.mapping)}
                          src="assets/images/common-delete-icon.png" alt=""/>
                     </span>
                            </div>
                        </div>
                        <div className="template-content custom-scrolling">
                            {sourceUrlError?"": R.map(t => this.getFeedView(t), feedData)}
                        </div>
                    </div>

                </div>);
            });
    }

    getFeedView(feed) {
        let imageLoadError = this.state.imageLoadError || {};
        // let feedLogoUrl = imageLoadError[feed.id + "logo"] ? "assets/images/feed-logo.png" : feed.logo;
        let feedLogoUrl = imageLoadError[feed.id + "logo"] ? null : feed.logo;
        let feedImageUrl = imageLoadError[feed.id + "image"] ? null : feed.image;

        let feedTitle = (feed.title) ? feed.title : "";
        let feedAuthor = feed.author || "";
        let feedDescription = (feed.description) ? feed.description : "";
        let feedReadMoreHyperlink = (feed.readMoreHyperlink) ? feed.readMoreHyperlink : "";

        let feedDescriptionMaxLength = 300;

        return (
            <div data-feed-id={feed.id} className="common-rss-wrapper">
                <img data-img-label="logo" className={'rss-pic' + (feedLogoUrl ? '' : ' invisible')} src={feedLogoUrl}
                     onerror={this.onImageLoadError} alt=""/>
                <div className="rss-details-wrapper">
                        <h4 className={"common-card-header-text" + (feedTitle ? '' : ' invisible')}>{feedTitle || "Title"}</h4>
                  <p className={"rss-feed-author-name" + (feedAuthor? '' : ' invisible')}>{feedAuthor}</p>
                  <p className={"common-secodary-text" + (feedDescription ? '' : ' invisible')}>{feedReadMoreHyperlink ? (feedDescription ? this.trimFeedDescription(feedDescription, feedDescriptionMaxLength): "Description") : feedDescription || "Description"}</p>
                    <img data-img-label="image" className={"common-rss-image" + (feedImageUrl ? '' : ' invisible')}
                         src={feedImageUrl} onError={this.onImageLoadError} alt=""/>
                    <div class={"feed-readmore " + (feedReadMoreHyperlink ? '' : ' invisible')}>
                        <a className='link-text' href={convertToUrl(feedReadMoreHyperlink)} target="_blank">Read more</a>
                    </div>
                    <p className={'common-date' + (feed.date ? '' : ' invisible')}>{feed.date || "Date"}</p>
                </div>
            </div>
        )
    }

    trimFeedDescription (description, descMaxLength) {
        let trimmedFeed = description.length > descMaxLength ? description.substring(0, descMaxLength) : description;
        return trimmedFeed.substring(0, trimmedFeed.lastIndexOf(' ', trimmedFeed.length)) + "...";
    }
}
