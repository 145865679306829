import moment from 'moment';
import dateformat from 'dateformat';

export let formatDateTime = (timeInMillis = 0, format) => {
    return formatDate(new Date(timeInMillis), format);
};

export let formatDate = (date, format = 'dddd, mmm dS, yyyy') => {
    return dateformat(date, format);
    // return moment(date).format(format);
};

export let getRelativeDateStringForDate = (date) => {
    let gd = moment(date);
    let cd = moment();

    let y = cd.year();
    let m = cd.month();
    let d = cd.dayOfYear();

    if(cd.year() == gd.year() && cd.month() == gd.month() && cd.dayOfYear() == gd.dayOfYear()) return gd.format('HH:mm');

    if(cd.year() == gd.year() && cd.month() == gd.month()) {
        if(cd.dayOfYear() == gd.dayOfYear() + 1) return 'Yesterday';
    } else {
        if(isYesterday(gd)) return 'Yesterday';
    }

    if(cd.year() == gd.year()) {
        if(cd.week() == gd.week()) return gd.format('dddd');
    } else {
        if(isDateInCurrentWeek(gd)) return gd.format('dddd');
    }

    if(cd.year() == gd.year()) return gd.format('D MMM');

    return gd.format('D/MMM/YYYY');
};

let isYesterday = (date) => {
    let cd = moment();
    cd.subtract(1, 'day');
    cd.set('hours', 0);
    cd.set('minutes', 0);
    cd.set('seconds', 0);
    cd.set('milliseconds', 0);
    return parseInt(cd.format('x')) <= parseInt(date.format('x'));
};

let isDateInCurrentWeek = (date) => {
    let cd = moment();
    cd.set('hours', 0);
    cd.set('minutes', 0);
    cd.set('seconds', 0);
    cd.set('milliseconds', 0);
    cd.subtract(parseInt(cd.format('E')), 'days');
    return parseInt(cd.format('x')) <= parseInt(date.format('x'));
};

export let getDateStringForDate = (date) => {
    let d = moment(date);

};

export let getDateStringForTimeInMillis = (time) => {
    return getDateStringForDate(new Date(time));
};


export let getDateStringWithFormatForTimeInMillis = (timeInMillis, format = 'dddd, mmm dS, yyyy') => {
    return moment(timeInMillis).format(format);
};

export let getNumberofDaysInBetween = (date1, date2) => {
    let startDate = moment(date1);
    let endDate = moment(date2);
    let numberOfDays = endDate.diff(startDate, 'days');
    return Math.abs(numberOfDays) + 1 ;
};

export let getRelativeTime = (date) => moment(date).fromNow();