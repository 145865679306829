import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import React from 'react'
import {
    createUserForTenant,
    deleteUserForTenantById,
    getAllUsersForTenant,
    updateUserForTenant
} from '../../service/tenant-service'
import {
    convertToMailToUrl,
    DATA_TYPE,
    getAttrValueForEventFromClosest,
    getClosestForEventWithAttribute,
    serializeForm,
    validateForm,
    VALIDATION_TYPE
} from '../../Backflipt_UI_Components/utils/generic-utils'
import ModalContainer from '../modal-container/modal-container-v2'
import {DEFAULT_COUNTRY_CODE, USER_ROLES} from '../../config/app-config'
import $ from 'jquery'
import {orderByAsc} from "../../utils/generic-utils";
import {getCountryCodes, sendRegistrationMailToUser} from "../../service/user-service";
import {formatDateTime} from "../../Backflipt_UI_Components/src/utils/date-utils";
import BlueBird from 'bluebird';

const R = require('ramda');

const CATEGORIES = {
    ALL: "ALL",
    REGISTERED: "REGISTERED",
    UN_REGISTERED: "UN_REGISTERED"
};

export default class TenantDetailUsers extends UIBaseComponent {

    beforeRender() {
        this.loadDataAsync();
        return super.beforeRender();
    }

    loadDataAsync() {
        return BlueBird.all([getAllUsersForTenant(this.props.tenant.id), getCountryCodes()])
            .then(resp => {
                let users = resp[0];
                let countryCodes = resp[1];
                users = orderByAsc(users, 'firstName');
                let filteredUsers = this.applySearch(users);
                let registered = this.getRegisteredUsers(users);
                return this.setState({users, filteredUsers, countryCodes, registeredCount: registered.length, dataLoaded: true});
            });
    }

    getLoadingView(message = "Loading..") {
        return (
            <div className="loading-wrapper"><p>Loading...</p></div>
        );
    }

    getNoDataView() {
        return (
            <div className="no-file-found-wrapper"><p>No Records Found</p></div>
        );
    }

    applySearch(users, selectedCategory) {
        selectedCategory = selectedCategory || this.state.selectedCategory || CATEGORIES.ALL;
        let searchString = this.state.searchString;
        let filteredUsers = !searchString ? users : R.filter(a => {
            let inputKeys = R.map(k => k.toLowerCase(), R.flatten(R.map(key => key.split(" "), [a.firstName, a.lastName, a.emailId])));
            let searchKeys = R.map(k => k.toLowerCase(), searchString.split(" "));
            return R.find(input => {
                return R.find(sk => input.includes(sk), searchKeys);
            }, inputKeys);
        }, users || []);

        if(selectedCategory !== CATEGORIES.ALL) {
            filteredUsers = R.filter(u => u.registered === (selectedCategory === CATEGORIES.REGISTERED), filteredUsers);
        }

        return filteredUsers;
    }

    onCategoryChange = (e) => {
        let selectedCategory = getAttrValueForEventFromClosest(e, 'data-category');
        if(this.state.selectedCategory !== selectedCategory) {
            let filteredUsers = this.applySearch(this.state.users, selectedCategory);
            return this.setState({selectedCategory, filteredUsers});
        }
    };

    getHeaderView() {
        let users = this.state.users || [];
        let registeredCount = this.state.registeredCount || 0;
        let category = this.state.selectedCategory || CATEGORIES.ALL;
        let isAllActive = category === CATEGORIES.ALL;
        let isUnregisteredActive = category === CATEGORIES.UN_REGISTERED;
        let isRegisteredActive = category === CATEGORIES.REGISTERED;
        return (
            <div className="semi-tabs-common-wrapper">
                <div className="semi-tabs-wrapper">
                    <div className="ui horizontal list">
                        <div data-category={CATEGORIES.ALL} className={"item " + (category === CATEGORIES.ALL ? "active": "")} onclick={this.onCategoryChange}>
                            <span id = "all" className="semi-tabs-icon">
                              <img className="ui mini image" src={"assets/images/all-request-" + (isAllActive ? "white" : "color") + "-icon.png"} alt=""/>
                            </span>
                            <span className="content ">{users.length} All Users</span>
                        </div>
                        <div data-category={CATEGORIES.UN_REGISTERED} className={"item " + (isUnregisteredActive ? "active": "")} onclick={this.onCategoryChange}>
                            <span className="semi-tabs-icon">
                                <img className="ui mini image" src={"assets/images/pending-" + (isUnregisteredActive ? "white" : "color") + "-icon.png"} alt=""/>
                            </span>

                            <span className="content">{users.length - registeredCount} Unregistered</span>
                        </div>
                        <div data-category={CATEGORIES.REGISTERED} className={"item " + (isRegisteredActive ? "active": "")} onclick={this.onCategoryChange}>
                            <span className="semi-tabs-icon">
                                <img className="ui mini image" src={"assets/images/approve-" + (isRegisteredActive ? "white" : "color") + "-icon.png"} alt=""/>
                            </span>
                            <span className="content">{registeredCount} Registered</span>
                        </div>
                        <div className="common-tab-button">
                            <div className="ui primary button buttons-primary-text buttons-primary-background" onclick={this.showAddOrUpdateUserPopup}>Add User</div>
                        </div>
                    </div>
                </div>
                <div className="common-search-wrapper">
                    <div className="ui search">
                        <input className="common-search-input" type="text" placeholder="Search User" onkeyup={this.onSearch}/>
                        <img className="common-search-icon" src="assets/images/search-icon.png" alt=""/>
                    </div>
                </div>
            </div>
        );
    }

    getRegisteredUsers = (users) => R.filter(user => user.registered, users || []);

    onSearch = (e) => {
        let searchString = e.target.value.trim();
        if(this.state.searchString !== searchString) {
            this.state.searchString = searchString;

            let filteredUsers = this.applySearch(this.state.users);
            return this.setState({filteredUsers});
        }
    };

    getUserView = (user) => {
        let tenant = user.tenant || this.props.tenant;
        let phoneCode = (user.mobileNumber && user.mobileNumber.code) || "";
        let number = (user.mobileNumber && user.mobileNumber.number) || "";
        let mobileNumber = phoneCode + " " + number;
        let mailLink = convertToMailToUrl(user.emailId);
        return (
            <tr data-id={user.id} data-type={DATA_TYPE.NUMBER} className={user.registered ? "yellow-bg" : ""}>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td className="table-grey-text">{tenant.name}</td>
                <td className="table-grey-text"><a className="link-text" href={mailLink}>{user.emailId}</a></td>
                <td className="table-grey-text">{mobileNumber || "-"}</td>
                <td className="table-grey-text">{user.lastLoginTimestamp ? formatDateTime(user.lastLoginTimestamp, 'd mmm yyyy, h:MM TT') : "-"}</td>
                <td className="table-grey-text status-td">{user.registered ? "Registered" : "Unregistered"}</td>
                <td className="table-grey-text">{USER_ROLES[user.role].name}</td><td className="common-action-td">
                    <span className="common-action-tag link-text" onclick={this.showEnableDisableConfirmation}>{user.enabled ? "Disable" : "Enable"}</span>
                    <span className="common-action-tag link-text" onclick={this.showReInviteUserPopup}>ReInvite</span>
                    <span className="common-action-tag link-text" onclick={this.showAddOrUpdateUserPopup}>Edit</span>
                    <span className="common-action-tag link-text" onclick={this.showDeleteUserConfirmation}>Delete</span>
                </td>
            </tr>
        );
    };

    showReInviteUserPopup = (e) => {
        let user = this.getUserFromEvent(e) || {};
        let tenant = user.tenant || this.props.tenant;
        let view = this.getReInviteUserPopupView(user, tenant);
        return ModalContainer.addModalView("reInvite-user-popup", view);
    };

    getReInviteUserPopupView(user, tenant){
        let errors = this.state.errors || {};
        return(
            <div data-id={user.id} data-type={DATA_TYPE.NUMBER} className="ui modal common-popup-wrapper reInvite-user-popup scrolling transition visible active" style="display: block !important;">
                <div className="content">
                    <div className="secondary-header">Do you want to send the email invitation again?</div>
                    <div className="description">
                        <table className="ui table">
                            <tbody>
                            <tr>
                                <td>Email Address</td>
                                <td>{user.emailId}</td>
                            </tr>
                            </tbody>
                        </table>
                        <p className={"error-text " + (errors.serverError ? "" : "hide")}>{(errors.serverError && errors.serverError.description) || "Something went wrong! Please try again."}</p>
                    </div>
                    <div className="actions">
                        <div className={"ui primary button buttons-primary-text buttons-primary-background " + (this.state.loading? "loading disabled" : "")} onclick={this.reInviteUser}>Yes</div>
                        <div className="ui cancel button buttons-secondary-text buttons-secondary-background" onclick={this.closeReInviteUserPopup}>No</div>
                    </div>
                </div>
            </div>
        );
    }

    reInviteUser = (e) => {
        let id = getAttrValueForEventFromClosest(e, 'data-id');
        let user = this.getUserById(id);
        return this.setState({loading: true})
            .then(_ => ModalContainer.updateModalView("reInvite-user-popup", this.getReInviteUserPopupView(user, user.tenant || this.props.tenant)))
            .then(_ => sendRegistrationMailToUser(this.props.tenant.id, user))
            .then(_ => this.setState({loading: false})
                    .then(_ => ModalContainer.removeModal("reInvite-user-popup"))
            )
            .catch(_ => {
                return this.setState({errors: {serverError: true}, loading: false}).then(_ => ModalContainer.updateModalView("reInvite-user-popup", this.getReInviteUserPopupView(user, user.tenant || this.props.tenant)))
            });
    };

    closeReInviteUserPopup = (e) => {
        return ModalContainer.removeModal("reInvite-user-popup")
            .then(_ => this.setState({errors: null, loading: false}));
    };

    showDeleteUserConfirmation = (e) => {
        let user = this.getUserFromEvent(e) || {};
        let tenant = user.tenant || this.props.tenant;
        let view = this.getDeleteUserPopupView(user, tenant);
        return ModalContainer.addModalView("delete-user-popup", view);
    };

    getDeleteUserPopupView(user, tenant){
        let errors = this.state.errors || {};
        let phoneCode = (user.mobileNumber && user.mobileNumber.code) || "";
        let number = (user.mobileNumber && user.mobileNumber.number) || "";
        let mobileNumber = phoneCode + " " + number;
        return(
            <div data-id={user.id} data-type={DATA_TYPE.NUMBER} className="ui modal common-popup-wrapper delete-user-popup scrolling transition visible active" style="display: block !important;">
                <div className="content">
                    <div className="secondary-header">Are you sure you want to Delete this User?</div>
                    <div className="description">
                        <table className="ui table">
                            <tbody>
                            <tr>
                                <td>First Name</td>
                                <td>{user.firstName}</td>
                            </tr>
                            <tr>
                                <td>Last Name</td>
                                <td>{user.lastName}</td>
                            </tr>
                            <tr>
                                <td>Company Name</td>
                                <td>{tenant.name}</td>
                            </tr>
                            <tr>
                                <td>Email Address</td>
                                <td>{user.emailId}</td>
                            </tr>
                            <tr>
                                <td>Mobile Number</td>
                                <td>{mobileNumber || "-"}</td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td>{user.registered ? "Registered" : "Unregistered"}</td>
                            </tr>
                            <tr>
                                <td>Role</td>
                                <td>{USER_ROLES[user.role].name}</td>
                            </tr>
                            </tbody>
                        </table>
                        <p className={"error-text " + (errors.serverError ? "" : "hide")}>{(errors.serverError && errors.serverError.description) || "Something went wrong! Please try again."}</p>
                    </div>
                    <div className="actions">
                        <div className={"ui primary button buttons-primary-text buttons-primary-background " + (this.state.loading? "loading disabled" : "")} onclick={this.deleteUser}>Yes</div>
                        <div className="ui cancel button buttons-secondary-text buttons-secondary-background" onclick={this.closeDeleteUserPopup}>No</div>
                    </div>
                </div>
            </div>
        );
    }

    closeDeleteUserPopup = (e) => {
        return ModalContainer.removeModal("delete-user-popup")
            .then(_ => this.setState({errors: null, loading: false}));
    };

    deleteUser = (e) => {
        let id = getAttrValueForEventFromClosest(e, 'data-id');
        let user = this.getUserById(id);
        return this.setState({loading: true})
            .then(_ => ModalContainer.updateModalView("delete-user-popup", this.getDeleteUserPopupView(user, user.tenant || this.props.tenant)))
            .then(_ => deleteUserForTenantById(this.props.tenant.id, id))
            .then(_ => {
                let users = R.reject(u => u.id === id, this.state.users || []);
                let filteredUsers = this.applySearch(users);
                let registered = this.getRegisteredUsers(users);
                return this.setState({users, filteredUsers, registeredCount: registered.length, loading: false})
                    .then(_ => ModalContainer.removeModal("delete-user-popup"));
            })
            .catch(_ => {
                return this.setState({errors: {serverError: true}, loading: false}).then(_ => ModalContainer.updateModalView("delete-user-popup", this.getDeleteUserPopupView(user, user.tenant || this.props.tenant)))
            });
    };

    getUserById = (id) => id && R.find(user => user.id === id, this.state.users || []);

    getUserFromEvent(e) {
        let id = getAttrValueForEventFromClosest(e, 'data-id');
        return this.getUserById(id);
    }

    showEnableDisableConfirmation = (e) => {
        let user = this.getUserFromEvent(e);
        let tenant = user.tenant || this.props.tenant;
        let view = this.getEnableDisablePopupView(user, tenant);
        ModalContainer.addModalView('enable-disable-confirmation', view);
    };

    getEnableDisablePopupView(user, tenant) {
        let errors = this.state.errors || {};
        let phoneCode = (user.mobileNumber && user.mobileNumber.code) || "";
        let number = (user.mobileNumber && user.mobileNumber.number) || "";
        let mobileNumber = phoneCode + " " + number;
        return (
            <div data-id={user.id} data-type={DATA_TYPE.NUMBER} className="ui modal common-popup-wrapper user-management-enable-disable-popup scrolling transition visible active" style="display: block !important;">
                <div className="content">
                    <div className="secondary-header">Are you sure you want to {user.enabled ? "Disable" : "Enable"} this User?</div>
                    <div className="description">
                        <table className="ui table">
                            <tbody>
                            <tr>
                                <td>First Name</td>
                                <td>{user.firstName}</td>
                            </tr>
                            <tr>
                                <td>Last Name</td>
                                <td>{user.lastName}</td>
                            </tr>
                            <tr>
                                <td>Company Name</td>
                                <td>{tenant.name}</td>
                            </tr>
                            <tr>
                                <td>Email Address</td>
                                <td>{user.emailId}</td>
                            </tr>
                            <tr>
                                <td>Mobile Number</td>
                                <td>{mobileNumber || "-"}</td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td>{user.registered ? "Registered" : "Unregistered"}</td>
                            </tr>
                            <tr>
                                <td>Role</td>
                                <td>{USER_ROLES[user.role].name}</td>
                            </tr>
                            </tbody>
                        </table>
                        <p className={"error-text " + (errors.serverError ? "" : "hide")}>{(errors.serverError && errors.serverError.description) || "Something went wrong! Please try again."}</p>
                    </div>
                    <div className="actions">
                        <div className={"ui primary button buttons-primary-text buttons-primary-background " + (this.state.loading ? "loading disabled" : "")} onclick={this.toggleEnableDisableState}>Yes</div>
                        <div className="ui cancel button buttons-secondary-text buttons-secondary-background" onclick={this.closeEnableDisableUserPopup}>No</div>
                    </div>
                </div>
            </div>
        );
    }

    toggleEnableDisableState = (e) => {
        let user = R.clone(this.getUserFromEvent(e));
        if(user) {
            return this.setState({loading: true})
                .then(_ => ModalContainer.updateModalView("enable-disable-confirmation", this.getEnableDisablePopupView(user, user.tenant || this.props.tenant)))
                .then(_ => {
                    user.enabled = !user.enabled;
                    return this.updateUser(this.props.tenant.id, user);
                })
                .then(_ => this.setState({loading: false}))
                .then(_ => ModalContainer.removeModal("enable-disable-confirmation"))
                .catch(_ => this.setState({errors: {serverError: true}, loading: false})
                    .then(_ => ModalContainer.updateModalView("enable-disable-confirmation", this.getEnableDisablePopupView(user, user.tenant || this.props.tenant))));
        }
    };

    closeEnableDisableUserPopup = (e) => {
        return ModalContainer.removeModal("enable-disable-confirmation")
            .then(_ => this.setState({errors: null, loading: false}));
    };

    updateUser(tenantId, user) {
        return updateUserForTenant(tenantId, user)
            .then(_ => {
                let index = R.findIndex(u => u.id === user.id, this.state.users || []);
                let users = R.update(index, user, this.state.users || []);
                users = orderByAsc(users, 'firstName');
                let filteredUsers = this.applySearch(users);
                return this.setState({users, filteredUsers});
            })
            .catch();
    }

    updateAddOrUpdateUserPopup = (user) => {
        let view = this.getAddOrUpdateUserPopupView(user);
        return ModalContainer.updateModalView('add-update-user-popup', view)
            .then(_ => ModalContainer.getModelEl('add-update-user-popup').find('.ui.dropdown').dropdown())
            .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("add-update-user-popup")));
    };

    addOrUpdateUser = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let errors = validateForm($form);
        console.log("errors" + JSON.stringify(errors));
        let user = serializeForm($form);
        user.emailId = user.emailId.trim();
        user.mobileNumber = !R.any(x => R.isEmpty(x), R.values(user.mobileNumber))? user.mobileNumber : null;
        let tenantId = this.props.tenant.id;
        if(errors && !R.isEmpty(errors)) {
            return this.setState({errors})
                .then(_ => this.updateAddOrUpdateUserPopup(user));
        }
        if(user.id) {
            let existing = this.getUserById(user.id) || {};
            user = R.mergeDeepLeft(user, existing);
            return this.setState({loading: true})
                .then(_ => this.updateAddOrUpdateUserPopup(user))
                .then(_ => this.updateUser(tenantId, user))
                .then(this.closeAddOrUpdateUserPopup)
                .catch(resp => {
                    let description = resp && resp.responseJSON && resp.responseJSON.description;
                    return this.setState({errors: {serverError: {description}}, loading: false})
                        .then(_ => this.updateAddOrUpdateUserPopup(user))
                });
        }
        return this.setState({loading: true})
            .then(_ => this.updateAddOrUpdateUserPopup(user))
            .then(_ => createUserForTenant(tenantId, user))
            .then(user => {
                let users = R.append(user, this.state.users || []);
                users = orderByAsc(users, 'firstName');
                let filteredUsers = this.applySearch(users);
                return this.setState({users, filteredUsers, errors: null, loading: false})
                    .then(this.closeAddOrUpdateUserPopup);
            })
            .catch(resp => {
                let description = resp && resp.responseJSON && resp.responseJSON.description;
                return this.setState({errors: {serverError: {description}}, loading: false})
                    .then(_ => this.updateAddOrUpdateUserPopup(user))
            });
    };

    getAddOrUpdateUserPopupView(user) {
        let newUser = !user.id;
        let errors = this.state.errors || {};
        let tenantId = user.tenantId || this.props.tenant.id;
        let tenantName = this.props.tenant.name;
        let popupState = this.state.popupState || {};
        let userRole = popupState.role || user.role || USER_ROLES.USER.id;
        let phoneCode = this.state.phoneCode || (user.mobileNumber && user.mobileNumber.code) || DEFAULT_COUNTRY_CODE;
        return (
            <div data-form-id="add-update-user-form" className="ui modal common-popup-wrapper add-user-popup scrolling transition visible active" style="display: block !important;">
                <div className="content">
                    <div className="header">{newUser ? "Add" : "Edit"} User</div>

                    <input type="hidden" data-key="id" data-type={DATA_TYPE.NUMBER} value={user.id || ""} />
                    <input type="hidden" data-key="tenantId" data-type={DATA_TYPE.NUMBER} value={tenantId} />

                    <div className="description">
                        <table className="ui table">
                            <tbody>
                            <tr>
                                <td>First Name <span className="mandatory-start">*</span></td>
                                <td>
                                    <input data-key="firstName"
                                           data-required="true"
                                           data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                           className={"common-popup-input " + (errors.firstName ? "validation-error" : "")}
                                           onkeyup={this.onAddOrUpdateDataChange} type="text" value={user.firstName || ""} oninput={this.onValueChange} autofocus={true}/>
                                    <p className={"error-text " + (errors.firstName ? "" : "hide")}>First Name cannot be empty</p>
                                </td>
                            </tr>
                            <tr>
                                <td>Last Name</td>
                                <td>
                                    <input data-key="lastName" className="common-popup-input"
                                           onkeyup={this.onAddOrUpdateDataChange} type="text"
                                           value={user.lastName || ""}/>
                                </td>
                            </tr>
                                <tr>
                                    <td>Company Name</td>
                                    <td><input type="text" className="common-popup-input" disabled={true} value={tenantName}/></td>
                                </tr>
                                <tr>
                                    <td>Email Address <span className="mandatory-start">*</span></td>
                                    <td>
                                        <input type="text" data-key="emailId" data-required="true" data-validation-type={VALIDATION_TYPE.EMAIL_ID} className={"common-popup-input " + (errors.emailId ? "validation-error" : "")} disabled={!newUser} onkeyup={this.onAddOrUpdateDataChange} oninput={this.onValueChange} value={user.emailId || ""}/>
                                        <p className={"error-text " + (errors.emailId ? "" : "hide")}>User EmailId must be a valid Email Address</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Mobile Number </td>
                                    <td className="mobile-number-wrapper">
                                        <input type="hidden" data-key="mobileNumber.code" value={phoneCode}/>
                                        <div key={new Date().getTime()} className="ui search selection dropdown">
                                            <div className="text">{phoneCode}</div>
                                            <i className ="dropdown icon"/>
                                            <div className="menu">
                                                {this.showCountryCodes()}
                                            </div>
                                        </div>
                                        <input type="text" pattern="[0-9]*" data-key="mobileNumber.number" data-required="false" data-validation-type={VALIDATION_TYPE.NUMBER} className={"common-popup-input " + (errors.mobileNumber ? "validation-error" : "")}  onkeyup={this.onAddOrUpdateDataChange} oninput={this.onValueChange} value={(user.mobileNumber && user.mobileNumber.number) || ""}/>
                                        <p className={"error-text " + ((errors.mobileNumber && errors.mobileNumber.number)? "" : "hide")}>Please enter a valid mobile number.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Assign Role</td>
                                    <td>
                                        <div key={new Date().getTime()} className="ui dropdown item common-popup-dropdown">
                                            {USER_ROLES[userRole].name}
                                            <input type="hidden" data-key="role" value={userRole}/>
                                            <i className="dropdown icon"/>
                                            <div className="menu">
                                                <div className={"item " + (userRole === USER_ROLES.USER.id ? "active" : "")} data-role={USER_ROLES.USER.id} onclick={this.onRoleChange}>{USER_ROLES.USER.name}</div>
                                                <div className={"item " + (userRole === USER_ROLES.TENANT_ADMIN.id ? "active" : "")} data-role={USER_ROLES.TENANT_ADMIN.id} onclick={this.onRoleChange}>{USER_ROLES.TENANT_ADMIN.name}</div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p className={"error-text add-user-default-error " + (errors.serverError ? "" : "hide")}>{(errors.serverError && errors.serverError.description) || "Something went wrong! Please try again."}</p>
                    </div>
                    <div className="actions">
                        <div id="addOrUpdateAction" className={"ui primary button buttons-primary-text buttons-primary-background " + (this.state.loading? "loading disabled" : "")} onclick={this.addOrUpdateUser}>{newUser ? "Add" : "Update"} User</div>
                        <div className="ui cancel button buttons-secondary-text buttons-secondary-background" onclick={this.closeAddOrUpdateUserPopup}>Cancel</div>
                    </div>
                </div>
            </div>
        );
    }

    showCountryCodes() {
        let selectedPhoneCode = this.state.phoneCode || DEFAULT_COUNTRY_CODE;
        return(
            R.map(countryAndCode => {
                let country = countryAndCode["country"];
                let code = countryAndCode["phoneCode"];
                return <div className={"item " + (selectedPhoneCode === code? "active": "")} data-code={code} data-value={country} data-text={code} onclick={this.onCountryChange}>{country}<span className="country-code">{code}</span></div>
            }, this.state.countryCodes)
        );
    }

    onCountryChange = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let selectedPhoneCode = getAttrValueForEventFromClosest(e, 'data-code');
        let user = serializeForm($form);
        let phoneCode = this.state.phoneCode || "";
        if(selectedPhoneCode !== phoneCode) {
            setTimeout(() => {
                this.setState({phoneCode: selectedPhoneCode})
                    .then(_ => ModalContainer.updateModalView('add-update-user-popup', this.getAddOrUpdateUserPopupView(user)))
                    .then(_ => ModalContainer.getModelEl('add-update-user-popup').find('.ui.dropdown').dropdown());
            }, 100);
        }
    };

    onRoleChange = (e) => {
        let role = getAttrValueForEventFromClosest(e, 'data-role');
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let user = serializeForm($form);
        let popupState = this.state.popupState || {};
        if(popupState.role !== role) {
            setTimeout(() => {
                this.setState({popupState: {role}})
                    .then(_ => ModalContainer.updateModalView('add-update-user-popup', this.getAddOrUpdateUserPopupView(user)))
                    .then(_ => ModalContainer.getModelEl('add-update-user-popup').find('.ui.dropdown').dropdown());
            }, 100);
        }
    };

    onAddOrUpdateDataChange = (e) => {
        if(e.keyCode === 13) {
            e.preventDefault();
            getClosestForEventWithAttribute(e, 'data-form-id')
                .find('#addOrUpdateAction')
                .click();
        }
    };

    onValueChange = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let user = serializeForm($form);
        let key = getAttrValueForEventFromClosest(e, 'data-key');
        let errors = R.clone(this.state.errors) || {};
        if(key === "mobileNumber.number" && !e.target.validity.valid)
        {
            let mobileNumber = user.mobileNumber;
            let number = user.mobileNumber && user.mobileNumber.number;
            mobileNumber.number = number.slice(0, -1);
            user.mobileNumber = mobileNumber;
            delete errors.mobileNumber;
        }
        if(key !== "mobileNumber.number") delete errors[key];
        return this.setState({errors})
            .then(_ => this.updateAddOrUpdateUserPopup(user))
            .then(_ => $(e.target).focus());
    };

    showAddOrUpdateUserPopup = (e) => {
        let user = this.getUserFromEvent(e) || {};
        let view = this.getAddOrUpdateUserPopupView(user);
        return ModalContainer.addModalView("add-update-user-popup", view)
            .then(_ => ModalContainer.getModelEl('add-update-user-popup').find('.ui.dropdown').dropdown())
            .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("add-update-user-popup")));
    };

    closeAddOrUpdateUserPopup = (e) => {
        return ModalContainer.removeModal('add-update-user-popup')
            .then(_ => this.setState({errors: null, phoneCode: null, popupState: null, loading: false}));
    };

    getView() {
        return super.getView()
            .then(_ => {
                let usersView = null;
                if(this.state.dataLoaded) {
                    if(!this.state.filteredUsers || R.isEmpty(this.state.filteredUsers)) usersView = this.getNoDataView();
                    else usersView = R.map(t => this.getUserView(t), this.state.filteredUsers);
                } else usersView = this.getLoadingView();
                return (
                    <div className="user-management-tab-content-wrapper">
                        {this.getHeaderView()}
                        <div className="common-table-wrapper">
                            <table className="ui single line table">
                                <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Company Name</th>
                                    <th>Email Address</th>
                                    <th>Mobile Number</th>
                                    <th>Last Login</th>
                                    <th>Status</th>
                                    <th>Role</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody className="user-management-tbody">
                                    {usersView}
                                </tbody>
                            </table>
                        </div>
                    </div>
                );
            });
    }

    applyAutoFocusForContainer = (container) => $(container).find('[autofocus]').focus();

    applyAutoFocus = () => this.applyAutoFocusForContainer(this.$container);
}