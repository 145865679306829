import UIBaseComponent from "../../Backflipt_UI_Components/src/ui-base-component/ui-base-component";
import React from "react";
import {getTokenInfo} from "../../service/user-service";
import {gotoRoute, goToRouteOnEvent} from "../../utils/generic-utils";
import {ROUTES} from "../../config/app-config";
import { getLogoUrl } from '../../utils/domain-utils'

export default class SSOLogin extends UIBaseComponent {

    beforeRender() {
        this.loadDataAsync();
        return super.beforeRender();
    }

    loadDataAsync() {
        return getTokenInfo(this.props.token)
            .then(payload => {
                if(payload.authenticated) return gotoRoute(ROUTES.HOME);
                return this.setState({dataLoaded: true});
            });
    }

    getView() {
        return super.getView()
            .then(_ => {
                let formLogo = getLogoUrl();
                if(this.state.dataLoaded)
                return (
                    //<div>SSO Login: {JSON.stringify(this.state.payload || {})}</div>
                    <div className="ics-form-wrapper successful-error-wrapper">
                        <div className="ics-common-wrapper">
                            <div className="form-common-header-wrapper">
                                <img src={formLogo} className="ics-form-logo" alt=""/>
                            </div>
                            <div className="ics-common-body-wrapper">
                                <div className="success-error-img-wrapper">
                                    <img src="assets/images/login-succes-icon.png" className="hide" alt=""/>
                                    <img src="assets/images/login-error-icon.png" alt=""/>
                                </div>
                                <h3 className="form-secondary-header-text">Your email does not match with the SSO login credentials.Please enter valid email!</h3>
                            </div>
                            <div className="common-form-btn-wrapper">
                                <button className="ui right labeled icon button"
                                    data-route={ROUTES.SIGN_IN} onclick={goToRouteOnEvent}>
                                    <i className="right arrow icon"></i>
                                    Sign In
                                </button>
                            </div>
                        </div>
                    </div>
                );
            });
    }
}