import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import React from 'react'

export default class Page extends UIBaseComponent {

    getView() {
        return super.getView()
            .then(_ => {
                return (
                    <div className="ics-admin-portal-flow-main-wrapper">
                        <div id="header"/>
                        <div id="body" />
                    </div>
                );
            });
    }
}