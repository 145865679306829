import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import React from 'react'
import { ROUTES } from '../../config/app-config'
import { goToRouteOnEvent } from '../../utils/generic-utils'

export default class Configurations extends UIBaseComponent {
    getView () {
        return super.getView()
            .then(_ => {

                let cr = this.props.routeAttributes.route

                let feedUploadActiveClass = cr === ROUTES.ADMIN_COMMON_FEED_UPLOAD ? 'active' : ''
                let standardAppsClass = cr === ROUTES.ADMIN_STANDARD_APPS ? 'active' : ''
                let emailTemplatesActiveClass = cr === ROUTES.ADMIN_EMAIL_TEMPLATES ? 'active' : ''

                return (
                    <div className="configuration-container">
                        <div className="common-tabs-wrapper">
                            <div className="tabs-wrapper">
                                <span className={'common-tab ' + feedUploadActiveClass} data-route={ROUTES.ADMIN_COMMON_FEED_UPLOAD} onclick={goToRouteOnEvent}>Common Feed </span>
                                {/*<span className={'common-tab ' + emailTemplatesActiveClass} data-route={ROUTES.ADMIN_EMAIL_TEMPLATES} onclick={goToRouteOnEvent}>Email Templates </span>*/}
                            </div>
                            <div className="tabs-wrapper">
                                <span className={'common-tab ' + standardAppsClass}
                                      data-route={ROUTES.ADMIN_STANDARD_APPS} onclick={goToRouteOnEvent}>Standard Applications </span>
                            </div>
                            <div className="tabs-wrapper">
                                <span className={'common-tab ' + emailTemplatesActiveClass}
                                      data-route={ROUTES.ADMIN_EMAIL_TEMPLATES} onclick={goToRouteOnEvent}>Email Templates </span>
                            </div>
                            <div className="tabs-active"/>
                        </div>
                        <div className="customer-tabs-content-wrapper">

                            {/* Common Feed Content */}
                            <div id="feed_upload_container"/>
                            <div id="standard-apps"/>
                            <div id="email-templates"/>
                            {/* Email Templates Content */}
                            {/*<div id="email_templates_container"/>*/}

                        </div>
                    </div>
                )
            })
    }
}