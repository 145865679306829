export const TEMPLATES = {
    "User Registration": {
        NAME: "User Registration",
        KEY: "userRegistration"
    },
    "Reset Password": {
        NAME: "Reset Password",
        KEY: "resetPassword"
    },
    "Download Client": {
        NAME: "Download Client",
        KEY: "downloadClient"
    }
}