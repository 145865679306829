import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import React from 'react'
import {
    createAppForTenant, createStandardAppForTenant,
    deleteAppForTenantById, encodeURIData,
    getAllAppsForTenant, getStandardApps, updateAppConfigForApp,
    updateAppForTenant, updateAppSequenceForTenant
} from '../../service/tenant-service'
import {
    convertToUrl,
    DATA_TYPE,
    getAttrValueForEventFromClosest,
    getClosestForEventWithAttribute, getValueForPathOrDefault,
    serializeForm,
    validateForm,
    VALIDATION_TYPE
} from '../../Backflipt_UI_Components/utils/generic-utils'
import ModalContainer from '../modal-container/modal-container-v2'
import $ from "jquery";
import {ERRORS} from "../../config/errors";
import {goToRouteOnEvent, prepareRESTURI} from "../../utils/generic-utils";
import {ROUTES} from "../../config/app-config";
import {amIAdmin} from "../../dao/user-profile-dao";

const R = require('ramda');

const API_CONFIG = {
    TRU_OPS: {
        id: "TRU_OPS",
        name: "TruOps"
    },
    BLU_SAPPHIRE: {
        id: "BLU_SAPPHIRE",
        name: "BluSapphire"
    }
};

export default class TenantDetailApps extends UIBaseComponent {

    beforeRender() {
        console.log("before render");
        this.loadDataAsync();
        return super.beforeRender();
    }

    loadDataAsync() {
        console.log("fetching apps");
        return getAllAppsForTenant(this.props.tenant.id)
            .then(apps => {
                let appSequence = R.map(app => app.id, apps);
                let filteredApps = this.applySearch(apps);
                return getStandardApps()
                    .then(standardApps => {
                        return this.setState({apps, standardApps, appSequence, filteredApps, dataLoaded: true});
                    })
            });
    }

    getLoadingView() {
        return (
            <div className="loading-wrapper"><p>Loading...</p></div>
        );
    }

    getNoDataView() {
        return (
            <div className="no-file-found-wrapper"><p>No Records Found</p></div>
        );
    }

    applySearch(apps) {
        let searchString = this.state.searchString;
        let filteredApps = !searchString ? apps : R.filter(t => {
            let inputKeys = R.map(k => k.toLowerCase(), R.flatten(R.map(key => key.split(" "), [t.name, t.url])));
            let searchKeys = R.map(k => k.toLowerCase(), searchString.split(" "));
            return R.find(input => {
                return R.find(sk => input.includes(sk), searchKeys);
            }, inputKeys);
        }, apps || []);

        return filteredApps;
    }

    getView() {
        return super.getView()
            .then(_ => {
                        return (
                            <div className="application-tab-content-wrapper">

                                {this.getHeaderView()}

                                <div className="common-table-wrapper">
                                    <table className="ui single line table">
                                        <thead>
                                        <tr>
                                            <th></th>
                                            <th>Application Name</th>
                                            <th>Application URL</th>
                                            <th>Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.getAppsView()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        );
                    }
            );
    }

    getAppsView(){
        if (this.state.dataLoaded) {
            if (!this.state.filteredApps || R.isEmpty(this.state.filteredApps)) return this.getNoDataView();
            return R.map(t => this.getAppRowView(t), this.state.filteredApps);
        } return this.getLoadingView();
    }

    getStandardAppsView() {
        let apps = this.state.apps || [];
        let standardApps = this.state.standardApps || [];
        standardApps = R.filter(standardApp => !R.includes(standardApp.url, R.map(app => app.url, apps)), standardApps);
        standardApps = R.filter(standardApp => !R.includes(standardApp.id, R.map(apps => apps.standardAppId, apps)), standardApps)
            if (!standardApps|| R.isEmpty(standardApps)) return this.getNoDataView();
            return R.map(t => this.getStandardAppRowView(t), standardApps);
    }

    getAppById = (id) => id && R.find(app => app.id === id, this.state.apps || []);
    getAppByStandardAppId = (id) => id && R.find(app => app.standardAppId === id, this.state.apps || []);
    getStandardAppById = (id) => id && R.find(app => app.id === id, this.state.standardApps || []);

    getAppFromEvent(e) {
        let appId = getAttrValueForEventFromClosest(e, 'data-id');
        return this.getAppById(appId);
    }

    getAppRowView(app) {
        let credentialsRoute = this.getAppUserCredentialsRoute(app);
        return (
            <tr data-id={app.id} data-type={DATA_TYPE.NUMBER}>
                <td class="up-down-arrow-td">
                    <div className={R.length(this.state.apps) === R.length(this.state.filteredApps) ? "" : "hide"}>
                        <i className="sort up icon" onclick = {() => this.positionTo(app, "up")}/>
                        <i className="sort down icon" onclick = {() => this.positionTo(app, "down")}/>
                    </div>
                </td>
                <td className={"app-name-text " + (this.isSeamlessSSOEnabledForApp(app) ? "hide" : "")}>{app.name}</td>
                <td className={"common-action-td " + (this.isSeamlessSSOEnabledForApp(app) ? "" : "hide")}>
                    <span className="common-action-tag link-text" data-route={credentialsRoute} onclick={goToRouteOnEvent}>{app.name}</span>
                </td>
                <td className="app-url-text"><a className="link-text" href={convertToUrl(app.url)} target="_blank">{app.url}</a></td>
                <td className="common-action-td">
                    <span className="common-action-tag link-text" onclick={this.showSeamlessSignOnAdditionalSetupPopup}>Additional setup</span>
                    <span className="common-action-tag link-text"
                          onclick={this.showEnableDisablePopup}>{app.enabled ? "Disable" : "Enable"}</span>
                    <span className="common-action-tag link-text" onclick={this.showAddOrUpdateAppPopup}>Edit</span>
                    <span className="common-action-tag link-text" onclick={this.showDeleteAppConfirmation}>Delete</span>
                </td>
            </tr>
        );
    }

    getStandardAppRowView(app) {
        let selectedStandardAppsIds = this.state.selectedStandardAppsIds || [];
        return (
            <div className="ui checkbox">
                <input id="select-standard-app" type="checkbox" data-id={app.id} data-type={DATA_TYPE.NUMBER} onclick={this.selectStandardApp} checked={R.includes(app.id, selectedStandardAppsIds) ? "checked" : ""}/>
                <label className="app-name-text">{app.name}</label>
            </div>
        );
    }


    getAppUserCredentialsRoute(app) {
        let appData = encodeURIData({id: app.id, name: app.name});
        let admin = amIAdmin();
        let credentialsRouteForTenant = prepareRESTURI(ROUTES.ADMIN_TENANT_DETAIL_APPS_USER_CREDENTIALS, {tenant: this.props.tenantPath, app: appData});
        let credentialsRouteForUserAdmin = prepareRESTURI(ROUTES.USER_ADMIN_APPS_USER_CREDENTIALS, {app: appData});
        return admin? credentialsRouteForTenant : credentialsRouteForUserAdmin;
    }

    getHeaderView() {
        return (
            <div className="semi-tabs-common-wrapper">
                <div className="common-tab-button">
                    <div className="ui primary button buttons-primary-text buttons-primary-background"
                         onclick={this.showAddOrUpdateAppPopup}>Add Application
                    </div>
                    <div className="ui primary button buttons-primary-text buttons-primary-background"
                         onclick={this.showAddStandardAppPopup}>Add Standard Application
                    </div>
                </div>
                <div className="common-search-wrapper">
                    <div className="ui search">
                        <input className="common-search-input" type="text" placeholder="Search Application"
                               onkeyup={this.onSearch}/>
                        <img className="common-search-icon" src="assets/images/search-icon.png" alt=""/>
                    </div>
                </div>
            </div>
        );
    }

    getAddStandardAppsPopUp() {
        let errors = this.state.errors || {};
        let selectStandardAppsIds = this.state.selectedStandardAppsIds || [];
        return (
            <div data-form-id="add-new-update-app standard-app-popup"
                 className="ui modal common-popup-wrapper add-application-popup-modal add-standard-apps-popup scrolling transition visible active"
                 style="display: block !important;">
                <div className="content">
                    <div className="header">Add Standard Applications</div>
                    <div className="description">
                        <div className="standard-apps-list-wrapper">
                            {this.getStandardAppsView()}
                            <p className={"error-text " + (errors.url ? "" : "hide")}>{(errors.url && errors.url.message) || "This app already exists!"}</p>
                        </div>
                    </div>
                    <div className="actions">
                        <div id="addOrUpdateAppAction"
                             className={"ui primary button buttons-primary-text buttons-primary-background " + (this.state.loading ? "loading disabled" : "") + (R.isEmpty(selectStandardAppsIds) ? "disabled" : "")}
                             onclick={this.addStandardAppToTenant}>Add Application
                        </div>
                        <div className="ui cancel button buttons-secondary-text buttons-secondary-background"
                             onclick={this.closeAddStandardAppPopup}>Cancel
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    selectStandardApp= (e) => {
        let selectedStandardAppsIds = this.state.selectedStandardAppsIds || [];
        let selectedStandardAppId = getAttrValueForEventFromClosest(e, 'data-id');
        let checked = R.includes(selectedStandardAppId, selectedStandardAppsIds);

        if (!checked) selectedStandardAppsIds.push(selectedStandardAppId);
        else selectedStandardAppsIds = R.filter(id => id !== selectedStandardAppId, selectedStandardAppsIds)
        return this.setState({selectedStandardAppsIds})
                    .then(_ => this.updateAddStandardAppPopup());
        }
        /*return this.setState({errors: {url: null}, selectedStandardAppsIds})
            .then(_ => {this.updateAddStandardAppPopup()});*/

    positionTo(app, position){
        let apps = this.state.apps;
        let appSequence = this.state.appSequence;
        let appIndex = R.findIndex(R.propEq('id',app.id), apps);
        let temp = apps[appIndex];
        let otherAppIndex = (position === "up")? appIndex-1:appIndex+1;
        apps[appIndex] = apps[otherAppIndex];
        apps[otherAppIndex] = temp;
        appSequence = R.map(app => app.id, apps);
        let filteredApps = this.applySearch(apps);
        return this.setState({apps, appSequence, filteredApps, dataLoaded: true})
            .then(_ => updateAppSequenceForTenant(this.props.tenant.id, appSequence));
    }

    onAddOrUpdateDataChange = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            getClosestForEventWithAttribute(e, 'data-form-id')
                .find('#addOrUpdateAppAction')
                .click();
        }
    };

    onValueChange = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let app = serializeForm($form);
        let key = getAttrValueForEventFromClosest(e, 'data-key');
        let errors = R.clone(this.state.errors) || {};
        delete errors[key];
        return this.setState({errors})
            .then(_ => this.updateAddOrUpdateAppPopup(app))
            .then(_ => $(e.target).focus());
    };

    getAddOrUpdateAppPopupView(app) {
        let newApp = !app.id;
        let apps = this.state.apps;
        let appId = this.state.appId;
        let appIndex = R.findIndex(R.propEq('id',parseInt(appId)), apps);
        let errors = this.state.errors || {};
        let tenantId = app.tenantId || this.props.tenant.id;
        return (
            <div data-form-id="add-new-update-app"
                 className="ui modal common-popup-wrapper add-application-popup-modal scrolling transition visible active"
                 style="display: block !important;">
                <div className="content">
                    <div className="header">{newApp ? "Add" : "Edit"} Application</div>
                    <div className="description">
                        <table className="ui table">
                            <input type="hidden" data-key="id" data-type={DATA_TYPE.NUMBER} value={app.id || ""}/>
                            <input type="hidden" data-key="tenantId" data-type={DATA_TYPE.NUMBER} value={tenantId}/>
                            <tbody>
                            <tr>
                                <td>Application Name <span className="mandatory-start">*</span></td>
                                <td>
                                    <input type="text" data-key="name" data-required="true"
                                           data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                           className={"common-popup-input " + (errors.name ? "validation-error" : "")}
                                           onkeyup={this.onAddOrUpdateDataChange} oninput={this.onValueChange} value={app.name || ""}
                                           autofocus={true}/>
                                    <p className={"error-text " + (errors.name ? "" : "hide")}>Application Name cannot be empty</p>
                                </td>
                            </tr>
                            <tr>
                                <td>Applications URL <span className="mandatory-start">*</span></td>
                                <td>
                                    <input type="text" data-key="url" data-required="true"
                                           data-validation-type={VALIDATION_TYPE.URL}
                                           className={"common-popup-input " + (errors.url ? "validation-error" : "")}
                                           onkeyup={this.onAddOrUpdateDataChange} oninput={this.onValueChange} value={app.url || ""}/>
                                    <p className={"error-text " + (errors.url ? "" : "hide")}>{(errors.url && errors.url.message) || "Please enter a valid URL"}</p>
                                    <p className={"error-text " + (errors.serverError ? "" : "hide")}>Something went wrong! Please try again.</p>
                                </td>
                            </tr>
                            <tr className={R.isEmpty(apps) || !newApp ? "hide":""}>
                                <td>Insert Below</td>
                                <td>
                                    <div key={new Date().getTime()} className="ui dropdown item common-popup-dropdown" tabindex="0">
                                        {appId?apps[appIndex].name:"None"}
                                        <i className="dropdown icon"></i>
                                        <div className="menu" tabindex="-1">
                                            <div className={"item " + (appId? "" : "active")} onclick={this.updateAppSequence}>None</div>
                                            {R.map(app => <div data-app-id = {app.id} className={"item " + (appId === app.id? "active" : "")} onclick={this.updateAppSequence}>{app.name}</div>, this.state.apps)}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="actions">
                        <div id="addOrUpdateAppAction"
                             className={"ui primary button buttons-primary-text buttons-primary-background " + (this.state.loading ? "loading disabled" : "")}
                             onclick={this.addOrUpdateApp}>{newApp ? "Add" : "Update"} Application
                        </div>
                        <div className="ui cancel button buttons-secondary-text buttons-secondary-background"
                             onclick={this.closeAddOrUpdateAppPopup}>Cancel
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    updateAppSequence = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let app = serializeForm($form);
        let appId = getAttrValueForEventFromClosest(e, 'data-app-id');
        return this.setState({appId: parseInt(appId)})
            .then(_ => this.updateAddOrUpdateAppPopup(app))
    };

    getDisableAppPopupView(app) {
        let errors = this.state.errors || {};
        return (
            <div data-id={app.id} data-type={DATA_TYPE.NUMBER}
                 className="ui modal common-popup-wrapper disable-application-popup-modal scrolling transition visible active"
                 style="display: block !important;">
                <div className="content">
                    <div className="secondary-header">Are you sure you want to {app.enabled ? "Disable" : "Enable"} this
                        Application?
                    </div>
                    <div className="description">
                        <table className="ui table">
                            <tbody>
                            <tr>
                                <td>Application Name</td>
                                <td>{app.name}</td>
                            </tr>
                            <tr>
                                <td>Application URL</td>
                                <td>
                                    <div className="popup-app-url-text">
                                        {app.url}
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p className={"error-text " + (errors.serverError ? "" : "hide")}>Something went wrong! Please try again.</p>
                    </div>
                    <div className="actions">
                        <div
                            className={"ui primary button buttons-primary-text buttons-primary-background " + (this.state.loading ? "loading disabled" : "")}
                            onclick={this.toggleEnableDisableState}>Yes
                        </div>
                        <div className="ui cancel button buttons-secondary-text buttons-secondary-background"
                             onclick={this.closeEnableDisableAppPopup}>No
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    showEnableDisablePopup = (e) => {
        let app = this.getAppFromEvent(e);
        if (app) {
            let popupView = this.getDisableAppPopupView(app);
            return ModalContainer.addModalView("enable-disable-app-popup", popupView);
        }
    };

    closeEnableDisableAppPopup =(e) => {
        return ModalContainer.removeModal("enable-disable-app-popup")
            .then(_ => this.setState({errors: null, loading: false}));
    };

    toggleEnableDisableState = (e) => {
        let app = R.clone(this.getAppFromEvent(e));
        if (app) {
            return this.setState({loading: true})
                .then(_ => ModalContainer.updateModalView("enable-disable-app-popup", this.getDisableAppPopupView(app)))
                .then(_ => {
                    app.enabled = !app.enabled;
                    return this.updateApp(this.props.tenant.id, app)
                })
                .then(_ => this.setState({loading: false}))
                .then(_ => ModalContainer.removeModal("enable-disable-app-popup"))
                .catch(_ => this.setState({errors: {serverError: true}, loading: false})
                    .then(_ => ModalContainer.updateModalView("enable-disable-app-popup", this.getDisableAppPopupView(app))));
        }
    };

    showAddOrUpdateAppPopup = (e) => {
        let app = this.getAppFromEvent(e) || {};
        let view = this.getAddOrUpdateAppPopupView(app);
        ModalContainer.addModalView("add-update-app-popup", view)
            .then(_ => ModalContainer.getModelEl('add-update-app-popup').find('.ui.dropdown').dropdown())
            .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("add-update-app-popup")));
    };

    closeAddOrUpdateAppPopup = (e) => {
        return ModalContainer.removeModal('add-update-app-popup')
            .then(_ => this.setState({errors: null, loading: false}));
    };

    updateApp(tenantId, app) {
        return updateAppForTenant(tenantId, app)
            .then(_ => {
                let index = R.findIndex(a => a.id === app.id, this.state.apps || []);
                let apps = R.update(index, app, this.state.apps || []);
                let filteredApps = this.applySearch(apps);
                return this.setState({apps, filteredApps});
            })
            .catch();
    }

    updateAddOrUpdateAppPopup = (app) => {
        let view = this.getAddOrUpdateAppPopupView(app);
        return ModalContainer.updateModalView('add-update-app-popup', view)
            .then(_ => ModalContainer.getModelEl('add-update-app-popup').find('.ui.dropdown').dropdown())
            .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("add-update-app-popup")));
    };

    showAddStandardAppPopup = () => {
        let view = this.getAddStandardAppsPopUp();
        return ModalContainer.addModalView('add-standard-app-popup', view);
    }

    updateAddStandardAppPopup() {
        let view = this.getAddStandardAppsPopUp();
        return ModalContainer.updateModalView('add-standard-app-popup', view);
    }

    closeAddStandardAppPopup = () => {
        return ModalContainer.removeModal('add-standard-app-popup')
            .then(_ => this.setState({loading: false, selectedStandardAppsIds: null}));
    };

    addOrUpdateApp = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let errors = validateForm($form);
        let app = serializeForm($form);
        let apps = R.clone(this.state.apps);
        let tenantId = this.props.tenant.id;
        if (errors && !R.isEmpty(errors)) {
            return this.setState({errors})
                .then(_ => this.updateAddOrUpdateAppPopup(app));
        }
        let appURLs = R.map(app => app.url, this.state.apps);
        if (app.id) {
            let existing = this.getAppById(app.id) || {};
            if(R.includes(app.url,R.reject(R.equals(existing.url),appURLs))) {
                return this.setState({errors:{url:{message:"App with this url already exists!"}}})
                    .then(_ => this.updateAddOrUpdateAppPopup(app));
            }
            app = R.mergeDeepLeft(app, existing);
            return this.setState({loading: true})
                .then(_ => this.updateAddOrUpdateAppPopup(app))
                .then(_ => this.updateApp(tenantId, app))
                .then(this.closeAddOrUpdateAppPopup)
                .catch(_ => this.setState({errors: {serverError: true}, loading: false})
                    .then(_ => this.updateAddOrUpdateAppPopup(app)));
        }
        if(R.includes(app.url,appURLs)) {
            return this.setState({errors:{url:{message:"App with this url already exists!"}}})
                .then(_ => this.updateAddOrUpdateAppPopup(app));
        }
        let appId = this.state.appId || "";
        let appIndex = R.findIndex(R.propEq('id',parseInt(appId)), apps);
        return this.setState({loading: true})
            .then(_ => this.updateAddOrUpdateAppPopup(app))
            .then(_ => createAppForTenant(tenantId, app))
            .then(app => {
                let apps = R.isEmpty(apps) || R.isEmpty(appId)? R.append(app, this.state.apps || []):R.insert(appIndex+1, app, this.state.apps || []);
                let appSequence = R.map(app => app.id, apps);
                let filteredApps = this.applySearch(apps);
                return updateAppSequenceForTenant(tenantId, appSequence)
                    .then(_ => this.setState({apps, filteredApps, errors: null, loading: false}))
                    .then(this.closeAddOrUpdateAppPopup);
            })
            .catch(_ => this.setState({errors: {serverError: true}, loading: false})
                .then(_ => this.updateAddOrUpdateAppPopup(app)));
    };

    addStandardAppToTenant = () => {
        let standardAppIds = this.state.selectedStandardAppsIds || [];
        let apps = R.clone(this.state.apps);
        let tenantId = this.props.tenant.id;
        this.setState({loading: true})
                .then(_ => this.updateAddStandardAppPopup())
                .then(_ => createStandardAppForTenant(tenantId, standardAppIds))
                .then(response => {
                    let standardApps = response.apps;
                    R.forEach(standardApp => apps.push(standardApp), standardApps);
                    let appSequence = R.map(app => app.id, apps);
                    let filteredApps = this.applySearch(apps);
                    return updateAppSequenceForTenant(tenantId, appSequence)
                        .then(_ => this.setState({apps, filteredApps, errors: null, loading: false}))
                        .then(this.closeAddStandardAppPopup);
                })
                .catch(_ => this.setState({errors: {serverError: true}, loading: false}))
                .then(_ => this.updateAddStandardAppPopup())
    }


    getDeleteAppPopupView(app) {
        let errors = this.state.errors || {};
        return (
            <div data-id={app.id} data-type={DATA_TYPE.NUMBER}
                 className="ui modal common-popup-wrapper delete-application-popup-modal scrolling transition visible active"
                 style="display: block !important;">
                <div className="content">
                    <div className="secondary-header">Are you sure you want to Delete this Application?</div>
                    <div className="description">
                        <table className="ui table">
                            <tbody>
                            <tr>
                                <td>Application Name</td>
                                <td>{app.name}</td>
                            </tr>
                            <tr>
                                <td>Application URL</td>
                                <td>
                                    <div className="popup-app-url-text">
                                        {app.url}
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p className={"error-text " + (errors.serverError ? "" : "hide")}>Something went wrong! Please try again.</p>
                    </div>
                    <div className="actions">
                        <div
                            className={"ui primary button buttons-primary-text buttons-primary-background " + (this.state.loading ? "loading disabled" : "")}
                            onclick={this.deleteApp}>Yes
                        </div>
                        <div className="ui cancel button buttons-secondary-text buttons-secondary-background"
                             onclick={this.closeDeleteAppPopup}>No
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    showDeleteAppConfirmation = (e) => {
        let app = this.getAppFromEvent(e) || {};
        let view = this.getDeleteAppPopupView(app);
        return ModalContainer.addModalView("delete-app-popup", view);
    };

    updateDeleteAppConfirmation = (app) => {
        let view = this.getDeleteAppPopupView(app);
        return ModalContainer.updateModalView('delete-app-popup', view);
    };

    closeDeleteAppPopup = (e) => {
        return ModalContainer.removeModal('delete-app-popup')
            .then(_ => this.setState({errors: null, loading: false}));
    };

    deleteApp = (e) => {
        let appId = getAttrValueForEventFromClosest(e, 'data-id');
        let app = this.getAppById(appId);
        return this.setState({loading: true})
            .then(_ => this.updateDeleteAppConfirmation(app))
            .then(_ => deleteAppForTenantById(this.props.tenant.id, appId))
            .then(_ => {
                let apps = R.reject(a => a.id === appId, this.state.apps || []);
                let filteredApps = this.applySearch(apps);
                return this.setState({apps, filteredApps, loading: false})
                    .then(_ => ModalContainer.removeModal("delete-app-popup"));
            })
            .catch(_ => this.setState({errors: {serverError: true}, loading: false})
                .then(_ => this.updateDeleteAppConfirmation(app)));
    };

    showAdditionalSetupPopup = (e) => {
        let app = this.getAppFromEvent(e) || {};
        let apiProvider = !app.apiEnabled ? "" : app.apiConfig.apiProvider;
        return this.setState({app, apiEnabled: app.apiEnabled, popupState: {apiProvider}, errors:null})
            .then(_ => ModalContainer.addModalView("additional-setup-popup", this.getAdditionalSetupPopupView(app)))
            .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("additional-setup-popup")))
            .then(_ => ModalContainer.getModelEl('additional-setup-popup').find('.ui.dropdown').dropdown());
    };

    getAdditionalSetupPopupView(apiConfiguredApp) {
        let popupState = this.state.popupState || {};
        let apiEnabled = this.state.apiEnabled;
        let apiConfig = (!apiConfiguredApp.apiConfig || apiConfiguredApp.apiConfig === null)? {} : apiConfiguredApp.apiConfig;
        let apiProvider = popupState.apiProvider;
        let appView = this.getDetailsFormView(!R.isEmpty(apiProvider) ?API_CONFIG[apiProvider].name:apiProvider, apiEnabled, apiConfig);
        return (
            <div data-form-id="app-additional-setup-form"
                 className="ui modal common-popup-wrapper app-additional-setup-popup scrolling transition visible active"
                 style="display: block !important;">
                <div className="content">
                    <div className="header">Additional Setup</div>

                    {/*<input type="hidden" data-key="id" data-type={DATA_TYPE.NUMBER} value={app.id || ""}/>*/}
                    {/*<input type="hidden" data-key="tenantId" data-type={DATA_TYPE.NUMBER} value={tenantId}/>*/}

                    <div className="description">
                        <table className="ui table">
                            <tbody>
                            <tr>
                                <td>Select Application</td>
                                <td>
                                    <div key={new Date().getTime()} className="ui dropdown item common-popup-dropdown">
                                        {!R.isEmpty(apiProvider) ?API_CONFIG[apiProvider].name:"Select"}
                                        <input type="hidden" data-key="apiConfig.apiProvider" value={apiProvider}/>
                                        <i className="dropdown icon"/>
                                        <div className="menu">
                                            <div
                                                className={"item " + (apiProvider === "" ? "active" : "")}
                                                data-api=""
                                                onclick={this.onApiChange}>Select</div>
                                            <div
                                                className={"item " + (apiProvider === API_CONFIG.TRU_OPS.id ? "active" : "")}
                                                data-api={API_CONFIG.TRU_OPS.id}
                                                onclick={this.onApiChange}>{API_CONFIG.TRU_OPS.name}</div>
                                            <div
                                                className={"item " + (apiProvider === API_CONFIG.BLU_SAPPHIRE.id ? "active" : "")}
                                                data-api={API_CONFIG.BLU_SAPPHIRE.id}
                                                onclick={this.onApiChange}>{API_CONFIG.BLU_SAPPHIRE.name}</div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        {this.getApiConfigErrorView()}
                        {appView}
                    </div>
                    <div className="actions">
                        <div id="addOrUpdateAppAction"
                             className={"ui primary button buttons-primary-text buttons-primary-background " + (this.state.loading ? "loading disabled" : "")}
                             onclick={this.saveAdditionalSetupDetails}>Save
                        </div>
                        <div className="ui cancel button buttons-secondary-text buttons-secondary-background"
                             onclick={this.closeAdditionalSetupPopup}>Cancel
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    isSeamlessSSOEnabledForApp = (app) => getValueForPathOrDefault(app, "attributes.seamlessSignOnConfig.enabled", false);

    showSeamlessSignOnAdditionalSetupPopup = (e) => {
        let app = this.getAppFromEvent(e) || {};
        let seamlessSignOnConfig = (app.attributes && app.attributes.seamlessSignOnConfig) || {};
        let seamlessSignOnEnabled = seamlessSignOnConfig.enabled || false;
        let autoSubmitEnabled = seamlessSignOnConfig.autoSubmitEnabled || false;
        let twoStepFlowEnabled = seamlessSignOnConfig.twoStepFlowEnabled || false;
        return this.setState({app, seamlessSignOnEnabled, autoSubmitEnabled, twoStepFlowEnabled,originalAutoSubmitEnabled: autoSubmitEnabled, seamlessSignOnConfig, errors: null})
            .then(_ => ModalContainer.addModalView("additional-setup-popup", this.getSeamlessSignOnAdditionalSetupPopupView(seamlessSignOnConfig)))
            .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("additional-setup-popup")))
    };

    getSeamlessSignOnAdditionalSetupPopupView(seamlessSignOnConfig) {
        let errors = this.state.errors || {};
        return (
            <div data-form-id="app-additional-setup-form"
                 className="ui modal common-popup-wrapper app-additional-setup-popup scrolling transition visible active"
                 style="display: block !important;">
                <div className="content">
                    <div className="header">Additional Setup</div>
            <div className="ui checkbox enable-seamless-sign-on"><input id="enable-seamless-sign-on"
                                                type="checkbox"
                                                onclick={this.toggleEnableDisableSeamlessSignOn}
                                                checked={this.state.seamlessSignOnEnabled ? "checked" : ""}/><label
                htmlFor="enable-seamless-sign-on">Enable Seamless Sign On</label></div>
                <div className={"second-description " + (this.state.seamlessSignOnEnabled? "": "hide")}>
                    <table className="ui table">
                        <tbody>
                        <tr>
                            <td>UserName Selector <span className="mandatory-start">*</span></td>
                            <td>
                                <input data-key="userNameSelector"
                                       data-required="true"
                                       data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                       className={"common-popup-input " + (errors.userNameSelector ? "validation-error" : "")}
                                       onkeyup={this.onAddOrUpdateDataChange} type="text"
                                       value={seamlessSignOnConfig.userNameSelector || ""}
                                       autoFocus={true}/>
                                <p className={"error-text " + (errors.userNameSelector ? "" : "hide")}>UserName Pattern can not be
                                    empty</p>
                            </td>

                        </tr>
                        <tr className="enable-two-step-flow">
                            <td>
                                <div className="ui checkbox"><input id="enable-two-step-flow"
                                                                    type="checkbox"
                                                                    onclick={this.toggleEnableDisableTwoStepFlow}
                                                                    checked={this.state.twoStepFlowEnabled ? "checked" : ""}/><label
                                    htmlFor="enable-two-step-flow">Enable Two Step Flow</label></div>
                            </td>
                        </tr>
                        <tr className={this.state.twoStepFlowEnabled ? "" : "hide"}>
                            <td>UserName Submit Selector <span className="mandatory-start">*</span></td>
                            <td>
                                <input data-key="userNameSubmitSelector"
                                       data-required="true"
                                       data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                       className={"common-popup-input " + (errors.userNameSubmitSelector ? "validation-error" : "")}
                                       onkeyup={this.onAddOrUpdateDataChange} type="text"
                                       value={seamlessSignOnConfig.userNameSubmitSelector || ""}/>
                                <p className={"error-text " + (errors.userNameSubmitSelector ? "" : "hide")}>UserName Submit Selector can
                                    not be
                                    empty</p>
                            </td>
                        </tr>
                        <tr>
                            <td>Password Selector <span className="mandatory-start">*</span></td>
                            <td>
                                <input data-key="passwordSelector"
                                       data-required="true"
                                       data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                       className={"common-popup-input " + (errors.passwordSelector ? "validation-error" : "")}
                                       onkeyup={this.onAddOrUpdateDataChange} type="text"
                                       value={seamlessSignOnConfig.passwordSelector || ""}/>
                                <p className={"error-text " + (errors.passwordSelector? "" : "hide")}>Password Selector can not be
                                    empty</p>
                            </td>
                        </tr>
                        <tr className="enable-auto-submit">
                            <td>
                                <div className="ui checkbox"><input id="enable-auto-submit"
                                                                    type="checkbox"
                                                                    onclick={this.toggleEnableDisableAutoSubmit}
                                                                    checked={this.state.autoSubmitEnabled ? "checked" : ""}/><label
                                    htmlFor="enable-auto-submit">Enable Auto Submit</label></div>
                            </td>
                        </tr>
                        <tr className={this.state.autoSubmitEnabled? "" : "hide"}>
                            <td>Submit Selector <span className="mandatory-start">*</span></td>
                            <td>
                                <input data-key="submitSelector"
                                       data-required="true"
                                       data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                       className={"common-popup-input " + (errors.submitSelector ? "validation-error" : "")}
                                       onkeyup={this.onAddOrUpdateDataChange} type="text"
                                       value={seamlessSignOnConfig.submitSelector || ""}/>
                                <p className={"error-text " + (errors.submitSelector ? "" : "hide")}>Submit Selector can not be
                                    empty</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    {this.getSeamlessSignOnConfigErrorView()}
                </div>
                    <div className="actions">
                        <div id="addOrUpdateAppAction"
                             className={"ui primary button buttons-primary-text buttons-primary-background " + (this.state.loading ? "loading disabled" : "")}
                             onclick={this.saveSeamlessSignOnAdditionalSetupDetails}>Save
                        </div>
                        <div className="ui cancel button buttons-secondary-text buttons-secondary-background"
                             onclick={this.closeSeamlessSignOnAdditionalSetupPopup}>Cancel
                        </div>
                    </div>
            </div>
            </div>

        );
    }

    toggleEnableDisableSeamlessSignOn = (e) => {
        let seamlessSignOnEnabled = this.state.seamlessSignOnEnabled || false;
        let seamlessSignOnConfig = this.state.seamlessSignOnConfig || {};
        let originalAutoSubmitEnabled = this.state.originalAutoSubmitEnabled || false;
        return this.setState({seamlessSignOnEnabled: !seamlessSignOnEnabled, autoSubmitEnabled: originalAutoSubmitEnabled, errors: null})
            .then(_ => ModalContainer.updateModalView('additional-setup-popup', this.getSeamlessSignOnAdditionalSetupPopupView(seamlessSignOnConfig)));
    };

    toggleEnableDisableAutoSubmit = (e) => {
        let autoSubmitEnabled = this.state.autoSubmitEnabled || false;
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let seamlessSignOnConfig = serializeForm($form);
        return this.setState({autoSubmitEnabled: !autoSubmitEnabled, errors: null})
            .then(_ => ModalContainer.updateModalView('additional-setup-popup', this.getSeamlessSignOnAdditionalSetupPopupView(seamlessSignOnConfig)));
    };

    toggleEnableDisableTwoStepFlow = (e) => {
        let twoStepFlowEnabled = this.state.twoStepFlowEnabled || false;
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let seamlessSignOnConfig = serializeForm($form);
        return this.setState({twoStepFlowEnabled: !twoStepFlowEnabled, errors: null})
            .then(_ => ModalContainer.updateModalView('additional-setup-popup', this.getSeamlessSignOnAdditionalSetupPopupView(seamlessSignOnConfig)));
    };

    getSeamlessSignOnConfigErrorView() {
        let errors = this.state.errors || {};
        return(
            <p className={"error-text " + (errors.seamlessSignOn ? "" : "hide")}>{ERRORS.COMMON}</p>
        )
    }

    saveSeamlessSignOnAdditionalSetupDetails = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let errors = validateForm($form);
        let seamlessSignOnConfig = serializeForm($form);
        let autoSubmitEnabled = this.state.autoSubmitEnabled || false;
        let seamlessSignOnEnabled = this.state.seamlessSignOnEnabled || false;
        let twoStepFlowEnabled = this.state.twoStepFlowEnabled || false;
        if(!autoSubmitEnabled) delete errors.submitSelector;
        if(!twoStepFlowEnabled) delete errors.userNameSubmitSelector;
        if(errors && !R.isEmpty(errors) && seamlessSignOnEnabled) {
            return this.setState({errors})
                .then(_ => {
                    let view = this.getSeamlessSignOnAdditionalSetupPopupView(seamlessSignOnConfig);
                    return ModalContainer.updateModalView('additional-setup-popup', view)
                        .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("additional-setup-popup")))
                });
        }
        let app = R.clone(this.state.app) || {};
        seamlessSignOnConfig.enabled = seamlessSignOnEnabled;
        seamlessSignOnConfig.autoSubmitEnabled = autoSubmitEnabled;
        seamlessSignOnConfig.twoStepFlowEnabled = twoStepFlowEnabled;
        let attributes = app.attributes || {};
        attributes.seamlessSignOnConfig = seamlessSignOnConfig;
        app.attributes = attributes;
        return this.setState({errors: null, loading: true})
            .then(_ => ModalContainer.updateModalView("additional-setup-popup", this.getSeamlessSignOnAdditionalSetupPopupView(seamlessSignOnConfig)))
            .then(_ => this.updateApp(app.tenantId, app))
            .then(_ => this.setState({loading: false}))
            .then(_ => ModalContainer.removeModal("additional-setup-popup"))
            .catch(_ => this.setState({errors: {seamlessSignOn : true}, loading: false})
                .then(_ => ModalContainer.updateModalView("additional-setup-popup", this.getSeamlessSignOnAdditionalSetupPopupView(seamlessSignOnConfig)))
                .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("additional-setup-popup")))
            );
    };

    closeSeamlessSignOnAdditionalSetupPopup = (e) => {
        return ModalContainer.removeModal("additional-setup-popup")
            .then(_ => this.setState({errors: null}));
    };

    getDetailsFormView(apiProvider, apiEnabled, apiConfig) {
        let errors = this.state.errors || {};
        if(R.isEmpty(apiProvider)) return "";
        if (apiProvider === API_CONFIG.TRU_OPS.name) {
            return (
                <div className="second-description">
                    <table className="ui table">
                        <tr>
                            <td>Master IP <span className="mandatory-start">*</span></td>
                            <td>
                                <input data-key="apiConfig.masterIP"
                                       data-required="true"
                                       data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                       className={"common-popup-input " + (errors.apiConfig && errors.apiConfig.masterIP ? "validation-error" : "")}
                                       onkeyup={this.onAddOrUpdateDataChange} type="text"
                                       value={apiConfig.masterIP || ""}
                                       autofocus={true}/>
                                <p className={"error-text " + (errors.apiConfig && errors.apiConfig.masterIP ? "" : "hide")}>Master IP can not be
                                    empty</p>
                            </td>

                        </tr>
                        <tr>
                            <td>Tenant Id <span className="mandatory-start">*</span></td>
                            <td>
                                <input data-key="apiConfig.tenantId"
                                       data-required="true"
                                       data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                       className={"common-popup-input " + (errors.apiConfig && errors.apiConfig.tenantId ? "validation-error" : "")}
                                       onkeyup={this.onAddOrUpdateDataChange} type="text"
                                       value={apiConfig.tenantId || ""}/>
                                <p className={"error-text " + (errors.apiConfig && errors.apiConfig.tenantId ? "" : "hide")}>Tenant Id can not be
                                    empty</p>
                            </td>
                        </tr>
                        <tr>
                            <td>User Name <span className="mandatory-start">*</span></td>
                            <td>
                                <input data-key="apiConfig.userName"
                                       data-required="true"
                                       data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                       className={"common-popup-input " + (errors.apiConfig && errors.apiConfig.userName ? "validation-error" : "")}
                                       onkeyup={this.onAddOrUpdateDataChange} type="text"
                                       value={apiConfig.userName || ""}/>
                                <p className={"error-text " + (errors.apiConfig && errors.apiConfig.userName ? "" : "hide")}>User Name can not be
                                    empty</p>
                            </td>
                        </tr>
                        <tr>
                            <td>Password <span className="mandatory-start">*</span></td>
                            <td>
                                <input data-key="apiConfig.password"
                                       data-required="true"
                                       data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                       className={"common-popup-input " + (errors.apiConfig && errors.apiConfig.password ? "validation-error" : "")}
                                       onkeyup={this.onAddOrUpdateDataChange} type="text"
                                       value={apiConfig.password || ""}/>
                                <p className={"error-text " + (errors.apiConfig && errors.apiConfig.password ? "" : "hide")}>Password can not be
                                    empty</p>
                            </td>
                        </tr>
                    </table>
                </div>
            );
        }
        if (apiProvider === API_CONFIG.BLU_SAPPHIRE.name) {
            return (
                <div className="second-description">
                    <table className="ui table">
                        <tr>
                            <td>Master IP <span className="mandatory-start">*</span></td>
                            <td>
                                <input data-key="apiConfig.masterIP"
                                       data-required="true"
                                       data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                       className={"common-popup-input " + (errors.apiConfig && errors.apiConfig.masterIP ? "validation-error" : "")}
                                       onkeyup={this.onAddOrUpdateDataChange} type="text"
                                       value={apiConfig.masterIP || ""}
                                       autofocus={true}/>
                                <p className={"error-text " + (errors.apiConfig && errors.apiConfig.masterIP ? "" : "hide")}>Master Ip can not be
                                    empty</p>
                            </td>
                        </tr>
                        <tr>
                            <td>API Token <span className="mandatory-start">*</span></td>
                            <td>
                                <input data-key="apiConfig.apiToken"
                                       data-required="true"
                                       data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                       className={"common-popup-input " + (errors.apiConfig && errors.apiConfig.apiToken ? "validation-error" : "")}
                                       onkeyup={this.onAddOrUpdateDataChange} type="text"
                                       value={apiConfig.apiToken || ""}/>
                                <p className={"error-text " + (errors.apiConfig && errors.apiConfig.apiToken ? "" : "hide")}>apiToken can not be
                                    empty</p>
                            </td>
                        </tr>
                    </table>
                </div>
            );
        }
    }

    getApiConfigErrorView(){
        let errors = this.state.errors || {};
        return(
            <p className={"error-text " + (errors.apiConfigCredentials ? "" : "hide")}>Invalid Credentials</p>
        )
    }
    saveAdditionalSetupDetails = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let errors = validateForm($form);
        let apiConfiguredApp = serializeForm($form);
        let app = R.clone(this.state.app);
        let apiEnabled = this.state.apiEnabled;
        let apiProvider = apiConfiguredApp.apiConfig.apiProvider;
        app.apiConfig = R.isEmpty(apiProvider) ? app.apiConfig : apiConfiguredApp.apiConfig;
        app.apiEnabled = apiEnabled;
        if(errors && !R.isEmpty(errors)) {
            return this.setState({errors})
                .then(_ => {
                    let view = this.getSeamlessSignOnAdditionalSetupPopupView(apiConfiguredApp);
                    return ModalContainer.updateModalView('additional-setup-popup', view)
                        .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("additional-setup-popup")))
                        .then(_ => ModalContainer.getModelEl('additional-setup-popup').find('.ui.dropdown').dropdown());
                });
        }
        return this.setState({loading: true})
            .then(_ => ModalContainer.updateModalView("additional-setup-popup", this.getSeamlessSignOnAdditionalSetupPopupView(apiConfiguredApp)))
            .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("additional-setup-popup")))
            .then(_ => ModalContainer.getModelEl('additional-setup-popup').find('.ui.dropdown').dropdown())
            .then(_ => this.updateAppConfig(app.tenantId, app))
            .then(_ => this.setState({loading: false}))
            .then(_ => ModalContainer.removeModal("additional-setup-popup"))
            .catch(_ => this.setState({errors: {apiConfigCredentials : true}, loading: false})
                .then(_ => ModalContainer.updateModalView("additional-setup-popup", this.getSeamlessSignOnAdditionalSetupPopupView(apiConfiguredApp)))
                .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("additional-setup-popup")))
                .then(_ => ModalContainer.getModelEl('additional-setup-popup').find('.ui.dropdown').dropdown()));
    };

    updateAppConfig(tenantId, app){
        return updateAppConfigForApp(tenantId, app)
            .then(_ => {
                let index = R.findIndex(a => a.id === app.id, this.state.apps || []);
                let apps = R.update(index, app, this.state.apps || []);
                let filteredApps = this.applySearch(apps);
                return this.setState({apps, filteredApps});
            });
    }

    closeAdditionalSetupPopup = (e) => {
        return ModalContainer.removeModal("additional-setup-popup")
            .then(_ => this.setState({errors: null}));
    };

    onApiChange = (e) => {
        let apiProvider = getAttrValueForEventFromClosest(e, 'data-api');
        let app = R.clone(this.state.app);
        let apiEnabled = !R.isEmpty(apiProvider);
        let apiConfiguredApp = (app.apiConfig === null)? {} : (app.apiConfig.apiProvider === apiProvider? app : {});
        let popupState = this.state.popupState || {};
        if (popupState.apiProvider !== apiProvider) {
            setTimeout(() => {
                this.setState({errors: null, popupState: {apiProvider},apiEnabled: apiEnabled})
                    .then(_ => ModalContainer.updateModalView('additional-setup-popup', this.getSeamlessSignOnAdditionalSetupPopupView(apiConfiguredApp)))
                    .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("additional-setup-popup")))
                    .then(_ => ModalContainer.getModelEl('additional-setup-popup').find('.ui.dropdown').dropdown());
            }, 100);
        }

    };

    onSearch = (e) => {
        let searchString = e.target.value.trim();
        if (this.state.searchString !== searchString) {
            this.state.searchString = searchString;

            let filteredApps = this.applySearch(this.state.apps);
            return this.setState({filteredApps});
        }
    };

    orderByAsc = (obj, property) => {
        return R.sortWith([R.ascend(R.prop(property))], obj);
    };

    applyAutoFocusForContainer = (container) => $(container).find('[autofocus]').focus();

    applyAutoFocus = () => this.applyAutoFocusForContainer(this.$container);
}