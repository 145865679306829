/**
 * Created by Sabbu on 01/09/16.
 */

import $ from 'jquery'
import * as UserProfileDAO from '../dao/user-profile-dao'

const R = require('ramda');

const HTTP_METHOD = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
    OPTIONS: 'OPTIONS'
};

let makeCall = (url, method, data, headers, cache= false, responseAsJSON = true) => {
    let pr = new Promise(function(resolve, reject) {
            let payload = {
                contentType: 'application/json', // payload type being sent
                // dataType: 'json', // payload being expected as response
                method,
                headers: headers || {},
                cache
            };
            if(responseAsJSON) payload.dataType = "json";
            if(data && !R.isEmpty(data)) payload.data = JSON.stringify(data);
            $.ajax(url, payload).done(resolve).fail(reject);
        })
        .then(x => x)
        .catch(resp => checkForSessionTimeout(resp));
    return pr;
};

export let postData = (url, data, headers) => makeCall(url, HTTP_METHOD.POST, data, headers);

export let postDataWithNoResponse = (url, data, headers) => makeCall(url, HTTP_METHOD.POST, data, headers, false, false);

export let putData = (url, data, headers) => makeCall(url, HTTP_METHOD.PUT, data, headers);

export let getData = (url, data, headers, cache= true) => makeCall(url, HTTP_METHOD.GET, data, headers, cache);

export let getResponseAsString = (url, data, headers, cache= true) => makeCall(url, HTTP_METHOD.GET, data, headers, cache, false);

export let deleteData = (url, data, headers, cache= true) => makeCall(url, HTTP_METHOD.DELETE, data, headers, cache);

export let postMultipart = (url, data, files, headers) => {
    let parts = new FormData();
    R.forEach(key => {
        if(data[key]) {
            parts.append(key, JSON.stringify(data[key]));
        }
    }, R.keys(data || {}));

    R.forEach(fid => {
        files[fid] && parts.append(fid, files[fid]);
    }, R.keys(files || {}));

    headers = headers || {};

    return new Promise((resolve, reject) => {
        $.ajax({
            enctype: 'multipart/form-data',
            headers,
            url,
            data: parts,
            cache: false,
            contentType: false,
            processData: false,
            method: 'POST',
            type: 'POST', // For jQuery < 1.9
        })
            .done(resolve)
            .fail(reject);
    }).then(x => x).catch(resp => checkForSessionTimeout(resp));
};

export let postFormUrlEncoded = (url, data, headers, responseAsJSON = false) => {
    return new Promise((resolve, reject) => {
        $.ajax({
            headers,
            url,
            data,
            method: "POST",
            type: "POST",
            dataType: responseAsJSON ? "json" : "text"
        })
            .done(resolve)
            .fail(reject);
    }).then(x => x).catch(resp => checkForSessionTimeout(resp));
}

export let validateIfProtocolExistForUrl = (url) =>{
    let regToCheckProtocolExist = /^(?:(ht|f)tp(s?):\/\/)?/;
    return url.match(regToCheckProtocolExist);
};

export let removeSecuredAccessForImage = (url) =>{
    if(url){
        url =  url.replace(/^https:\/\//i, 'http://');
    }
    return url;
};

let checkForSessionTimeout = (response) => {
    if(response.status === 401 && response.responseJSON.code === "INVALID_SESSION") {
        return UserProfileDAO.logout();
    }
    throw response;
};
