import $ from 'jquery';
import Bluebird from 'bluebird';
import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import React from 'react'
import {getMyAppCredentials, getMyApps} from '../../service/user-service'
import {
    convertToMailToUrl,
    convertToUrl,
    DATA_TYPE,
    getAttrValueForEventFromClosest,
    getClosestForEventWithAttribute,
    getValueForPathOrDefault,
    serializeForm, setValueForPathOrDefault,
    validateForm,
    VALIDATION_TYPE
} from '../../Backflipt_UI_Components/utils/generic-utils'
import {
    getMyTenantDetails,
    getMyTenantLogoUrl,
    getProfile, getUserPreferences,
} from '../../dao/user-profile-dao'
import {getAdminMail, getSupportMail} from "../../utils/domain-utils";
import ModalContainer from "../modal-container/modal-container-v2";
import {updateProfile} from "../../service/user-profile-service";
import {
    getAllPagesForTenant,
    updateLastLoginTimeForAppUser,
    updateUserCredentialsForApp
} from "../../service/tenant-service";
import HomePreview from "../homepage-customization/home-preview";

const R = require('ramda');

const CONTENT_TAB_TYPE = {
    APP_TAB: "app-tab",
    PAGE_TAB: "page-tab"
};

export default class UserApps extends UIBaseComponent {

    beforeRender() {
        this.logInScreenCheckCount = 3;
        this.logInCount = {};
        this.retryCount = {};
        this.listenerAdded = {};
        this.loadDataAsync();
        return super.beforeRender();
    }

    loadDataAsync() {
        let profile = getProfile();
        let jobs = [getMyApps(), getMyAppCredentials(), getAllPagesForTenant(profile.tenantId)];
        return Bluebird.all(jobs)
            .then(result => {
                let apps = result[0];
                R.forEach(app => app.tabType = CONTENT_TAB_TYPE.APP_TAB, apps);
                let appCredentials = result[1];
                const enabledApps = R.filter(app => app.enabled, R.clone(apps));
                let pages = result[2] || [];
                R.forEach(page => page.tabType = CONTENT_TAB_TYPE.PAGE_TAB, pages);
                const publishedPages = R.filter(page => !R.isEmpty(page.publishedConfig), R.clone(pages));
                let contentTabs = R.append(R.clone(enabledApps), R.clone(publishedPages));
                contentTabs = R.flatten(contentTabs);
                R.forEach(tab => tab.tabId = tab.id + tab.tabType, contentTabs);
                let preferences = getUserPreferences();
                let selectedTabId = preferences.selectedTabId || this.state.selectedTabId;
                let selectedTab = R.find(tab => tab.tabId === selectedTabId, contentTabs || []) || contentTabs[0] || {};
                let openedTabs = R.uniq(R.append(selectedTab.tabId, this.state.openedTabs || []));
                return this.setState({
                    contentTabs,
                    apps,
                    publishedPages,
                    selectedTabId: selectedTab.tabId,
                    openedTabs,
                    dataLoaded: true,
                    appCredentials
                });
            });
    }

    getTabIdFromEvent = (e) => getAttrValueForEventFromClosest(e, 'data-tab-id');

    getTabFromEvent = (e) => this.getTabForTabId(this.getTabIdFromEvent(e));

    getTabForTabId = (tabId) => tabId && R.find(tab => tab.tabId === tabId, this.state.contentTabs || []);

    getAppIdFromEvent = (e) => getAttrValueForEventFromClosest(e, 'data-app-id');

    getAppFromEvent = (e) => this.getAppForAppId(this.getAppIdFromEvent(e));

    getAppForAppId = (appId) => appId && R.find(app => app.id === appId, this.state.apps || []);

    selectTab = (e) => {
        let selectedTabId = this.getTabIdFromEvent(e);
        if (this.state.selectedTabId !== selectedTabId) {
            let openedTabs = R.uniq(R.append(selectedTabId, this.state.openedTabs || []));
            let userProfile = getProfile();
            setValueForPathOrDefault(userProfile, "attributes.preferences.selectedTabId", selectedTabId);
            return this.setState({selectedTabId, openedTabs})
                .then(_ => updateProfile(userProfile));
        }
    };

    getTabsView() {
        //let apps = this.state.apps || [];
        let contentTabs = this.state.contentTabs || [];
        let activeTab = this.getTabForTabId(this.state.selectedTabId) || contentTabs[0] || {};
        let tabsView = R.map(tab => {
            if(tab.tabType === CONTENT_TAB_TYPE.APP_TAB) {
                return (
                    <span data-tab-id={tab.tabId}
                          className={"common-tab safe-tab " + (activeTab.tabId === tab.tabId ? "app-tab-primary-text app-tab-primary-background active" : "app-tab-secondary-text app-tab-secondary-background")}
                          onclick={this.selectTab}>{tab.name}</span>
                );
            }
            else
                return (
                    <span data-tab-id={tab.tabId}
                          className={"common-tab safe-tab " + (activeTab.tabId === tab.tabId ? "app-tab-primary-text app-tab-primary-background active" : "app-tab-secondary-text app-tab-secondary-background")}
                          onclick={this.selectTab}>{tab.name || "Home"}</span>
                );

        }, contentTabs);
        return (
            <div className="tabs-wrapper">
                {tabsView}
            </div>
        );
    }

    getLogoView() {
        let url = getMyTenantLogoUrl() || "assets/images/comp-logo-1.png";
        return (
            <div className="comp-logo-wrapper hide">
                <img src={url} alt=""/>
            </div>
        );
    }

    getCurrentTabContentView() {
        let contentTabs = this.state.contentTabs || [];
        let activeTab = this.getTabForTabId(this.state.selectedTabId) || contentTabs[0] || {};
        let updateCredentialsTime = this.state.updateCredentialsTime || {};
        let tabsView = R.map(tab => {
            let key = updateCredentialsTime[tab.tabId] || tab.tabId;
            console.log("key -> " + key + " app " + tab.name);
            let view = null;
            if(tab.tabType === CONTENT_TAB_TYPE.APP_TAB) {
                view = R.includes(tab.tabId, this.state.openedTabs || []) ?
                    <webview webpreferences="nativeWindowOpen=yes" allowpopups="true" key={key} data-tab-id={tab.tabId}
                             src={convertToUrl(tab.url)}
                             className={"common-tabs-content-wrapper safe-tab-wrapper " + (activeTab.tabId === tab.tabId ? "app-background" : "hide")}
                             nodeintegration="true" nodeintegrationinsubframes="true">
                        <div className="app-loading">Loading...</div>
                    </webview> : null;
            }
            else view = <div id={tab.tabId} className="layout-wrapper"/>;
            return (
                <div key={tab.tabId} data-tab-content={true} data-tab-id={tab.tabId}
                     className={"common-tabs-content-wrapper safe-tab-wrapper " + (activeTab.tabId === tab.tabId ? "home-page" : "hide")}>
                    {view}
                </div>
            );
        }, contentTabs);
        return tabsView;
    }

    postRenderView() {
        return super.postRenderView()
            .then(this.renderPages)
            .then(this.feedCredentials)
            .then(this.showPopup)
            .then(this.setAppHeights)
            .then(_ => window.addEventListener("resize", this.setAppHeights));
    }

    renderPages = () => {
        let pages = this.state.publishedPages || [];
        R.map(page => {
            let config = getValueForPathOrDefault(page, "publishedConfig", {});
            let id = page.id;
            let container = this.$container.find("#" + id + CONTENT_TAB_TYPE.PAGE_TAB)[0];
            const props = {
                editTemplate: false,
                selectedLayoutId: config.layoutId,
                config
            };

            let instance = new HomePreview(container, props);
            return instance.render();
        }, pages);
    };

    feedCredentials = () => {

        let invalidCredentials = this.state.invalidCredentials || {};

        if (invalidCredentials[this.state.selectedTabId]) return;

        let webView = this.$container.find(`div[data-tab-id="${this.state.selectedTabId}"]`).find('webview');

        // if(this.state.updateCredentials) {
        //     this.setRetryCountForApp(this.state.selectedAppId);
        //     //return this.appDOMReadyHandler({target: webView[0]});
        //     //return webView[0].reloadIgnoringCache();
        // }

        if (this.listenerAdded[this.state.selectedTabId]) {
            //this.setRetryCountForApp(this.state.selectedAppId);
            //return this.appDOMReadyHandler({target: webView[0]});
        } else {
            webView.each((index, webViewEl) => {
                // webViewEl.removeEventListener('dom-ready', this.appDOMReadyHandler);
                webViewEl.addEventListener('dom-ready', this.appDOMReadyHandler);
                this.listenerAdded[this.state.selectedTabId] = true;
            });
        }
    };

    appDOMReadyHandler = (e) => {

        console.log("inTwoStepFlow ->" + this.state.inTwoStepFlow);

        if(this.state.inTwoStepFlow) return;

        if (this.state.popup) return;

        let webViewEl = e.target;

        window.webViewEl = webViewEl;

        let src = webViewEl.src;

        let selectedAppId = webViewEl.getAttribute('data-tab-id');

        let app = this.getTabForTabId(selectedAppId);

        console.log("appId -> " + app.id);

        // let credentials = {
        //     352 : {
        //         userName: "anil",
        //         password: "icstruops"
        //     },
        //     353: {
        //         userName: "anilkapatkar",
        //         password: "gN2C(9Nt"
        //     }
        // };

        // let appConfig = {
        //     352 : {
        //         userNameSelector: "#username",
        //         passwordSelector: "#password",
        //         submitSelector: "document.getElementsByName('submit')[0]"
        //     },
        //     353: {
        //         userNameSelector: "#uname",
        //         passwordSelector: "#loginform > div > div > div > form > div:nth-child(2) > div > input",
        //         submitSelector: `document.querySelector("#loginform > div > div > div > form > div.form-group.text-center.m-t-40 > div > button")`
        //     }
        // };

        let selectedAppConfig = getValueForPathOrDefault(app, "attributes.seamlessSignOnConfig", {});
        let selectedCredentials = R.find(cred => cred.appId === app.id, this.state.appCredentials || []) || {};

        let userNameSelector = selectedAppConfig.userNameSelector;
        let userNameSubmitSelector = selectedAppConfig.userNameSubmitSelector || "";
        let passwordSelector = selectedAppConfig.passwordSelector;
        let submitSelector = selectedAppConfig.submitSelector;
        let autoSubmitEnabled = selectedAppConfig.autoSubmitEnabled;
        let twoStepFlowEnabled = selectedAppConfig.twoStepFlowEnabled;


        if (!selectedAppConfig.enabled || !userNameSelector || !passwordSelector) return Promise.resolve(false);

        console.log("checking for login for app -> " + src);

        let retryCount = this.retryCount[selectedAppId] || 0;

        console.log("retry count-> " + retryCount);

        if (retryCount > 0) return;

        return this.isInLoginScreen(webViewEl, userNameSelector, passwordSelector, twoStepFlowEnabled)
            .then(loginScreen => {

                console.log("in loginScreen ? " + loginScreen);

                if (loginScreen) {

                    //if (this.logInCount[selectedAppId] >= 1) return;

                    let retryCount = this.retryCount[selectedAppId] || 0;

                    console.log("retry count-> " + retryCount);

                    if (retryCount < 1) {

                        let userName = selectedCredentials.userName;
                        let password = selectedCredentials.password;

                        if (!userName || !password) { // user credentials not configured

                            // TODO listening for username & password changes

                            // let listenerJS = `document.getElementsByName('submit')[0].addEventListener('click', function() {
                            //                 window.userName = document.querySelector('#username').value;
                            //                 window.password = document.querySelector('#password').value;
                            //             });`;
                            //
                            // console.log("listenerJS-> " + listenerJS);
                            //
                            // window.webViewEl = webViewEl;
                            //
                            // webViewEl.executeJavaScript(listenerJS, true);
                            //
                            // webViewEl.addEventListener("did-navigate", (e) => {
                            //     debugger;
                            //     console.log("new url navigation-> " + e.url);
                            //     return webViewEl.executeJavaScript('return {"userName": window.userName, "password": window.password};', true)
                            //         .then(val => console.log("value-> " + val));
                            // });

                            // TODO record username & password and save

                        } else {

                            return this.autoLogin(webViewEl, userName, userNameSelector, userNameSubmitSelector, password, passwordSelector, submitSelector, autoSubmitEnabled, twoStepFlowEnabled)
                                .then(loginSubmitted => {

                                    this.incrementRetryCountForApp(selectedAppId);

                                    console.log("autoSubmitEnabled-> " + autoSubmitEnabled);

                                    if (!autoSubmitEnabled) return;

                                    if(!loginSubmitted) return;

                                    console.log("loginSubmitted ? " + loginSubmitted);

                                    return this.checkForLoginScreen(webViewEl, userNameSelector, passwordSelector, twoStepFlowEnabled, selectedAppId, app)

                                    // this.isInLoginScreen(webViewEl, userNameSelector, passwordSelector, twoStepFlowEnabled)
                                    //     //.then(loginScreen => loginScreen ? this.appDOMReadyHandler({target: webViewEl}): this.setLoggedInCountForApp(selectedAppId));
                                    //     .then(loginScreen => {
                                    //         if (loginScreen) {
                                    //             let invalidCredentials = R.clone(this.state.invalidCredentials) || {};
                                    //             invalidCredentials[selectedAppId] = true;
                                    //             return this.setState({invalidCredentials});
                                    //         }
                                    //         else {
                                    //             //this.setLoggedInCountForApp(selectedAppId);
                                    //             return this.updateLastLoginTimeForApp(selectedAppId);
                                    //         }
                                    //     })

                                });
                        }
                    } else {
                        if (this.state.popup) return;
                        console.log("exceeded retry count for " + src);
                    }
                } else {
                    this.setLoggedInCountForApp(selectedAppId);
                    return this.updateLastLoginTimeForApp(app.id)
                }
            });
    };

    async checkForLoginScreen(webViewEl, userNameSelector, passwordSelector, twoStepFlowEnabled, selectedAppId, app) {
        for(let i = 0; i < this.logInScreenCheckCount; i++) {
            let loginScreen = await this.isInLoginScreen(webViewEl, userNameSelector, passwordSelector, twoStepFlowEnabled);
            if(loginScreen) {
                if(i >= this.logInScreenCheckCount - 1) {
                    let invalidCredentials = R.clone(this.state.invalidCredentials) || {};
                    invalidCredentials[selectedAppId] = true;
                    return this.setState({invalidCredentials});}
            }
            else {
                i = this.logInScreenCheckCount;
                return this.updateLastLoginTimeForApp(app.id);
            }
        }
    }

    async checkForPasswordScreen(webViewEl, passwordSelector) {
        for(let i = 0; i < this.logInScreenCheckCount; i++) {
            let passwordScreen = await this.isInPasswordScreen(webViewEl, passwordSelector);
            if (!passwordScreen) {
                if (i >= this.logInScreenCheckCount - 1) {
                    let invalidCredentials = R.clone(this.state.invalidCredentials) || {};
                    invalidCredentials[this.state.selectedTabId] = true;
                    return this.setState({invalidCredentials, inTwoStepFlow: false});
                }
            } else return true;
        }
    }

    incrementRetryCountForApp = (appId) => this.setRetryCountForApp(appId, (this.retryCount[appId] || 0) + 1);

    setRetryCountForApp = (appId, count = 0) => this.retryCount[appId] = count;

    setLoggedInCountForApp = (appId) => {
        if (!this.logInCount[appId]) this.logInCount[appId] = 1;
        else this.logInCount[appId]++;
    };

    updateLastLoginTimeForApp = (appId) => {
        let tenant = getMyTenantDetails();
        let profile = getProfile();
        return updateLastLoginTimeForAppUser(tenant.id, appId, profile.tenantUserId)
            .then(_ => true)
    };

    isInLoginScreen = (wvel, userNameSelector, passwordSelector, twoStepFlowEnabled = false) => {
        return new Promise(resolve => {
            setTimeout(() => {
                // debugger;
                if (twoStepFlowEnabled) {
                    return wvel.executeJavaScript(`document.querySelector("${userNameSelector}")`, true)
                        .then(element => {
                            if (element) return true;
                            return wvel.executeJavaScript(`document.querySelector("${passwordSelector}")`, true)
                                .then(element => element ? true : Promise.reject("Not In Login Screen"))
                                .then(resolve)
                        })
                        .then(resolve)
                        .catch(err => {
                            console.log(err);
                            return resolve(false)
                        });
                } else {
                    return wvel.executeJavaScript(`document.querySelector("${userNameSelector}")`, true)
                        .then(element => element ? true : Promise.reject("No UserName Field"))
                        .then(_ => wvel.executeJavaScript(`document.querySelector("${passwordSelector}")`, true))
                        .then(element => element ? true : Promise.reject("No Password Field"))
                        .then(resolve)
                        .catch(err => {
                            console.log(err);
                            return resolve(false)
                        });
                }
            }, 1000);
        });
    };

    isInPasswordScreen = (wvel, passwordSelector) => {
        return new Promise(resolve => {
            setTimeout(() => {
                // debugger;
                return wvel.executeJavaScript(`document.querySelector("${passwordSelector}")`, true)
                    .then(element => element && !($(element).is(":hidden"))? true : Promise.reject("No Password Field"))
                    .then(resolve)
                    .catch(err => {
                        console.log(err);
                        return resolve(false)
                    })
                    ;
            }, 1000);
        });
    };

    clearField = (wvel) => {
        wvel.selectAll();
        wvel.delete();
    };

    autoLogin = (wvel, userName, userNameSelector, userNameSubmitSelector, password, passwordSelector, submitSelector, autoSubmitEnabled = false, twoStepFlowEnabled = false) => {

        if(twoStepFlowEnabled) {
            return new Promise(resolve => {
                setTimeout(() => {
                    // debugger;
                    return wvel.executeJavaScript(`document.querySelector("${userNameSelector}")`, true)
                        .then(element => {
                            if (!element) return Promise.reject("No UserName Field");
                            return wvel.executeJavaScript(`document.querySelector("${userNameSelector}").focus();`, true)
                        })
                        .then(_ => this.clearField(wvel))
                        .then(_ => {
                            // debugger;
                            return wvel.insertText(userName)
                        })
                        .then(_ => this.setState({inTwoStepFlow: true}))
                        .then(_ => wvel.executeJavaScript(`document.querySelector("${userNameSubmitSelector}").click();`, true))
                        .then(_ => this.checkForPasswordScreen(wvel, passwordSelector))
                        .then(_ => {
                            return new Promise(resolve => {
                                    // debugger;
                                    return wvel.executeJavaScript(`document.querySelector("${passwordSelector}").focus();`, true)
                                        .then(_ => this.clearField(wvel))
                                        .then(_ => {
                                            // debugger;
                                            return wvel.insertText(password)
                                        })
                                        .then(_ => {
                                            if (autoSubmitEnabled) {
                                                return this.setState({inTwoStepFlow: false})
                                                    .then(_ => wvel.executeJavaScript(`document.querySelector("${submitSelector}").click();`, true));
                                                // .then(element => {
                                                //     if(!element) return Promise.reject("No Submit Script");
                                                //     return wvel.executeJavaScript(`${submitSelector}.click();`, true)
                                                // })
                                            } else return true;
                                        })
                                        .then(_ => resolve(true))
                            });
                        })
                        .then(_ => resolve(true))
                        .catch(err => {
                            console.log("error came");
                            console.log(err);
                            return resolve(false)
                        })
                        ;
                }, 1000);
            });
        }
        return new Promise(resolve => {
            setTimeout(() => {
                // debugger;
                return wvel.executeJavaScript(`document.querySelector("${userNameSelector}")`, true)
                    .then(element => {
                        if (!element) return Promise.reject("No UserName Field");
                        return wvel.executeJavaScript(`document.querySelector("${userNameSelector}").focus();`, true)
                    })
                    .then(_ => this.clearField(wvel))
                    .then(_ => {
                        // debugger;
                        return wvel.insertText(userName)
                    })
                    .then(_ => {
                        // debugger;
                        return wvel.executeJavaScript(`document.querySelector("${passwordSelector}").focus();`, true)
                    })
                    .then(_ => this.clearField(wvel))
                    .then(_ => {
                        // debugger;
                        return wvel.insertText(password)
                    })
                    .then(_ => {
                        if (autoSubmitEnabled) {
                            return wvel.executeJavaScript(`document.querySelector("${submitSelector}").click();`, true)
                                .then(_ => this.setState({inTwoStepFlow: false}));
                            // .then(element => {
                            //     if(!element) return Promise.reject("No Submit Script");
                            //     return wvel.executeJavaScripst(`${submitSelector}.click();`, true)
                            // })
                        } else return true;
                    })
                    .then(_ => resolve(true))
                    .catch(err => {
                        console.log("error came");
                        console.log(err);
                        return resolve(false)
                    })
                    ;
            }, 1000);
        });
    };

    twoStepFlowAutoLogin = (wvel, userName, userNameSelector, userNameSubmitSelector, password, passwordSelector, submitSelector, autoSubmitEnabled = false) => {

    };

    showPopup = () => {
        let invalidCredentials = this.state.invalidCredentials || {};
        if (!this.state.popup && invalidCredentials[this.state.selectedTabId])
            return this.showUpdateAppUserCredentialsPopup()
    };

    showUpdateAppUserCredentialsPopup = () => {
        let selectedAppId = this.state.selectedTabId;
        let app = this.getTabForTabId(selectedAppId);
        let selectedCredentials = R.find(cred => cred.appId === app.id, this.state.appCredentials || []) || {};
        let view = this.getUpdateAppUserCredentialsPopupView(app.id, selectedCredentials.userName, selectedCredentials.password);
        return this.setState({popup: true, updateCredentials: null})
            .then(_ => ModalContainer.addModalView("update-app-user-credentials-popup", view))
            .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("update-app-user-credentials-popup")));
    };

    getUpdateAppUserCredentialsPopupView(appId, userName, password) {
        let errors = this.state.formErrors || {};
        let supportMail = getSupportMail();
        let mailLink = convertToMailToUrl(supportMail);
        let showPassword = this.state.showPassword || false;
        return (
            <div data-form-id="update-app-user-credentials-form"
                 className="ui modal common-popup-wrapper add-user-popup edit-user-credential scrolling transition visible active"
                 style="display: block !important;">
                <div data-id={appId} data-type={DATA_TYPE.NUMBER}/>

                <div className="content">
                    <div className="header">Edit Credentials</div>

                    <input type="hidden" data-key="appId" data-type={DATA_TYPE.NUMBER} value={appId}/>

                    <div className="description">
                        <div className="check-user-cred">Please check your username and password. If you still can't log
                            in, contact your administrator at <a href={mailLink}>{supportMail}</a></div>
                        <table className="ui table">
                            <tbody>
                            <tr>
                                <td>User Name <span className="mandatory-start">*</span></td>
                                <td>
                                    <input data-key="userName"
                                           data-required="true"
                                           data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                           className={"common-popup-input " + (errors.userName ? "validation-error" : "")}
                                           onkeyup={this.onAddOrUpdateDataChange} oninput={this.onValueChange}
                                           type="text"
                                           value={userName || ""} autofocus={true}/>
                                    <p className={"error-text " + (errors.userName ? "" : "hide")}>UserName cannot be
                                        empty</p>
                                </td>
                            </tr>
                            <tr>
                                <td>Password <span className="mandatory-start">*</span></td>
                                <td className="show-password-td">
                                    <input data-key="password"
                                           data-required="true"
                                           data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                           className={"common-popup-input " + (errors.password ? "validation-error" : "")}
                                           onkeyup={this.onAddOrUpdateDataChange} oninput={this.onValueChange}
                                           type={showPassword ? "text" : "password"} value={password || ""}/>
                                    <p className={"error-text " + (errors.password ? "" : "hide")}>Password cannot be
                                        empty</p>
                                    <i className={"eye slash icon toggle-icon " + (showPassword ? "hide" : "")}
                                       onclick={this.showOrHidePassword}/>
                                    <i className={"eye icon toggle-icon " + (showPassword ? "" : "hide")}
                                       onclick={this.showOrHidePassword}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p className={"error-text add-user-default-error " + (errors.serverError ? "" : "hide")}>{(errors.serverError && errors.serverError.description) || "Something went wrong! Please try again."}</p>
                    </div>
                    <div className="actions">
                        <div id="addOrUpdateAction"
                             className={"ui primary button buttons-primary-text buttons-primary-background " + (this.state.loading ? "loading disabled" : "")}
                             onclick={this.editCredentials}>Update
                        </div>
                        <div className="ui cancel button buttons-secondary-text buttons-secondary-background"
                             onclick={this.closeUpdateAppUserCredentialsPopup}>Cancel
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    showOrHidePassword = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let data = serializeForm($form);
        let appId = data.appId;
        let showPassword = !(this.state.showPassword || false);
        return this.setState({showPassword})
            .then(_ => this.updateAddAppUserCredentialsPopup(appId, data.userName, data.password))
    };


    editCredentials = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let data = serializeForm($form);
        let errors = validateForm($form);
        let appId = data.appId;
        let tenant = getMyTenantDetails();
        let profile = getProfile();
        let credentials = {userName: data.userName, password: data.password};
        if (errors && !R.isEmpty(errors)) {
            return this.setState({formErrors: errors})
                .then(_ => this.updateAddAppUserCredentialsPopup(appId, data.userName, data.password))
        }
        return this.setState({formErrors: null, loading: true})
            .then(_ => this.updateAddAppUserCredentialsPopup(appId, data.userName, data.password))
            .then(_ => updateUserCredentialsForApp(tenant.id, appId, profile.tenantUserId, credentials))
            .then(_ => {
                let appCredentials = R.clone(this.state.appCredentials) || {};
                let index = R.findIndex(cred => cred.appId === appId, appCredentials || []);
                appCredentials[index].userName = data.userName;
                appCredentials[index].password = data.password;
                return this.setState({appCredentials})
            })
            .then(_ => this.setState({loading: false}))
            .then(_ => ModalContainer.removeModal('update-app-user-credentials-popup'))
            .then(_ => {
                this.listenerAdded[this.state.selectedTabId] = null;
                this.setRetryCountForApp(this.state.selectedTabId);
                let invalidCredentials = R.clone(this.state.invalidCredentials) || {};
                invalidCredentials[this.state.selectedTabId] = null;
                let updateCredentialsTime = R.clone(this.state.updateCredentialsTime || {});
                updateCredentialsTime[this.state.selectedTabId] = new Date().getTime();
                return this.setState({
                    showPassword: false,
                    invalidCredentials,
                    popup: false,
                    // updateCredentials: appId,
                    updateCredentialsTime
                })
            });

    };

    onAddOrUpdateDataChange = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            getClosestForEventWithAttribute(e, 'data-form-id')
                .find('#addOrUpdateAction')
                .click();
        }
    };

    onValueChange = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let user = serializeForm($form);
        let key = getAttrValueForEventFromClosest(e, 'data-key');
        let errors = R.clone(this.state.errors) || {};
        delete errors[key];
        return this.setState({formErrors: errors})
            .then(_ => this.updateAddAppUserCredentialsPopup(user.appId, user.userName, user.password))
            .then(_ => $(e.target).focus());
    };

    updateAddAppUserCredentialsPopup = (appId, userName, password) => {
        let view = this.getUpdateAppUserCredentialsPopupView(appId, userName, password);
        return ModalContainer.updateModalView('update-app-user-credentials-popup', view)
            .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("update-app-user-credentials-popup")));
    };

    closeUpdateAppUserCredentialsPopup = (e) => {
        return ModalContainer.removeModal('update-app-user-credentials-popup')
            .then(_ => {
                let invalidCredentials = R.clone(this.state.invalidCredentials) || {};
                invalidCredentials[this.state.selectedTabId] = null;
                return this.setState({
                    formErrors: null,
                    popup: false,
                    updateCredentials: null,
                    showPassword: false,
                    invalidCredentials
                })
            });
    };

    setAppHeights = () => {
        this.$container.find('[data-tab-content]').each((index, el) => {
            $(el).outerHeight(window.innerHeight - el.offsetTop - 10);
        });
    };

    destroy() {
        window.removeEventListener("resize", this.setAppHeights);
        super.destroy();
    }

    applyAutoFocusForContainer = (container) => $(container).find('[autofocus]').focus();

    applyAutoFocus = () => this.applyAutoFocusForContainer(this.$container);

    getView() {
        return super.getView()
            .then(_ => {
                let pages = this.state.publishedPages || [];
                if (this.state.dataLoaded)
                    if (R.isEmpty(this.state.contentTabs)) return this.getNoAppsView();
                return this.getAppsView();
            });
    }

    getAppsView() {
        return (
            <div className="user-apps-wrapper full-height">
                <div className="common-tabs-wrapper">
                    {this.getTabsView()}
                    {this.getLogoView()}
                </div>
                <div className="tabs-active app-tab-primary-background"/>
                {this.getCurrentTabContentView()}
            </div>
        );
    }

    getNoAppsView() {
        let adminMail = getAdminMail();
        let mailLink = convertToMailToUrl(adminMail);
        return (
            <div className="no-app-found">There are no applications configured for display. If you are an administrator,
                please add applications using the admin portal. If you are an user, please contact your administrator
                at <a href={mailLink}>{adminMail}</a></div>
        )
    }

}