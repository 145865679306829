import UIBaseComponent from "../../Backflipt_UI_Components/src/ui-base-component/ui-base-component";
import React from 'react';
import {
    getAttrValueForEventFromClosest,
    getAttrValueFromClosest,
    getClosestForEventWithAttribute, getValueForPathOrDefault,
    mapIndexed,
    serializeForm, setValueForPathOrDefault,
    validateForm,
    VALIDATION_TYPE
} from "../../Backflipt_UI_Components/utils/generic-utils";
import ModalContainer from "../modal-container/modal-container-v2";
import {getData} from "../../Backflipt_UI_Components/utils/http-utils";
import {SOURCE_TYPE} from "./templates-config";
import {convertListToObj, isValidJSON} from "../../utils/generic-utils";
import {getJsonFromUrl} from "../../service/tenant-service";

const R = require('ramda');

export default class BaseTemplateConfigurator extends UIBaseComponent {

    getLoadingView() {
        return <div className="common-loading-text">Loading..</div>
    }

    beforeRender() {
        this.state = {
            contentAttributes: this.props.contentAttributes,
            sourceUrlData: this.props.sourceUrlData
        };
        return super.beforeRender();
    }

    getTemplatePreviewView() {
        return (
            <div id="content-template">
                <div>Please configure the keys</div>
            </div>
        );
    }

    renderContentTemplate = () => {
        if(!this.StaticContentTemplate) return Promise.resolve();

        const container = this.$container.find('#content-template')[0];
        if(!container) return Promise.resolve();

        let sourceUrlData = this.state.sourceUrlData;
        // const contentAttr = this.state.contentAttributes || {};
        // let attributeMappings = contentAttr.attributeMappings || {};
        // if(sourceUrlData && !R.isEmpty(sourceUrlData) && R.isEmpty(attributeMappings)) return Promise.resolve();

        const props = {
            sourceUrlData,
            mapping: {contentAttributes: this.state.contentAttributes}
        };

        if(!this.contentTemplate) {
            this.contentTemplate = new this.StaticContentTemplate(container, props);
            return this.contentTemplate.render();
        }

        this.contentTemplate.props = props;
        return this.contentTemplate.render();
    };

    get attributes() { throw new Error("Has to be implemented at specific configurator") }

    get attrMappings() { throw new Error("Has to be implemented at specific configurator") }

    prepareKeysList(obj) {
        return R.map(key => [key, obj[key]], R.keys(obj));
    }

    getView() {
        return super.getView()
            .then(_ => {

                let contentAttributes = this.state.contentAttributes || {};

                let source = (contentAttributes.sources && contentAttributes.sources[0]) || {};

                let sourceUrlData = this.state.sourceUrlData || {};

                let sourceUrl = getValueForPathOrDefault(source, "url", "");

                let errors = this.state.errors || {};

                let sourceUrlError = !!getValueForPathOrDefault(errors, "sources.0.url", "");

                let sourceUrlErrorText = getValueForPathOrDefault(errors, "sources.0.url.message", "Please enter valid source url");

                return (
                    <div data-form-id="configure-content-template" className="ui dimmer modals page transition visible active" style="display: flex !important;">
                        <div
                            className="ui modal common-popup-wrapper home-page-common-popup-wrapper transition visible active scrolling"
                            id="commonPopupModal" style="display: block !important;">
                            <div className="content edit-content-template">
                                <div className="header">Edit Content Template</div>
                                <div className="description">
                                    <div className="home-page-common-popup">
                                        <div className="hp-common-popup-left-wrapper">
                                            <div className="popup-common-div-wrapper">
                                                <h4>Panel name</h4>
                                                <input data-key="name" data-required="true"
                                                       data-validation-type={VALIDATION_TYPE.NON_EMPTY} type="text"
                                                       className={"common-popup-input " + (errors.name ? "validation-error" : "")}
                                                       value={contentAttributes.name || ''} onkeyup={this.onDataChange}
                                                       oninput={this.onInputChange} autofocus={true}/>
                                                <p className={"error-text " + (errors.name ? "" : "hide")}>{(errors.name && errors.name.message) || "Name cannot be empty"}</p>
                                            </div>
                                            <input type="hidden" data-key="sources.0.type" value={SOURCE_TYPE.URL}/>
                                            <div className="popup-common-div-wrapper json-url-input-wrapper">
                                                <h4>JSON URL</h4>
                                                <input data-key="sources.0.url" type="text" data-required="true"
                                                       data-validation-type={VALIDATION_TYPE.URL}
                                                       className={"common-popup-input " + (sourceUrlError ? "validation-error" : "")}
                                                       value={sourceUrl || ''}
                                                       onkeyup={this.onDataChange}
                                                       oninput={this.onInputChange}/>
                                                <span className="popup-upload-json" onclick={this.uploadJSON}>Upload JSON</span>
                                                <p className={"error-text " + (sourceUrlError? "" : "hide")}>{sourceUrlErrorText}</p>
                                            </div>
                                            <div className={"common-key-mapping-wrapper" + ((sourceUrlData && !R.isEmpty(sourceUrlData)) ? "": " hide")}>
                                                <div className="popup-common-div-wrapper">
                                                    <h4>Configure Keys</h4>
                                                    <div className="key-mapping-details-wrapper">
                                                    {this.getKeyValueMappingView(sourceUrlData)}
                                                    </div>
                                                </div>
                                            </div>
                                            <p className={"error-text " + (errors.keyValueMapping ? "" : "hide")}>{(errors.keyValueMapping && errors.keyValueMapping.message) || "Please configure at least one key"}</p>
                                        </div>
                                        <div className="hp-common-popup-right-wrapper">
                                            <div className="popup-common-div-wrapper">
                                                <h4>Preview</h4>
                                            </div>
                                            {this.getTemplatePreviewView()}
                                        </div>
                                    </div>
                                </div>
                                <div className="actions">
                                    <div id="addOrUpdateTenantAction"
                                         className={"ui primary button buttons-primary-text buttons-primary-background "}
                                         onclick={this.saveTemplateChanges}>Save
                                    </div>
                                    <div
                                        className="ui cancel button buttons-secondary-text buttons-secondary-background"
                                        onclick={this.cancelTemplateChanges}>Cancel
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
    }

    uploadJSON = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let errors = R.clone(this.state.errors || {});
        let contentAttributes = R.clone(this.state.contentAttributes || {});
        let data = serializeForm($form);
        let formErrors = validateForm($form);
        let sourceUrlError = getValueForPathOrDefault(formErrors, "sources.0.url", false);
        if(sourceUrlError) setValueForPathOrDefault(errors, "sources.0.url", true);
        setValueForPathOrDefault(data, "sources.0.attributeMappings", {});
        let url = getValueForPathOrDefault(data,"sources.0.url", "");
        if(sourceUrlError) return this.setState({errors, contentAttributes});
        return this.setState({loading: true})
            .then(_ => getJsonFromUrl({url}))
            .then(resp => {
                let sourceUrlData = null;
                if(!isValidJSON(resp)) setValueForPathOrDefault(errors, "sources.0.url.message", "Invalid JSON detected");
                else sourceUrlData = resp;
                return this.setState({sourceUrlData, loading: false, errors, selected: null, contentAttributes: data, uploadJSON: true});
            })
            .catch(resp => {
                let error = (resp && resp.responseJSON) || {};
                if(error.code === "INVALID_JSON") setValueForPathOrDefault(errors, "sources.0.url.message", "Invalid JSON detected");
                else setValueForPathOrDefault(errors, "sources.0.url.message", "No response from url");
                return this.setState({sourceUrlData: null, loading: false, errors, contentAttributes: data, uploadJSON: false});
            });
    };

    onDataChange = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            getClosestForEventWithAttribute(e, 'data-form-id')
                .find('#addOrUpdateTenantAction')
                .click();
        }
    };

    onInputChange = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let contentAttributes = this.state.contentAttributes || {};
        let data = serializeForm($form);
        let attributeMappings = getValueForPathOrDefault(contentAttributes, "sources.0.attributeMappings", {});
        setValueForPathOrDefault(data, "sources.0.attributeMappings", attributeMappings);
        let key = getAttrValueForEventFromClosest(e, 'data-key');
        let errors = R.clone(this.state.errors) || {};
        setValueForPathOrDefault(errors, key, "");
        return this.setState({errors, contentAttributes: data})
            .then(_ => e.target.focus());
    };

    getKeyValueMappingView(sourceUrlData) {

        if(this.state.loading) return this.getLoadingView();

        let contentAttributes = this.state.contentAttributes || {};

        let source = (contentAttributes.sources && contentAttributes.sources[0]) || {};

        const sourceUrlKeysList = R.keys(sourceUrlData[0]);

        let attributeMappings = source.attributeMappings || {};

        attributeMappings = R.isEmpty(attributeMappings)? (this.state.selected? {}: this.attrMappings) : attributeMappings;

        let keyValuesObj = this.prepareViewKeyValueObj(sourceUrlKeysList, attributeMappings);

        let keyValuesList = this.prepareKeysList(keyValuesObj);

        //let attributeMappings = this.state.selectedKeys? R.invertObj(this.state.selectedKeys): (contentAttributes.attributeMappings || {});

        //let keyValuesList = this.prepareViewKeyValueObj(sourceUrlKeysList, attributeMappings);
        // keyValuesList = this.prepareKeysList(keyValuesList);
        //
        // let attributesLength = R.length(this.attributes);
        //
        // const displayNameList = R.map(data => data.displayName, this.attributes);
        //
        // let checkedKeysCount = R.length(R.keys(this.state.checkedKeys) || []);

        const displayNameList = R.map(value => value.displayName, this.attributes);

        const views = mapIndexed((keyValue, index) => {


            const key = keyValue[0];

            const value = keyValue[1];

            // let checked = (this.state.checkedKeys && this.state.checkedKeys[key]) || false;
            //

            return (
                <tr>
                    <td data-key-index={index}>
                        <div className="">
                            <input type="hidden" data-key={"sources.0.attributeMappings." + index + ".1"} defaultValue={key || ""}/>
                            <label>{key}</label>
                        </div>
                    </td>
                    <td>
                        {this.getDropdownView(displayNameList, value, "sources.0.attributeMappings." + index + ".0", this.onDropdownValueChange, key)}
                    </td>
                </tr>
            );
        }, keyValuesList);

        return (
            <table className="ui celled table" data-dropdown={true}>
                <thead>
                <tr>
                    <th>Select</th>
                    <th>Map</th>
                </tr>
                </thead>
                <tbody>
                {views}
                </tbody>
            </table>
        );
    }

    prepareViewKeyValueObj(sourceUrlKeys, attrMappings) {
        attrMappings = R.invertObj(attrMappings);
        let obj = R.map(key => {
            let obj = {};
            obj[key] = attrMappings[key] || "";
            return obj;
        }, sourceUrlKeys);
        return R.mergeAll(obj);
    }

    getDropdownView(dropdownList, selectedValue, inputDataKey, onChangeHandler, key) {
        if(onChangeHandler) this.ddOnChangeHandlers = R.assoc(inputDataKey, onChangeHandler, this.ddOnChangeHandlers || {});
        let views = R.map(name => {
            let value = R.find(attribute => attribute.displayName === name, this.attributes).key;
            return (
                <div className={"item " + (selectedValue === value ? "active selected" : "")} data-value={value}>{name}</div>
            );
        }, dropdownList);
        return (
            <div key={new Date().getTime()} data-label={key} id={key} data-dd-controller={onChangeHandler?inputDataKey: ""} className="ui selection dropdown" tabIndex={0}>
                <input id={"dd-input-" + inputDataKey} type="hidden" data-key={inputDataKey} value={selectedValue}/>
                <i className="dropdown icon"/>
                <div className="default text">{selectedValue ? selectedValue : "Select"}</div>
                <div className="menu" tabIndex={-1}>
                    <div className={"item " + (selectedValue ? "" : "active selected")} data-value="">Select</div>
                    {views}
                </div>
            </div>
        )
    }

    onDropdownValueChange = (value, text, $selectedItem) => {
        let contentAttributes = R.clone(this.state.contentAttributes || {});
        let jsonKey = getAttrValueFromClosest($selectedItem, "data-label");
        let attributeMappings = getValueForPathOrDefault(contentAttributes, "sources.0.attributeMappings", {});
        attributeMappings = R.isEmpty(attributeMappings)? (this.state.selected? {}: this.attrMappings) : attributeMappings;
        attributeMappings = R.filter(data => data !== value, R.invertObj(attributeMappings));
        if(value) attributeMappings[jsonKey] = value;
        else delete attributeMappings[jsonKey];
        setValueForPathOrDefault(contentAttributes, "sources.0.attributeMappings", R.invertObj(attributeMappings));
        return this.setState({contentAttributes, selected: true});
    };

    saveTemplateChanges = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let contentAttributes = R.clone(this.state.contentAttributes || {});
        let data = serializeForm($form);
        let errors = validateForm($form);
        let sourceUrlError = getValueForPathOrDefault(errors, "sources.0.url", false);
        let sourceUrlData = this.state.sourceUrlData;
        let sourceType = getValueForPathOrDefault(contentAttributes, "sources.0.type", "");
        let sourceId = getValueForPathOrDefault(contentAttributes, "sources.0.sourceId", "");
        if(sourceType === SOURCE_TYPE.PRE_CONFIGURED_FEED && !sourceId) setValueForPathOrDefault(errors, "sourceId", true);
        if(!sourceUrlError && !sourceUrlData) setValueForPathOrDefault(errors, "sources.0.url.message", "Please Upload the JSON");
        // let attributeMappings = getValueForPathOrDefault(contentAttributes, "sources.0.attributeMappings", {});
        // setValueForPathOrDefault(data, "sources.0.attributeMappings", attributeMappings);
        let attributeMappings = getValueForPathOrDefault(data, "sources.0.attributeMappings", {});
        attributeMappings = R.filter(keyValue => !!keyValue[0], attributeMappings || []);
        attributeMappings = attributeMappings? convertListToObj(attributeMappings): {};
        setValueForPathOrDefault(data,"sources.0.attributeMappings", attributeMappings);
        if(R.isEmpty(attributeMappings) && sourceUrlData) errors.keyValueMapping = true;
        else delete errors.keyValueMapping;
        if(errors && !R.isEmpty(errors)) return this.setState({errors, contentAttributes});
        //data.attributeMappings = R.invertObj(data.attributeMappings);
        return this.setState({contentAttributes: data, errors: null, selected: null})
            .then(_ => this.props.onSave(data));
        // TODO - save contentAttributes
    };

    cancelTemplateChanges = (e) => {
        return ModalContainer.removeModal('edit-template')
            .then(_ => this.setState({errors: null, selected: null}));
    };

    postRenderView() {
        return super.postRenderView()
            .then(this.renderDropDowns)
            .then(this.renderContentTemplate)
            .then(this.applyAutoFocus);
    }

    renderDropDowns = (e) => {
        const _self = this;
        return this.$container.find('.ui.dropdown').dropdown({onChange: function (value, text, $selectedItem) {
                const controller = getAttrValueFromClosest($selectedItem, 'data-dd-controller');
                if(controller && _self.ddOnChangeHandlers) {
                    _self.ddOnChangeHandlers[controller](value, text, $selectedItem);
                }
            }});
    };

    applyAutoFocus = () => this.$container.find('[autofocus]').focus();

}