/**
 * Created by Sabbu on 01/09/16.
 */

import $ from 'jquery';

const R = require('ramda');

export let postData = (url, data, headers) => {
    return new Promise(function(resolve, reject) {
        let payload = {
            contentType: 'application/json', // payload type being sent
            dataType: 'json', // payload being expected as response
            method: 'POST',
            headers: headers || {},
            data: JSON.stringify(data)
        };
        $.ajax(url, payload).done(resolve).fail(resolve);
    });
};

export let putData = (url, data, headers) => {
    return new Promise(function(resolve, reject) {
        let payload = {
            contentType: 'application/json', // payload type being sent
            dataType: 'json', // payload being expected as response
            method: 'PUT',
            headers: headers || {},
            data: JSON.stringify(data)
        };
        $.ajax(url, payload).done(resolve).fail(resolve);
    });
};

export let getData = (url, data, headers, cache=true) => {
    return new Promise(function(resolve, reject) {
        let payload = {
            contentType: 'application/json', // payload type being sent
            dataType: 'json', // payload being expected as response
            method: 'GET',
            cache: cache,
            headers: headers || {}
        };
        if(data && !R.isEmpty(data)) payload.data = JSON.stringify(data);
        $.ajax(url, payload).done(resolve).fail(resolve);
    });
};


export let validateIfProtocolExistForUrl = (url) =>{
    let regToCheckProtocolExist = /^(?:(ht|f)tp(s?):\/\/)?/;
    return url.match(regToCheckProtocolExist);
};

export let removeSecuredAccessForImage = (url) =>{
    if(url){
        url =  url.replace(/^https:\/\//i, 'http://');
    }
    return url;
};