import $ from 'jquery'
import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import React from 'react'
import {
  createTenant,
  deleteTenantById,
  encodeURIData,
  getAllTenants,
  updateTenant
} from '../../service/tenant-service'
import {
  convertToMailToUrl,
  convertToUrl,
  DATA_TYPE,
  getAttrValueForEventFromClosest,
  getClosestForEventWithAttribute,
  prepareRESTURI,
  serializeForm,
  validateForm
} from '../../Backflipt_UI_Components/utils/generic-utils'
import ModalContainer from '../modal-container/modal-container-v2'
import {goToRouteOnEvent, orderByAsc} from '../../utils/generic-utils'
import { ROUTES } from '../../config/app-config'
import { getAddOrUpdateTenantPopupView } from '../../views/add-update-tenant-popup'

const R = require('ramda');

export default class Tenants extends UIBaseComponent {

    beforeRender() {
        this.loadData();
        return super.beforeRender();
    }

    loadData() {
        return getAllTenants()
            .then(tenants => {
                tenants = orderByAsc(tenants, 'name');
                let filteredTenants = this.applySearch(tenants);
                return this.setState({tenants, filteredTenants, dataLoaded: true});
            });
    }

    getLoadingView() {
        return (
            <div className="loading-wrapper"><p>Loading...</p></div>
        );
    }

    getNoDataView() {
        return (
            <div className="no-file-found-wrapper"><p>No Records Found</p></div>
        );
    }

    onSearch = (e) => {
        let searchString = e.target.value.trim();
        if (this.state.searchString !== searchString) {
            this.state.searchString = searchString;

            let filteredTenants = this.applySearch(this.state.tenants);
            return this.setState({filteredTenants});
        }
    };

    applySearch(tenants) {
        let searchString = this.state.searchString;
        let filteredTenants = !searchString ? tenants : R.filter(t => {
            let inputKeys = R.map(k => k.toLowerCase(), R.flatten(R.map(key => key.split(" "), [t.name, t.address, t.website, t.contactName, t.contactEmailId])));
            let searchKeys = R.map(k => k.toLowerCase(), searchString.split(" "));
            return R.find(input => {
                return R.find(sk => input.includes(sk), searchKeys);
            }, inputKeys);
        }, tenants || []);

        return filteredTenants;
    }

    getView() {
        return super.getView()
            .then(_ => {

                let tenantsView = null;
                if (this.state.dataLoaded) {
                    if (!this.state.filteredTenants || R.isEmpty(this.state.filteredTenants)) tenantsView = this.getNoDataView();
                    else tenantsView = R.map(t => this.getTenantView(t), this.state.filteredTenants);
                } else tenantsView = this.getLoadingView();

                return (
                    <div className="client-management-content-wrapper">
                        <div className="semi-tabs-common-wrapper">
                            <div className="common-tab-button">
                                <div id="add-client-action" className="ui primary button"
                                     onclick={this.showAddOrUpdateTenantPopup}>Add Client
                                </div>
                            </div>
                            <div className="common-search-wrapper">
                                <div className="ui search">
                                    <input className="common-search-input" type="text" onkeyup={this.onSearch}
                                           placeholder="Search Client"/>
                                    <img className="common-search-icon" src="assets/images/search-icon.png" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="common-table-wrapper">
                            <table className="ui single line table">
                                <thead>
                                <tr>
                                    <th>Client Name</th>
                                    <th>Address</th>
                                    <th>Website</th>
                                    <th>Contact Name</th>
                                    <th>Contact Email</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {tenantsView}
                                </tbody>
                            </table>
                        </div>
                    </div>
                );
            });
    }

    getTenantDetailView(tenant) {
        return (
            <table className="ui table">
                <tbody>
                <tr>
                    <td>Client Name</td>
                    <td>{tenant.name}</td>
                </tr>
                <tr>
                    <td>Address</td>
                    <td>{tenant.address}</td>
                </tr>
                <tr>
                    <td>Website</td>
                    <td>{tenant.website}</td>
                </tr>
                <tr>
                    <td>Contact Name</td>
                    <td>{tenant.contactName}</td>
                </tr>
                <tr>
                    <td>Contact Email</td>
                    <td>{tenant.contactEmailId}</td>
                </tr>
                </tbody>
            </table>
        );
    }

    getDisableTenantPopupView = (tenant) => {
        let errors = this.state.errors || {};
        return (
            <div data-id={tenant.id} data-type={DATA_TYPE.NUMBER}
                 className="ui modal common-popup-wrapper disable-client-popup scrolling transition visible active"
                 style="display: block !important;">
                <div className="content">
                    <div className="secondary-header">Are you sure you want to Disable this Client?</div>
                    <div className="description">
                        {this.getTenantDetailView(tenant)}
                    </div>
                    <p className={"error-text " + (errors.serverError ? "" : "hide")}>Something went wrong! Please try again.</p>
                    <div className="actions">
                        <div className={"ui primary button " + (this.state.loading? "loading disabled" : "")} onclick={this.toggleEnableState}>Yes</div>
                        <div className="ui cancel button"
                             onclick={() => ModalContainer.removeModal("enable-disable-tenant-popup")}>No
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    toggleEnableState = (e) => {
        let tenant = R.clone(this.getTenantForEvent(e));
        let enabled = tenant.enabled;
        // let popupView = enabled ? this.getDisableTenantPopupView(tenant) : this.getEnableTenantPopupView(tenant);
        if (tenant) {
            tenant.enabled = !tenant.enabled;
            return this.setState({loading: true})
                .then(_ => ModalContainer.updateModalView("enable-disable-tenant-popup", enabled ? this.getDisableTenantPopupView(tenant) : this.getEnableTenantPopupView(tenant)))
                .then(_ => this.updateTenant(tenant))
                .then(_ => this.setState({loading: false}))
                .then(_ => ModalContainer.removeModal("enable-disable-tenant-popup"))
                .catch(_ => this.setState({errors: {serverError: true}, loading: false})
                    .then(_ => ModalContainer.updateModalView("enable-disable-tenant-popup", enabled ? this.getDisableTenantPopupView(tenant) : this.getEnableTenantPopupView(tenant))));
        }
    };

    getEnableTenantPopupView = (tenant) => {
        let errors = this.state.errors || {};
        return (
            <div data-id={tenant.id} data-type={DATA_TYPE.NUMBER}
                 className="ui modal common-popup-wrapper enable-client-popup scrolling transition visible active"
                 style="display: block !important;">
                <div className="content">
                    <div className="secondary-header">Are you sure you want to Enable this Client?</div>
                    <div className="description">
                        {this.getTenantDetailView(tenant)}
                    </div>
                    <p className={"error-text " + (errors.serverError ? "" : "hide")}>Something went wrong! Please try again.</p>
                    <div className="actions">
                        <div className={"ui primary button " + (this.state.loading? "loading disabled" : "")} onclick={this.toggleEnableState}>Yes</div>
                        <div className="ui cancel button"
                             onclick={this.closeEnableDisableTenantPopup}>No
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    closeEnableDisableTenantPopup = (e) => {
        return ModalContainer.removeModal("enable-disable-tenant-popup")
            .then(_ => this.setState({errors: null, loading: false}));
    };

    getTenantById(id) {
        // return getValueForPathOrDefault(this.state,`tenants[${id}]`);
        return id && R.find(t => t.id === id, this.state.tenants || []);
    }

    getTenantForEvent(e) {
        let tenantId = getAttrValueForEventFromClosest(e, 'data-id');
        return this.getTenantById(tenantId);
    }

    showEnableDisablePopup = (e) => {
        let tenant = this.getTenantForEvent(e);
        if (tenant) {
            let popupView = tenant.enabled ? this.getDisableTenantPopupView(tenant) : this.getEnableTenantPopupView(tenant);
            return ModalContainer.addModalView("enable-disable-tenant-popup", popupView);
        }
    };

    getTenantView(tenant) {
        let tenantParam = encodeURIData({id: tenant.id, name: tenant.name});
        let tenantRoute = prepareRESTURI(ROUTES.ADMIN_TENANT_DETAIL, {tenant: tenantParam});
        let mailLink = convertToMailToUrl(tenant.contactEmailId);
        return (
            <tr className="client-details-tr" data-id={tenant.id} data-type={DATA_TYPE.NUMBER}>
                <td class="client-name-tag-td">
                    <span className="client-name-tag" data-route={tenantRoute}
                          onclick={goToRouteOnEvent}>{tenant.name}</span>
                </td>
                <td className="table-grey-text address-td">{tenant.address}</td>
                <td className="table-grey-text website-td"><a className="link-text" href={convertToUrl(tenant.website)} target="_blank">{tenant.website}</a></td>
                <td className="table-grey-text contact-name-td">{tenant.contactName}</td>
                <td className="table-grey-text contact-email-td"><a className="link-text" href={mailLink}>{tenant.contactEmailId}</a></td>
                <td className="common-action-td">
                    <span className="common-action-tag" data-route={tenantRoute}
                          onclick={goToRouteOnEvent}>Manage</span>
                    <span className="common-action-tag"
                          onclick={this.showEnableDisablePopup}>{tenant.enabled ? "Disable" : "Enable"}</span>
                    <span className="common-action-tag" onclick={this.showAddOrUpdateTenantPopup}> Edit</span>
                    <span className="common-action-tag" onclick={this.showDeleteTenantConfirmation}>Delete</span>
                </td>
            </tr>
        );
    }

    updateTenant(tenant) {
        return updateTenant(tenant)
            .then(_ => {
                let index = R.findIndex(t => t.id === tenant.id, this.state.tenants || []);
                let tenants = R.update(index, tenant, this.state.tenants || []);
                tenants = orderByAsc(tenants, 'name');
                let filteredTenants = this.applySearch(tenants);
                return this.setState({tenants, filteredTenants});
            })
            .catch(resp => {throw resp});
    }

    onAddOrUpdateDataChange = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            getClosestForEventWithAttribute(e, 'data-form-id')
                .find('#addOrUpdateTenantAction')
                .click();
        }
    };

    onAddOrUpdateValueChange = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let tenant = serializeForm($form);
        let key = getAttrValueForEventFromClosest(e, 'data-key');
        let errors = R.clone(this.state.errors) || {};
        delete errors[key];
        return this.setState({errors})
            .then(_ => this.updateAddOrUpdateTenantPopup(tenant, this.state.errors || {}, this.onAddOrUpdateValueChange, this.onAddOrUpdateDataChange, this.addOrUpdateTenant, this.closeAddOrUpdateTenantPopup))
            .then(_ => e.target.focus());
    };

    showAddOrUpdateTenantPopup = (e) => {
        let tenant = this.getTenantForEvent(e) || {};
        let view = getAddOrUpdateTenantPopupView(tenant, false,this.state.errors || {}, this.onAddOrUpdateValueChange, this.onAddOrUpdateDataChange, this.addOrUpdateTenant, this.closeAddOrUpdateTenantPopup);
        return ModalContainer.addModalView("add-update-tenant-popup", view)
            .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("add-update-tenant-popup")));
    };

    updateAddOrUpdateTenantPopup = (tenant) => {
        let view = getAddOrUpdateTenantPopupView(tenant, this.state.loading, this.state.errors || {}, this.onAddOrUpdateValueChange, this.onAddOrUpdateDataChange, this.addOrUpdateTenant, this.closeAddOrUpdateTenantPopup);
        return ModalContainer.updateModalView("add-update-tenant-popup", view);
    };

    closeAddOrUpdateTenantPopup = (e) => {
        return ModalContainer.removeModal('add-update-tenant-popup')
            .then(_ => this.setState({errors: null, loading: false}));
    };

    addOrUpdateTenant = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let errors = validateForm($form);
        console.log("errors" + JSON.stringify(errors));
        let tenant = serializeForm($form);
        if (errors && !R.isEmpty(errors)) {
            return this.setState({errors})
                .then(_ => {
                    let view = getAddOrUpdateTenantPopupView(tenant, false,this.state.errors || {}, this.onAddOrUpdateValueChange, this.onAddOrUpdateDataChange, this.addOrUpdateTenant, this.closeAddOrUpdateTenantPopup);
                    return ModalContainer.updateModalView('add-update-tenant-popup', view);
                });
        }
        let tenantNames = R.map(tenant => tenant.name.toLowerCase(), this.state.tenants);
        if (tenant.id) {
            let existing = this.getTenantById(tenant.id) || {};
            if(R.includes(tenant.name.toLowerCase(),R.reject(R.equals(existing.name.toLowerCase()),tenantNames))) {
                return this.setState({errors:{name:{message:"A Client with this name already exists!"}}})
                    .then(_ => this.updateAddOrUpdateTenantPopup(tenant));
            }
            tenant = R.mergeDeepLeft(tenant, existing);
            return this.setState({loading: true})
                .then(_ => this.updateAddOrUpdateTenantPopup(tenant))
                .then(_ => this.updateTenant(tenant))
                .then(_ => this.closeAddOrUpdateTenantPopup())
                .catch(_ => this.setState({errors: {serverError: true}, loading: false})
                    .then(_ => this.updateAddOrUpdateTenantPopup(tenant)));
        }
        if(R.includes(tenant.name.toLowerCase(),tenantNames)) {
            return this.setState({errors:{name:{message:"A Client with this name already exists!"}}})
                .then(_ => this.updateAddOrUpdateTenantPopup(tenant));
        }
        return this.setState({loading: true})
            .then(_ => this.updateAddOrUpdateTenantPopup(tenant))
            .then(_ => createTenant(tenant))
            .then(tenant => {
                let tenants = R.append(tenant, this.state.tenants || []);
                tenants = orderByAsc(tenants, 'name');
                let filteredTenants = this.applySearch(tenants);
                return this.setState({tenants, filteredTenants, errors: null})
                    .then(_ => this.closeAddOrUpdateTenantPopup());
            })
            .catch(_ => this.setState({errors: {serverError: true}, loading: false})
                .then(_ => this.updateAddOrUpdateTenantPopup(tenant)));
    };

    getDeleteTenantPopupView(tenant) {
        let errors = this.state.errors || {};
        return(
            <div data-id={tenant.id} data-type={DATA_TYPE.NUMBER}
                 className="ui modal common-popup-wrapper delete-client-popup scrolling transition visible active"
                 style="display: block !important;">
                <div className="content">
                    <div className="secondary-header">Are you sure you want to Delete this Client?</div>
                    <div className="description">
                        {this.getTenantDetailView(tenant)}
                    </div>
                    <p className={"error-text " + (errors.serverError ? "" : "hide")}>Something went wrong! Please try again.</p>
                    <div className="actions">
                        <div className={"ui primary button " + (this.state.loading? "loading disabled" : "")} onclick={this.deleteTenant}>Yes</div>
                        <div className="ui cancel button"
                             onclick={this.closeDeleteTenantPopup}>No
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    showDeleteTenantConfirmation = (e) => {
        let tenant = this.getTenantForEvent(e);
        let view = this.getDeleteTenantPopupView(tenant);
        if (tenant) {
            return ModalContainer.addModalView("delete-tenant-popup", view)
        }
    };

    applyAutoFocusForContainer = (container) => $(container).find('[autofocus]').focus();

    applyAutoFocus = () => this.applyAutoFocusForContainer(this.$container);

    deleteTenant = (e) => {
        let tenantId = getAttrValueForEventFromClosest(e, 'data-id');
        let tenant = this.getTenantById(tenantId);
        return this.setState({loading: true})
            .then(_ => ModalContainer.updateModalView("delete-tenant-popup", this.getDeleteTenantPopupView(tenant)))
            .then(_ => deleteTenantById(tenantId))
            .then(_ => {
                let tenants = R.reject(t => t.id === tenantId, this.state.tenants || []);
                let filteredTenants = this.applySearch(tenants);
                return this.setState({tenants, filteredTenants, loading: false})
                    .then(_ => ModalContainer.removeModal("delete-tenant-popup"));
            })
            .catch(_ => this.setState({errors: {serverError: true}, loading: false})
                .then(_ => ModalContainer.updateModalView("delete-tenant-popup", this.getDeleteTenantPopupView(tenant))));
    };

    closeDeleteTenantPopup = (e) => {
        return ModalContainer.removeModal('delete-tenant-popup')
            .then(_ => this.setState({errors: null, loading: false}))
    };

}