import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import React from 'react'
import { ROUTES } from '../../config/app-config'
import { goToRouteOnEvent } from '../../utils/generic-utils'

export default class AdminHome extends UIBaseComponent {

    getView() {
        return super.getView()
            .then(_ => {

                let cr = this.props.routeAttributes.route;

                let requestsTabActiveClass = cr === ROUTES.ADMIN_ACCESS_REQUESTS ? "active" : "";
                let cmTabActiveClass = cr.startsWith(ROUTES.ADMIN_TENANTS) ? "active" : "";
                let configurationsActiveClass = cr.startsWith(ROUTES.ADMIN_CONFIGURATIONS) ? "active" : ""

                return (
                    <div className="ics-landing-body-wrapper">
                        <div className="admin-portal-flow-container">
                            <div className="common-tabs-container">
                                <div className="common-tabs-wrapper">
                                    <div className="tabs-wrapper">
                                        <span className={"common-tab hide " + requestsTabActiveClass} data-route={ROUTES.ADMIN_ACCESS_REQUESTS} onclick={goToRouteOnEvent}>Portal Access Requests</span>
                                        <span className={"common-tab " + cmTabActiveClass} data-route={ROUTES.ADMIN_TENANTS} onclick={goToRouteOnEvent}>Client Management</span>
                                        <span className={"common-tab " + configurationsActiveClass} data-route={ROUTES.ADMIN_CONFIGURATIONS} onclick={goToRouteOnEvent}>Configurations</span>
                                    </div>
                                </div>
                                <div className="tabs-active"/>
                                <div className="common-tabs-content-wrapper">
                                    <div id="tab-content"/>
                                    <div id="configurations"/>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
    }
}