import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import {
    convertToMailToUrl, getAttrValueForEventFromClosest,
    getClosestForEventWithAttribute,
    serializeForm,
    validateForm,
    VALIDATION_TYPE
} from '../../Backflipt_UI_Components/utils/generic-utils'
import {userSignIn, verifyEmailWithTenant, verifyUserNameExists} from '../../service/user-profile-service'
import EventsHandler from '../../event-handler'
import Events from '../../config/events'
import React from 'react'
import {ROUTES} from '../../config/app-config'
import {getValueFromStore, setValueToStore} from "../../service/storage-service";
import $ from "jquery";
import {getAdminMail, getClientAppLogoUrl, getLogoUrl} from '../../utils/domain-utils'
import {sendLoginOTP, validateLoginOTP} from "../../service/user-service";
import {ERRORS} from "../../config/errors";
import {orderByAsc} from "../../utils/generic-utils";

const R = require('ramda');

export default class UserSignIn extends UIBaseComponent {

    beforeRender() {
        this.state = {
            stage: 1,
            emailId: getValueFromStore("emailId") || ""
        };
        return super.beforeRender();
    }

    getStageView() {
        if(this.state.stage === 1) return this.getEmailIdView();
        else if(this.state.stage === 2) return this.getTenantDropdownView();
        else if (this.state.stage === 3) return this.getPasswordView();
        else if(this.state.stage === 4) return this.getMobileNumberVerificationView();
        return this.getErrorView()
    }

    getEmailIdView() {
        let errors = this.state.errors || {};
        let adminMail = getAdminMail();
        let mailLink = convertToMailToUrl(adminMail);
        let formLogo = getLogoUrl();
        let clientAppFormLogo = getClientAppLogoUrl();
        return (
            <div data-form-id="sign-in-form" className="ics-form-wrapper sign-in-wrapper ">
                <div className="ics-common-wrapper">
                    <div className="form-common-header-wrapper">
                        <img src={formLogo} className="ics-form-logo" alt=""/>
                        <span className="ics-form-header-pipeline"></span>
                        <img src={clientAppFormLogo} className="ics360-form-logo" alt=""/>
                        <img src="assets/images/@.png" className="at-logo hide" alt=""/>
                        <span className="ics-form-header-pipeline" />
                        <span className="form-header-text">
                                    <h3>Sign In</h3>
                                </span>
                    </div>
                    <div>
                        <div className="ics-common-body-wrapper">
                            <div className="common-input-label-wrapper">
                                <div className="ui form">
                                    <div className="inline field">
                                        <label>Work Email <span className="mandatory-start">*</span></label>
                                        <input type="text" data-key="emailId" data-required="true"
                                               data-validation-type={VALIDATION_TYPE.EMAIL_ID}
                                               onkeyup={this.onDataChange}
                                               value={this.state.emailId}
                                               className={errors.emailId ? "validation-error" : ""}
                                               autofocus={true} oninput={this.onValueChange}/>
                                        <p className={"error-text " + (errors.emailId ? "" : "hide")}>{(errors.emailId && errors.emailId.message) || "Please provide a valid email address"}</p>
                                        <p className={"error-text " + (errors.admin ? "" : "hide")}>Your account is disabled! Please contact your administrator at <a href={mailLink}>{adminMail}</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="common-form-btn-wrapper">
                                <button id="login-action"
                                        className={"ui right labeled icon button " + (this.state.loading ? "loading disabled" : "")}
                                        onclick={this.signIn}>
                                    <i className="right arrow icon"></i>
                                    Continue
                                </button>
                            </div>
                        </div>
                        <div className="common-form-divider">
                            <div className="ui divider"></div>
                        </div>
                        <div className="form-common-bottom-wrapper">
                            <div className="form-bottom-left-wrapper hide">
                                <span className="bottom-left-text">Don't have an account?</span>
                                <span className="bottom-common-anchor-tag">
                                        {/*<a href="">Sign Up</a>*/}
                                    </span>
                            </div>
                            <div
                                className={"form-bottom-right-wrapper " + (this.state.stage === 3 ? "hide" : "")}>
                                    <span className="bottom-common-anchor-tag">
                                        <a href={"#" + ROUTES.FORGOT_PASSWORD}>Forgot Password?</a>
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getTenantDropdownView() {
        let errors = this.state.errors || {};
        let adminMail = getAdminMail();
        let mailLink = convertToMailToUrl(adminMail);
        let formLogo = getLogoUrl();
        let clientAppFormLogo = getClientAppLogoUrl();
        let tenantsInfo = this.state.tenantsInfo;
        let tenantId = this.state.tenantId || tenantsInfo[0].id;
        return (
            <div data-form-id="sign-in-form" className="ics-form-wrapper sign-in-wrapper select-client-wrapper ">
                <div className="ics-common-wrapper">
                    <div className="form-common-header-wrapper">
                        <img src={formLogo} className="ics-form-logo" alt=""/>
                        <span className="ics-form-header-pipeline"></span>
                        <img src={clientAppFormLogo} className="ics360-form-logo" alt=""/>
                        <img src="assets/images/@.png" className="at-logo hide" alt=""/>
                        <span className="ics-form-header-pipeline" />
                        <span className="form-header-text">
                                    <h3>Sign In</h3>
                                </span>
                    </div>
                    <div>
                        <div className="ics-common-body-wrapper">
                            <div className="common-input-label-wrapper">
                                <div className="ui form">
                                    <div className="inline field">
                                        <label>{"Select client for " + (this.state.emailId)}</label>
                                        <input type="hidden" data-key="emailId" value={this.state.emailId}/>
                                        <div key={new Date().getTime()} className="ui dropdown item common-popup-dropdown">
                                            {R.find(R.propEq('id', tenantId), tenantsInfo).name}
                                            <input type="hidden" data-key="tenantId" value={tenantId}/>
                                            <i className ="dropdown icon"/>
                                            <div className="menu">
                                                {this.showTenants()}
                                            </div>
                                        </div>
                                        <p className={"error-text " + (errors.emailId ? "" : "hide")}>{(errors.emailId && errors.emailId.message)}</p>
                                        <p className={"error-text " + (errors.admin ? "" : "hide")}>Your account is disabled! Please contact your administrator at <a href={mailLink}>{adminMail}</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="common-form-btn-wrapper">
                                <button  onclick={this.goBack}
                                         className="ui left labeled icon button">
                                    <i className="left arrow icon"/>
                                    Back
                                </button>
                                <button id="login-action"
                                        className={"ui right labeled icon button " + (this.state.loading ? "loading disabled" : "")}
                                        onclick={this.signIn}>
                                    <i className="right arrow icon"></i>
                                    Continue
                                </button>
                            </div>
                        </div>
                        <div className="common-form-divider">
                            <div className="ui divider"></div>
                        </div>
                        <div className="form-common-bottom-wrapper">
                            <div className="form-bottom-left-wrapper hide">
                                <span className="bottom-left-text">Don't have an account?</span>
                                <span className="bottom-common-anchor-tag">
                                        {/*<a href="">Sign Up</a>*/}
                                    </span>
                            </div>
                            <div className="form-bottom-right-wrapper">
                                    <span className="bottom-common-anchor-tag">
                                        <a href={"#" + ROUTES.FORGOT_PASSWORD}>Forgot Password?</a>
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    showTenants() {
        let tenantsInfo = this.state.tenantsInfo || {};
        let tenantId = this.state.tenantId || tenantsInfo[0].id;
        return R.map(tenant =>
            <div className={"item " + (tenantId ===  tenant.id? "active" : "")} data-id={tenant.id}
                 onclick={this.onTenantChange}>{tenant.name}</div>,
            orderByAsc(tenantsInfo, 'name')
        )
    }

    onTenantChange = (e) => {
        let key = getAttrValueForEventFromClosest(e, 'data-id');
        let selectedTenantId = parseInt(key);
        let tenantId = this.state.tenantId || "";
        if(selectedTenantId !== tenantId) {
            setTimeout(() => {
                return this.setState({tenantId: selectedTenantId})
                    .then(_ => this.$container.find('.ui.dropdown').dropdown());
            }, 100);
        }
    };

    getPasswordView() {
        let errors = this.state.errors || {};
        let formLogo = getLogoUrl();
        let clientAppFormLogo = getClientAppLogoUrl();
        let tenantsInfo = this.state.tenantsInfo || {};
        let tenantId = this.state.tenantId || tenantsInfo[0].id;
        return (
            <div data-form-id="sign-in-form" className="ics-form-wrapper sign-in-wrapper ">
                <div className="ics-common-wrapper">
                    <div className="form-common-header-wrapper">
                        <img src={formLogo} className="ics-form-logo" alt=""/>
                        <span className="ics-form-header-pipeline"></span>
                        <img src={clientAppFormLogo} className="ics360-form-logo" alt=""/>
                        <img src="assets/images/@.png" className="at-logo hide" alt=""/>
                        <span className="ics-form-header-pipeline" />
                        <span className="form-header-text">
                                    <h3>Sign In</h3>
                                </span>
                    </div>
                    <div>
                        <div className="ics-common-body-wrapper">
                            <div className="common-input-label-wrapper">
                                <div className="ui form">
                                    <div className="inline field">
                                        <label>Password</label>
                                        <input type="hidden" data-key="emailId" value={this.state.emailId}/>
                                        <input type="hidden" data-key="tenantId" value={tenantId}/>
                                        <input type="password" data-key="password" data-required="true"
                                               data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                               onkeyup={this.onDataChange}
                                               autofocus={true}
                                               className={errors.password ? "validation-error" : ""}
                                               oninput={this.onValueChange}/>
                                        <p className={"error-text " + (errors.password ? "" : "hide")}>{(errors.password && errors.password.message) || "Please enter password"}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="common-form-btn-wrapper">
                                <button  onclick={this.goBack}
                                        className="ui left labeled icon button">
                                    <i className="left arrow icon"/>
                                    Back
                                </button>
                                <button id="login-action"
                                    className={"ui right labeled icon button " + (this.state.loading ? "loading disabled" : "")}
                                    onclick={this.signIn}>
                                    <i className="right arrow icon"></i>
                                    Continue
                                </button>
                            </div>
                        </div>
                        <div className="common-form-divider">
                            <div className="ui divider"></div>
                        </div>
                        <div className="form-common-bottom-wrapper">
                            <div className="form-bottom-left-wrapper hide">
                                <span className="bottom-left-text">Don't have an account?</span>
                                <span className="bottom-common-anchor-tag">
                                        {/*<a href="">Sign Up</a>*/}
                                    </span>
                            </div>
                            <div className="form-bottom-right-wrapper">
                                    <span className="bottom-common-anchor-tag">
                                        <a href={"#" + ROUTES.FORGOT_PASSWORD}>Forgot Password?</a>
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getMobileNumberVerificationView() {
        let errors = this.state.errors || {};
        let formLogo = getLogoUrl();
        let clientAppFormLogo = getClientAppLogoUrl();
        let resendOTP = R.isEmpty(errors) && this.state.resendOTP;
        let maskedMobileNumber = this.state.maskedMobileNumber || "";
        return (
            <div data-form-id="sign-in-form" className="ics-form-wrapper mobile-verification-code">
                <div className="ics-common-wrapper">
                    <div className="form-common-header-wrapper">
                        <img src={formLogo} className="ics-form-logo" alt=""/>
                        <span className="ics-form-header-pipeline"></span>
                        <img src={clientAppFormLogo} className="ics360-form-logo hide" alt=""/>
                        <span className="ics-form-header-pipeline hide" />
                        <span className="form-header-text">
                                    <h3>Mobile Verification Code</h3>
                                </span>
                    </div>
                        <div className="ics-common-body-wrapper">
                            <h3 className="form-secondary-header-text">{"Please enter the verification code sent to your registered mobile number ending in " + maskedMobileNumber}</h3>
                            <div className="common-input-label-wrapper">
                                <div className="ui form">
                                    <div className="inline field">
                                        <label>Verification Code</label>
                                        <input data-key="otp" type="text" data-required="true"
                                               data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                               className={"common-popup-input " + (errors.otp ? "validation-error" : "")}
                                               onkeyup={this.onDataChange} autofocus={true}
                                               oninput={this.onValueChange}/>
                                        <p className={"error-text " + (errors.otp ? "" : "hide")}>{(errors.otp && errors.otp.message) || "Please enter valid OTP."}</p>
                                        <p className={"otp-sent " + (resendOTP ? "" : "hide")}>OTP has been Resent.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="common-form-btn-wrapper">
                                <button id="back-button"
                                        className="ui left labeled icon button"
                                        onclick={this.goBack}>
                                    <i className="left arrow icon"/>
                                    Back
                                </button>
                                <button id="login-action"
                                        className={"ui right labeled icon button " + (this.state.loading ? "loading disabled" : "")}
                                        onclick={this.signIn}>
                                    <i className="right arrow icon"/>
                                    Verify
                                </button>
                            </div>
                        </div>
                        <div className="common-form-divider">
                            <div className="ui divider"></div>
                        </div>
                        <div className="form-common-bottom-wrapper">
                            <div class="form-bottom-right-wrapper">
                                <span class="bottom-common-anchor-tag" >
                                    <a onclick={this.resendOTP}>Resend OTP</a></span>
                           </div>
                        </div>
                </div>
            </div>
        );

    }

    getErrorView(){
        let errors = this.state.errors || {};
        let formLogo = getLogoUrl();
        let adminMail = getAdminMail();
        let mailLink = convertToMailToUrl(adminMail);
        let clientAppFormLogo = getClientAppLogoUrl();
        return (
            <div data-form-id="sign-in-form" className="ics-form-wrapper mobile-verification-code">
                <div className="ics-common-wrapper">
                    <div className="form-common-header-wrapper">
                        <img src={formLogo} className="ics-form-logo" alt=""/>
                        <span className="ics-form-header-pipeline"></span>
                        <img src={clientAppFormLogo} className="ics360-form-logo hide" alt=""/>
                        <span className="ics-form-header-pipeline hide" />
                        <span className="form-header-text">
                                    <h3>Mobile Verification Code</h3>
                                </span>
                    </div>
                    <div className="ics-common-body-wrapper">
                        <div className="success-error-img-wrapper">
                          <img src="assets/images/login-error-icon.png" alt=""/>
                        </div>
                        <h3 className={"form-secondary-header-text " + (errors.admin ? "" : "hide")}>No mobile number found for Two Factor Authentication. Please contact your administrator at <a href={mailLink}>{adminMail}</a></h3>
                        <h3 className={"form-secondary-header-text " + (errors.tfa ? "" : "hide")}>{errors.tfa && errors.tfa.description}</h3>
                    </div>
                    <div className="common-form-btn-wrapper">
                        <button id="back-button"
                                className="ui right labeled icon button"
                                onclick={this.goBack}>
                            <i className="right arrow icon"/>
                            Sign In
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    getView() {
        return super.getView()
            .then(_ => {
                return this.getStageView();
            });
    }


    goBack = (e) => {
        if(this.state.stage === 2)
            return this.setState({stage: 1, showTenants: false, errors: null});
        if (this.state.stage === 3 && this.state.showTenants)
            return this.setState({stage: 2, errors: null});
        if(this.state.stage === 3 && !this.state.showTenants)
            return this.setState({stage: 1, errors: null});
        if (this.state.stage === 4)
            return this.setState({fingerPrint: null, stage: 1, maskedMobileNumber: null, errors: null});
        return this.setState({fingerPrint: null, stage: 1, maskedMobileNumber: null, errors: null})
    };

    resendOTP = (e) => {
        return this.setState({errors: null, resendOTP:false})
            .then(_ => sendLoginOTP(this.state.fingerPrint))
            .then(_ => this.setState({resendOTP: true}))
            .catch(resp => {
                let error = (resp && resp.responseJSON) || {};
                let description = error.description || ERRORS.MOBILE_NO_VERIFICATION[error.code] || ERRORS.COMMON;
                if(error.code === "TOKEN_ERROR_EXPIRED"){
                    return this.setState({loading: false, stage: 4, errors: {tfa: {description}}});}
                return this.setState({loading: false, errors: {otp: {message: description}}});
            })
    };

    onDataChange = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            getClosestForEventWithAttribute(e, 'data-form-id')
                .find('#login-action')
                .click();
        }
    };

    onValueChange = (e) => {
        let emailId = this.state.stage === 1? e.target.value: this.state.emailId;
        return this.setState({errors: null, emailId})
            .then(_ => $(e.target).focus());
    };

    signIn = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let data = serializeForm($form);
        let errors = validateForm($form);
        if (this.state.stage === 1) {
            if(!data.emailId)  errors.emailId = {...errors.emailId,message:"Please enter email address"};
            if (errors && !R.isEmpty(errors)) return this.setState({errors});
            return this.setState({emailId: data.emailId.toLowerCase().trim(), loading: true, errors: null})
                .then(_ => verifyUserNameExists(data.emailId))
                .then(resp => {
                    let tenantsInfo = resp.tenantsInfo;
                    if(tenantsInfo.length === 1) {
                        let tenantId = tenantsInfo[0].id;
                        return this.setState({tenantsInfo})
                            .then(_ => verifyEmailWithTenant({emailId: data.emailId, tenantId}))
                            .then(_ => this.setState({stage: 3, loading: false}))
                    }
                    return this.setState({stage: 2, showTenants: true, tenantsInfo, loading: false})
                })
                .catch(resp => {
                    let error = (resp && resp.responseJSON) || {};
                    error.message = ERRORS.USER_SIGN_IN[error.code] || ERRORS.COMMON;
                    if(ERRORS.CONTACT.ADMIN.includes(error.code))
                        return this.setState({loading: false, errors: {admin: true}});
                    return this.setState({loading: false, errors: {emailId: error}})
                });
        } else if(this.state.stage === 2) {
            if (errors && !R.isEmpty(errors)) return this.setState({errors});
            return this.setState({loading: true, errors: null})
                .then(_ => verifyEmailWithTenant(data))
                .then(resp => {
                    setValueToStore("emailId", data.emailId);
                    let ssoEnabled = resp.ssoDetails && resp.ssoDetails.ssoEnabled;
                    if(ssoEnabled) return window.location.href = resp.ssoDetails.ssoLoginUrl;
                    return this.setState({emailId: data.emailId, stage: 3, loading: false});
                })
                .catch(resp => {
                    let error = (resp && resp.responseJSON) || {};
                    error.message = ERRORS.USER_SIGN_IN[error.code] || ERRORS.COMMON;
                    if(ERRORS.CONTACT.ADMIN.includes(error.code))
                        return this.setState({loading: false, errors: {admin: true}});
                    return this.setState({loading: false, errors: {emailId: error}})
                });
        }
        else if(this.state.stage === 3) {
            if(!data.emailId)  errors.emailId = {...errors.emailId, message:"Please enter email address"};
            if (errors && !R.isEmpty(errors)) return this.setState({errors});
            return this.setState({loading: true, errors: null})
                .then(_ => userSignIn(data))
                .then(resp => {
                    if(resp.twoFactorAuthEnabled) return this.setState({fingerPrint: resp.fingerPrint, maskedMobileNumber: resp.maskedMobileNumber, stage: 4, loading: false});
                    if(resp) {
                        //let rd = this.props.routeAttributes.params.rd;
                        //if (rd) return window.location.href = rd;
                        return EventsHandler.push({name: Events.GO_TO_HOME});
                    }
                    let errors = {password: {message: "Invalid Password!"}};
                    return this.setState({errors, loading: false});
                })
                .catch(resp => {
                    let error = (resp && resp.responseJSON) || {};
                    if(ERRORS.CONTACT.ADMIN.includes(error.code))
                        return this.setState({loading: false, stage: 5, errors: {admin: true}});
                    error.description = error.description || ERRORS.MOBILE_NO_VERIFICATION[error.code] || ERRORS.COMMON;
                    return this.setState({loading: false, errors: {password:{message: error.description}}});
                });
        } else {
            if (errors && !R.isEmpty(errors)) return this.setState({errors});
            return this.setState({loading: true, errors: null})
                .then(_ => {
                    data.token = this.state.fingerPrint;
                    return validateLoginOTP(data)
                })
                .then(_ => {
                    //let rd = this.props.routeAttributes.params.rd;
                    //if (rd) return window.location.href = rd;
                    return EventsHandler.push({name: Events.GO_TO_HOME});
                })
                .catch(resp => {
                    let error = (resp && resp.responseJSON) || {};
                    let description = error.description || ERRORS.MOBILE_NO_VERIFICATION[error.code] || ERRORS.COMMON;
                    if(error.code === "TOKEN_ERROR_EXPIRED")
                        return this.setState({loading: false, stage: 5, errors: {tfa: {description}}});
                    return this.setState({loading: false, errors: {otp: {message: description}}});
                });
        }

    };

    postRenderView () {
        return super.postRenderView()
            .then(this.applyAutoFocus)
            .then(_ => this.$container.find('.ui.dropdown').dropdown());
    }

    applyAutoFocus = () => this.$container.find('[autofocus]').focus();
}