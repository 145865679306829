import uuid from 'uuid'

import md5 from 'blueimp-md5'
import $ from 'jquery'
import CopyToClipbord from 'copy-to-clipboard'
import { getAttrValueForEventFromClosest } from '../Backflipt_UI_Components/utils/generic-utils'
import Router from '../router/router'

const R = require('ramda');

export let newUUID = () => uuid.v4();

export let mapFromList = (list, key) => {
    return R.fromPairs(R.map(x => [x[key], x], list));
};

export let isValidEmailId = (emailId) => {
    if(!emailId) return false;
    let reg = /^([A-Za-z0-9_\-\\.])+@([A-Za-z0-9_\-\\.])+\.([A-Za-z]{2,4})$/;
    return reg.test(emailId);
};

export let getRedirectURI = () => {
    return 'https://backflipt.com/customer/admin/oauth';
};

export let getQueryStringForUrl = (url) => {
    let qs = (url && url.split("?")[1]) || "";
    return qs.split('#')[0];
};

export let getParamsMapForQueryString = (qs) => {
    let params = qs && qs.split("&");
    return params && R.reduce(function(acc, p) {
        let keys = p.split("=");
        acc[keys[0]] = keys[1];
        return acc;
    }, {}, params);
};

export let getAbsoluteUrl = (url) => {
    let u = url && url.split("?")[0];
    return u && u.split("#")[0];
};

export let getHashStringForUrl = (url) => {
    return url && url.split("#")[1];
};

export let generateQueryStringFromParams = (paramMap) => {
    if(!paramMap) return;
    return R.reduce(function(acc, k) {
        if(acc.length > 0) acc = acc + "&";
        return acc + k + "=" + paramMap[k];
    }, "", R.keys(paramMap));
};

export let appendParamsAsQueryStringToUrl = (url, paramMap) => {
    let absUrl = getAbsoluteUrl(url);
    let params = getQueryStringForUrl(url) || {};
    paramMap = R.merge(params, paramMap || {});
    let hs = getHashStringForUrl(url);
    let qs = generateQueryStringFromParams(paramMap);
    return absUrl + (qs ? ("?" + qs) : "") + (hs ? ("#" + hs) : "");
};

export let appendParamsAsHashStringToUrl = (url, paramMap) => {
    let absUrl = url.split("#")[0];
    let hs = getHashStringForUrl(url);
    let params = getParamsMapForQueryString(hs) || {};
    console.log("absUrl-> " + absUrl + " hs-> " + hs + " params-> " + JSON.stringify(params));
    paramMap = R.merge(params, paramMap || {});
    let qs = generateQueryStringFromParams(paramMap);
    console.log("qs-> " + qs + " paramMap-> " + JSON.stringify(paramMap));
    return absUrl + (qs ? ("#" + qs) : "");
};

export let generateOAuthLoginUrl = (url, state) => {
    state = state || {};
    let queryString = getQueryStringForUrl(url);
    let paramMap = getParamsMapForQueryString(queryString);
    //let stateToken = generateNewStateToken();
    paramMap["redirect_uri"] = getRedirectURI();
    paramMap["state"] = JSON.stringify(state);

    return appendParamsAsQueryStringToUrl(url, paramMap);
};

export let getTrimmedSummaryForFile = (fileSummary) => {

    if(!fileSummary) return;
    let summaryLength = fileSummary.length;
    if(summaryLength <= 55) return fileSummary;
    else return fileSummary.substring(0, 52) + "...";
};

export let getExtensionForFile = (fileName) => {
    let parts = fileName.split(".");
    return parts.length > 1 ? parts.pop() : "";
};

export let getFileNameWithoutExtension = (fileName) => {
    if(!fileName) return;
    // let fileNameWithoutExtsn = fileName.substring(0, fileName.length - getExtensionForFile(fileName).length - 1);
    // let fileNameLength = fileNameWithoutExtsn && fileNameWithoutExtsn.length;
    // if(fileNameLength <= 35) return fileNameWithoutExtsn;
    // else return fileNameWithoutExtsn.substring(0, 32) + "...";
    return fileName && fileName.substring(0, fileName.length - getExtensionForFile(fileName).length - 1);
};

export let getDomainFromEmail = (email) => {
    let temp = email && email.replace(/.*@/, '').split('.');
    return temp[temp.length - 2];
};

export let capitalizeFirstLetter = (str) => {
    return str && str.charAt(0).toUpperCase() + str.slice(1);
};

export let openUrlInBrowser = (url) => {
    window.openUrlInBrowser && window.openUrlInBrowser(url);
};

export let prepareRESTURI = (uri, params) => {
    if(!params || R.isEmpty(params) || !uri) return uri;
    return R.reduce((acc, k) => {
        let key = ":" + k;
        let value = params[k] || ' ';
        if(acc.endsWith(key)) {
            return acc.substring(0, acc.length - key.length) + value;
        }
        return acc.replace(key + '/', value + "/");
    }, uri, R.keys(params));
};

export let md5Hash = str => str && md5(str);

export let LoadingView = "Loading";
// (
//     <div className="loader max-width-70">
//         <img alt="" src="images/loading.svg"/>
//     </div>
// );

export let copyToClipboard = (text) => {
    CopyToClipbord(text);
};

export let sortTheListByKey = (list, key) => {
    if(!list || R.isEmpty(list)) return list;
    let sorted = R.sortBy(R.prop(key), list);
    return sorted;
};

export let getTrimmedTextOfGivenLength = (text, maxLength) => {
    if(text.length <= maxLength) return text;
    return text.substring(0,maxLength-1) + "...";
};
export let captalizeString = (str) => str && str.substring(0, 1).toUpperCase() + str.substring(1);

export let getContainerFromClosest = (e, attrName) => {
    return $(e.target).closest('[' + attrName + ']');
};

export let openUrlInNewTab = (url) => {

    let elm = document.createElement("a");
    elm.setAttribute("target", "_blank");
    elm.setAttribute("style", "display:none;");
    elm.href = url;
    document.body.appendChild(elm);
    elm.click();

};

// TODO this wont work for keys having .
export let getValueFromObject = (obj, expr) => {
    return R.path(expr.split("."), obj);
};

export let getValueFromObjectOrDefault = (obj, expr, def) => {
    try {
        return getValueFromObject(obj, expr) || def;
    } catch(ex) {}
    return def;
};

export let gotoRoute = (route) => route && Router.goToRoute(route);
export let reloadCurrentRoute = () => Router.reloadCurrentRoute();

export let goToRouteOnEvent = (e) => {
    let route = getAttrValueForEventFromClosest(e, 'data-route');
    return gotoRoute(route);
};

export let goToRouteOnEventWithDelay = (e) => {
    let delay = Number(getAttrValueForEventFromClosest(e, 'data-delay') || "100");
    return setTimeout(() => goToRouteOnEvent(e), delay);
};

export let download = (filename, content, mimeType = "text/plain") => {
    let element = document.createElement('a');
    element.setAttribute('href', `data:${mimeType};charset=utf-8,` + encodeURIComponent(content));
    element.setAttribute('download', filename);
    element.setAttribute('class', 'hide');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    // document.body.removeChild(element);
};

export let orderByAsc = (obj, property) => {
    return R.sortWith([R.ascend(R.compose(R.toUpper,R.prop(property)))], obj);
};

export let isValidJSON = (value) => {
    value = typeof(value) === "string"? value : JSON.stringify(value);
    try {
        JSON.parse(value);
        return true
    }
    catch(e) {
        return false
    }
};

export let convertListToObj = (list) => {
    return R.mergeAll(
        R.map(keyValue => {
            let obj = {};
            obj[keyValue[0]] = keyValue[1];
            return obj
        }, list)
    );
};