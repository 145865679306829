import React from 'react'
import {
    getValueForKeyInConfig,
    storeKeyValueInConfigAsObject
} from '../../service/storage-service'
import {
    getAttrValueForEventFromClosest,
    getClosestForEventWithAttribute,
    serializeForm,
    validateForm, VALIDATION_TYPE
} from '../../Backflipt_UI_Components/utils/generic-utils'
import $ from 'jquery'
import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import { COMMON_FEED_SAMPLE } from '../../config/app-config'

const R = require('ramda');

export default class CommonFeedUpload extends UIBaseComponent {

    beforeRender() {
        this.loadDataAsync();
        return super.beforeRender();
    }

    loadDataAsync () {
        return getValueForKeyInConfig("commonFeedDetails")
            .then(feedDetails =>{
                let feedUrl = feedDetails.commonFeedUrl;
                let feedTitle = feedDetails.commonFeedTitle || "Trending Security News";
                return this.setState({feedUrl, feedTitle, originalFeedUrl: feedUrl, originalFeedTitle: feedTitle})
            })
            .catch(_ => {
                let feedTitle = "Trending Security News";
                return this.setState({feedTitle, originalFeedTitle : feedTitle})
            })
    }

    getHeaderView() {
        return (
            <h5>{ "Please provide the URL to the JSON formatted file to be shown as feed to all clients."}</h5>
        );
    }

    getFeedTitle() {
        return(
            <label>Common Feed Title<span
                className="mandatory-start">*</span></label>
        );
    }

    getView() {
        let feedTitle = this.state.feedTitle;
        let errors = this.state.errors || {};
        return super.getView()
            .then(_ => {
                    return (
                        <div data-form-id="json-feed" className="json-feed-container">
                            <div className="json-feed-detail-wrapper">
                                <div className="json-feed-header-wrapper">
                                    <div className="json-new-header">
                                        <div className="client-json-feed">
                                            <div className="json-url-form">
                                                <div className="ui form">
                                                    <div className="inline field">
                                                        {this.getFeedTitle()}
                                                        <input type="text" data-required="true" data-key="feedTitle"
                                                               className={errors.feedTitle ? "validation-error" : ""}
                                                               data-validation-type={VALIDATION_TYPE.NON_EMPTY} autofocus="true"
                                                               value={feedTitle || ""} oninput={this.onValueChange}/>
                                                        <p className={"feed-title-error " + (errors.feedTitle? "" : "hide")}>Feed title cannot be empty.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="json-feed-header-action-btns">
                                        <div className="actions">
                                            <div
                                                className={"ui primary button buttons-primary-text buttons-primary-background " + (this.state.loading? "loading disabled":"") + (this.hasUnSavedChanges()? "":"disabled")} onclick={this.saveFeedUrl}>Save
                                            </div>
                                            <div
                                                className={"ui cancel button buttons-secondary-text buttons-secondary-background " + (this.hasUnSavedChanges()? "":"disabled")} onclick={this.resetFeedUrl}>Cancel
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="json-feed-header-text">
                                    {this.getHeaderView()}
                                </div>
                                <div className="json-feed-scrollable-wrapper">
                                    {this.uploadFeed()}
                                </div>

                            </div>
                        </div>
                    );
                }
            );
    }

    hasUnSavedChanges() {
        return !(R.equals(this.state.feedUrl, this.state.originalFeedUrl) && R.equals(this.state.feedTitle, this.state.originalFeedTitle));
    }


    uploadFeed(){
        let feedUrl = this.state.feedUrl;
        let errors = this.state.errors || {};
        return(
            <div className="client-json-feed">
                {/*<h5 className="json-feed-secondary-header"> </h5>*/}
                <div className="json-url-form">
                    <div className="ui form">
                        <div className="inline field">
                            <label>JSON URL <span className="mandatory-start">*</span></label>
                            <input type="text" data-required="true" data-key="feedUrl" className={errors.feedUrl ? "validation-error" : ""}
                                   data-validation-type={VALIDATION_TYPE.URL} value={feedUrl || ""} oninput={this.onValueChange}/>
                            <p className={"json-url-error " + (errors.feedUrl? "" : "hide")}>Please enter a valid URL.</p>
                            <p className="show-json" onclick={this.showOrHideJSONExample}> {this.state.showJSON ? "Hide Specific JSON Example" : "Show Common JSON Example"}</p>
                            <div className={"json-data" + (this.state.showJSON ? "" : "hide")}>{this.state.showJSON? this.jsonText() : ""}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    saveFeedUrl = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let data = serializeForm($form);
        let errors = validateForm($form);
        let feedUrl = data.feedUrl;
        let feedTitle = data.feedTitle;
        if(!R.isEmpty(errors)) return this.setState({feedUrl, feedTitle, errors});
        let params = {"commonFeedDetails" : {"commonFeedUrl": feedUrl, "commonFeedTitle": feedTitle}};
        return this.setState({loading: true})
            .then(_ => storeKeyValueInConfigAsObject(params))
            .then(_ => this.setState({loading: false, originalFeedUrl: feedUrl, feedUrl, originalFeedTitle: feedTitle, feedTitle}))
    };

    resetFeedUrl = (e) => {
        return this.setState({feedUrl: this.state.originalFeedUrl, feedTitle: this.state.originalFeedTitle, errors: null});
    };

    onValueChange = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let data = serializeForm($form);
        let errors = R.clone(this.state.errors) || {};
        let feedUrl = data.feedUrl;
        let feedTitle = data.feedTitle;
        let dataKey = getAttrValueForEventFromClosest(e, 'data-key');
        delete errors[dataKey];
        return this.setState({errors, feedUrl, feedTitle})
            .then(_ => $(e.target).focus());
    };

    postRenderView () {
        return super.postRenderView()
            .then(this.applyAutoFocus)
            ;
    }

    showOrHideJSONExample = (e) => {
        return this.setState({showJSON: !this.state.showJSON});
    };

    jsonText () {
        return JSON.stringify(COMMON_FEED_SAMPLE, null, "\t");
    }

    applyAutoFocus = () => this.$container.find('[autofocus]').focus();

}