// debugger;

import $ from 'jquery';
import {getData} from "./utils/http-utils";

const R = require('ramda');

export let setAppAttributes = () => {

    let configFileName = "domainConfig.json";
    let url = "https://ics-client.s3.us-east-2.amazonaws.com/web/config/domains/" + window.env.domain + "/" + configFileName;

    return getData(url, null, null, false)
        .then(dc => window.domainConfig = dc)
        .then(applyAttributes)
        .then(loadCustomStyles);
};

let applyAttributes = () => {
    const dc = window.domainConfig || {};
    const title = dc.title;
    const favIcon = dc.favIconUrl;

    document.getElementById("title").innerHTML = title;
    document.getElementById("shortcut-icon").setAttribute("href", favIcon);
    document.getElementById("apple-touch-icon").setAttribute("href", favIcon);
    document.getElementById("icon").setAttribute("href", favIcon);

    return loadCustomStyles();
};

let loadCustomStyles = () => {
    const dc = window.domainConfig || {};
    const styles = dc.customStyles || [];
    return R.forEach(loadStyle, styles);
};

let loadStyle = (href) => $('head').append(`<link rel="stylesheet" href="${href}"/>`);
