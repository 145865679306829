import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'

import React from 'react'
import {amIAdmin, getMyTenantLogoUrl, getProfile, isRouteAllowedForMe, logout} from '../../dao/user-profile-dao'
import {
    getClosestForEventWithAttribute,
    serializeForm,
    validateForm,
    VALIDATION_TYPE
} from '../../Backflipt_UI_Components/utils/generic-utils'
import ModalContainer from '../modal-container/modal-container-v2'
import {updatePassword, updateProfile} from '../../service/user-profile-service'
import {ROUTES} from '../../config/app-config'
import {goToRouteOnEvent, goToRouteOnEventWithDelay} from '../../utils/generic-utils'
import $ from 'jquery'
import {deleteImageForUser, getImageUrlForUser, uploadImageForUser} from "../../service/user-service";
import { getClientAppLogoUrl, getLogoUrl} from '../../utils/domain-utils'

const R = require('ramda');

export default class Header extends UIBaseComponent {

    beforeRender(){
        return super.beforeRender()
            .then(_ => this.loadDataAsync());
    }

    loadDataAsync(){
        let url = getImageUrlForUser(getProfile());
        let defaultPhoto = "assets/images/default-profile-pic.svg";
        let isDefault = !url;
        let image = url || defaultPhoto;
        let profile = getProfile();
        return this.setState({originalImageFile: image, default: isDefault, defaultPhoto, hideDelImage: isDefault, orgProfile: profile, profile});
    }

    getView() {
        return super.getView()
            .then(_ => {

                let profile = this.state.orgProfile;

                let admin = amIAdmin();

                let url = !admin? getMyTenantLogoUrl() || "assets/images/comp-logo-1.png":"";

                let imageSrc = this.state.originalImageFile;

                let landingLogo = getLogoUrl();

                let clientAppLandingLogo = getClientAppLogoUrl();

                return (
                    <div className="ics-landing-header-wrapper">
                        <div className="ics-landing-header-left-wrapper">
                            <img src= {landingLogo} className="landing-ics-logo" alt=""/>
                            <span className="ics-header-pipeline"/>
                            <img src= {clientAppLandingLogo} className={"ics360-landing-logo " + (admin ? "hide" : "")} alt=""/>
                            {/*<span className={"ics-header-pipeline " + (admin? "hide" : "")}/>*/}
                            <img src="assets/images/@.png" className={"at-logo " + (admin? "hide" : "")} alt=""/>
                            <img src={url} className={"header-comp-logo " + (admin? "hide" : "")} alt=""/>
                            <span className={"ics-header-pipeline " + (admin? "hide" : "")}/>
                            <img src="assets/images/ics-home-icon.png" className={"home-icon "  + (admin? "hide" : "")} data-route={ROUTES.HOME} onclick={goToRouteOnEvent} alt=""/>
                            <span className={"ics-header-text " + (admin ? "" : "hide")}>
                                <h3>Admin Portal</h3>
                            </span>
                        </div>
                        <div className="ics-landing-header-right-wrapper">
                            <span className="header-home hide">Home</span>
                            <span className="ics-header-pipeline hide"/>
                            <div className="header-profile-dropdown">
                                <div className="ui dropdown button" tabIndex={0}>
                                    <span className="person-name">{profile.firstName} {profile.lastName || ""}</span>
                                    <img src={imageSrc} className="person-img" alt=""/>
                                    <i className="dropdown icon"/>
                                    <div className="menu" tabIndex={-1}>
                                        <div className="item" onclick={this.showProfilePopupView}>Edit Profile</div>
                                        <div className="item" onclick={this.showPasswordPopupView}>Change Password</div>
                                        <div className={"item " + (isRouteAllowedForMe(ROUTES.USER_ADMIN_HOME) ? "" : "hide")} data-route={ROUTES.USER_ADMIN_HOME} data-delay={300} onclick={goToRouteOnEventWithDelay}>Administration</div>
                                    </div>
                                </div>
                            </div>
                            <span className="ics-header-pipeline"/>
                            <span className="header-logout" onclick={logout}>Logout</span>
                        </div>
                    </div>
                );
            });
    }

    onProfileUpdateDataChange = (e) => {
        if(e.keyCode === 13) {
            e.preventDefault();
            getClosestForEventWithAttribute(e, 'data-form-id')
                .find('#updateUserProfileAction')
                .click();
        }
    };

    onProfilePopupValueChange = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let profile = R.clone(this.state.profile);
        let data = serializeForm($form);
        profile = R.mergeDeepRight(profile, data);
        return this.setState({profile, errors: null})
            .then(_ => this.updateProfilePopUpView(profile))
            .then(_ => $(e.target).focus());
    };

    getProfilePopupView(data) {
        let profile = data;
        let errors = this.state.errors || {};

        let imageFile = this.state.imageFile? URL.createObjectURL(this.state.imageFile) : this.state.originalImageFile;

        let imageSrc =this.state.hideDelImage? this.state.defaultPhoto : imageFile;

        return (
            <div data-form-id="update-user-profile" className="ui modal common-popup-wrapper update-user-profile-popup scrolling transition visible active" style="display: block !important;">
                <div className="content">
                    <div className="header">Edit Profile</div>
                    <div className="upload-file-wrapper">
                        <span className="upload-file-img">
                            <img className="ui middle aligned image" src={imageSrc} alt=""/>
                        </span>
                        <span className={"delete-photo " + (this.state.hideDelImage? "hide" : "")}>
                            <button className="ui button"  onclick={this.onImageDelete}>
                              Delete Photo
                            </button>
                        </span>
                        <span className="upload-file-input">
                            <input type="file" accept="image/*" onchange={this.onImageChanged} value=""/>
                        </span>
                    </div>
                    <div className="description">
                        <table className="ui table">
                            <tbody>
                            <tr>
                                <td>First Name <span className="mandatory-start">*</span></td>
                                <td>
                                    <input data-key="firstName" data-required="true"
                                           data-validation-type={VALIDATION_TYPE.NON_EMPTY} type="text"
                                           className={"common-popup-input " + (errors.firstName ? "validation-error" : "")}
                                           value={profile.firstName || ''} onkeyup={this.onProfileUpdateDataChange} oninput = {this.onProfilePopupValueChange} autofocus={true}/>
                                    <p className={"error-text " + (errors.firstName ? "" : "hide")}>First Name cannot be empty</p>
                                </td>
                            </tr>
                            <tr>
                                <td>Last Name</td>
                                <td><input data-key="lastName" type="text" className="common-popup-input"
                                           value={profile.lastName || ''}  onkeyup={this.onProfileUpdateDataChange} oninput={this.onProfilePopupValueChange}/>
                                    <p className={"error-text " + (errors.serverError ? "" : "hide")}>Something went wrong! Please try again.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="actions">
                        <div id="updateUserProfileAction" className={"ui primary button buttons-primary-text buttons-primary-background " + (this.state.loading? "loading disabled" : "")} onclick={this.updateProfile}>Update Profile</div>
                        <div className="ui cancel button buttons-secondary-text buttons-secondary-background" onclick={this.closeAddOrUpdateUserProfilePopup}>Cancel</div>
                    </div>
                </div>
            </div>
        );
    }

    showProfilePopupView = () => {
        let profile = this.state.profile;
        let view = this.getProfilePopupView(profile);
        return ModalContainer.addModalView("update-user-profile-popup", view)
            .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("update-user-profile-popup")));
    };

    onImageChanged = (e) => {
        let profile = this.state.profile;
        let imageFile = e.target.files[0];
        return this.setState({imageFile, hideDelImage: false})
            .then(_ => this.updateProfilePopUpView(profile));
    };

    onImageDelete = () => {
        let profile = this.state.profile;
        return this.setState({imageFile: null, loading: false, hideDelImage: true, delClicked: true})
            .then(_ => this.updateProfilePopUpView(profile));
    };

    updateProfilePopUpView = (data) => {
        let view = this.getProfilePopupView(data);
        return ModalContainer.updateModalView('update-user-profile-popup', view);
    };

    updateProfile = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let profile = R.clone(this.state.profile);
        let data = serializeForm($form);
        let errors = validateForm($form);
        let imageFile = this.state.imageFile? URL.createObjectURL(this.state.imageFile) : this.state.originalImageFile;
        let imageSrc =this.state.hideDelImage? this.state.defaultPhoto : imageFile;
        if(errors && !R.isEmpty(errors)) {
            return this.setState({errors})
                .then(_ => this.updateProfilePopUpView(data));
        }
        profile = R.mergeDeepRight(profile, data);
        return this.setState({loading: true})
            .then(_ => this.updateProfilePopUpView(data))
            .then(_ => this.state.imageFile? this.uploadImage(profile) : this.deleteImage(profile))
            .then(imageAttributes => {
                let attributes = profile.attributes || {};
                attributes.image = imageAttributes;
                profile.attributes = attributes;
                return updateProfile(profile)
            })
            .then(_ => {

                return ModalContainer.removeModal('update-user-profile-popup')
                        .then(_ => this.setState({originalImageFile: imageSrc, imageFile: null, default: this.state.hideDelImage, delClicked: false, profile, orgProfile: profile, errors: null, loading: false}))
                }
            )
            .catch(_ => {
                return this.setState({errors:{serverError:true}, loading: false})
                    .then(_ => this.updateProfilePopUpView(profile))
            });
    };

    uploadImage = (user) => uploadImageForUser(this.state.imageFile, user.id);


    deleteImage = (user) => {
        if(this.state.delClicked) {
            return deleteImageForUser(user.id)
                .then(_ => Promise.resolve({}))
        }
        let attributes = user.attributes;
        return Promise.resolve(attributes.image);
    };

    closeAddOrUpdateUserProfilePopup = (e) => {
        return ModalContainer.removeModal('update-user-profile-popup')
            .then(_ => this.setState({imageFile: null, hideDelImage: this.state.default, delClicked: false, profile: this.state.orgProfile, errors: null, loading: false}))
            .then(_ => this.updateProfilePopUpView(this.state.profile));
    };

    onPasswordDataChange = (e) => {
        if(e.keyCode === 13) {
            e.preventDefault();
            getClosestForEventWithAttribute(e, 'data-form-id')
                .find('#updatePasswordAction')
                .click();
        }
    };

    onPasswordPopupValueChange = (e) => {
        return this.setState({errors: null})
            .then(_ => this.updatePasswordPopUpView())
            .then(_ => $(e.target).focus());
    };

    getPasswordPopupView() {
        let errors = this.state.errors || {};
        return (
            <div data-form-id="update-user-password" className="ui modal common-popup-wrapper update-user-password-popup scrolling transition visible active" style="display: block !important;">
                <div className="content">
                    <div className="header">Edit Password</div>
                    <div className="description">
                        <table className="ui table">
                            <tbody>
                            <tr>
                                <td>Old Password <span className="mandatory-start">*</span></td>
                                <td>
                                    <input data-key="oldPassword" data-required="true"
                                           data-validation-type={VALIDATION_TYPE.NON_EMPTY} type="password"
                                           className={"common-popup-input " + (errors.oldPassword ? "validation-error" : "")}
                                           onkeyup={this.onPasswordDataChange} autocomplete="off" oninput={this.onPasswordPopupValueChange} autofocus={true}/>
                                    <p className={"error-text " + (errors.oldPassword ? "" : "hide")}>{(errors.oldPassword && errors.oldPassword.message) || "Old Password can not be empty"}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>New Password <span className="mandatory-start">*</span></td>
                                <td>
                                    <input data-key="newPassword" data-required="true"
                                           data-validation-type={VALIDATION_TYPE.NON_EMPTY} type="password"
                                           className={"common-popup-input " + (errors.newPassword ? "validation-error" : "")}
                                           oninput={this.onPasswordPopupValueChange} onkeyup={this.onPasswordDataChange}/>
                                    <p className={"error-text " + (errors.newPassword ? "" : "hide")}>{(errors.newPassword && errors.newPassword.message) || "New Password can not be empty"}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>Re-type New Password <span className="mandatory-start">*</span></td>
                                <td>
                                    <input data-key="retypeNewPassword" data-required="true"
                                           data-validation-type={VALIDATION_TYPE.NON_EMPTY} type="password"
                                           className={"common-popup-input " + (errors.retypeNewPassword ? "validation-error" : "")}
                                           oninput={this.onPasswordPopupValueChange} onkeyup={this.onPasswordDataChange}/>
                                    <p className={"error-text " + (errors.retypeNewPassword ? "" : "hide")}>{(errors.retypeNewPassword && errors.retypeNewPassword.message) || "Retype Password does not match with New Password"}</p>
                                    <p className={"error-text " + (errors.serverError ? "" : "hide")}>Something went wrong! Please try again.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="actions">
                        <div id="updatePasswordAction" className={"ui primary button buttons-primary-text buttons-primary-background " + (this.state.loading? "loading disabled" : "")} onclick={this.updatePassword}>Update Password</div>
                        <div className="ui cancel button buttons-secondary-text buttons-secondary-background" onclick={this.closeAddOrUpdateUserPasswordPopup}>Cancel</div>
                    </div>
                </div>
            </div>
        );
    }

    showPasswordPopupView = () => {
        let view = this.getPasswordPopupView();
        return ModalContainer.addModalView("update-user-password-popup", view)
            .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("update-user-password-popup")));
    };

    updatePasswordPopUpView = (data) => {
        let view = this.getPasswordPopupView(data);
        return ModalContainer.updateModalView('update-user-password-popup', view);
    };

    updatePassword = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let data = serializeForm($form);
        let errors = validateForm($form) || {};
        if(data.oldPassword && data.newPassword === data.oldPassword) errors.newPassword = {message: "New Password can not be same as Old Password"};
        if(!data.newPassword) delete errors.retypeNewPassword;
        else if(data.newPassword && data.newPassword !== data.retypeNewPassword) errors.retypeNewPassword = true;
        if(errors && !R.isEmpty(errors)) {
            return this.setState({errors})
                .then(_ => {
                    let view = this.getPasswordPopupView();
                    return ModalContainer.updateModalView('update-user-password-popup', view);
                });
        }

        return this.setState({loading: true})
            .then(_ => this.updatePasswordPopUpView())
            .then(_ => updatePassword(data.oldPassword, data.newPassword))
            .then(_ => this.closeAddOrUpdateUserPasswordPopup())
            .catch(resp => {
                let errors = resp.status === 401? {oldPassword: {message: "Incorrect Old Password entered."}} : {serverError: true};
                return this.setState({errors, loading: false})
                    .then(_ => this.updatePasswordPopUpView());});
    };

    closeAddOrUpdateUserPasswordPopup = (e) => {
        return ModalContainer.removeModal('update-user-password-popup')
            .then(_ => this.setState({errors: null, loading: false}));
    };

    applyAutoFocusForContainer = (container) => $(container).find('[autofocus]').focus();

    applyAutoFocus = () => this.applyAutoFocusForContainer(this.$container);

    postRenderView() {
        return super.postRenderView()
            .then(_ => this.$container.find('.ui.dropdown').dropdown());
    }
}