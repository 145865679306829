import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import React from 'react'
import { getLogoUrlForTenant, getTenantById, updateTenant, uploadLogoForTenant } from '../../service/tenant-service'
import {
  getAttrValueForEventFromClosest,
  getValueForPathOrDefault, setValueForPathOrDefault
} from '../../Backflipt_UI_Components/utils/generic-utils'
import ColorPicker from '../color-picker/color-picker'

const R = require('ramda');

export default class TenantDetailGUIAdmin extends UIBaseComponent {

  beforeRender () {
    return super.beforeRender()
        .then(_ => this.loadDataAsync());
  }

  loadDataAsync() {
    return getTenantById(this.props.tenant.id)
      .then(tenant => this.setState({originalTenant: tenant, tenant}));
  }

  getColorPickerButtonView(attributeName) {
    let key = attributeName.replace(/\./g, "-");
    return (
        <span id={key} data-container-id={key} data-attribute-name={attributeName} className="gui-edit-action tooltip top"
              onclick={this.showColorPicker}>
            <img src="assets/images/gui-edit-icon.png" className="gui-edit-icon" alt=""/>
        </span>
    );
  }

  getColorForAttribute(attributeName) {
    let color = getValueForPathOrDefault(this.state.tenant.attributes, "colors." + attributeName);
    return color;
  }

  onColorPicked = (colorPicker, color) => {
    let attributeName = colorPicker.props.attributeName;
    // colorPicker.hide();
    let tenant = R.clone(this.state.tenant);
    setValueForPathOrDefault(tenant.attributes, "colors." + attributeName, color);
    return this.setState({tenant});
  };

  showColorPicker = (e) => {
    let attributeName = getAttrValueForEventFromClosest(e, 'data-attribute-name');
    let color = this.getColorForAttribute(attributeName);
    let containerId = getAttrValueForEventFromClosest(e, 'data-container-id');
    let $container = this.$container.find('#' + containerId);
    let props = {
      $container: $container,
      attributeName,
      color,
      onColorPicked: this.onColorPicked
    };
    if(!this.colorPicker) {
      this.colorPicker = new ColorPicker(this.$container.find('#color-picker')[0], props);
      return this.colorPicker.render();
    } else {
      return this.colorPicker.setProps(props);
    }
  };

  destroyColorPicker() {
    if(this.colorPicker) this.colorPicker.destroy();
    this.colorPicker = null;
  }

  destroy () {
    this.destroyColorPicker();
    return super.destroy();
  }

  getTextView(attributeName, text) {
    let style = this.getStyleAttributeForColor(attributeName);
    return (
      <div className="gui-edit-wrapper">
        {this.getColorPickerButtonView(attributeName)}
        <div className="gui-edit-content">
          <p {...style}>{text}</p>
        </div>
      </div>
    );
  }

  getStyleAttributeForColor(attributeName) {
    let color = this.getColorForAttribute(attributeName);
    return color && {style: "color: " + color + " !important;"};
  }

  getStyleAttributeForBGColor(attributeName) {
    let backgroundColor = this.getColorForAttribute(attributeName);
    return backgroundColor && {style: "background-color: " + backgroundColor + " !important;"};
  }

  getTabView(attribute, tabHeading, tabText) {
    let tabBGAttributeName = attribute + ".background";
    let tabTextAttributeName = attribute + ".text";
    let style = this.getStyleAttributeForBGColor(tabBGAttributeName);
    return (
      <div className="gui-common-edit-wrapper">
        <p className="gui-tab-header-text-wrapper">{tabHeading}</p>
        <div className="gui-edit-wrapper">
          {this.getColorPickerButtonView(tabBGAttributeName)}
          <div className="gui-edit-content gui-tab-content" {...style}>
            {this.getTextView(tabTextAttributeName, tabText)}
          </div>
        </div>
      </div>
    );
  }

  saveAttributes = (e) => {
    return this.setState({loading: true})
        .then(this.uploadLogo)
        .then(logoAttributes => {
          let tenant = R.clone(this.state.tenant);
          let attributes = tenant.attributes || {};
          attributes.logo = logoAttributes;
          tenant.attributes = attributes;
          if(!R.equals(this.state.tenant, this.state.originalTenant)) {
            return updateTenant(tenant);
          }
          return tenant;
        })
        .then(tenant => this.setState({logoFile: null, loading: false, originalTenant: tenant, tenant}));
  };

  resetAttributes = (e) => this.setState({tenant: this.state.originalTenant, logoFile: null});

  hasUnSavedChanges() {
    return this.state.logoFile || !R.equals(this.state.tenant, this.state.originalTenant);
  }

  onLogoChanged = (e) => {
      let logoFile = e.target.files[0];
      return this.setState({logoFile});
  };

  uploadLogo = () => {
    if(!this.state.logoFile) {
      let attributes = this.state.originalTenant.attributes || {};
      return Promise.resolve(attributes.logo);
    }
    return uploadLogoForTenant(this.state.tenant.id, this.state.logoFile);
  };

  getView () {
    return super.getView()
      .then(_ => {

        let logoSrc = "assets/images/default-client-logo.png";

        if(this.state.logoFile) logoSrc = URL.createObjectURL(this.state.logoFile);
        else {
          let url = getLogoUrlForTenant(this.state.tenant);
          if(url) logoSrc = url;
        }

        return (
          <div className="gui-setting-tab-content-wrapper ui grid">
            <div id="color-picker"/>
            <div className="gui-settings-header">
              <img src={logoSrc} className="client-admin-logo" alt=""/>
              <span className="edit-icon" onclick={() => this.$container.find('#tenant-logo-upload-input').click()}>
                <img src="assets/images/blue-edit-icon.png" className="edit-blue-icon" alt=""/>
              </span>
              <input id="tenant-logo-upload-input" type="file" accept="image/*" className="upload-file-input hide" onchange={this.onLogoChanged} value=""/>
              <div className="file-visible-button" onclick={() => this.$container.find('#tenant-logo-upload-input').click()}>Browse Logo</div>
              <div className="flright">
                <div className={"ui primary button buttons-primary-text buttons-primary-background " + (this.state.loading ? "loading disabled" : "") + (this.hasUnSavedChanges() ? "" : " disabled")} onclick={this.saveAttributes}>Save</div>
                <div className={"ui secondary button  buttons-secondary-text buttons-secondary-background" + (this.hasUnSavedChanges() ? "" : " disabled")} onclick={this.resetAttributes}>Cancel</div>
              </div>
            </div>
            <div className="gui-setting-content-detail-wrapper row">
              <div className="gui-tabs-container-wrapper nine wide column">
                <div className="gui-left-tabs-wrapper">
                  <div className="gui-primary-tab">
                    {this.getTabView('app.tab.primary', 'Primary Tab', 'Tab Text')}
                  </div>
                  <div className="gui-secondary-tab">
                    {this.getTabView('app.tab.secondary', 'Secondary Tab', 'Tab Text')}
                  </div>
                </div>
                <div className="gui-tabs-main-headings-wrapper">
                  <div className="gui-common-edit-wrapper">
                    <div className="gui-edit-wrapper gui-main-heading-text-wrapper">
                      {this.getColorPickerButtonView("app.background")}
                      <div className="gui-edit-content gui-main-heading-content-text-content" {...this.getStyleAttributeForBGColor("app.background")}>
                        <div className="hide gui-edit-wrapper gui-main-heading-content-text-wrapper">
                                                      <span
                                                        className="gui-edit-action gui-main-heading-content-text-action tooltip top">
                                                        <img src="assets/images/gui-edit-icon.png"
                                                             className="gui-edit-icon" alt=""/>
                                                      </span>
                          <div className="gui-edit-content gui-main-heading-content-text">
                            <h3>Main Heading</h3>
                          </div>
                        </div>
                        <div
                          className="hide gui-common-edit-wrapper secondary-header-gui-common-edit-wrapper">
                          <div
                            className="gui-edit-wrapper secondary-main-heading-text-wrapper">
                                                        <span
                                                          className="gui-edit-action secondary-main-heading-text-action tooltip top">
                                                          <img src="assets/images/gui-edit-icon.png"
                                                               className="gui-edit-icon" alt=""/>
                                                        </span>
                            <div
                              className="gui-edit-content secondary-main-heading-content-text-content">
                              <div
                                className="gui-edit-wrapper secondary-main-heading-content-text-wrapper">
                                                                <span
                                                                  className="gui-edit-action secondary-main-heading-content-text-action tooltip top">
                                                                  <img src="assets/images/gui-edit-icon.png"
                                                                       className="gui-edit-icon" alt=""/>
                                                                </span>
                                <div
                                  className="gui-edit-content secondary-main-heading-content-text">
                                  <h5>Main Heading</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="gui-right-sidebar-wrapper three wide column hide">
                <div className="gui-common-edit-wrapper">
                  <p className="gui-tab-header-text-wrapper">Right Sidebar</p>
                  <div className="gui-edit-wrapper gui-right-sidebar-edit-wrapper">
                    {this.getColorPickerButtonView("rightSideBar.background")}
                    <div className="gui-edit-content gui-right-sidebar-content"
                         {...this.getStyleAttributeForBGColor("rightSideBar.background")}>
                      <div className="gui-edit-wrapper gui-right-sidebar-text-wrapper">
                        {this.getColorPickerButtonView("rightSideBar.section.heading")}
                        <div className="gui-edit-content gui-right-sidebar-text-content">
                          <p {...this.getStyleAttributeForColor("rightSideBar.section.heading")}>Section
                            Heading</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="gui-styles-wrapper four wide column">
                <div className="gui-common-edit-wrapper">
                  <p className="gui-button-header-text-wrapper">Primary Button</p>
                  <div className="gui-edit-wrapper gui-primary-button-wrapper">
                    {this.getColorPickerButtonView("buttons.primary.background")}
                    <div className="gui-edit-content gui-primary-button-content" {...this.getStyleAttributeForBGColor("buttons.primary.background")}>
                      <div className="gui-edit-wrapper gui-primary-button-text-wrapper">
                        {this.getColorPickerButtonView("buttons.primary.text")}
                        <div className="gui-edit-content gui-primary-button-text-content">
                          <p {...this.getStyleAttributeForColor("buttons.primary.text")}>Button
                            Text</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gui-common-edit-wrapper">
                  <p className="gui-button-header-text-wrapper">Secondary Button</p>
                  <div className="gui-edit-wrapper gui-secondary-button-wrapper">
                    {this.getColorPickerButtonView("buttons.secondary.background")}
                    <div className="gui-edit-content gui-secondary-button-content" {...this.getStyleAttributeForBGColor("buttons.secondary.background")}>
                      <div className="gui-edit-wrapper gui-secondary-button-text-wrapper">
                        {this.getColorPickerButtonView("buttons.secondary.text")}
                        <div className="gui-edit-content gui-secondary-button-text-content">
                          <p {...this.getStyleAttributeForColor("buttons.secondary.text")}>Button
                            Text</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gui-colors-wrapper">
                  <div className="gui-two-colors-wrapper">
                    <div className="gui-common-edit-wrapper">
                      <p className="gui-button-header-text-wrapper">Primary Color</p>
                      <div className="gui-edit-wrapper gui-primary-color-wrapper">
                        {this.getColorPickerButtonView("primary.text")}
                        <div className="gui-edit-content gui-primary-color-content" {...this.getStyleAttributeForBGColor("primary.text")}>
                        </div>
                      </div>
                    </div>
                    <div className="gui-common-edit-wrapper">
                      <p className="gui-button-header-text-wrapper">Secondary Color</p>
                      <div className="gui-edit-wrapper gui-secondary-color-wrapper">
                        {this.getColorPickerButtonView("secondary.text")}
                        <div className="gui-edit-content gui-secondary-color-content" {...this.getStyleAttributeForBGColor("secondary.text")}>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gui-common-edit-wrapper">
                    <p className="gui-button-header-text-wrapper">Link Color</p>
                    <div className="gui-edit-wrapper gui-link-color-wrapper">
                      {this.getColorPickerButtonView("link.text")}
                      <div className="gui-edit-content gui-link-color-content" {...this.getStyleAttributeForBGColor("link.text")}>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })
  }
}