import TenantDetailApps from "../tenant-detail/tenant-detail-apps";
import React from 'react'
import {
    createStandardApp,
    deleteStandardApp,
    getStandardApps,
    updateStandardApp,
} from "../../service/tenant-service";
import {
    convertToUrl,
    DATA_TYPE,
    getAttrValueForEventFromClosest,
    getClosestForEventWithAttribute,
    serializeForm,
    validateForm, VALIDATION_TYPE
} from "../../Backflipt_UI_Components/utils/generic-utils";
import ModalContainer from "../modal-container/modal-container-v2";
import {storeKeyValueInConfigAsObject} from "../../service/storage-service";

const R = require('ramda');

export default class StandardApps extends TenantDetailApps {

    loadDataAsync() {
        console.log("fetching apps");
        return getStandardApps()
            .then(apps => {
                let appSequence = R.map(app => app.id, apps);
                let filteredApps = this.applySearch(apps);
                return this.setState({apps: apps, appSequence, filteredApps, dataLoaded: true});
            });
    }

    getHeaderView() {
        return (
            <div className="semi-tabs-common-wrapper">
                <div className="common-tab-button">
                    <div className="ui primary button buttons-primary-text buttons-primary-background"
                         onclick={this.showAddOrUpdateAppPopup}>Add Application
                    </div>
                </div>
                <div className="common-search-wrapper">
                    <div className="ui search">
                        <input className="common-search-input" type="text" placeholder="Search Application"
                               onkeyup={this.onSearch}/>
                        <img className="common-search-icon" src="assets/images/search-icon.png" alt=""/>
                    </div>
                </div>
            </div>
        );
    }

    getView() {
        return super.getView()
            .then(_ => {
                    return (
                        <div className="application-tab-content-wrapper standard-applications-wrapper">

                            {this.getHeaderView()}

                            <div className="common-table-wrapper">
                                <table className="ui single line table">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>Application Name</th>
                                        <th>Application URL</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.getAppsView()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    );
                }
            );
    }

    getAppsView(){
        if (this.state.dataLoaded) {
            if (!this.state.filteredApps || R.isEmpty(this.state.filteredApps)) return this.getNoDataView();
            return R.map(t => this.getAppRowView(t), this.state.filteredApps);
        } return this.getLoadingView();
    }

    getAppRowView(app) {
        return (
            <tr data-id={app.id} data-type={DATA_TYPE.NUMBER}>
                <td class="up-down-arrow-td">
                    <div className={R.length(this.state.apps) === R.length(this.state.filteredApps) ? "" : "hide"}>
                        <i className="sort up icon" onclick = {() => this.positionTo(app, "up")}/>
                        <i className="sort down icon" onclick = {() => this.positionTo(app, "down")}/>
                    </div>
                </td>
                <td className={"app-name-text"}>{app.name}</td>
                <td className="app-url-text"><a className="link-text" href={convertToUrl(app.url)} target="_blank">{app.url}</a></td>
                <td className="common-action-td">
                    <span className="common-action-tag link-text" onclick={this.showSeamlessSignOnAdditionalSetupPopup}>Additional setup</span>
                    <span className="common-action-tag link-text"
                          onclick={this.showEnableDisablePopup}>{app.enabled ? "Disable" : "Enable"}</span>
                    <span className="common-action-tag link-text" onclick={this.showAddOrUpdateAppPopup}>Edit</span>
                    <span className="common-action-tag link-text" onclick={this.showDeleteAppConfirmation}>Delete</span>
                </td>
            </tr>
        );
    }

    getAddOrUpdateAppPopupView(app) {
        let newApp = !app.id;
        let apps = this.state.apps;
        let appId = this.state.appId;
        let appIndex = R.findIndex(R.propEq('id',parseInt(appId)), apps);
        let errors = this.state.errors || {};
        return (
            <div data-form-id="add-new-update-app"
                 className="ui modal common-popup-wrapper add-application-popup-modal scrolling transition visible active"
                 style="display: block !important;">
                <div className="content">
                    <div className="header">{newApp ? "Add" : "Edit"} Application</div>
                    <div className="description">
                        <table className="ui table">
                            <input type="hidden" data-key="id" data-type={DATA_TYPE.NUMBER} value={app.id || ""}/>
                            <tbody>
                            <tr>
                                <td>Application Name <span className="mandatory-start">*</span></td>
                                <td>
                                    <input type="text" data-key="name" data-required="true"
                                           data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                           className={"common-popup-input " + (errors.name ? "validation-error" : "")}
                                           onkeyup={this.onAddOrUpdateDataChange} oninput={this.onValueChange} value={app.name || ""}
                                           autofocus={true}/>
                                    <p className={"error-text " + (errors.name ? "" : "hide")}>Application Name cannot be empty</p>
                                </td>
                            </tr>
                            <tr>
                                <td>Applications URL <span className="mandatory-start">*</span></td>
                                <td>
                                    <input type="text" data-key="url" data-required="true"
                                           data-validation-type={VALIDATION_TYPE.URL}
                                           className={"common-popup-input " + (errors.url ? "validation-error" : "")}
                                           onkeyup={this.onAddOrUpdateDataChange} oninput={this.onValueChange} value={app.url || ""}/>
                                    <p className={"error-text " + (errors.url ? "" : "hide")}>{(errors.url && errors.url.message) || "Please enter a valid URL"}</p>
                                    <p className={"error-text " + (errors.serverError ? "" : "hide")}>Something went wrong! Please try again.</p>
                                </td>
                            </tr>
                            <tr className={R.isEmpty(apps) || !newApp ? "hide":""}>
                                <td>Insert Below</td>
                                <td>
                                    <div key={new Date().getTime()} className="ui dropdown item common-popup-dropdown" tabindex="0">
                                        {appId?apps[appIndex].name:"None"}
                                        <i className="dropdown icon"></i>
                                        <div className="menu" tabindex="-1">
                                            <div className={"item " + (appId? "" : "active")} onclick={this.updateAppSequence}>None</div>
                                            {R.map(app => <div data-app-id = {app.id} className={"item " + (appId === app.id? "active" : "")} onclick={this.updateAppSequence}>{app.name}</div>, this.state.apps)}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="actions">
                        <div id="addOrUpdateAppAction"
                             className={"ui primary button buttons-primary-text buttons-primary-background " + (this.state.loading ? "loading disabled" : "")}
                             onclick={this.addOrUpdateApp}>{newApp ? "Add" : "Update"} Application
                        </div>
                        <div className="ui cancel button buttons-secondary-text buttons-secondary-background"
                             onclick={this.closeAddOrUpdateAppPopup}>Cancel
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    addOrUpdateApp = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let errors = validateForm($form);
        console.log("errors" + JSON.stringify(errors));
        let app = serializeForm($form);
        let apps = R.clone(this.state.apps);
        if (errors && !R.isEmpty(errors)) {
            return this.setState({errors})
                .then(_ => this.updateAddOrUpdateAppPopup(app));
        }
        let appURLs = R.map(app => app.url, this.state.apps);
        if (app.id) {
            let existing = this.getAppById(app.id) || {};
            if(R.includes(app.url,R.reject(R.equals(existing.url),appURLs))) {
                return this.setState({errors:{url:{message:"App with this url already exists!"}}})
                    .then(_ => this.updateAddOrUpdateAppPopup(app));
            }
            app = R.mergeDeepLeft(app, existing);
            return this.setState({loading: true})
                .then(_ => this.updateAddOrUpdateAppPopup(app))
                .then(_ => this.updateStandardApp(app.id, app))
                .then(this.closeAddOrUpdateAppPopup)
                .catch(_ => this.setState({errors: {serverError: true}, loading: false})
                    .then(_ => this.updateAddOrUpdateAppPopup(app)));
        }
        if(R.includes(app.url,appURLs)) {
            return this.setState({errors:{url:{message:"App with this url already exists!"}}})
                .then(_ => this.updateAddOrUpdateAppPopup(app));
        }
        let appId = this.state.appId || "";
        let appIndex = R.findIndex(R.propEq('id',parseInt(appId)), apps);
        return this.setState({loading: true})
            .then(_ => this.updateAddOrUpdateAppPopup(app))
            .then(_ => createStandardApp(app))
            .then(app => {
                let apps = R.isEmpty(apps) || R.isEmpty(appId)? R.append(app, this.state.apps || []):R.insert(appIndex+1, app, this.state.apps || []);
                let appSequence = R.map(app => app.id, apps);
                let filteredApps = this.applySearch(apps);
                let params ={"appSequence": {"standardApps": appSequence}}
                return storeKeyValueInConfigAsObject(params)
                    .then(_ => this.setState({apps, filteredApps, errors: null, loading: false}))
                    .then(this.closeAddOrUpdateAppPopup);
            })
            .catch(_ => this.setState({errors: {serverError: true}, loading: false})
                .then(_ => this.updateAddOrUpdateAppPopup(app)));
    };

    updateStandardApp(appId, app) {
        return updateStandardApp(appId, app)
            .then(_ => {
                let index = R.findIndex(a => a.id === app.id, this.state.apps || []);
                let apps = R.update(index, app, this.state.apps || []);
                let filteredApps = this.applySearch(apps);
                return this.setState({apps, filteredApps});
            })
            .catch();
    }

    toggleEnableDisableState = (e) => {
        let app = R.clone(this.getAppFromEvent(e));
        if (app) {
            return this.setState({loading: true})
                .then(_ => ModalContainer.updateModalView("enable-disable-app-popup", this.getDisableAppPopupView(app)))
                .then(_ => {
                    app.enabled = !app.enabled;
                    return this.updateStandardApp(app.id, app)
                })
                .then(_ => this.setState({loading: false}))
                .then(_ => ModalContainer.removeModal("enable-disable-app-popup"))
                .catch(_ => this.setState({errors: {serverError: true}, loading: false})
                    .then(_ => ModalContainer.updateModalView("enable-disable-app-popup", this.getDisableAppPopupView(app))));
        }
    };

    saveSeamlessSignOnAdditionalSetupDetails = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let errors = validateForm($form);
        let seamlessSignOnConfig = serializeForm($form);
        let autoSubmitEnabled = this.state.autoSubmitEnabled || false;
        let seamlessSignOnEnabled = this.state.seamlessSignOnEnabled || false;
        let twoStepFlowEnabled = this.state.twoStepFlowEnabled || false;
        if(!autoSubmitEnabled) delete errors.submitSelector;
        if(!twoStepFlowEnabled) delete errors.userNameSubmitSelector;
        if(errors && !R.isEmpty(errors) && seamlessSignOnEnabled) {
            return this.setState({errors})
                .then(_ => {
                    let view = this.getSeamlessSignOnAdditionalSetupPopupView(seamlessSignOnConfig);
                    return ModalContainer.updateModalView('additional-setup-popup', view)
                        .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("additional-setup-popup")))
                });
        }
        let app = R.clone(this.state.app) || {};
        seamlessSignOnConfig.enabled = seamlessSignOnEnabled;
        seamlessSignOnConfig.autoSubmitEnabled = autoSubmitEnabled;
        seamlessSignOnConfig.twoStepFlowEnabled = twoStepFlowEnabled;
        let attributes = app.attributes || {};
        attributes.seamlessSignOnConfig = seamlessSignOnConfig;
        app.attributes = attributes;
        return this.setState({errors: null, loading: true})
            .then(_ => ModalContainer.updateModalView("additional-setup-popup", this.getSeamlessSignOnAdditionalSetupPopupView(seamlessSignOnConfig)))
            .then(_ => this.updateStandardApp(app.id, app))
            .then(_ => this.setState({loading: false}))
            .then(_ => ModalContainer.removeModal("additional-setup-popup"))
            .catch(_ => this.setState({errors: {seamlessSignOn : true}, loading: false})
                .then(_ => ModalContainer.updateModalView("additional-setup-popup", this.getSeamlessSignOnAdditionalSetupPopupView(seamlessSignOnConfig)))
                .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("additional-setup-popup")))
            );
    };

    positionTo(app, position){
        let apps = this.state.apps;
        let appSequence = this.state.appSequence;
        let appIndex = R.findIndex(R.propEq('id',app.id), apps);
        let temp = apps[appIndex];
        let otherAppIndex = (position === "up")? appIndex-1:appIndex+1;
        apps[appIndex] = apps[otherAppIndex];
        apps[otherAppIndex] = temp;
        appSequence = R.map(app => app.id, apps);
        let filteredApps = this.applySearch(apps);
        let params ={"appSequence": {"standardApps": appSequence}};
        return this.setState({apps, appSequence, filteredApps, dataLoaded: true})
            .then(_ => storeKeyValueInConfigAsObject(params));
    }

    deleteApp = (e) => {
        let appId = getAttrValueForEventFromClosest(e, 'data-id');
        let app = this.getAppById(appId);
        return this.setState({loading: true})
            .then(_ => this.updateDeleteAppConfirmation(app))
            .then(_ => deleteStandardApp(appId))
            .then(_ => {
                let apps = R.reject(a => a.id === appId, this.state.apps || []);
                let filteredApps = this.applySearch(apps);
                return this.setState({apps, filteredApps, loading: false})
                    .then(_ => ModalContainer.removeModal("delete-app-popup"));
            })
            .catch(_ => this.setState({errors: {serverError: true}, loading: false})
                .then(_ => this.updateDeleteAppConfirmation(app)));
    };

}