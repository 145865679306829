import React from "react";
import {
    deleteUserCredentialsForApp,
    getAllUserCredentialsForApp,
    updateUserCredentialsForApp
} from "../../service/tenant-service";
import {ROUTES} from "../../config/app-config";
import {goToRouteOnEvent, orderByAsc, prepareRESTURI} from "../../utils/generic-utils";
import {
    convertToMailToUrl,
    DATA_TYPE,
    getAttrValueForEventFromClosest, getClosestForEventWithAttribute, serializeForm, validateForm, VALIDATION_TYPE
} from "../../Backflipt_UI_Components/utils/generic-utils";
import ModalContainer from "../modal-container/modal-container-v2";
import TenantDetailUsers from "./tenant-detail-users";
import $ from "jquery";
import {formatDateTime} from "../../Backflipt_UI_Components/src/utils/date-utils";

const R = require('ramda');

const CATEGORIES = {
    ALL: "ALL",
    REGISTERED: "REGISTERED",
    UN_REGISTERED: "UN_REGISTERED"
};

export default class TenantDetailAppUserCredentials extends TenantDetailUsers {

    loadingView = <div className="loading-wrapper"><p>Loading...</p></div>;

    beforeRender() {
        this.loadDataAsync();
        return super.beforeRender();
    }

    loadDataAsync() {
        return getAllUserCredentialsForApp(this.props.tenant.id, this.props.app.id)
            .then(users => {
                 users = orderByAsc(users, 'firstName');
                let filteredUsers = this.applySearch(users);
                let registered = this.getRegisteredUsers(users);
                return this.setState({users, filteredUsers, registeredCount: registered.length, dataLoaded: true})
            });
    }

    getView() {
        return super.getView()
            .then(_ => {

                if(!this.state.dataLoaded) return this.loadingView;

                return (
                    <div className="application-user-management-wrapper">
                        {this.getBreadcrumbView()}
                        {this.getUsersView()}
                    </div>
                );
            });
    }

    getBreadcrumbView() {
        let route = prepareRESTURI(ROUTES.ADMIN_TENANT_DETAIL_APPS, {tenant: this.props.tenantPath});
        return (
            <div className="semi-tabs-common-wrapper">
                <div className="common-tab-button">
                    <span className="customer-back-btn" data-route={route} onclick={goToRouteOnEvent}>
                      <img src="assets/images/customer-back-btn.png" alt=""/>
                    </span>
                    <div className="customer-name-text">{this.props.app.name}</div>
                </div>
            </div>
        );
    }

    getUsersView() {
        let usersView = R.map(this.getUserView, this.state.users);
        if(this.state.dataLoaded) {
            if(!this.state.filteredUsers || R.isEmpty(this.state.filteredUsers)) usersView = this.getNoDataView();
            else usersView = R.map(t => this.getUserView(t), this.state.filteredUsers);
        } else usersView = this.getLoadingView();
        return (
            <div className="user-management-tab-content-wrapper application-management-wrapper">
                {this.getHeaderView()}
                <div className="common-table-wrapper">
                    <table className="ui single line table">
                        <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email Address</th>
                            <th>Last Login</th>
                            <th>Username</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody className="user-management-tbody">
                        {usersView}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    getHeaderView() {
        let users = this.state.users || [];
        let registeredCount = this.state.registeredCount || 0;
        let category = this.state.selectedCategory || CATEGORIES.ALL;
        let isAllActive = category === CATEGORIES.ALL;
        let isUnregisteredActive = category === CATEGORIES.UN_REGISTERED;
        let isRegisteredActive = category === CATEGORIES.REGISTERED;
        return (
            <div className="semi-tabs-common-wrapper">
                <div className="semi-tabs-wrapper">
                    <div className="ui horizontal list">
                        <div data-category={CATEGORIES.ALL}
                             className={"item " + (category === CATEGORIES.ALL ? "active" : "")}
                             onclick={this.onCategoryChange}>
                            <span id="all" className="semi-tabs-icon">
                              <img className="ui mini image"
                                   src={"assets/images/all-request-" + (isAllActive ? "white" : "color") + "-icon.png"}
                                   alt=""/>
                            </span>
                            <span className="content ">{users.length} All Users</span>
                        </div>
                        <div data-category={CATEGORIES.UN_REGISTERED}
                             className={"item " + (isUnregisteredActive ? "active" : "")}
                             onclick={this.onCategoryChange}>
                            <span className="semi-tabs-icon">
                                <img className="ui mini image"
                                     src={"assets/images/pending-" + (isUnregisteredActive ? "white" : "color") + "-icon.png"}
                                     alt=""/>
                            </span>

                            <span className="content">{users.length - registeredCount} Unregistered</span>
                        </div>
                        <div data-category={CATEGORIES.REGISTERED} className={"item " + (isRegisteredActive ? "active": "")} onclick={this.onCategoryChange}>
                            <span className="semi-tabs-icon">
                                <img className="ui mini image" src={"assets/images/approve-" + (isRegisteredActive ? "white" : "color") + "-icon.png"} alt=""/>
                            </span>
                            <span className="content">{registeredCount} Registered</span>
                        </div>
                    </div>
                </div>
                <div className="common-search-wrapper">
                    <div className="ui search">
                        <input className="common-search-input" type="text" placeholder="Search User" onkeyup={this.onSearch}/>
                        <img className="common-search-icon" src="assets/images/search-icon.png" alt=""/>
                    </div>
                </div>
            </div>
        );
    }

    applySearch(users, selectedCategory) {
        selectedCategory = selectedCategory || this.state.selectedCategory || CATEGORIES.ALL;
        let searchString = this.state.searchString;
        let filteredUsers = !searchString ? users : R.filter(a => {
            let inputKeys = R.map(k => k.toLowerCase(), R.flatten(R.map(key => key.split(" "), [a.firstName, a.lastName, a.emailId])));
            let searchKeys = R.map(k => k.toLowerCase(), searchString.split(" "));
            return R.find(input => {
                return R.find(sk => input.includes(sk), searchKeys);
            }, inputKeys);
        }, users || []);

        if(selectedCategory !== CATEGORIES.ALL) {
            filteredUsers = R.filter(u => u.registered === (selectedCategory === CATEGORIES.REGISTERED), filteredUsers);
        }

        return filteredUsers;
    }

    onCategoryChange = (e) => {
        let selectedCategory = getAttrValueForEventFromClosest(e, 'data-category');
        if(this.state.selectedCategory !== selectedCategory) {
            let filteredUsers = this.applySearch(this.state.users, selectedCategory);
            return this.setState({selectedCategory, filteredUsers});
        }
    };

    getUserView = (user) => {
        let mailLink = convertToMailToUrl(user.emailId);
            return (
                <tr data-id={user.id} data-type={DATA_TYPE.NUMBER} className={user.registered ? "yellow-bg" : ""}>
                    <td className="table-grey-text">{user.firstName}</td>
                    <td className="table-grey-text">{user.lastName}</td>
                    <td className="table-grey-text"><a className="link-text" href={mailLink}>{user.emailId}</a></td>
                    <td className="table-grey-text">{user.lastLoginTimestamp ? formatDateTime(user.lastLoginTimestamp, 'd mmm yyyy, h:MM TT') : "-"}</td>
                    <td className="table-grey-text">{user.userName}</td>
                    <td className="common-action-td">
                        <span className="common-action-tag link-text" onclick={this.showUpdateAppUserCredentialsPopup}>Edit Credentials</span>
                        <span className="common-action-tag link-text" onclick={this.showDeleteAppUserCredentialsPopup}>Delete Credentials</span>
                    </td>
                </tr>
            );
    };

    showUpdateAppUserCredentialsPopup = (e) => {
        let user = this.getUserFromEvent(e) || {};
        let view = this.getUpdateAppUserCredentialsPopupView(user);
        return ModalContainer.addModalView("update-app-user-credentials-popup", view)
            .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("update-app-user-credentials-popup")));
    };

    getUpdateAppUserCredentialsPopupView(user) {
        let showPassword = this.state.showPassword || false;
        let errors = this.state.errors || {};
        let tenantId = user.tenantId || this.props.tenant.id;
        return(
            <div data-form-id="update-app-user-credentials-form"
                 className="ui modal common-popup-wrapper add-user-popup edit-user-credential scrolling transition visible active"
                 style="display: block !important;">
                <div className="content">
                    <div className="header">Edit Credentials</div>

                    <input type="hidden" data-key="id" data-type={DATA_TYPE.NUMBER} value={user.id || ""}/>
                    <input type="hidden" data-key="tenantId" data-type={DATA_TYPE.NUMBER} value={tenantId}/>

                    <div className="description">
                        <table className="ui table">
                            <tbody>
                            <tr>
                                <td>User Name <span className="mandatory-start">*</span></td>
                                <td>
                                    <input data-key="userName"
                                           data-required="true"
                                           data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                           className={"common-popup-input " + (errors.userName ? "validation-error" : "")}
                                           onkeyup={this.onAddOrUpdateDataChange} type="text"
                                           value={user.userName || ""} oninput={this.onValueChange} autoFocus={true}/>
                                    <p className={"error-text " + (errors.userName ? "" : "hide")}>UserName cannot be
                                        empty</p>
                                </td>
                            </tr>
                            <tr>
                                <td>Password <span className="mandatory-start">*</span></td>
                                <td className="show-password-td">
                                    <input data-key="password"
                                           data-required="true"
                                           data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                           className={"common-popup-input " + (errors.password ? "validation-error" : "")}
                                           onkeyup={this.onAddOrUpdateDataChange} oninput={this.onValueChange} type={showPassword ? "text" : "password"} value={user.password || ""}/>
                                    <p className={"error-text " + (errors.password ? "" : "hide")}>Password cannot be
                                        empty</p>
                                    <i className={"eye slash icon toggle-icon " + (showPassword ? "hide" : "")}
                                       onclick={this.showOrHidePassword}/>
                                    <i className={"eye icon toggle-icon " + (showPassword ? "" : "hide")}
                                       onclick={this.showOrHidePassword}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p className={"error-text add-user-default-error " + (errors.serverError ? "" : "hide")}>{(errors.serverError && errors.serverError.description) || "Something went wrong! Please try again."}</p>
                    </div>
                    <div className="actions">
                        <div id="addOrUpdateAction"
                             className={"ui primary button buttons-primary-text buttons-primary-background " + (this.state.loading ? "loading disabled" : "")}
                             onclick={this.editCredentials}>Update
                        </div>
                        <div className="ui cancel button buttons-secondary-text buttons-secondary-background"
                             onclick={this.closeUpdateAppUserCredentialsPopup}>Cancel
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    showOrHidePassword = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let user = serializeForm($form);
        let showPassword = !(this.state.showPassword || false);
        return this.setState({showPassword})
            .then(_ => this.updateAddAppUserCredentialsPopup(user))
    };

    editCredentials = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let user = serializeForm($form);
        let errors = validateForm($form);
        let credentials = {userName: user.userName, password: user.password};
        if(errors && !R.isEmpty(errors)) {
            return this.setState({errors})
                .then(_ => this.updateAddAppUserCredentialsPopup(user))
        }
        return this.setState({loading: true, errors: null})
            .then(_ => this.updateAddAppUserCredentialsPopup(user))
            .then(_ => updateUserCredentialsForApp(user.tenantId, this.props.app.id, user.id, credentials))
            .then(_ => {
                let users = R.clone(this.state.users) || {};
                let userIndex = R.findIndex(R.propEq('id',user.id), users);
                users[userIndex] = R.mergeDeepRight(users[userIndex], user);
                users[userIndex].userName = user.userName;
                users[userIndex].password = user.password;
                let filteredUsers = this.applySearch(users);
                return this.setState({users, filteredUsers});
            })
            .then(_ => ModalContainer.removeModal('update-app-user-credentials-popup'))
            .then(_ => this.setState({loading: false, showPassword: false}))

    };

    updateAddAppUserCredentialsPopup = (user) => {
        let view = this.getUpdateAppUserCredentialsPopupView(user);
        return ModalContainer.updateModalView('update-app-user-credentials-popup', view)
            .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("update-app-user-credentials-popup")));
    };

    closeUpdateAppUserCredentialsPopup = (e) => {
        return ModalContainer.removeModal('update-app-user-credentials-popup')
            .then(_ => this.setState({errors: null, loading: false, showPassword: false}));
    };

    showDeleteAppUserCredentialsPopup = (e) => {
        let user = this.getUserFromEvent(e) || {};
        let view = this.deleteAppUserCredentialsPopup(user);
        return ModalContainer.addModalView("delete-app-user-credentials-popup", view)
            .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("delete-app-user-credentials-popup")));
    };

    deleteAppUserCredentialsPopup(user) {
        return (
            <div data-id={user.id} data-type={DATA_TYPE.NUMBER}
                 className="ui modal common-popup-wrapper delete-user-popup scrolling transition visible active"
                 style="display: block !important;">
                <div className="content">
                    <div className="secondary-header">Are you sure you want to Delete this User Credentials?</div>
                    <div className="actions">
                        <div
                            className={"ui primary button buttons-primary-text buttons-primary-background " + (this.state.loading ? "loading disabled" : "")}
                            onclick={this.deleteCredentials}>Yes
                        </div>
                        <div className="ui cancel button buttons-secondary-text buttons-secondary-background"
                             onclick={this.closeDeleteAppUserCredentialsPopup}>No
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    deleteCredentials = (e) => {
        let id = getAttrValueForEventFromClosest(e, 'data-id');
        let user = this.getUserById(id);
        return this.setState({loading: true, errors: null})
            .then(_ => this.updateDeleteAppUserCredentialsPopup(user))
            .then(_ => deleteUserCredentialsForApp(this.props.tenant.id, this.props.app.id, id))
            .then(_ => {
                let users = R.clone(this.state.users) || {};
                let userIndex = R.findIndex(R.propEq('id',user.id), users);
                delete users[userIndex].userName;
                delete users[userIndex].password;
                let filteredUsers = this.applySearch(users);
                return this.setState({users, filteredUsers});
            })
            .then(_ => ModalContainer.removeModal('delete-app-user-credentials-popup'))
            .then(_ => this.setState({loading: false}))
    };

    closeDeleteAppUserCredentialsPopup = (e) => {
        return ModalContainer.removeModal('delete-app-user-credentials-popup')
            .then(_ => this.setState({errors: null, loading: false}));
    };

    updateDeleteAppUserCredentialsPopup = (user) => {
        let view = this.deleteAppUserCredentialsPopup(user);
        return ModalContainer.updateModalView('delete-app-user-credentials-popup', view)
            .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("delete-app-user-credentials-popup")));
    };

    onValueChange = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let user = serializeForm($form);
        let key = getAttrValueForEventFromClosest(e, 'data-key');
        let errors = R.clone(this.state.errors) || {};
        delete errors[key];
        return this.setState({errors})
            .then(_ => this.updateAddAppUserCredentialsPopup(user))
            .then(_ => $(e.target).focus());
    };
}