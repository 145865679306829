import * as HttpUtils from '../utils/http-utils';
import Endpoints from '../config/endpoints'

export let setValueToStore = (key, value) => localStorage.setItem(key, value);

export let getValueFromStore = (key) => localStorage.getItem(key);

export let storeKeyValueInConfig = (data) => HttpUtils.postFormUrlEncoded(Endpoints.STORE_KEY_VALUE, data);

export let storeKeyValueInConfigAsObject = (data) => HttpUtils.postData(Endpoints.STORE_KEY_VALUE, data);

export let getValueForKeyInConfig = (key) => HttpUtils.getResponseAsString(Endpoints.GET_VALUE_FOR_KEY(key));

//export let getValueForKeyInConfig = (key) => HttpUtils.getData(Endpoints.GET_VALUE_FOR_KEY(key));

export let getAllValuesforKeysInConfig = (keys) => HttpUtils.getData(Endpoints.GET_ALL_VALUES_FOR_KEYS(keys));