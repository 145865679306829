export const CONTENT_TEMPLATES = [
    {
        "id": "team-members",
        "name": "Team Members"
    },
    {
        "id": "rss-feed",
        "name": "RSS Feed"
    }
];

export const TEMPLATE_TYPE = {
    STATIC: "static",
    DYNAMIC: "dynamic"
};

export const DEFAULT_IMAGE = {
    TEAM_MEMBERS: "assets/images/default-team-members-profile-pic.png",
    FEED_LOGO: "assets/images/feed-logo.png"
};

export const SOURCE_TYPE = {
    PRE_CONFIGURED_FEED: "PRE_CONFIGURED_FEED",
    URL: "URL",
    APP_FEED: "APP_FEED"
};

export const RSS_FEEDS = {
    KREBSON_SECURITY: "KREBSON_SECURITY",
    FEED_BURNER: "FEED_BURNER",
    SECURITY_WIZARD: "SECURITY_WIZARD"
};
