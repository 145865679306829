import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import React from 'react'
import {
    convertToMailToUrl,
    convertToUrl,
    getAttrValueForEventFromClosest, mapIndexed
} from '../../Backflipt_UI_Components/utils/generic-utils'
import {getCommonFeed} from "../../service/tenant-service";
import {ERRORS} from "../../config/errors";
import {getSupportMail} from "../../utils/domain-utils";

const R = require('ramda');

export default class CommonFeed extends UIBaseComponent {

    beforeRender () {
        this.loadDataAsync();
        return super.beforeRender();
    }

    loadDataAsync () {
        return getCommonFeed()
            .then(feedDetails => {
                let feedTitle = feedDetails.title;
                let feed = feedDetails.feed;
                return this.setState({ feedTitle, feed, dataLoaded: true });})
            .catch(resp => {
                let feedTitle = "Trending Security News";
                let error = (resp && resp.responseJSON) || {};
                let errorDescription = ERRORS.FEED[error.code];

                if (error.code === "NO_RESPONSE") {
                    return this.setState({
                        feedTitle,
                        dataLoaded: true,
                        errors: {invalidURL: true}
                    });
                }
                if (error.code === "INVALID_JSON") {
                    return this.setState({
                        feedTitle,
                        dataLoaded: true,
                        errors: {invalidJSON: true}
                    });
                }
                return this.setState({feedTitle, dataLoaded: true, errors: {feedError: errorDescription}})
            })
    }

    getNoDataView () {
        let errors = this.state.errors || {};
        let feedErrorText = errors.feedError || "";

        let supportMail = getSupportMail();
        let mailLink = convertToMailToUrl(supportMail);

        if(errors.invalidURL)
            return (
              <div>There is no response from the server. Please try again or contact support at <a href={mailLink}>{supportMail}</a></div>
            );
        if (errors.invalidJSON)
            return (
                <div>Invalid Json format detected. Please contact support at <a href={mailLink}>{supportMail}</a></div>
            );
        return (
            <div>{feedErrorText}</div>
        )
    }

    getLoadingView () {
        return (
            <div className="tenant-loading-wrapper">Loading...</div>
        )
    }

    getRatingView (rating = '0') {
        rating = parseFloat(rating);
        let filled = R.map(_ => <img src="assets/images/filled-star.svg" alt=""/>, R.range(1, Math.floor(rating + 1)));
        if (Math.floor(rating) < rating) filled.push(<img src="assets/images/half-filled-star.svg" alt=""/>);
        filled = R.concat(filled, R.map(_ => <img src="assets/images/empty-star.svg" alt=""/>, R.range(1, Math.floor(6 - rating))));
        return filled;
    }

    getFeedView (feed) {
        let feedAuthorLogoError = this.state.feedAuthorLogoError || {};
        let feedAuthorLogoUrl = feedAuthorLogoError[feed.id] ? null : feed.authorPhotoUrl;

        let feedReadMoreHyperLink = feed.readMoreHyperlink || "";
        let feedSourceLogoError = this.state.feedSourceLogoError || {};
        let feedSourceLogoUrl = feedSourceLogoError[feed.id] ? null : feed.logoUrl;
        let showFeedUser = !!(feedAuthorLogoUrl || feed.author);
        let feedImageError = this.state.feedImageError || {};
        let feedImageUrl = feedImageError[feed.id] ? null : feed.imageUrl;
        return (
            <div data-feed-id={feed.id}>
                <div className="ics-feeds-wrapper">
                    <div className="commom-card-wrapper">
                        <div className="ics-announcement-common-text-wrapper">
                            <h3 className="ics-feeds-header primary-text">{feed.title}</h3>
                        </div>
                    </div>
                    <div className="ics-announcement-common-text-wrapper">
                        <p className={'ics-feeds-time ' + (feed.lastUpdatedTime ? '' : 'hide')}>{feed.lastUpdatedTime}</p>
                        <div className={"ui star rating " + (feed.rating? "" : "hide")}>
                            {this.getRatingView(feed.rating)}
                        </div>
                    </div>
                    <div className={"feed-user " + (showFeedUser? "" : "hide")}>
                        <img className={"ui avatar image " + (feedAuthorLogoUrl? "" : "hide")} src={feedAuthorLogoUrl}
                             onerror={this.onFeedAuthorLogoLoadError} alt=""/>
                        <span>{feed.author}</span>
                    </div>
                    <div className="ics-feeds-details">
                        {feed.description}
                    </div>
                    <div className={"feed-img " + (feedImageUrl ? '' : 'hide')}>
                        <img src={feedImageUrl} onerror={this.onFeedImageLoadError} alt=""/>
                    </div>
                    <div className="ics-feeds-source-link">
                        <img className={feedSourceLogoUrl ? '' : 'hide'} src={feedSourceLogoUrl} onerror={this.onFeedSourceLogoLoadError} alt=""/>
                        <a className={'link-text ' + (feed.readMoreHyperlink? '' : 'hide')}
                           href={convertToUrl(feedReadMoreHyperLink)} target="_blank">Read more</a>
                    </div>
                </div>
                <div className="ui divider"/>
            </div>
        );
    }

    /*
        trimFeedDescription (description, descMaxLength) {
        return description.length > descMaxLength ? description.substring(0, descMaxLength ) + '...' : description;
    }*/

    onFeedAuthorLogoLoadError = (e) => {
        //let imageUrl = e.target.getAttribute("src");
        let feedId = getAttrValueForEventFromClosest(e, 'data-feed-id');
        let feedAuthorLogoError = R.clone(this.state.feedAuthorLogoError || {});
        feedAuthorLogoError[feedId] = true;
        return this.setState({feedAuthorLogoError});
    };

    onFeedSourceLogoLoadError = (e) => {
        //let imageUrl = e.target.getAttribute("src");
        let feedId = getAttrValueForEventFromClosest(e, 'data-feed-id');
        let feedSourceLogoError = R.clone(this.state.feedSourceLogoError || {});
        feedSourceLogoError[feedId] = true;
        return this.setState({feedSourceLogoError});
    };

    onFeedImageLoadError = (e) => {
        //let imageUrl = e.target.getAttribute("src");
        let feedId = getAttrValueForEventFromClosest(e, 'data-feed-id');
        let feedImageError = R.clone(this.state.feedImageError || {});
        feedImageError[feedId] = true;
        return this.setState({feedImageError});
    };

    getHeaderView () {
        let feedTitle = this.state.feedTitle || "";
        return(
            <span className="ics-common-header-text primary-text">{feedTitle}</span>
        );
    }

    getView () {
        return super.getView()
            .then(_ => {
                let FeedView = null;
                if (this.state.dataLoaded) {
                    if (!this.state.feed || R.isEmpty(this.state.feed)) FeedView = this.getNoDataView();
                    else {
                        let feed = this.state.feed;
                        feed = mapIndexed((feed, index) => {
                                feed.id = index;
                                return feed;
                            }, feed);
                        FeedView = R.map(t => this.getFeedView(t), feed);
                    }
                } else FeedView = this.getLoadingView();
                return (
                    <div className="tenent-stats-wrapper">
                        <div className="ics-announcements-first-section">
                            <div className="ics-announcements-common-header">
                                <span className="ics-common-header-img">
                                    <img src="assets/images/ics-announcement-icon.png " alt=""/>
                                </span>
                                {this.getHeaderView()}
                            </div>
                            <div className="stats-feed-details-wrapper">
                                {FeedView}
                            </div>
                        </div>
                    </div>
                );
            });
    }
}