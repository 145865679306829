import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import { ROUTES } from '../../config/app-config'
import React from 'react'
import { goToRouteOnEvent } from '../../utils/generic-utils'
import {getClientAppName, getLogoUrl, getTitle } from '../../utils/domain-utils'

export default class SuccessView extends UIBaseComponent {
    getView() {
        return super.getView()
            .then(_ => {
                    let title = getTitle();
                    let clientAppName = getClientAppName();
                    let formLogo = getLogoUrl();
                    return (
                        <div className="ics-form-wrapper successful-error-wrapper">
                            <div className="ics-common-wrapper">
                                <div className="form-common-header-wrapper">
                                    <img src={formLogo} className="ics-form-logo" alt=""/>
                                </div>
                                <div className="ics-common-body-wrapper">
                                    <div className="success-error-img-wrapper">
                                        <img src="assets/images/login-succes-icon.png" alt=""/>
                                        <img src="assets/images/login-error-icon.png" className="hide" alt=""/>
                                    </div>
                                    <h3 className="form-secondary-header-text">{"Your password has been reset. Please use the new password in " + title + " client app to access " + clientAppName + " portal."}</h3>
                                </div>
                                <div className="common-form-btn-wrapper hide">
                                    <button className="ui right labeled icon button" data-route={ROUTES.SIGN_IN} onclick={goToRouteOnEvent}>
                                        <i className="right arrow icon"></i>
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                    );
                }
            );
    };
}