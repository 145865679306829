import TenantDetailGUIAdmin from './tenant-detail-gui.admin'
import { updateMyTenant } from '../../service/tenant-service'
import { getMyTenantDetails, setMyTenantDetails } from '../../dao/user-profile-dao'
import { reloadCurrentRoute } from '../../utils/generic-utils'

const R = require('ramda');

export default class TenantDetailGUITenant extends TenantDetailGUIAdmin {

  saveAttributes = () => {
    return this.setState({loading: true})
        .then(this.uploadLogo)
        .then(logoAttributes => {
          let tenant = R.clone(this.state.tenant);
          let attributes = tenant.attributes || {};
          attributes.logo = logoAttributes;
          tenant.attributes = attributes;
          if(!R.equals(this.state.tenant, this.state.originalTenant)) {
            return updateMyTenant(tenant);
          }
          setMyTenantDetails(tenant);
          return tenant;
        })
        .then(tenant => this.setState({logoFile: null, loading: false, originalTenant: tenant, tenant}))
        .then(_ => reloadCurrentRoute());
  };

  loadDataAsync() {
    let tenant = getMyTenantDetails();
    return this.setState({originalTenant: tenant, tenant});
  }
}