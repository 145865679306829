import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import React from 'react'
import {
    convertToMailToUrl,
    getAttrValueForEventFromClosest,
    getClosestForEventWithAttribute, getValueForPathOrDefault, serializeForm,
    validateForm,
    VALIDATION_TYPE
} from '../../Backflipt_UI_Components/utils/generic-utils'
import {
    getCountryCodes,
    getRegisterUserDetails,
    registerUser,
    sendRegistrationOTP,
    validateRegistrationOTP
} from '../../service/user-service'
import Endpoints from '../../config/endpoints'
import {ERRORS} from "../../config/errors";
import {getAdminMail, getClientAppLogoUrl, getClientAppName, getLogoUrl, getTitle} from '../../utils/domain-utils'
import $ from "jquery";
import {DEFAULT_COUNTRY_CODE} from "../../config/app-config";
import BlueBird from 'bluebird';

const OS = window.ui && window.ui.os;

const OS_CONFIG = {'Mac OS X': 'dmg', 'Windows': 'exe'};

const FILE_TYPE = OS_CONFIG[OS];

const R = require('ramda');

export default class UserRegistration extends UIBaseComponent {

    beforeRender () {
        this.state = {
            stage: 1
        };
        this.loadDataAsync();
        return super.beforeRender();
    }

    loadDataAsync() {
        return BlueBird.all([getRegisterUserDetails(this.props.token), getCountryCodes()])
            .then(resp => {
                let regDetails = resp[0];
                let countryCodes = resp[1];
                let userDetails = regDetails.user;
                let twoFactorAuthEnabled = regDetails.twoFactorAuthEnabled;
                return this.setState({userDetails, twoFactorAuthEnabled, countryCodes, dataLoaded: true});
            })
            .catch(resp => {
                let error = (resp && resp.responseJSON) || {};
                if (ERRORS.CONTACT.ADMIN.includes(error.code)) {
                    return this.setState({dataLoaded: true, errors: {tokenError: true, admin: true}});
                }
                if (error.code === "USER_NOT_FOUND") {
                    return this.setState({dataLoaded: true, errors: {tokenError: true, userNotFound: true}})
                }
                error.description = error.description || ERRORS.REGISTRATION[error.code] || ERRORS.REGISTRATION.GENERIC;
                return this.setState({dataLoaded: true, errors: {tokenError: error}});
            });
    }

    getUserRegistrationView () {
        let ud = this.state.userDetails || {};
        let errors = this.state.errors || {};

        // let adminMail = getAdminMail();
        // let mailLink = convertToMailToUrl(adminMail);
        //
        // let tokenErrorDesc = getValueForPathOrDefault(errors, "tokenError.description", ERRORS.REGISTRATION.GENERIC);

        let formLogo = getLogoUrl();

        let appFormLogo = getClientAppLogoUrl();

        let phoneCode = this.state.phoneCode || (ud.mobileNumber && ud.mobileNumber.code) || DEFAULT_COUNTRY_CODE;

        return (
            <div data-form-id="sign-up-form" className="ics-form-main-container">
                <div className="ics-form-wrapper sign-up-wrapper">
                    <div className="ics-common-wrapper">
                        <div className="form-common-header-wrapper">
                            <img src={formLogo} className="ics-form-logo"  alt=""/>
                            <span className="ics-form-header-pipeline" />
                            <img src={appFormLogo} className="ics360-form-logo" alt=""/>
                            <img src="assets/images/@.png" className="at-logo hide" alt=""/>
                            <span className="ics-form-header-pipeline" />
                            <span className="form-header-text"> <h3>Sign Up</h3></span>
                        </div>
                        {this.getError()}
                        <div className={"ics-common-body-wrapper " + (errors.tokenError ? "hide" : "")}>
                            <h3 className="form-secondary-header-text">Register to get access to your reporting
                                dashboard</h3>
                            <div className="signup-multiple-inputs-wrapper">
                                <div className="first-input-wrapper">
                                            <span className="first-label">
                                                <label>First Name<span className="mandatory-start">*</span></label>
                                            </span>
                                    <span className="first-input">
                                                <input type="text" data-key="firstName" data-required="true"
                                                       data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                                       value={ud.firstName} onkeyup={this.onDataChange}
                                                       oninput={this.onValueChange}
                                                       autofocus={true}/>
                                                <p className={"error-text " + (errors.firstName ? "" : "hide")}>First Name is mandatory</p>
                                            </span>
                                </div>
                                <div className="second-input-wrapper">
                                              <span className="second-label">
                                                <label>Last Name</label>
                                              </span>
                                    <span className="second-input">
                                                <input type="text" data-key="lastName" value={ud.lastName}
                                                       onkeyup={this.onDataChange} oninput={this.onValueChange}/>
                                              </span>
                                </div>
                            </div>
                            <div className="common-input-label-wrapper">
                                <div className="ui form">
                                    <div className="inline field">
                                        <label>Password<span className="mandatory-start">*</span></label>
                                        <input name="password" type="password" data-key="password"
                                               data-required="true"
                                               data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                               onkeyup={this.onDataChange} oninput={this.onValueChange}/>
                                        <p className={"error-text " + (errors.password ? "" : "hide")}>Password
                                            is mandatory</p>
                                    </div>
                                </div>
                            </div>
                            <div className="common-input-label-wrapper">
                                <div className="ui form">
                                    <div className="inline field">
                                        <label>Confirm Password<span
                                            className="mandatory-start">*</span></label>
                                        <input name="confirmPassword" type="password" data-key="confirmPassword"
                                               data-required="true"
                                               data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                               onkeyup={this.onDataChange} oninput={this.onValueChange}/>
                                        <p className={"error-text " + (errors.confirmPassword ? "" : "hide")}>Passwords
                                            didn't
                                            match.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="common-input-label-wrapper">
                                <div className="ui form">
                                    <div className="inline field">
                                        <label>Email Address </label>
                                        <input name="emailId" type="text" disabled={true} value={ud.emailId}/>
                                    </div>
                                </div>
                            </div>
                            <div className="common-input-label-wrapper">
                                <div className="ui form">
                                    <div className="inline field mobile-number-wrapper">
                                        <label>Mobile Number <span
                                            className={"mandatory-start " + (this.state.twoFactorAuthEnabled ? "" : "hide")}>*</span></label>
                                        <input type="hidden" data-key="mobileNumber.code" value={phoneCode}/>
                                        <div key={new Date().getTime()} className="ui search selection dropdown">
                                            <div className="text">{phoneCode}</div>
                                            <i className ="dropdown icon"/>
                                            <div className="menu">
                                                {this.showCountryCodes()}
                                            </div>
                                        </div>
                                        <input name="mobileNumber" type="text" data-key="mobileNumber.number" pattern="[0-9]*"
                                               data-required={this.state.twoFactorAuthEnabled ? "true" : "false"}
                                               data-validation-type={VALIDATION_TYPE.NUMBER}
                                               value={(ud.mobileNumber && ud.mobileNumber.number) || ""}
                                               onkeyup={this.onDataChange} oninput={this.onValueChange}/>
                                        <p className={"error-text " + ((errors.mobileNumber && errors.mobileNumber.number)? "" : "hide")}>Please
                                            enter a valid mobile number.</p>
                                    </div>
                                </div>
                            </div>
                            <p className={"error-text " + (errors.serverError ? "" : "hide")}>{(errors.serverError && errors.serverError.description) || "Something went wrong! Please try again."}</p>
                            <div className="common-form-btn-wrapper">
                                <div className="reaptcha-wrapper hide">
                                    <img src="assets/images/ics-reg-recaptcha-img.png" alt=""/>
                                </div>
                                <button id="sign-up-button" onclick={this.register}
                                        className={"ui right labeled icon button " + (this.state.loading ? "loading disabled" : "")}>
                                    <i className="right arrow icon"/>
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getMobileNumberVerificationView() {
        let errors = this.state.errors || {};
        let resendOTP = R.isEmpty(errors) && this.state.resendOTP;
        let mobileNumber = String(this.state.mobileNumber);
        let maskedMobileNumber = mobileNumber.substr(mobileNumber.length - 4, mobileNumber.length - 1);
        let tokenErrorDesc = getValueForPathOrDefault(errors, "tokenError.description", ERRORS.REGISTRATION.GENERIC);

        let formLogo = getLogoUrl();

        let appFormLogo = getClientAppLogoUrl();

        return (
            <div data-form-id="sign-up-form" className="ics-form-wrapper mobile-verification-code">
                    <div className="ics-common-wrapper">
                        <div className="form-common-header-wrapper">
                            <img src={formLogo} className="ics-form-logo"  alt=""/>
                            <span className="ics-form-header-pipeline" />
                            <img src={appFormLogo} className="ics360-form-logo hide" alt=""/>
                            <span className="ics-form-header-pipeline hide" />
                            <span className="form-header-text"> <h3>Mobile Verification Code</h3></span>
                        </div>
                        <p className={"error-text invalid-registration-error " + (errors.tokenError ? "" : "hide")}>{tokenErrorDesc}</p>
                        <div className={"ics-common-body-wrapper " + (errors.tokenError ? "hide" : "")}>
                            <h3 className="form-secondary-header-text">{"Please enter the verification code sent to your mobile number ending in " + maskedMobileNumber}</h3>
                                <div className="common-input-label-wrapper">
                                    <div className="ui form">
                                        <div className="inline field">
                                            <label>Verification Code</label>
                                            <input data-key="otp" type="text" data-required="true"
                                                   data-validation-type={VALIDATION_TYPE.NON_EMPTY}
                                                   className={"common-popup-input " + (errors.otp ? "validation-error" : "")}
                                                   onkeyup={this.onDataChange} autofocus={true}
                                                   oninput={this.onValueChange}/>
                                            <p className={"error-text " + (errors.otp ? "" : "hide")}>{(errors.otp && errors.otp.description) || "Please enter valid OTP."}</p>
                                            <p className={"otp-sent " + (resendOTP ? "" : "hide")}>OTP has been Resent.</p>
                                        </div>
                                    </div>
                                </div>
                            <div className="common-form-btn-wrapper">
                                <button id="back-button" onclick={this.goBackToPrevStage}
                                        className="ui left labeled icon button">
                                    <i className="left arrow icon"/>
                                    Back
                                </button>
                                <button id="sign-up-button" onclick={this.register}
                                        className={"ui right labeled icon button " + (this.state.loading ? "loading disabled" : "")}>
                                    <i className="right arrow icon"/>
                                    Verify
                                </button>
                            </div>
                        </div>
                        <div className={"common-form-divider " + (errors.tokenError ? "hide" : "")}>
                            <div className="ui divider"></div>
                        </div>
                        <div className={"form-common-bottom-wrapper " + (errors.tokenError ? "hide" : "")}>
                            <div className="form-bottom-right-wrapper">
                                <span className="bottom-common-anchor-tag">
                                    <a onclick={this.resendOTP}>Resend OTP</a></span>
                            </div>
                        </div>
                    </div>
            </div>
        );
    }

    resendOTP = (e) => {
        let user = this.state.userFormDetails;
        return this.setState({errors: null, resendOTP: false})
            .then(this.applyAutoFocus)
            .then(_ => sendRegistrationOTP(this.props.token, user))
            .then(_ => this.setState({resendOTP: true}))
            .catch(resp => {
                let error = (resp && resp.responseJSON) || {};
                error.description = error.description || ERRORS.REGISTRATION[error.code] || ERRORS.REGISTRATION.GENERIC;
                if(error.code === "TOKEN_ERROR_EXPIRED")
                    return this.setState({errors: {tokenError: error}});
                return this.setState({errors: {otp: error}});
            });
    };

    goBackToPrevStage = (e) => {
        return this.setState({stage: 1, errors: null});
    };

    getStageView() {
        if(this.state.stage === 1) return this.getUserRegistrationView();
        else return this.getMobileNumberVerificationView();
    }

    getView () {
        return super.getView()
            .then(_ => {

                if(this.state.unsupportedOS) return this.getErrorView();

                if(!this.state.dataLoaded) return this.getLoadingView();

                if(this.state.registered) return this.getSuccessView();

                // let ud = this.state.userDetails || {};
                // let errors = this.state.errors || {};

                return this.getStageView();

            });
    }

    getError() {
        let errors = this.state.errors || {};
        let tokenErrorDesc = getValueForPathOrDefault(errors, "tokenError.description", ERRORS.REGISTRATION.GENERIC);

        let adminMail = getAdminMail();
        let mailLink = convertToMailToUrl(adminMail);

        if (errors.admin) {
            return <p className={"error-text invalid-registration-error " + (errors.tokenError ? "" : "hide")}>Your registration link has expired. Please contact your administrator at <a href={mailLink}>{adminMail}</a> to resend the link.</p> }
        else if (errors.userNotFound) {
            return <p className={"error-text invalid-registration-error " + (errors.tokenError ? "" : "hide")}>Invalid User Registration! Please contact your administrator at <a href={mailLink}>{adminMail}</a> to resend the link.</p> }
        else {
            return <p className={"error-text invalid-registration-error " + (errors.tokenError ? "" : "hide")}>{tokenErrorDesc}</p>}
    }

    showCountryCodes() {
        let selectedPhoneCode = this.state.phoneCode || DEFAULT_COUNTRY_CODE;
        let countryCodes = this.state.countryCodes || {};
        return(
            R.map(countryAndCode => {
                let country = countryAndCode["country"];
                let code = countryAndCode["phoneCode"];
                return <div className={"item " + (selectedPhoneCode === code? "active": "")} data-code={code} data-value={country} data-text={code} onclick={this.onCountryChange}>{country}<span className="country-code">{code}</span></div>
            }, countryCodes)
        );
    }

    onCountryChange = (e) => {
        let selectedPhoneCode = getAttrValueForEventFromClosest(e, 'data-code');
        let phoneCode = this.state.phoneCode || "";
        if(selectedPhoneCode !== phoneCode) {
            setTimeout(() => {
                return this.setState({phoneCode: selectedPhoneCode})
                    .then(_ => this.$container.find('.ui.dropdown').dropdown());
            }, 100);
        }
    };

    onDataChange = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            getClosestForEventWithAttribute(e, 'data-form-id')
                .find('#sign-up-button')
                .click();
        }
    };

    onValueChange = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let user = serializeForm($form);
        let key = getAttrValueForEventFromClosest(e, 'data-key');
        let errors = R.clone(this.state.errors) || {};
        if(key === "mobileNumber.number" && !e.target.validity.valid)
        {
            let mobileNumber = user.mobileNumber;
            let number = user.mobileNumber && user.mobileNumber.number;
            mobileNumber.number = number.slice(0, -1);
            user.mobileNumber = mobileNumber;
            delete errors.mobileNumber;
        }
        let userDetails = R.clone(this.state.userDetails) || {};
        userDetails = R.mergeDeepRight(userDetails, user);
        if(key !== "mobileNumber.number") delete errors[key];
        return this.setState({errors, userDetails}, true)
            .then(_ => $(e.target).focus());
    };

    register = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let errors = validateForm($form) || {};
        if(this.state.stage === 1) {
            let user = serializeForm($form);
            user.mobileNumber = !R.any(x => R.isEmpty(x), R.values(user.mobileNumber))? user.mobileNumber : null;
            let userDetails = R.clone(this.state.userDetails);
            userDetails = R.mergeDeepRight(userDetails, user);
            if(user.password !== user.confirmPassword) errors.confirmPassword = true;
            if(!R.isEmpty(errors)) return this.setState({errors, userDetails});
            return this.setState({loading: true, userDetails, userFormDetails: user})
                .then(_ => {
                    if (!user.mobileNumber)
                        return registerUser(this.props.token, user)
                            .then(_ => this.setState({registered: true}));
                    return sendRegistrationOTP(this.props.token, user)
                        .then(_ => this.setState({errors: null, mobileNumber: (user.mobileNumber && user.mobileNumber.number), stage: 2, loading: false}));
                })
                .catch(resp => {
                    let error = (resp && resp.responseJSON) || {};
                    error.description = error.description || ERRORS.REGISTRATION[error.code] || ERRORS.MOBILE_NO_VERIFICATION[error.code] ||ERRORS.REGISTRATION.GENERIC;
                    if(error.code === "TOKEN_ERROR_EXPIRED")
                        return this.setState({errors:{tokenError: error}, loading: false});
                    return this.setState({errors: {serverError: error}, loading: false})
                });
        }
        else {
            if(!R.isEmpty(errors)) return this.setState({errors});
            let user = this.state.userFormDetails || {};
            let data = serializeForm($form);
            let otp = data.otp;
            return this.setState({loading: true})
                .then(_ => validateRegistrationOTP(this.props.token, otp, user))
                .then(_ => this.setState({registered: true, loading: false}))
                .catch(resp => {
                    let error = (resp && resp.responseJSON) || {};
                    error.description = error.description || ERRORS.REGISTRATION[error.code] || ERRORS.MOBILE_NO_VERIFICATION[error.code] || ERRORS.COMMON;
                    if(error.code === "TOKEN_ERROR_EXPIRED")
                      return this.setState({loading: false, errors: {tokenError: error}});
                    return this.setState({loading: false, errors: {otp: error}});
                })
        }

    };

    getLoadingView() {
        return (
            <div className="main-loading-text">
                Loading...
            </div>
        )
    }

    getSuccessView() {
        let title = getTitle();
        let clientAppName = getClientAppName();
        let formLogo = getLogoUrl();
        return (
            <div className="ics-form-main-container">
                <div className="ics-form-wrapper successful-error-wrapper download-app">
                    <div className="ics-common-wrapper">
                        <div className="form-common-header-wrapper">
                            <img src={formLogo} className="ics-form-logo" alt=""/>
                        </div>
                        <div className="ics-common-body-wrapper">
                            <div className="success-error-img-wrapper">
                                <img src="assets/images/login-succes-icon.png" alt=""/>
                            </div>
                            <h3 className="form-secondary-header-text">{"Registration successful. Please download the " + title + " client app to access the " + clientAppName + " portal."}</h3>
                        </div>
                        <div className="common-form-btn-wrapper">
                            <button className="ui right labeled icon button" onclick={() => this.downloadApp()}>
                                Download
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    downloadApp = () => {
        if(!R.includes(OS, R.keys(OS_CONFIG)))
            return this.setState({unsupportedOS: true});
        return window.open(Endpoints.CLIENT_APP_DOWNLOAD_URL(FILE_TYPE), '_blank');
    };

    getErrorView() {

        let formLogo = getLogoUrl();

        return (
            <div className="ics-form-main-container">
                <div className="ics-form-wrapper successful-error-wrapper download-app">
                    <div className="ics-common-wrapper">
                        <div className="form-common-header-wrapper">
                            <img src={formLogo} className="ics-form-logo" alt=""/>
                        </div>
                        <div className="ics-common-body-wrapper">
                            <div className="success-error-img-wrapper">
                                <img src="assets/images/login-error-icon.png" alt=""/>
                            </div>
                            <h3 className="form-secondary-header-text">This OS is not supported. Please use Mac or Windows OS to download and install the app.</h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    postRenderView () {
        return super.postRenderView()
            .then(this.applyAutoFocus)
            .then(_ => this.$container.find('.ui.dropdown').dropdown());
    }

    applyAutoFocus = () => {
        this.$container.find('[autofocus]').focus()
    }
}