import * as HttpUtils from '../utils/http-utils'
import Endpoints from '../config/endpoints'
import * as UserProfileDAO from '../dao/user-profile-dao'
import {ROUTES, SESSION_ID} from '../config/app-config'
import {prepareRESTURI} from "../Backflipt_UI_Components/utils/generic-utils";

export let autoLogin = () => {
    return getProfile()
        .then(profile => {
            if (profile) {
                return setUserProfileInSession(profile);
            }
        });
};

let setUserProfileInSession = (profile) => {
    profile[SESSION_ID] = profile.id;
    return UserProfileDAO.setSession(profile);
};

export let updatePassword = (oldPassword, newPassword) => HttpUtils.postData(Endpoints.UPDATE_PASSWORD, {
    oldPassword,
    newPassword
}).catch(resp => {throw resp});

export let getProfile = () => HttpUtils.getData(Endpoints.GET_PROFILE);

export let updateProfile = (profile) => {
    return HttpUtils.postData(Endpoints.GET_PROFILE, profile)
        .then(setUserProfileInSession)
        .catch();
};

export let userSignIn = (data) => {
    data.env = window.env;
    return HttpUtils.postData(Endpoints.USER_SIGN_IN, data)
        .then(resp =>{
            if(resp.step && resp.step === "PHONE_OTP_VALIDATION")
                return {fingerPrint: resp.fingerPrint, maskedMobileNumber: resp.maskedMobileNumber, twoFactorAuthEnabled: true};
            return autoLogin()
                .then(_ => true)
        })
        .catch(resp => {
            if(resp.status === 401) return false;
            throw resp;
        });
};

export let adminSignIn = (data) => {
    // data.adminApp = window.env.adminApp;
    data.env = window.env;
    return HttpUtils.postData(Endpoints.SIGN_IN, data)
        .then(autoLogin)
        .then(_ => true)
        .catch(resp => {
            if(resp.status === 401) return false;
            throw resp;
        });
};

export let emailCheck = (data) => {
    data.adminApp = window.env.adminApp;
    // data.env = window.env;
    return HttpUtils.postData(Endpoints.FORGOT_PASSWORD, data)
        .then(_ => ({success: true}))
        .catch(resp => {
            if(resp.status === 400) return ({success: false});
            throw resp;
        });
};

export let setNewPassword = (data) => HttpUtils.postData(Endpoints.RESET_PASSWORD, data)
    .then(_ => ({success:true}))
    .catch(resp => {
        if(resp.status === 400) return ({success:false});
        throw resp;
     });

export let verifyUserNameExists = (emailId) => HttpUtils.postData(Endpoints.VALIDATE_USER_NAME, {emailId, adminApp: window.env.adminApp});

export let verifyEmailWithTenant = (data) => {
    let params = {...data,
        adminApp: window.env.adminApp,
        relayState: {
            env: window.env,
            redirectUrl: prepareRESTURI(window.buildConfig.clientAppUri + "#" + ROUTES.SSO_LOGIN, {token: "${token}"})
        }
    };
    return HttpUtils.postData(Endpoints.VALIDATE_EMAIL_WITH_TENANT, params)
};

export let signOut = () => HttpUtils.postData(Endpoints.SIGN_OUT);
