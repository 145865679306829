import { getAllAppsForTenant, getFeedDetails, getTenantById } from './tenant-service'
import { getMyTenantId} from '../dao/user-profile-dao'
import * as HttpUtils from '../utils/http-utils';
import Endpoints from '../config/endpoints';
import {getValueForPathOrDefault, prepareRESTURI} from "../Backflipt_UI_Components/utils/generic-utils";
import {ROUTES} from "../config/app-config";
import {autoLogin} from "./user-profile-service";

export let getTenant = () => {
    let tenantId = getMyTenantId();
    return tenantId ? getTenantById(tenantId) : Promise.reject();
};

export let getFeedURLs = () => {
    let tenantId = getMyTenantId();
    return tenantId ? getFeedDetails(tenantId) : Promise.reject();
};

export let getMyTenantDetails = () => {
    let tenantId = getMyTenantId();
    return tenantId ? getTenantById(tenantId) : Promise.resolve();
};

export let getMyApps = () => getAllAppsForTenant(getMyTenantId());
export let getMyAppCredentials = () => HttpUtils.getData(Endpoints.GET_MY_APP_CREDENTIALS());

export let getRegisterUserDetails = (token) => HttpUtils.getData(Endpoints.REGISTER_USER(token));
export let registerUser = (token, user) => HttpUtils.postDataWithNoResponse(Endpoints.REGISTER_USER(token), user);
export let getTokenInfo = (token) => HttpUtils.getData(Endpoints.VERIFY_EMAIL_TOKEN(token));

export let sendRegistrationMailToUser = (tenantId, user) => {
    let registerUrl = prepareRESTURI(window.buildConfig.clientAppUri + "#" + ROUTES.REGISTER_USER, {token: "${token}"});
    return HttpUtils.postData(Endpoints.SEND_REGISTRATION_MAIL_TO_USER(tenantId, user.id),{user, registerUrl});
};

export let uploadImageForUser = (image, userId) => HttpUtils.postMultipart(Endpoints.GET_IMAGE_FOR_USER(userId), null, {image});

export let getImageUrlForUser = (user) => {
    let imageInfo = getValueForPathOrDefault(user, "attributes.image");
    return imageInfo && imageInfo.contentType && Endpoints.GET_IMAGE_FOR_USER(user.id, imageInfo.version);
};

export let deleteImageForUser = (userId) => HttpUtils.deleteData(Endpoints.GET_IMAGE_FOR_USER(userId));

export let sendLoginOTP = (token) => HttpUtils.postData(Endpoints.LOGIN_SEND_OTP, {token});
export let validateLoginOTP = (data) => {
    data.adminApp = window.env.adminApp;
    return HttpUtils.postData(Endpoints.LOGIN_VALIDATE_OTP, data)
        .then(autoLogin)
        .then(_ => true)
        .catch(resp => {
            throw resp;
        });
};

export let sendRegistrationOTP = (token, user) => HttpUtils.postData(Endpoints.REGISTER_SEND_OTP(token), user);
export let validateRegistrationOTP = (token, otp, user) => HttpUtils.postDataWithNoResponse(Endpoints.REGISTER_VALIDATE_OTP(token), {otp, user});

export let getCountryCodes = () => HttpUtils.getData(Endpoints.GET_COUNTRY_CODES);