import { getMyTenantDetails } from '../../dao/user-profile-dao'
import { getTenantFeed } from '../../service/tenant-service'
import CommonFeed from '../common-feed/common-feed'
import {ERRORS} from "../../config/errors";

export default class TenantFeed extends CommonFeed {

    loadDataAsync() {
        let tenant = getMyTenantDetails();
        return getTenantFeed(tenant.id)
            .then(feedDetails => {
                let feedTitle = feedDetails.title;
                let feed = feedDetails.feed;
                return this.setState({ feedTitle, feed, dataLoaded: true });})
            .catch(resp => {
                let feedTitle = "Client Specific News";
                let error = (resp && resp.responseJSON) || {};
                let errorDescription = ERRORS.FEED[error.code];

                if (error.code === "NO_RESPONSE") {
                    return this.setState({
                        feedTitle,
                        dataLoaded: true,
                        errors: {invalidURL: true}
                    });
                }
                if (error.code === "INVALID_JSON") {
                    return this.setState({
                        feedTitle,
                        dataLoaded: true,
                        errors: {invalidJSON: true}
                    });
                }
                return this.setState({feedTitle, dataLoaded: true, errors: {feedError: errorDescription}})
            })
    }

}