import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import React from 'react'
import {
    convertToMailToUrl,
    convertToUrl, getAttrValueForEventFromClosest,
    getClosestForEventWithAttribute,
    serializeForm,
    validateForm
} from '../../Backflipt_UI_Components/utils/generic-utils'
import { getAddOrUpdateTenantPopupView } from '../../views/add-update-tenant-popup'
import ModalContainer from '../modal-container/modal-container-v2'
import { updateMyTenant } from '../../service/tenant-service'
import $ from 'jquery'

const R = require('ramda');

export default class TenantDetails extends UIBaseComponent {

    beforeRender () {
        this.state = {
            tenant: R.clone(this.props.tenant)
        };
        return super.beforeRender()
    }

    getView () {
        return super.getView()
            .then(_ => {

                let tenant = this.state.tenant || {};

                return (
                    <div className="client-details-tab-content-wrapper">
                        <div className="client-details-wrapper">
                            <div className="client-details-header-wrapper">
                                <div className="client-details-header-text">
                                    <h3 className="primary-text">Client Details</h3>
                                </div>
                                <div className="client-details-header-action-btn">
                                    <div className="actions">
                                        <div className="ui primary button buttons-primary-text buttons-primary-background" onclick={this.showUpdateTenantPopup}>Edit Details</div>
                                    </div>
                                </div>
                            </div>
                            <div className="client-details-section-wrapper">
                                <div className="description">
                                    <table className="ui table">
                                        <tbody>
                                        <tr>
                                            <td>Client Name</td>
                                            <td>{tenant.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Address</td>
                                            <td>{tenant.address}</td>
                                        </tr>
                                        <tr>
                                            <td>Website</td>
                                            <td><a className="link-text" href={convertToUrl(tenant.website)} target="_blank">{tenant.website}</a></td>
                                        </tr>
                                        <tr>
                                            <td>Contact Name</td>
                                            <td>{tenant.contactName}</td>
                                        </tr>
                                        <tr>
                                            <td>Contact Email</td>
                                            <td><a className="link-text" href={convertToMailToUrl(tenant.contactEmailId)}>{tenant.contactEmailId}</a></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
    }

    onTenantUpdateDataChange = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            getClosestForEventWithAttribute(e, 'data-form-id')
                .find('#addOrUpdateTenantAction')
                .click();
        }
    };

    onTenantUpdateValueChange = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let tenant = serializeForm($form);
        let key = getAttrValueForEventFromClosest(e, 'data-key');
        let errors = R.clone(this.state.errors) || {};
        delete errors[key];
        return this.setState({errors})
            .then(_ => this.updateUpdateTenantPopup(tenant, this.state.errors || {}, this.onTenantUpdateValueChange, this.onTenantUpdateDataChange, this.addOrUpdateTenant, this.closeAddOrUpdateTenantPopup))
            .then(_ => e.target.focus());
    };

    updateTenant = (e) => {
        let $form = getClosestForEventWithAttribute(e, 'data-form-id');
        let errors = validateForm($form);
        let tenant = serializeForm($form);
        if (errors && !R.isEmpty(errors)) {
            return this.setState({errors})
                .then(_ => {
                    let view = getAddOrUpdateTenantPopupView(tenant, false, this.state.errors || {}, this.onTenantUpdateValueChange, this.onTenantUpdateDataChange, this.updateTenant, this.closeUpdateTenantPopup);
                    return ModalContainer.updateModalView('update-tenant-popup', view);
                });
        }
        let existing = this.state.tenant || {};
        tenant = R.mergeDeepLeft(tenant, existing);
        return this.setState({loading: true})
            .then(_ => this.updateUpdateTenantPopup(tenant))
            .then(_ => updateMyTenant(tenant))
            .then(_ => this.setState({tenant}))
            .then(_ => this.closeUpdateTenantPopup())
            .then(_ => this.updateUpdateTenantPopup(tenant))
            .catch(_ => this.setState({tenant, errors: {serverError: true}, loading: false})
                .then(_ => this.updateUpdateTenantPopup(tenant)));
    };

    updateUpdateTenantPopup = (tenant) => {
        let view = getAddOrUpdateTenantPopupView(tenant, this.state.loading,this.state.errors || {}, this.onTenantUpdateValueChange, this.onTenantUpdateDataChange, this.updateTenant, this.closeUpdateTenantPopup);
        return ModalContainer.updateModalView("update-tenant-popup", view);
    };

    showUpdateTenantPopup = (e) => {
        let tenant = this.state.tenant;
        let view = getAddOrUpdateTenantPopupView(tenant, false,this.state.errors || {}, this.onTenantUpdateValueChange, this.onTenantUpdateDataChange, this.updateTenant, this.closeUpdateTenantPopup);
        return ModalContainer.addModalView("update-tenant-popup", view)
            .then(_ => this.applyAutoFocusForContainer(ModalContainer.getModelEl("update-tenant-popup")));
    };

    closeUpdateTenantPopup = (e) => {
        return ModalContainer.removeModal('update-tenant-popup')
            .then(_ => this.setState({errors: null, loading: false}));
    };

    applyAutoFocusForContainer = (container) => $(container).find('[autofocus]').focus();

    applyAutoFocus = () => this.applyAutoFocusForContainer(this.$container);
}