/**
 * Created by Sabbu on 13/05/16.
 */

import $ from 'jquery';
import m from 'mithril';
import uuid from 'uuid';

const R = require('ramda');
// import UUIDForString from 'uuid-by-string';

/*
    Lifecycle is:

        constructor
        init
        render:
            beforeRender
            renderView
                getView
                postRenderView
            afterRender
        destroy
 */

const promiseWait = (wait) => new Promise(resolve => setTimeout(resolve, wait));

export default class UIBaseComponent {

    constructor (container, props = {}, lazyContainer = false, containerId, parentRef = '') {
        this.state = {};
        this.cssPaths = [];
        this.lazyContainer = lazyContainer;
        this._containerId = containerId;
        this.container = container;
        this.props = props;
        this.checkContainerExists();
        this.parentRef = parentRef;
        this.childComponents = [];
        if(!this.lazyContainer) this.unloadPreviousComponent();
        this.featureId = this.getFeatureId();
        this.computeFeatureHierarchy();
    }



    setState(state, forceUpdate = false, waitForRender) {
        const pr = waitForRender ? promiseWait(waitForRender) : Promise.resolve();
        if(state && !R.isEmpty(state)) {
            let different = R.find(k => {
                let ov = this.state[k];
                let nv = state[k];
                // return !R.equals(ov || "", nv || "");
                return !R.equals(ov, nv);
            }, R.keys(state));

            if(different) {
                this.state = R.merge(this.state, state);
                return pr
                    .then(_ => this.renderView())
                    .then(_ => true);
            }
        }
        if(forceUpdate) {
            return pr
                .then(_ => this.renderView())
                .then(_ => true);
        }
        return Promise.resolve(false);
    }

    initializeHandlers() {}

    reconstruct(container, props = {}, lazyContainer = false, containerId) {
        // constructor(container, props, lazyContainer, containerId);
        this.container = container;
        this.lazyContainer = lazyContainer;
        this._containerId = containerId || (container && container.id);
        this.props = props;
        this.destroyed = false;
        return this;
    }

    getFeatureId() {
        return this.containerId;
    }

    checkContainerExists() {
        if(!this.lazyContainer && !this.container) throw new Error('container can not be null');
        else if(this.lazyContainer && !this._containerId) throw new Error('containerId is mandatory for lazy containers');
    }

    unloadPreviousComponent() {
        if(this.container) {
            if(this.container.compContext) {
                this.container.compContext.destroy();
            }
            this.container.compContext = this;
        }
    }

    computeFeatureHierarchy() {
        let parentFeatureHierarchy = this.parentRef ? this.parentRef.featureHierarchy : [];
        let featureId = this.getFeatureId();
        this.featureHierarchy = R.concat(parentFeatureHierarchy, [featureId]);
    }

    static getDOMElementForSelector(sel) {
        let container = $(sel);
        if(container) container = container[0];
        return container;
    }

    get props() { return this._props; }
    set props(props) { this._props = props; }
    get containerId() {
        return this.container ? this.container.id : this._containerId;
    }

    get $container() {
        return this.container ? $(this.container) : undefined;
    }

    getView() { return Promise.resolve(); }

    bindWithContainer() {
        if(this.lazyContainer && !this.container) {
            this.container = document.getElementById(this._containerId);
            this.checkContainerExists();
            this.unloadPreviousComponent();
        }
    }

    loadStyles() {
        if(this.cssPaths && this.cssPaths.length > 0) {
            this.cssPaths.forEach(loc => {
                let styleId = uuid.v4();
                let envPath = window.envPath || "";
                let app_version = window.app_version || "";
                if (!$(`link#${styleId}`).length) {
                    if(loc && loc.startsWith('http')){
                        $("head").prepend($(`<link id="${styleId}" href="${loc}" rel="stylesheet">`));
                    }else{
                        $("head").prepend($(`<link id="${styleId}" href="/${envPath}${loc}?v=${app_version}" rel="stylesheet">`));
                    }
                }
            });
        }
    }

    beforeRender() {
        this.initializeHandlers();
        this.loadStyles();
        this.bindWithContainer();
        this.triggerGetFeatureStateEvent();
        return Promise.resolve(this);
    }

    render() {
        let self = this;
        this.bindWithContainer();
        return self.beforeRender()
            .then(_ => self.renderView())
            .then(_ => self.afterRender());
    }

    renderView() {
        if(this.destroyed) return Promise.resolve();
        let self = this;
        return self.getView()
            .then(view => view ? m.render(self.container, view) : view)
            .then(_ => self.postRenderView());
    }

    postRenderView() { return Promise.resolve(this); }

    afterRender() { return Promise.resolve(this); }

    inputSelfEvents(){
        return Promise.resolve([]);
    }

    outputSelfEvents(){
        return Promise.resolve([]);
    }


    triggerGetFeatureStateEvent(){
        let featureId = this.getFeatureId();
        let payload = {event:"get_feature_state", featureId, featureHierarchy:this.featureHierarchy};
        this.props.emitter && this.props.emitter.emit(payload);
    }

    //listener for listening incoming events
    on(payload) {
        if (payload.event === "feature_state" && this.featureHierarchy === payload.featureHierarchy) {
            this.props = R.merge(this.props, payload.featureState);

            let functionProps = R.filter(prop => prop.startsWith('fn_'), R.keys(this.props));
            functionProps && R.forEach(prop => {
                let fn = this.props[prop];
                this.props[prop] = fn();
            }, functionProps);

            this.renderView();
        } else {
            this.inputSelfEvents().then(events => {
                if (payload.event && R.contains(payload.event, events)) {
                    this.setModel && this.setModel(payload);
                }
            });
        }
    }

    //publisher to send out going events
    emit(payload){
        this.outputSelfEvents().then(outEvents => {
            if(this.parentRef && payload.event && R.contains(payload.event, outEvents)){
                this.parentRef.emit(payload);
            }else{
                console.error('Trying to emit an event which is not configured in self or in children. containerId => '+ this.containerId +' event => '+ R.toString(payload));
            }
        });
    }

    destroy() {
        this.state = {};
        this.destroyed = true;
        if(this.container) this.container.compContext = null;
        if(!this.container) this.container = document.getElementById(this._containerId);
        if(this.container) m.deleteCacheForNode(this.container);
        if(this.parentRef) this.parentRef = null;
        // this.container = null;
        // this._containerId = null;
        // this._props = null;
    }
}