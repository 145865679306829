import UIBaseComponent from '../../Backflipt_UI_Components/src/ui-base-component/ui-base-component'
import React from 'react'
import { getLogoUrl } from '../../utils/domain-utils'
import {ROUTES} from "../../config/app-config";
import {goToRouteOnEvent} from "../../utils/generic-utils";

export default class VerifyEmail extends UIBaseComponent {
    getView() {
        return super.getView()
            .then(_ => {
                    let formLogo = getLogoUrl();
                    return (
                        <div className="ics-form-wrapper successful-error-wrapper">
                            <div className="ics-common-wrapper">
                                <div className="form-common-header-wrapper">
                                    <img src={formLogo} className="ics-form-logo" alt=""/>
                                </div>
                                <div className="ics-common-body-wrapper">
                                    <div className="success-error-img-wrapper">
                                        <img src="assets/images/login-succes-icon.png" alt=""/>
                                        <img src="assets/images/login-error-icon.png" className="hide" alt=""/>
                                    </div>
                                    <h3 className="form-secondary-header-text">A mail has been sent to your registered email account. Please verify to reset the password.<br/><br/>Click on Sign In below to login using your new password.</h3>
                                    <div className="common-form-btn-wrapper">
                                    <button className="ui right labeled icon button" data-route={ROUTES.SIGN_IN} onclick={goToRouteOnEvent}>
                                            <i className="right arrow icon"></i>
                                        Sign In
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            );
    };
}