
export const ERRORS = {
    USER_SIGN_IN: {
        "INVALID_USER_NAME_OR_PASSWORD": "No User exists with this email!",
    },

    REGISTRATION: {
        "GENERIC": "Something went wrong! Please try again.",
        "USER_ALREADY_REGISTERED": "User with this email is already registered.",
    },

    RESET_PASSWORD : {
        "TOKEN_ERROR_EXPIRED": "The link is invalid or expired! Please try again."
    },

    MOBILE_NO_VERIFICATION : {
        "INVALID_OTP": "Invalid OTP.",
        "TOKEN_ERROR_EXPIRED": "Mobile Verification Time Expired. Please Sign In again.",
        "SMS_API_ERROR": "Please enter a valid mobile number."
    },
    FEED: {
        "NO_RESPONSE": "There is no response from the server. Please try again.",
        "INVALID_JSON": "Invalid Json format detected.",
        "FEED_NOT_FOUND": "No feed found."
    },

    COMMON : "Something went wrong! Please try again.",

    CONTACT : {
        ADMIN: ["TOKEN_ERROR_EXPIRED", "MOBILE_NO_NOT_FOUND", "USER_IS_DISABLED"],
        SUPPORT: ["NO_RESPONSE", "INVALID_JSON"]
    },

    HOMEPAGE: {
        PREVIEW: {
            "NO_RESPONSE": "No data available. Please check the data source.",
            "INVALID_JSON": "Invalid Json file.",
            "FEED_NOT_FOUND": "No feed found."
        },
        PUBLISH: {
            "NO_RESPONSE": "No data available. Please contact your administrator.",
            "INVALID_JSON": "Invalid Json file. Please contact your administrator",
            "FEED_NOT_FOUND": "No feed found."
        }
    }
};