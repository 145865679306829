import {ROUTES} from '../config/app-config'
import Header from '../components/header/header'
import SignInRegion from '../region/signin/signin-region'
import AdminSignIn from '../components/signin/admin-sign-in'
import AccessRequests from '../region/access-requests/access-requests'
import Page from '../region/app/page'
import AdminHome from '../region/home/admin-home'
import Tenants from '../components/tenants/tenants'
import TenantDetail from '../region/tenant-detail/tenant-detail'
import TenantDetailGUI from '../components/tenant-detail/tenant-detail-gui'
import TenantDetailApps from '../components/tenant-detail/tenant-detail-apps'
import TenantDetailUsers from '../components/tenant-detail/tenant-detail-users'
import TenantDetailUserAuth from '../components/tenant-detail/tenant-detail-user-auth'
import UserSignIn from '../components/signin/user-sign-in'
import UserHome from '../region/home/user-home'
import TenantFeed from '../components/tenant-feed/tenant-feed'
import UserApps from '../components/user-apps/user-apps'
import ForgotPassword from '../components/forgot-password/forgot-password'
import ResetPassword from '../components/reset-password/reset-password'
import SuccessView from '../components/success-view/success-view'
import VerifyEmail from '../components/verify-email/verify-email'
import TenantAdminHome from '../region/home/tenant-admin-home'
import TenantDetails from '../components/tenant-detail/tenant-details'
import UserRegistration from '../components/user-registration/user-registration'
import SSOLogin from "../components/signin/sso-login";
import CommonFeed from '../components/common-feed/common-feed'
import TenantFeedUpload from '../components/tenant-detail/tenant-feed-upload'
import CommonFeedUpload from '../components/common-feed-upload/common-feed-upload'
import StandardApps from '../components/standard-apps/standard-apps'
import Configurations from '../components/admin-detail/configurations'
import EmailTemplates from '../components/email-templates/email-templates'
import TenantDetailAppUserCredentials from "../components/tenant-detail/tenant-detail-app-user-credentails";
import HomepageCustomization from "../components/homepage-customization/homepage-customization";
let getRoute = (containerId, component, parent, children) => {
    let r = {};
    if (containerId) r.containerId = containerId;
    if (component) r.component = component;
    if (parent) r.parent = parent;
    if (children) r.children = children;
    return r;
};

const config = {

    "header": getRoute('header', Header),

    "page_layout": getRoute('container', Page, null, ["header"]),

    /******************************************* Admin Routes *******************************************/

    "admin_home": getRoute('body', AdminHome, "page_layout"),

    "admin_sign_in": getRoute('container', SignInRegion, null, ["admin_sign_in_component"]),

    "admin_sign_in_component": getRoute('sign-in-container', AdminSignIn),

    [ROUTES.FORGOT_PASSWORD]: getRoute('container', ForgotPassword, null, []),

    [ROUTES.REGISTER_USER]: getRoute('container', UserRegistration, null, []),

    [ROUTES.RESET_PASSWORD]: getRoute('container', ResetPassword, null, []),

    [ROUTES.SSO_LOGIN]: getRoute('container', SSOLogin, null, []),

    [ROUTES.SUCCESS_VIEW]: getRoute('container', SuccessView, null, []),

    [ROUTES.VERIFY_EMAIL]: getRoute('container', VerifyEmail, null, []),

    [ROUTES.ADMIN_ACCESS_REQUESTS]: getRoute('tab-content', AccessRequests, "admin_home"),

    [ROUTES.ADMIN_TENANTS]: getRoute('tab-content', Tenants, "admin_home"),

    [ROUTES.ADMIN_CONFIGURATIONS]: getRoute('configurations', Configurations, "admin_home"),

    [ROUTES.ADMIN_COMMON_FEED_UPLOAD]: getRoute('feed_upload_container', CommonFeedUpload, ROUTES.ADMIN_CONFIGURATIONS),

    [ROUTES.ADMIN_STANDARD_APPS]: getRoute('standard-apps', StandardApps, ROUTES.ADMIN_CONFIGURATIONS),

    [ROUTES.ADMIN_EMAIL_TEMPLATES]: getRoute('email-templates', EmailTemplates, ROUTES.ADMIN_CONFIGURATIONS),

    [ROUTES.ADMIN_TENANT_DETAIL]: getRoute('tab-content', TenantDetail, "admin_home"),

    [ROUTES.ADMIN_TENANT_DETAIL_GUI]: getRoute('tenant_detail_gui_container', TenantDetailGUI, ROUTES.ADMIN_TENANT_DETAIL),
    [ROUTES.ADMIN_TENANT_DETAIL_APPS]: getRoute('tenant_detail_apps_container', TenantDetailApps, ROUTES.ADMIN_TENANT_DETAIL),

    [ROUTES.ADMIN_TENANT_DETAIL_APPS_USER_CREDENTIALS]: getRoute('tenant_detail_apps_container', TenantDetailAppUserCredentials, ROUTES.ADMIN_TENANT_DETAIL),

    [ROUTES.ADMIN_TENANT_DETAIL_USERS]: getRoute('tenant_detail_users_container', TenantDetailUsers, ROUTES.ADMIN_TENANT_DETAIL),
    [ROUTES.ADMIN_TENANT_DETAIL_USER_AUTH]: getRoute('tenant_detail_user_auth_container', TenantDetailUserAuth, ROUTES.ADMIN_TENANT_DETAIL),
    [ROUTES.ADMIN_TENANT_FEED_UPLOAD]: getRoute('tenant_feed_upload_container', TenantFeedUpload, ROUTES.ADMIN_TENANT_DETAIL),
    [ROUTES.ADMIN_TENANT_HOMEPAGE_CUSTOMIZATION]: getRoute('tenant_homepage_customization', HomepageCustomization, ROUTES.ADMIN_TENANT_DETAIL),

    /******************************************* Admin Routes *******************************************/

    /******************************************* User Routes *******************************************/

    "user_sign_in": getRoute('container', SignInRegion, null, ["user_sign_in_component"]),

    "user_sign_in_component": getRoute('sign-in-container', UserSignIn),

    [ROUTES.USER_HOME]: getRoute('body', UserHome, "page_layout", ["user_apps", "common_feed", "tenant_feed"]),

    [ROUTES.APPS_ZOOM]: getRoute('body', UserHome, "page_layout", ["user_apps"]),

    "user_apps": getRoute('user-apps', UserApps),

    "tenant_feed": getRoute('tenant-feed', TenantFeed),

    //"tenant_stats": getRoute('tenant-stats', TenantStats),
    "common_feed": getRoute('common-feed', CommonFeed),

    /******************************************* User Routes *******************************************/

    /******************************************* Tenant Admin Routes *******************************************/

    [ROUTES.USER_ADMIN_HOME]: getRoute('body', TenantAdminHome, "page_layout"),

    [ROUTES.USER_ADMIN_GUI]: getRoute('tenant_detail_gui_container', TenantDetailGUI, ROUTES.USER_ADMIN_HOME),

    [ROUTES.USER_ADMIN_USER_AUTH]: getRoute('tenant_detail_user_auth_container', TenantDetailUserAuth, ROUTES.USER_ADMIN_HOME),

    [ROUTES.USER_ADMIN_APPS]: getRoute('tenant_detail_apps_container', TenantDetailApps, ROUTES.USER_ADMIN_HOME),

    [ROUTES.USER_ADMIN_APPS_USER_CREDENTIALS]: getRoute('tenant_detail_apps_container', TenantDetailAppUserCredentials, ROUTES.USER_ADMIN_HOME),

    [ROUTES.USER_ADMIN_USERS]: getRoute('tenant_detail_users_container', TenantDetailUsers, ROUTES.USER_ADMIN_HOME),

    [ROUTES.USER_ADMIN_DETAILS]: getRoute('tenant_details_container', TenantDetails, ROUTES.USER_ADMIN_HOME),

    [ROUTES.USER_ADMIN_FEED_UPLOAD]: getRoute('tenant_feed_upload_container', TenantFeedUpload, ROUTES.USER_ADMIN_HOME),

    [ROUTES.USER_ADMIN_HOMEPAGE_CUSTOMIZATION]: getRoute('tenant_homepage_customization', HomepageCustomization, ROUTES.USER_ADMIN_HOME)


    /******************************************* Tenant Admin Routes *******************************************/

};

export default config;