import uuid from 'uuid';

import md5 from 'blueimp-md5';
import $ from 'jquery';
import CopyToClipbord from 'copy-to-clipboard';
import * as AppConfig from "../config/cloud-config";
import jsonpath from 'jsonpath';
import validator from 'validator';

const R = require('ramda');

export let newUUID = () => uuid.v4();

export let mapFromList = (list, key) => {
    return R.fromPairs(R.map(x => [x[key], x], list));
};

export let isValidEmailId = (emailId) => {
    if(!emailId) return false;
    let reg = /^([A-Za-z0-9_\-\\.])+@([A-Za-z0-9_\-\\.])+\.([A-Za-z]{2,4})$/;
    return reg.test(emailId);
};

export let getRedirectURI = () => {
    return 'https://backflipt.com/customer/admin/oauth';
};

export let getQueryStringForUrl = (url) => {
    let qs = (url && url.split("?")[1]) || "";
    return qs.split('#')[0];
};

export let getParamsMapForQueryString = (qs) => {
    let params = qs && qs.split("&");
    return params && R.reduce(function(acc, p) {
        let keys = p.split("=");
        let key = decodeURIComponent(keys[0]);
        let value = decodeURIComponent(keys[1]);
        acc[key] = acc[key] ? R.flatten([value, acc[key]]) : value;
        return acc;
    }, {}, params);
};

export let getAbsoluteUrl = (url) => {
    let u = url && url.split("?")[0];
    return u && u.split("#")[0];
};

export let getHashStringForUrl = (url) => {
    return url && url.split("#")[1];
};

export let generateQueryStringFromParams = (paramMap) => {
    if(!paramMap) return;
    return R.reduce(function(acc, k) {
        if(acc.length > 0) acc = acc + "&";
        return acc + k + "=" + paramMap[k];
    }, "", R.keys(paramMap));
};

export let appendParamsAsQueryStringToUrl = (url, paramMap) => {
    let absUrl = getAbsoluteUrl(url);
    let urlWithoutHash = url.split("#")[0];
    let params = getParamsMapForQueryString(getQueryStringForUrl(urlWithoutHash)) || {};
    paramMap = R.merge(params, paramMap || {});
    let hs = getHashStringForUrl(url);
    let qs = generateQueryStringFromParams(paramMap);
    return absUrl + (qs ? ("?" + qs) : "") + (hs ? ("#" + hs) : "");
};

export let appendParamsAsHashStringToUrl = (url, paramMap) => {
    let absUrl = url.split("#")[0];
    let hs = getHashStringForUrl(url);
    let params = getParamsMapForQueryString(hs) || {};
    console.log("absUrl-> " + absUrl + " hs-> " + hs + " params-> " + JSON.stringify(params));
    paramMap = R.merge(params, paramMap || {});
    let qs = generateQueryStringFromParams(paramMap);
    console.log("qs-> " + qs + " paramMap-> " + JSON.stringify(paramMap));
    return absUrl + (qs ? ("#" + qs) : "");
};

export let generateOAuthLoginUrl = (url, state) => {
    state = state || {};
    let queryString = getQueryStringForUrl(url);
    let paramMap = getParamsMapForQueryString(queryString);
    //let stateToken = generateNewStateToken();
    paramMap["redirect_uri"] = getRedirectURI();
    paramMap["state"] = JSON.stringify(state);

    return appendParamsAsQueryStringToUrl(url, paramMap);
};

export let getTrimmedSummaryForFile = (fileSummary) => {

    if(!fileSummary) return;
    let summaryLength = fileSummary.length;
    if(summaryLength <= 55) return fileSummary;
    else return fileSummary.substring(0, 52) + "...";
};

export let getIconUrlForFileExtn = (extn) => {
    extn = extn && extn.toLowerCase();
    let type = AppConfig.fileTypes[extn] || AppConfig.fileTypes['txt'];
    return AppConfig.fileExtnIcons[type];
};

export let getExtensionForFile = (fileName) => {
    let parts = fileName.split(".");
    return parts.length > 1 ? parts.pop() : "";
};

export let getFileNameWithoutExtension = (fileName) => {
    if(!fileName) return;
    // let fileNameWithoutExtsn = fileName.substring(0, fileName.length - getExtensionForFile(fileName).length - 1);
    // let fileNameLength = fileNameWithoutExtsn && fileNameWithoutExtsn.length;
    // if(fileNameLength <= 35) return fileNameWithoutExtsn;
    // else return fileNameWithoutExtsn.substring(0, 32) + "...";
    return fileName && fileName.substring(0, fileName.length - getExtensionForFile(fileName).length - 1);
};

export let getDomainFromEmail = (email) => {
    let temp = email && email.replace(/.*@/, '').split('.');
    return temp[temp.length - 2];
};

export let capitalizeFirstLetter = (str) => {
    return str && str.charAt(0).toUpperCase() + str.slice(1);
};

export let openUrlInBrowser = (url) => {
    window.openUrlInBrowser && window.openUrlInBrowser(url);
};

export let prepareRESTURI = (uri, params) => {
    if(!params || R.isEmpty(params) || !uri) return uri;
    return R.reduce((acc, k) => {
        let key = ":" + k;
        let value = params[k] || ' ';
        if(acc.endsWith(key)) {
            return acc.substring(0, acc.length - key.length) + value;
        }
        return acc.replace(key + '/', value + "/");
    }, uri, R.keys(params));
};

export let md5Hash = str => str && md5(str);

export const DATA_TYPE = {
    NUMBER: "number",
    STRING: "string",
    BOOLEAN: "boolean",
    JSON: "json",
    ARRAY: "array"
};

export let getClosestForEventWithAttribute = (e, attrName) => getClosestForElementWithAttribute($(e.target), attrName);

export let getAttrValueFromClosest = ($el, attrName) => {
    let $closest = getClosestForElementWithAttribute($el, attrName);
    let value = $closest.attr(attrName);
    let type = $closest.attr('data-type');
    return convertValue(value, type);
};

export let convertValue = (value, type) => {
    if(type === DATA_TYPE.NUMBER) return Number(value);
    else if(type === DATA_TYPE.BOOLEAN) return value === "true";
    else if(type === DATA_TYPE.JSON) return isValidJSONString(value)? JSON.parse(value): null;
    else if(type === DATA_TYPE.ARRAY) return value? value.split(","): null;
    return value;
};

export let getAttrValueForEventFromClosest = (e, attrName) => getAttrValueFromClosest($(e.target), attrName);

export let getClosestForElementWithAttribute = ($el, attrName) => $el.closest('[' + attrName + ']');



export let LoadingView = "Loading";
// (
//     <div className="loader max-width-70">
//         <img alt="" src="images/loading.svg"/>
//     </div>
// );

export let copyToClipboard = (text) => {
    CopyToClipbord(text);
};

export let sortTheListByKey = (list, key) => {
    if(!list || R.isEmpty(list)) return list;
    let sorted = R.sortBy(R.prop(key), list);
    return sorted;
};

export let getTrimmedTextOfGivenLength = (text, maxLength) => {
    if(text.length <= maxLength) return text;
    return text.substring(0,maxLength-1) + "...";
};

export let captalizeString = (str) => str && str.substring(0, 1).toUpperCase() + str.substring(1);

export let getContainerFromClosest = (e, attrName) => {
    return $(e.target).closest('[' + attrName + ']');
};

export let serializeForm = ($form) => {
    let obj = {};
    $form.find('[data-key]').each((index, el) => {
        const $el = $(el);
        const key = $el.attr("data-key");
        const type = $el.attr("data-type");
        const ignore = $el.attr("data-ignore-serialize") === "true";
        if(!ignore) {
            let value = $el.val();
            value = isCheckedElement($el) ? ($el.is(':checked') ? value : null) : value;
            const valueInReqType = convertValue(value, type);
            value = R.isNil(valueInReqType)? getValueForPathOrDefault(obj, key): valueInReqType; 
            setValueForPathOrDefault(obj, key, value);
        }
    });
    return obj;
};

const isCheckedElement = ($el) => $el.is(':radio') || $el.is(':checkbox');

export let validateForm = ($form) => {
    let errors = {};
    $form.find('[data-key][data-validation-type]').each((index, el) => {
        let $el = $(el);
        let key = $el.attr("data-key");
        let required = $el.attr("data-required") === "true";
        let value = $el.val().trim();
        let validationType = $el.attr('data-validation-type');
        if(required || (validationType && value))
            if(!isValidValue(value, validationType))
                setValueForPathOrDefault(errors, key, true);
    });
    return errors;
};

export let validateFormV2 = ($form) => {
    let errors = {};
    $form.find('[data-key][data-validation-type]').each((index, el) => {
        let $el = $(el);
        let key = $el.attr("data-key");
        let required = $el.attr("data-required") === "true";
        let value = $el.val().trim();
        let validationType = $el.attr('data-validation-type');
        if(required || (validationType && value))
            if(!isValidValue(value, validationType))
                errors[key] = true;
    });
    return errors;
};

export let isValidValue = (value, validationType, pattern) => {
    value = value && value.trim();
    if(!validationType || validationType === VALIDATION_TYPE.NON_EMPTY) return !!value;
    else if(validationType === VALIDATION_TYPE.NON_WHITE_SPACE) return !!value;
    else if(validationType === VALIDATION_TYPE.NUMBER) return validator.isNumeric(value);
    else if(validationType === VALIDATION_TYPE.EMAIL_ID) return validator.isEmail(value);
    else if(validationType === VALIDATION_TYPE.URL) return validator.isURL(value);
    else if(validationType === VALIDATION_TYPE.PATH) return !!value;
    else if(validationType === VALIDATION_TYPE.IP) return validator.isIP(value);
    else if(validationType === VALIDATION_TYPE.JSON) return isValidJSONString(value);
    else if(validationType === VALIDATION_TYPE.MOBILE_NO) return validator.isMobilePhone(value);
    else if(validationType === VALIDATION_TYPE.REG_EXPR) return new RegExp(pattern).test(value);
    // else if(validationType === VALIDATION_TYPE.DATE) return new Date(value)
    return true;
};

export const VALIDATION_TYPE = {
    NON_EMPTY: "non_empty",
    NON_WHITE_SPACE: "non_white_space",
    NUMBER: "number",
    EMAIL_ID: "email_id",
    URL: "url",
    PATH: "path",
    IP: "ip",
    MOBILE_NO: "mobile_no",
    // PASSWORD: "password",
    REG_EXPR: "reg_expr",
    JSON: "JSON"
    // DATE: "date",
    // TIME: "time",
    // DATE_TIME: "date_time"
};

export let getValueForPathOrDefault = (obj, path, def) => {
    if(!obj || !path) return def;
    const value = jsonpath.value(obj, "$." + path);
    return R.isNil(value) ? def : value;
};

export let getValueForPathSpecialCharsOrDefault = (obj, paths, def) => {
    if(!paths || !obj) return def;
    let arr = paths.split(".");
    let value = obj[arr[0]];
    return arr.length <= 1 ? (value || def) : getValueForPathSpecialCharsOrDefault(value, R.join(".", R.drop(1, arr)), def);
};

export let setValueForPathSpecialChars = (obj, paths, newValue) => {
    if(!paths || !obj) return obj;
    let arr = paths.split(".");
    if(arr.length <= 1) obj[arr[0]] = newValue;
    else setValueForPathSpecialChars(obj[arr[0]], R.join(".", R.drop(1, arr)), newValue);
    return obj;
};

export let getAllNodesForPath = (obj, path, limit = Number.MAX_SAFE_INTEGER) => {
    const nodes = jsonpath.nodes(obj, "$." + path, limit);
    return R.map(n => {
        return R.assoc('path', R.drop(1, n.path), n);
    }, nodes);
};

export let getAllPathsForObjectWithPath = (obj, path) => {
    const paths = jsonpath.paths(obj, "$." + path) || [];
    console.log("paths-> " + JSON.stringify(paths));
    return R.map(path => R.join(".", path), R.map(ps => R.drop(1, ps), paths));
};

export let getAllPathsAsArrayForObjectWithPath = (obj, path) => {
    const paths = jsonpath.paths(obj, "$." + path) || [];
    console.log("paths-> " + JSON.stringify(paths));
    return R.map(ps => R.drop(1, ps), paths);
};

export let setValueForPathOrDefault = (obj, path, value) => {
    return jsonpath.value(obj, "$." + path, value);
};

export let removePathForObject = (obj, path) => {
    return removePathsForObject(obj, path, 1)[0];
};

export let removePathsForObject = (obj, path, limit = Number.MAX_SAFE_INTEGER) => {
    const nodes = getAllNodesForPath(obj, path, limit);
    return R.map(n => {
        const parentPath = R.join(".", R.dropLast(1, n.path));
        const parent = getValueForPathOrDefault(obj, parentPath);
        if(parent) delete parent[R.tail(n.path)];
        return n.value;
    }, nodes);
};

export let convertToUrl = (url, protocol = window.location.protocol) => {
    if(!url || typeof(url) !== "string") return url;
    try {
        let parts = url.split("//");
        if(parts.length > 1) return url;
        else return protocol + "//" + url;
    } catch (e) {
        console.error("error while converting url: " + url);
        console.error(e);
    }
    return url;
};

export let convertToMailToUrl = (to, from, cc, bcc, subject, body, htmlBody = true) => {
    let data = {};
    data.from = from;
    to = typeof(to) === "string" ? to : R.join(",", (to || []));
    data.subject = encodeURIComponent(subject || "");
    data.cc = R.join(",", (cc || []));
    data.bcc = R.join(",", (bcc || []));
    let qs = R.join("&", R.filter(x => x, R.map(key => data[key] && `${key}=${data[key]}`, R.keys(data))));
    return `mailto:${to}${qs ? "?" : ""}${qs}`;
};

export let mapIndexed = (fn, elements) => {
    if(!elements || !fn) return elements;
    let index = 0;
    return R.map(elem => fn(elem, index++), elements);
};

export let reduceIndexed = (fn, initial, elements) => {
    if(!elements || !fn) return elements;
    let index = 0;
    return R.reduce((acc, elem) => fn(acc, elem, index++), initial, elements);
};

export let flatMap = (fn, elements) => {
    if(!elements || !fn) return elements;
    let newElements = [];
    R.forEach(elem => {
        newElements = R.concat(newElements, fn(elem));
    }, elements);
    return newElements;
};

export const mapNotEmpty = (fn, elements) => {
    return R.reduce((acc, elem) => {
        const out = elem && fn(elem);
        if(out) acc.push(out);
        return acc;
    }, [], elements);
};

export let isValidJSONString = (json) => {
    try {
        JSON.parse(json);
        return true;
    }
    catch(e) {
        return false;
    }
};